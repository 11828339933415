/** @format */

export const AlevelMathsAQA = {
	1: {
		topic: "Proof",
		code: "AlevelMathsAQA",
		name: "A-level Maths AQA",
		subject: "Maths",
		board: "AQA",
		level: 'A-level',
		subs: {
			1.1: {
				"sub-topic": "Part 1",
				spec: {
					1.11: "Understand and use the structure of mathematical proof, proceeding from given assumptions through a series of logical steps to a conclusion; use methods of proof, including proof by deduction, proof by exhaustion.",
					1.12: "Disproof by counter example.",
					1.13: "Proof by contradiction (including proof of the irrationality of √2 and the infinity of primes, and application to unfamiliar proofs).",
				},
			},
		},
	},
	2: {
		topic: "Algebra and functions",
		subs: {
			2.1: {
				"sub-topic": "Differentiation",
				spec: {
					2.11: "Understand and use the laws of indices for all rational exponents",
					2.12: "Use and manipulate surds, including rationalising the denominator.",
					2.13: "Work with quadratic functions and their graphs; the discriminant of a quadratic function, including the conditions for real and repeated roots; completing the square; solution of quadratic equations including solving quadratic equations in a function of the unknown.",
					2.14: "Solve simultaneous equations in two variables by elimination and by substitution, including one linear and one quadratic equation.",
					2.15: "Solve linear and quadratic inequalities in a single variable and interpret such inequalities graphically, including inequalities with brackets and fractions.",
					2.16: "Express solutions through correct use of 'and' and 'or', or through set notation.",
					2.17: "Represent linear and quadratic inequalities such as y > x + 1 and y > ax^2 + bx + c graphically.",
				},
			},
			2.2: {
				"sub-topic": "Integrations",
				spec: {
					2.20: "Manipulate polynomials algebraically, including expanding brackets and collecting like terms, factorisation and simple algebraic division; use of the factor theorem.",
					2.21: "Simplify rational expressions including by factorising and cancelling, and algebraic division (by linear expressions only).",
					2.22: "Understand and use graphs of functions; sketch curves defined by simple equations including polynomials, the modulus of a linear function, y=ax and y=ax^2",
					2.23: "Interpret algebraic solution of equations graphically; use intersection points of graphs to solve equations.",
					2.24: "Understand and use proportional relationships and their graphs.",
					2.25: "Understand and use composite functions; inverse functions and their graphs.", 
					2.26: "Understand the effect of simple transformations on the graph of y = f x including sketching associated graphs:y = af (x) , y = f (x) + a , y = f (x + a) , y = f (ax) , and combinations of thesetransformations.",
					2.27: "Decompose rational functions into partial fractions (denominators not more complicated than squared linear terms and with no more than 3 terms, numerators constant or linear).",
					2.28: "Use of functions in modelling, including consideration of limitations and refinements of the models."

				}
				
			}
		},
	},
	3: {
		topic: "Coordinate geometry in the (x, y) plane",
		subs: {
			3.1: {
				"sub-topic": "Part 1",
				spec: {
					3.11: "Understand and use the equation of a straight line, including the forms y − y1 = m x − x1 and ax + by + c = 0 ; gradient conditions for two straight lines to be parallel or perpendicular.",
					3.12: "Understand and use the coordinate geometry of the circle including using the equation of a circle in the form x − a 2 + y − b 2 = r2; completing the square to find the centre and radius of a circle; use of the following properties: • the angle in a semicircle is a right angle • the perpendicular from the centre to a chord bisects the chord • the radius of a circle at a given point on its circumference is perpendicular to the tangent to the circle at that point.", 
					3.13: "Understand and use the parametric equations of curves and conversion between Cartesian and parametric forms.", 
					3.14: "Use parametric equations in modelling in a variety of contexts."

				}
			}

		}
	},
	4: {
		topic: "Sequences and series",
		subs: {
			4.1: {
				"sub-topic": "Part 1",
				spec: {
					4.11: "Understand and use the binomial expansion of (a + bx)^ n for positive integer n ; the notations n! , nCr and nr ; link to binomial probabilities.", 
					4.12: "Extend to any rational n , including its use for approximation; be aware that the expansion is valid for bx a < 1 . (Proof not required.)", 
					4.13: "Work with sequences including those given by a formula for the n th term and those generated by a simple relation of the form xn + 1	= f xn; increasing sequences; decreasing sequences; periodic sequences.", 
					4.14: "Understand and use sigma notation for sums of series.", 
					4.15: "Understand and work with arithmetic sequences and series, including the formulae for n th term and the sum to n terms.", 
					4.16: "Understand and work with geometric sequences and series including the formulae for the n th term and the sum of a finite geometric series; the sum to infinity of a convergent geometric series, including the use of r< 1 ; modulus notation", 
					4.17: "Use sequences and series in modelling."

				}
			}

		}
	}, 
	5: {
		topic: "Trigonometry",
		subs: {
			5.1: {
				"sub-topic": "Part 1",
				spec: {
					5.11: "Understand and use the definitions of sine, cosine and tangent for all arguments; the sine and cosine rules; the area of a triangle in the form 1/2 absinC", 
					5.12: "Work with radian measure, including use for arc length and area of sector.", 
					5.13: "Understand and use the standard small angle approximations of sine, cosine and tangent sin � ≈ �, cos � ≈ 1 − � ^2/2, tan � ≈ � where � is in radians.", 
					5.14: "Understand and use the sine, cosine and tangent functions; their graphs, symmetries and periodicity.", 
					5.15: "Know and use exact values of sin and cos for 0, π/6, π/4, π/3, π/2, π and multiples thereof, and exact values of tan for 0, π/6, π/4, π/3, π and multiples thereof.", 
					5.16: "Understand and use the definitions of secant, cosecant and cotangent and of arcsin, arccos and arctan; their relationships to sine, cosine and tangent; understanding of their graphs; their ranges and domains.", 

				}
			},
			5.2: {
				"sub-topic": "Part 2",
				spec: {
					5.21: "Understand and use tan � ≡ sin�/cos�", 
					5.22: "Understand and use sin2 � + cos2 � ≡ 1 ; sec2 � ≡ 1 + tan2 � and cosec2 � ≡ 1 + cot2 �", 
					5.23: "Understand and use double angle formulae; use of formulae for sin A ± B , cos A ± B and tan A ± B ; understand geometrical proofs of these formulae.", 
					5.24: "Understand and use expressions for acos � + bsin � in the equivalent forms of rcos � ± � or rsin � ± �", 
					5.25: "Solve simple trigonometric equations in a given interval, including quadratic equations in sin, cos and tan and equations involving multiples of the unknown angle.", 
					5.26 : "Construct proofs involving trigonometric functions and identities.", 
					5.27: "Use trigonometric functions to solve problems in context, including problems involving vectors, kinematics and forces."

				}
			}

		}
	}, 
	6: {
		topic: "Exponentials and logarithms", 
		subs: {
			6.1: {
				"sub-topic": "Part 1", 
				spec: {
					6.11: "Know and use the function ax and its graph, where a is positive.", 
					6.12: "Know and use the function ex and its graph.", 
					6.13: "Know that the gradient of ekx is equal to kekx and hence understand why the exponential model is suitable in many applications.", 
					6.14: "Know and use the definition of logax as the inverse of ax , where a is positive and x≥0", 
					6.15: "Know and use the function lnx and its graph.", 
					6.16: "Know and use lnx as the inverse function of ex", 
					6.17: "Understand and use the laws of logarithms: log ax + log ay ≡ log axy ; logax − logay ≡ logaxy; klogax ≡ logax k(including, for example, k = − 1 and k = − 1/2)", 
					6.18: "Solve equations of the form ax=b", 
					6.19: "Use logarithmic graphs to estimate parameters in relationships of the form y = ax n and y = kbx, given data for x and y .", 
					6.20: "Understand and use exponential growth and decay; use in modelling (examples may include the use of e in continuous compound interest, radioactive decay, drug concentration decay, exponential growth as a model for population growth); consideration of limitations and refinements of exponential models."
				}
			}
			
		}

	}, 
	7: {
		topic: "Differentiation", 
		subs: {
			7.1: {
				"sub-topic": "Part 1", 
				spec: {
					7.11: "Understand and use the derivative of fx as the gradient of the tangent to the graph of y = fx at a general point ( x , y ); the gradient of the tangent as a limit; interpretation as a rate of change; sketching the gradient function for a given curve; second derivatives; differentiation from first principles for small positive integer powers of x and for sin x and cos x", 
					7.12: "Understand and use the second derivative as the rate of change of gradient; connection to convex and concave sections of curves and points of inflection.", 
					7.13: "Differentiate xn , for rational values of n , and related constant multiples, sums and differences.", 
					7.14: "Differentiate ekx and akx , sinkx , coskx , tankx and related sums, differences and constant multiples.", 
					7.15: "Understand and use the derivative of lnx", 
					7.16: "Apply differentiation to find gradients, tangents and normals, maxima and minima and stationary points, points of inflection.", 
					7.17: "Identify where functions are increasing or decreasing.",
					7.18: " Differentiate using the product rule, the quotient rule and the chain rule, including problems involving connected rates of change and inverse functions.", 
					7.19: "Differentiate simple functions and relations defined implicitly or parametrically, for first derivative only.", 
					7.20: "Construct simple differential equations in pure mathematics and in context, (contexts may include kinematics, population growth and modelling the relationship between price and demand)."
				}
			}
			
			
		}
	}, 
	8: {
		topic: "Integration",
		subs: {
			 8.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 8.11: "Know and use the Fundamental Theorem of Calculus.", 
					 8.12: "Integrate xn (excluding n = −1 ), and related sums, differences and constant multiples.", 
					 8.13: "Integrate ekx , 1x , sinkx , coskx and related sums, differences and constant multiples.", 
					 8.14: "Evaluate definite integrals; use a definite integral to find the area under a curve and the area between two curves.", 
					 8.15: "Understand and use integration as the limit of a sum.",
					 8.16: "Carry out simple cases of integration by substitution and integration by parts; understand these methods as the inverse processes of the chain and product rules respectively.", 
					 8.17: "Integrate using partial fractions that are linear in the denominator.", 
					 8.18: "Evaluate the analytical solution of simple first order differential equations with separable variables, including finding particular solutions (Separation of variables may require factorisation involving a common factor).", 
					 8.19: "Interpret the solution of a differential equation in the context of solving a problem, including identifying limitations of the solution; includes links to kinematics"

				 }
			 }
		}
	},
	9: {
		topic: "Numerical Methods",
		subs: {
			 9.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 9.11: "Locate roots of fx = 0 by considering changes of sign of fx in an interval of xon which fx is sufficiently well-behaved. Understand how change of sign methods can fail", 
					 9.12: "Solve equations approximately using simple iterative methods; be able to draw associated cobweb and staircase diagrams.", 
					 9.13: "Solve equations using the Newton-Raphson method and other recurrence relations of the form xn + 1 = g xn Understand how such methods can fail.",
					 9.14: "Understand and use numerical integration of functions, including the use of the trapezium rule and estimating the approximate area under a curve and limits that it must lie between.",
					 9.15: "Use numerical methods to solve problems in context."
					 
				 }
			 }
		}
	},
	10: {
		topic: "Vectors",
		subs: {
			 10.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 10.11: "Use vectors in two dimensions and in three dimensions.",
					 10.12: "Calculate the magnitude and direction of a vector and convert between component form and magnitude/direction form.",
					 10.13: "Add vectors diagrammatically and perform the algebraic operations of vector addition and multiplication by scalars, and understand their geometrical interpretations.",
					 10.14: "Understand and use position vectors; calculate the distance between two points represented by position vectors.",
					 10.15: "Use vectors to solve problems in pure mathematics and in context, including forces and kinematics."
					 
				 }
			 }
		}
	},
	11: {
		topic: "Statistical sampling",
		subs: {
			 11.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 11.11: "Understand and use the terms 'population' and 'sample'.",
					 11.12: "Use samples to make informal inferences about the population.",
					 11.13: "Understand and use sampling techniques, including simple random sampling and opportunity sampling.",
					 11.14: "Select or critique sampling techniques in the context of solving a statistical problem, including understanding that different samples can lead to different conclusions about the population."
					 
				 }
			 }
		}
	},
	12: {
		topic: "Data presentation and interpretation",
		subs: {
			 12.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 12.11: "Interpret diagrams for single-variable data, including understanding that area in a histogram represents frequency. Connect to probability distributions.",
					 12.12: " Interpret scatter diagrams and regression lines for bivariate data, including recognition of scatter diagrams which include distinct sections of the population(calculations involving regression lines are excluded).",
					 12.13: "Understand informal interpretation of correlation.",
					 12.14: "Interpret measures of central tendency and variation, extending to standard deviation.",
					 12.15: "Be able to calculate standard deviation, including from summary statistics.",
					 12.16: "Recognise and interpret possible outliers in data sets and statistical diagrams.",
					 12.17: "Select or critique data presentation techniques in the context of a statistical problem.",
					 12.18: "Be able to clean data, including dealing with missing data, errors and outliers."
					 
				 }
			 }
		}
	},
	13: {
		topic: "Probability",
		subs: {
			 13.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 13.11: "Understand and use mutually exclusive and independent events when calculating probabilities. Link to discrete and continuous distributions.",
					 13.12: "Understand and use conditional probability, including the use of tree diagrams, Venn diagrams, two-way tables.",
					 13.13: "Understand and use the conditional probability formula. P A B = P A ∩ B/ P B", 
					 13.14: "Modelling with probability, including critiquing assumptions made and the likely effect of more realistic assumptions."
					 
				 }
			 }
		}
	},
	14: {
		topic: "Statistical distributions",
		subs: {
			 14.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 14.11: "Understand and use simple, discrete probability distributions (calculation of mean and variance of discrete random variables is excluded), including the binomial distribution, as a model; calculate probabilities using the binomial distribution.",
					 14.12: "Understand and use the Normal distribution as a model; find probabilities using the Normal distribution.",
					 14.13: "Link to histograms, mean, standard deviation, points of inflection and the binomial distribution.",
					 14.14: "Select an appropriate probability distribution for a context, with appropriate reasoning, including recognising when the binomial or Normal model may not be appropriate."
					 
				 }
			 }
		}
	},
	15: {
		topic: "Statistical hypothesis testing",
		subs: {
			 15.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 15.11: "Understand and apply the language of statistical hypothesis testing, developed through a binomial model: null hypothesis, alternative hypothesis, significance level, test statistic, 1-tail test, 2-tail test, critical value, critical region, acceptance region, p -value; extend to correlation coefficients as measures of how close data points lie to a straight line and be able to interpret a given correlation coefficient using a given p -value or critical value (calculation of correlation coefficients is excluded).", 
					 15.12: "Conduct a statistical hypothesis test for the proportion in the binomial distribution and interpret the results in context.",
					 15.13: "Understand that a sample is being used to make an inference about the population and appreciate that the significance level is the probability of incorrectly rejecting the null hypothesis.",
					 15.14: "Conduct a statistical hypothesis test for the mean of a Normal distribution with known, given or assumed variance and interpret the results in context."
					 
				 }
			 }
		}
	},
	16: {
		topic: "Quantities and units in mechanics",
		subs: {
			 16.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 16.11: "Understand and use fundamental quantities and units in the SI system: length, time, mass.",
					 16.12: "Understand and use derived quantities and units: velocity, acceleration, force, weight, moment.",
				 }
			 }
		}
	},
	17: {
		topic: "Kinematics",
		subs: {
			 17.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 17.11: "Understand and use the language of kinematics: position; displacement; distance travelled; velocity; speed; acceleration.",
					 17.12: "Understand, use and interpret graphs in kinematics for motion in a straight line: displacement against time and interpretation of gradient; velocity against time and interpretation of gradient and area under the graph.",
					 17.13: "Understand, use and derive the formulae for constant acceleration for motion in a straight line; extend to 2 dimensions using vectors.",
					 17.14: "Use calculus in kinematics for motion in a straight line: v = dr/dt, a = dv/dt = d2r/dt2, r = ∫v dt , v = ∫a dt ; extend to 2 dimensions using vectors.",
					 17.15: "Model motion under gravity in a vertical plane using vectors; projectiles."
					 
				 }
			 }
		}
	},
	18: {
		topic: "Forces and Newton's laws",
		subs: {
			 18.1: {
				 "sub-topic" : "Part 1", 
				 spec: {
					 18.11: "Understand the concept of a force; understand and use Newton's first law.",
					 18.12: "Understand and use Newton’s second law for motion in a straight line (restricted to forces in two perpendicular directions or simple cases of forces given as 2D vectors); extend to situations where forces need to be resolved (restricted to 2 dimensions).",
					 18.13: "Understand and use weight and motion in a straight line under gravity; gravitational acceleration, g , and its value in SI units to varying degrees of accuracy.",
					 18.14: "Understand and use Newton’s third law; equilibrium of forces on a particle and motion in a straight line (restricted to forces in two perpendicular directions or simple cases of forces given as 2D vectors); application to problems involving smooth pulleys and connected particles; resolving forces in 2 dimensions; equilibrium of a particle under coplanar forces.",
					 18.15: "Understand and use addition of forces; resultant forces; dynamics for motion in a plane.",
					 18.16: "Understand and use the F ≤ �R model for friction; coefficient of friction; motion of a body on a rough surface; limiting friction and statics."
					 
				 }
			 }
		}
	},
	19: {
		topic: "Moments",
		subs: {
			19.1: {
				"sub-topic": "Part 1", 
				spec: {
					19.11: "Understand and use moments in simple static contexts."
				}
			}
		}
	}
	
};

