/** @format */

export const AlevelPhysicsAQA = {
	1: {
		topic: "Measurements and their errors",
		nested: true,
		topicQuestions: "",
		specLink: "",
		code: "AlevelPhysicsAQA",
		name: "Alevel Physics AQA",
		subject: "Physics",
		board: "AQA",
		level: "A-level",
		subs: {
			1.1: {
				"sub-topic": "Measurements and their errors",
				spec: {
					1.11: {
						"sub-topic": "Use of SI units and their prefixes",
						spec: {
							1.111: "Fundamental (base) units.",
							1.112:
								"Use of mass, length, time, amount of substance, temperature, electric current and their associated SI units. ",
							1.113: "SI units derived.",
							1.114:
								" Knowledge and use of the SI prefixes, values and standard form. ",
							1.115:
								"The fundamental unit of light intensity, the candela, is excluded.",
							1.116:
								" Students are not expected to recall definitions of the fundamental quantities. ",
							1.117: "Dimensional analysis is not required. ",
							1.118:
								"Students should be able to use the prefixes: T,G, M, k, c, m, μ, n, p, f, ",
							1.119:
								"Students should be able to convert between different units of the same quantity, eg J and eV, J and kW h.",
						},
					},
					1.12: {
						"sub-topic": "Limitation of physical measurements",
						spec: {
							1.121: "Random and systematic errors. ",
							1.122:
								"Precision, repeatability, reproducibility, resolution and accuracy. ",
							1.123:
								"Uncertainty: \n Absolute, fractional and percentage uncertainties represent uncertainty in the final answer for a quantity. ",
							1.124: "Combination of absolute and percentage uncertainties. ",
							1.125:
								"Represent uncertainty in a data point on a graph using error bars. ",
							1.126:
								"Determine the uncertainties in the gradient and intercept of a straight-line graph. ",
							1.127:
								"Individual points on the graph may or may not have associated error bars.",
						},
					},
					1.13: {
						"sub-topic": "Estimation of physical quantities",
						spec: {
							1.131: "Orders of magnitude. ",
							1.132: "Estimation of approximate values of physical quantities.",
						},
					},
				},
			},
		},
	},
	2: {
		topic: "Particles and radiation",
		topicQuestions: "",
		subs: {
			2.1: {
				"sub-topic": "Particles",
				spec: {
					2.11: {
						"sub-topic": "Constituents of the atom",
						spec: {
							2.111:
								"Simple model of the atom, including the proton, neutron and electron. Charge and mass of the proton, neutron and electron in SI units and relative units. ",
							2.112:
								"The atomic mass unit (amu) is included in the A-level Nuclear physics section. ",
							2.113:
								"Specific charge of the proton and the electron, and of nuclei and ions. ",
							2.114: "Proton number Z, nucleon number A, nuclide notation.",
							2.115: " Students should be familiar with the notation. ",
							2.116: "Meaning of isotopes and the use of isotopic data.",
						},
					},
					2.12: {
						"sub-topic": "Stable and unstable nuclei",
						spec: {
							2.121:
								"The strong nuclear force; its role in keeping the nucleus stable; short-range attraction up to approximately 3 fm, very-short range repulsion closer than approximately 0.5 fm.",
							2.122: " Unstable nuclei; alpha and beta decay. ",
							2.123:
								"Equations for alpha decay, β− decay including the need for the neutrino. ",
							2.124:
								"The existence of the neutrino was hypothesised to account for conservation of energy in beta decay.",
						},
					},
					2.13: {
						"sub-topic": "Particles, antiparticles and photons",
						spec: {
							2.131:
								"For every type of particle, there is a corresponding antiparticle. ",
							2.132:
								"Comparison of particle and antiparticle masses, charge and rest energy in MeV.",
							2.134:
								" Students should know that the positron, antiproton, antineutron and antineutrino are the antiparticles of the electron, proton, neutron and neutrino respectively. ",
							2.135:
								"Photon model of electromagnetic radiation, the Planck constant.",
							2.136: "Formula 3.2.1.3",
							2.137:
								" Knowledge of annihilation and pair production and the energies involved. ",
							2.138: "The use of E = mc2 is not required in calculations",
						},
					},
					2.14: {
						"sub-topic": "Particle interactions",
						spec: {
							2.141:
								"Four fundamental interactions: gravity, electromagnetic, weak nuclear, strong nuclear. (The strong nuclear force may be referred to as the strong interaction.) ",
							2.142:
								"The concept of exchange particles to explain forces between elementary particles. ",
							2.143:
								"Knowledge of the gluon, Z 0 and graviton will not be tested. ",
							2.144:
								"The electromagnetic force; virtual photons as the exchange particle.",
							2.145:
								" The weak interaction limited to β−and β + decay, electron capture and electron–proton collisions; W + and W − as the exchange particles. ",
							2.146:
								"Simple diagrams to represent the above reactions or interactions in terms of incoming and outgoing particles and exchange particles.",
						},
					},
					2.15: {
						"sub-topic": "Classification of particles",
						spec: {
							2.151: "Hadrons are subject to the strong interaction.",
							2.152:
								" The two classes of hadrons:\n • baryons (proton, neutron) and antibaryons (antiproton and antineutron)\n • mesons (pion, kaon).",
							2.153: "Baryon number as a quantum number. ",
							2.154: "Conservation of baryon number. ",
							2.155:
								"The proton is the only stable baryon into which other baryons eventually decay.",
							2.156:
								" The pion as the exchange particle of the strong nuclear force. ",
							2.157: "The kaon as a particle that can decay into pions.",
							2.158:
								" Leptons: electron, muon, neutrino (electron and muon types only) and their antiparticles. ",
							2.159:
								"Lepton number as a quantum number; conservation of lepton number for muon leptons and for electron leptons. ",
							2.151: "The muon as a particle that decays into an electron. ",
							2.1511: "Strange particles ",
							2.1512:
								"Strange particles as particles that are produced through the strong interaction and decay through the weak interaction (eg kaons).",
							2.1513:
								"Strangeness (symbol s) as a quantum number to reflect the fact that strange particles are always created in pairs. ",
							2.1514: "Conservation of strangeness in strong interactions. ",
							2.1515:
								"Strangeness can change by 0, +1 or -1 in weak interactions.",
							2.1516:
								" Appreciation that particle physics relies on the collaborative efforts of large teams of scientists and engineers to validate new knowledge.",
						},
					},
					2.16: {
						"sub-topic": "Quarks and antiquarks",
						spec: {
							2.161:
								"Properties of quarks and antiquarks: charge, baryon number and strangeness. ",
							2.162:
								"Combinations of quarks and antiquarks required for baryons (proton and neutron only), antibaryons (antiproton and antineutron only) and mesons (pion and kaon only).",
							2.163:
								" Only knowledge of up (u), down (d) and strange (s) quarks and their antiquarks will be tested. ",
							2.164: "The decay of the neutron should be known.",
						},
					},
					2.17: {
						"sub-topic": "Applications of conservation laws",
						spec: {
							2.171: "Change of quark character in β−and in β + decay.",
							2.172:
								" Application of the conservation laws for charge, baryon number, lepton number and strangeness to particle interactions. The necessary data will be provided in questions for particles outside those specified.",
							2.173:
								" Students should recognise that energy and momentum are conserved in interactions.",
						},
					},
				},
			},
			2.2: {
				"sub-topic": "Electromagnetic radiation and quantum phenomena",
				spec: {
					2.21: {
						"sub-topic": "The photoelectric effect",
						spec: {
							2.211:
								"Threshold frequency; photon explanation of threshold frequency. ",
							2.212: "Work function ϕ, stopping potential.",
							2.213: " Photoelectric equation: h f = ϕ + Ek (max) ",
							2.214:
								"Ek (max) is the maximum kinetic energy of the photoelectrons. ",
							2.215:
								"The experimental determination of stopping potential is not required.",
						},
					},
					2.22: {
						"sub-topic": "Collisions of electrons with atoms",
						spec: {
							2.221:
								"Ionisation and excitation; understanding of ionisation and excitation in the fluorescent tube.",
							2.222: " The electron volt.",
							2.223:
								" Students will be expected to be able to convert eV into J and vice versa.",
						},
					},
					2.23: {
						"sub-topic": "Energy levels and photon emission",
						spec: {
							2.231:
								"Line spectra (eg of atomic hydrogen) as evidence for transitions between discrete energy levels in atoms.",
							2.232: " h f = E1 − E2",
							2.233: " In questions, energy levels may be quoted in J or eV.",
						},
					},
					2.24: {
						"sub-topic": "Wave-particle duality",
						spec: {
							2.241:
								"Students should know that electron diffraction suggests that particles possess wave properties and the photoelectric effect suggests that electromagnetic waves have a particulate nature. ",
							2.242:
								"Details of particular methods of particle diffraction are not expected.",
							2.243:
								" de Broglie wavelength λ = h/mv where mv is the momentum.",
							2.244:
								"Students should be able to explain how and why the amount of diffraction changes when the momentum of the particle is changed.",
							2.245:
								" Appreciation of how knowledge and understanding of the nature of matter changes over time. ",
							2.246:
								"Appreciation that such changes need to be evaluated through peer review and validated by the scientific community",
						},
					},
				},
			},
		},
	},
	3: {
		topic: "Waves",
		topicQuestions: "",
		subs: {
			3.1: {
				"sub-topic": "Progressive and stationary waves",
				spec: {
					3.11: {
						"sub-topic": "Progressive waves",
						spec: {
							3.111: "Oscillation of the particles of the medium; ",
							3.112:
								"amplitude, frequency, wavelength, speed, phase, phase difference, c = f λ , f =1/T ",
							3.113:
								"Phase difference may be measured as angles (radians and degrees) or as fractions of a cycle.",
						},
					},
					3.12: {
						"sub-topic": "Longitudinal and transverse waves",
						spec: {
							3.121: "Nature of longitudinal and transverse waves.",
							3.122:
								" Examples to include: sound, electromagnetic waves, and waves on a string. ",
							3.123:
								"Students will be expected to know the direction of displacement of particles/fields relative to the direction of energy propagation and that all electromagnetic waves travel at the same speed in a vacuum. ",
							3.124:
								"Polarisation as evidence for the nature of transverse waves.",
							3.125:
								" Applications of polarisers to include Polaroid material and the alignment of aerials for transmission and reception. ",
							3.126: "Malus’s law will not be expected.",
						},
					},
					3.13: {
						"sub-topic":
							"Principle of superposition of waves and formation of stationary waves",
						spec: {
							3.131: "Stationary waves. ",
							3.132: "Nodes and antinodes on strings. ",
							3.133:
								"The formation of stationary waves by two waves of the same frequency travelling in opposite directions. ",
							3.134: "formula 3.3.1.3",
							3.135:
								"A graphical explanation of formation of stationary waves will be expected.",
							3.136:
								" Stationary waves formed on a string and those produced with microwaves and sound waves should be considered.",
							3.137:
								" Stationary waves on strings will be described in terms of harmonics. The terms fundamental (for first harmonic) and overtone will not be used.",
						},
					},
				},
			},
			3.2: {
				"sub-topic": "Refraction, diffraction and interference",
				spec: {
					3.21: {
						"sub-topic": "Interference",
						spec: {
							3.211: "Path difference. Coherence. ",
							3.212:
								"Interference and diffraction using a laser as a source of monochromatic light. ",
							3.213:
								"Young’s double-slit experiment: the use of two coherent sources or the use of a single source with double slits to produce an interference pattern. ",
							3.214: "Fringe spacing, w = λD/s ",
							3.215: "Production of interference pattern using white light. ",
							3.216:
								"Students are expected to show awareness of safety issues associated with using lasers.",
							3.217:
								" Students will not be required to describe how a laser works. ",
							3.218:
								"Students will be expected to describe and explain interference produced with sound and electromagnetic waves.",
							3.219:
								" Appreciation of how knowledge and understanding of nature of electromagnetic radiation has changed over time.",
						},
					},
					3.22: {
						"sub-topic": "Diffraction",
						spec: {
							3.221:
								"Appearance of the diffraction pattern from a single slit using monochromatic and white light. ",
							3.222:
								"Qualitative treatment of the variation of the width of the central diffraction maximum with wavelength and slit width. The graph of intensity against angular separation is not required. ",
							3.223:
								"Plane transmission diffraction grating at normal incidence. ",
							3.224: "Derivation of dsinθ = nλ  ",
							3.225: "Use of the spectrometer will not be tested.",
							3.226: "Applications of diffraction gratings.",
						},
					},
					3.23: {
						"sub-topic": "Refraction at a plane surface",
						spec: {
							3.231: "Refractive index of a substance, n = c/cs",
							3.232:
								" Students should recall that the refractive index of air is approximately 1. ",
							3.233:
								"Snell’s law of refraction for a boundary n1sin θ1 = n2sin θ2 ",
							3.234: "Total internal reflection sin θc = n2/n1 ",
							3.235:
								"Simple treatment of fibre optics including the function of the cladding. ",
							3.236: "Optical fibres will be limited to step index only. ",
							3.237: "Material and modal dispersion. ",
							3.238:
								"Students are expected to understand the principles and consequences of pulse broadening and absorption.",
						},
					},
				},
			},
		},
	},
	4: {
		topic: "Mechanics and materials",
		topicQuestions: "",
		subs: {
			4.1: {
				"sub-topic": "Force, energy and momentum",
				spec: {
					4.11: {
						"sub-topic": "Scalars and vectors",
						spec: {
							4.111: "Nature of scalars and vectors.",
							4.112:
								" Examples should include:  \n velocity/speed, mass, force/weight, acceleration, displacement/distance. ",
							4.113: "Addition of vectors by calculation or scale drawing.",
							4.114:
								" Calculations will be limited to two vectors at right angles. Scale drawings may involve vectors at angles other than 90°. ",
							4.115:
								"Resolution of vectors into two components at right angles to each other.",
							4.116:
								" Examples should include components of forces along and perpendicular to an inclined plane. ",
							4.117:
								"Problems may be solved either by the use of resolved forces or the use of a closed triangle. ",
							4.118:
								"Conditions for equilibrium for two or three coplanar forces acting at a point. Appreciation of the meaning of equilibrium in the context of an object at rest or moving with constant velocity.",
						},
					},
					4.12: {
						"sub-topic": "Moments",
						spec: {
							4.121: "Moment of a force about a point. ",
							4.122:
								"Moment defined as force × perpendicular distance from the point to the line of action of the force. ",
							4.123: "Couple as a pair of equal and opposite coplanar forces.",
							4.124:
								"Moment of couple defined as force × perpendicular distance between the lines of action of the forces. ",
							4.125: "Principle of moments.",
							4.126: " Centre of mass.",
							4.127:
								" Knowledge that the position of the centre of mass of uniform regular solid is at its centre.",
						},
					},
					4.13: {
						"sub-topic": "Motion along a straight line",
						spec: {
							4.131: "Displacement, speed, velocity, acceleration. \n 3.4.1.3",
							4.132:
								"Calculations may include average and instantaneous speeds and velocities. ",
							4.133:
								"Representation by graphical methods of uniform and non-uniform acceleration. ",
							4.134:
								"Significance of areas of velocity–time and acceleration– time graphs and gradients of displacement–time and velocity–time graphs for uniform and non-uniform acceleration eg graphs for motion of bouncing ball.",
							4.135: " Equations for uniform acceleration : \n 3.4.1.3 ",
						},
					},
					4.14: {
						"sub-topic": "Projectile motion",
						spec: {
							4.141:
								"Independent effect of motion in horizontal and vertical directions of a uniform gravitational field. Problems will be solvable using the equations of uniform acceleration.",
							4.142: "Qualitative treatment of friction.",
							4.143:
								"Distinctions between static and dynamic friction will not be tested. ",
							4.144: "Qualitative treatment of lift and drag forces. ",
							4.145: "Terminal speed.",
							4.146: " Knowledge that air resistance increases with speed. ",
							4.147:
								"Qualitative understanding of the effect of air resistance on the trajectory of a projectile and on the factors that affect the maximum speed of a vehicle.",
						},
					},
					4.15: {
						"sub-topic": "Newton’s laws of motion",
						spec: {
							4.151:
								"Knowledge and application of the three laws of motion in appropriate situations. ",
							4.152: "F = ma for situations where the mass is constant.",
						},
					},
					4.16: {
						"sub-topic": "Momentum",
						spec: {
							4.161: "momentum = mass × velocity",
							4.162: " Conservation of linear momentum. ",
							4.163:
								"Principle applied quantitatively to problems in one dimension. ",
							4.164: "Force as the rate of change of momentum, F = ∆ mv/∆ t",
							4.165: " Impulse = change in momentum",
							4.166: " F∆t = ∆ mv , where F is constant.",
							4.167: " Significance of the area under a force–time graph. ",
							4.168:
								"Quantitative questions may be set on forces that vary with time. Impact forces are related to contact times (eg kicking a football, crumple zones, packaging)",
							4.169: "Elastic and inelastic collisions; explosions.",
							4.161:
								" Appreciation of momentum conservation issues in the context of ethical transport design.",
						},
					},
					4.17: {
						"sub-topic": "Work, energy and power",
						spec: {
							4.171: "Energy transferred, W = Fscos θ",
							4.172:
								" rate of doing work = rate of energy transfer, P = ∆ W/ ∆ t = Fv ",
							4.173: "Quantitative questions may be set on variable forces. ",
							4.174:
								"Significance of the area under a force–displacement graph.",
							4.175: " efficiency = useful output power /input power ",
							4.176: "Efficiency can be expressed as a percentage.",
						},
					},
					4.18: {
						"sub-topic": "Conservation of energy",
						spec: {
							4.181: "Principle of conservation of energy.",
							4.182: "Formula 3.4.1.8",
							4.183:
								" Quantitative and qualitative application of energy conservation to examples involving gravitational potential energy, kinetic energy, and work done against resistive forces.",
						},
					},
				},
			},
			4.2: {
				"sub-topic": "Materials",
				spec: {
					4.21: {
						"sub-topic": "Bulk properties of solids",
						spec: {
							4.211: "Density, ρ = m/ V ",
							4.212: "Hooke’s law, elastic limit,",
							4.213: " F = k∆L , k as stiffness and spring constant. ",
							4.214:
								"Tensile strain and tensile stress. Elastic strain energy, breaking stress. ",
							4.215: " formula 3.4.2.1",
							4.216:
								"Description of plastic behaviour, fracture and brittle behaviour linked to force–extension graphs. ",
							4.217:
								"Quantitative and qualitative application of energy conservation to examples involving elastic strain energy and energy to deform. ",
							4.218:
								"Spring energy transformed to kinetic and gravitational potential energy. ",
							4.219: "Interpretation of simple stress–strain curves. ",
							4.211:
								"Appreciation of energy conservation issues in the context of ethical transport design.",
						},
					},
					4.22: {
						"sub-topic": "The Young modulus",
						spec: {
							4.221: "formula 3.4.2.2",
							4.222:
								"Use of stress–strain graphs to find the Young modulus. (One simple method of measurement is required.)",
						},
					},
				},
			},
		},
	},
	5: {
		topic: "Electricity",
		topicQuestions: "",
		subs: {
			5.1: {
				"sub-topic": "Current electricity",
				spec: {
					5.11: {
						"sub-topic": "Basics of electricity",
						spec: {
							5.111:
								"Electric current as the rate of flow of charge; potential difference as work done per unit charge. ",
							5.112: "formula 3.5.1.1",
							5.113: "Resistance defined as R =V/I",
						},
					},
					5.12: {
						"sub-topic": "Current–voltage characteristics",
						spec: {
							5.121:
								"For an ohmic conductor, semiconductor diode, and filament lamp. ",
							5.122:
								"Ohm’s law as a special case where I ∝ V under constant physical conditions.",
							5.123:
								" Unless specifically stated in questions, ammeters and voltmeters should be treated as ideal (having zero and infinite resistance respectively). ",
							5.124:
								"Questions can be set where either I or V is on the horizontal axis of the characteristic graph.",
						},
					},
					5.13: {
						"sub-topic": "Resistivity",
						spec: {
							5.131: "Resistivity, ρ = RA/L",
							5.132:
								" Description of the qualitative effect of temperature on the resistance of metal conductors and thermistors. ",
							5.133:
								"Only negative temperature coefficient (ntc) thermistors will be considered. ",
							5.134:
								"Applications of thermistors to include temperature sensors and resistance–temperature graphs.",
							5.135:
								" Superconductivity as a property of certain materials which have zero resistivity at and below a critical temperature which depends on the material. ",
							5.136:
								"Applications of superconductors to include the production of strong magnetic fields and the reduction of energy loss in transmission of electric power. ",
							5.137: "Critical field will not be assessed.",
						},
					},
					5.14: {
						"sub-topic": "Circuits",
						spec: {
							5.141: "Formula 3.5.1.4 ",
							5.142:
								"The relationships between currents, voltages and resistances in series and parallel circuits, including cells in series and identical cells in parallel.",
							5.143:
								" Conservation of charge and conservation of energy in dc circuits.",
						},
					},
					5.15: {
						"sub-topic": "Potential divider",
						spec: {
							5.151:
								"The potential divider used to supply constant or variable potential difference from a power supply.",
							5.152:
								" The use of the potentiometer as a measuring instrument is not required. ",
							5.153:
								"Examples should include the use of variable resistors, thermistors, and light dependent resistors (LDR) in the potential divider.",
						},
					},
					5.16: {
						"sub-topic": "Electromotive force and internal resistance",
						spec: {
							5.161: "formula 3.5.1.6  Terminal pd; emf",
							5.162:
								" Students will be expected to understand and perform calculations for circuits in which the internal resistance of the supply is not negligible.",
						},
					},
				},
			},
		},
	},
	6: {
		topic: "Further mechanics and thermal physics (A-level only)",
		topicQuestions: "",
		subs: {
			6.1: {
				"sub-topic": "Periodic motion (A-level only)",
				spec: {
					6.11: {
						"sub-topic": "Circular motion (A-level only)",
						spec: {
							6.111:
								"Motion in a circular path at constant speed implies there is an acceleration and requires a centripetal force. ",
							6.112: "Magnitude of angular speed ω = v/ r = 2π f ",
							6.113: "Radian measure of angle. ",
							6.114: "Direction of angular velocity will not be considered. ",
							6.115: "Centripetal acceleration a = v 2 / r = ω 2 r ",
							6.116:
								"The derivation of the centripetal acceleration formula will not be examined. ",
							6.117: "Centripetal force F = mv2/ r = mω 2 r",
						},
					},
					6.12: {
						"sub-topic": "Simple harmonic motion (SHM) (A-level only)",
						spec: {
							6.121:
								"Analysis of characteristics of simple harmonic motion (SHM). ",
							6.122: "Condition for SHM: a ∝ − x ",
							6.123: "Defining equation: a = − ω 2 x ",
							6.124: "equation 3.6.1.2 ",
							6.125:
								"Graphical representations linking the variations of x, v and a with time.",
							6.126:
								" Appreciation that the v − t graph is derived from the gradient of the x − t graph and that the a − t graph is derived from the gradient of the v − t graph. ",
							6.127: "Maximum speed = ωA",
							6.128: " Maximum acceleration = ω 2A",
						},
					},
					6.13: {
						"sub-topic": "Simple harmonic systems (A-level only)",
						spec: {
							6.131: "Study of mass-spring system: formula 3.6.1.3",
							6.132: "Study of simple pendulum: formula 3.6.1.3",
							6.133:
								"Questions may involve other harmonic oscillators (eg liquid in U-tube) but full information will be provided in questions where necessary. ",
							6.134:
								"Variation of Ek , Ep , and total energy with both displacement and time. ",
							6.135: "Effects of damping on oscillations.",
						},
					},
					6.14: {
						"sub-topic": "Forced vibrations and resonance (A-level only)",
						spec: {
							6.141: "Qualitative treatment of free and forced vibrations.",
							6.142:
								" Resonance and the effects of damping on the sharpness of resonance.",
							6.143:
								" Examples of these effects in mechanical systems and situations involving stationary waves.",
						},
					},
				},
			},
			6.2: {
				"sub-topic": "Thermal physics (A-level only)",
				spec: {
					6.21: {
						"sub-topic": "Thermal energy transfer (A-level only)",
						spec: {
							6.211:
								"Internal energy is the sum of the randomly distributed kinetic energies and potential energies of the particles in a body. ",
							6.212:
								"The internal energy of a system is increased when energy is transferred to it by heating or when work is done on it (and vice versa), eg a qualitative treatment of the first law of thermodynamics.",
							6.213:
								" Appreciation that during a change of state the potential energies of the particle ensemble are changing but not the kinetic energies. Calculations involving transfer of energy.  ",
							6.214:
								"For a change of temperature: Q = mc ∆ θ where c is specific heat capacity.",
							6.215: " Calculations including continuous flow. ",
							6.216:
								"For a change of state Q = ml where l is the specific latent heat.",
						},
					},
					6.22: {
						"sub-topic": "Ideal gases (A-level only)",
						spec: {
							6.221:
								"Gas laws as experimental relationships between p, V, T and the mass of the gas.",
							6.222: " Concept of absolute zero of temperature.",
							6.223:
								"Ideal gas equation: pV = nRT for n moles and pV = NkT for N molecules. ",
							6.224: "Work done = p∆V ",
							6.225:
								"Avogadro constant NA, molar gas constant R, Boltzmann constant k ",
							6.226: "Molar mass and molecular mass.",
						},
					},
					6.23: {
						"sub-topic": "Molecular kinetic theory model (A-level only)",
						spec: {
							6.231: "Brownian motion as evidence for existence of atoms.",
							6.232:
								" Explanation of relationships between p, V and T in terms of a simple molecular model.",
							6.233:
								" Students should understand that the gas laws are empirical in nature whereas the kinetic theory model arises from theory. ",
							6.234:
								"Assumptions leading to including derivation of the equation and calculations. ",
							6.235:
								"A simple algebraic approach involving conservation of momentum is required. ",
							6.236:
								"Appreciation that for an ideal gas internal energy is kinetic energy of the atoms. ",
							6.237: "Use of average molecular kinetic energy = 3.6.2.3 ",
							6.238:
								"Appreciation of how knowledge and understanding of the behaviour of a gas has changed over time.",
						},
					},
				},
			},
		},
	},
	7: {
		topic: "Fields and their consequences (A-level only)",
		topicQuestions: "",
		subs: {
			7.1: {
				"sub-topic": "Fields (A-level only)",
				spec: {
					7.11: {
						"sub-topic": "Fields (A-level only)",
						spec: {
							7.11: "Concept of a force field as a region in which a body experiences a non-contact force.",
							7.12: " Students should recognise that a force field can be represented as a vector, the direction of which must be determined by inspection. ",
							7.13: "Force fields arise from the interaction of mass, of static charge, and between moving charges.",
							7.14: " Similarities and differences between gravitational and electrostatic forces: ",
							7.15: "Similarities: Both have inverse-square force laws that have many characteristics in common, eg use of field lines, use of potential concept, equipotential surfaces etc",
							7.15: " Differences: masses always attract, but charges may attract or repel",
						},
					},
				},
			},
			7.2: {
				"sub-topic": "Gravitational fields (A-level only)",
				spec: {
					7.21: {
						"sub-topic": "Newton's law (A-level only)",
						spec: {
							7.211:
								"Gravity as a universal attractive force acting between all matter. ",
							7.212:
								"Magnitude of force between point masses: F = Gm1m2 /r 2 where G is the gravitational constant",
						},
					},
					7.22: {
						"sub-topic": "Gravitational field strength (A-level only)",
						spec: {
							7.221:
								"Representation of a gravitational field by gravitational field lines. ",
							7.222: "g as force per unit mass as defined by 3.7.2.2",
							7.223: "Magnitude of g in a radial field given by 3.7.2.2",
						},
					},
					7.23: {
						"sub-topic": "Gravitational potential (A-level only)",
						spec: {
							7.231:
								"Understanding of definition of gravitational potential, including zero value at infinity. ",
							7.232: "Understanding of gravitational potential difference. ",
							7.233: "Work done in moving mass m given by ∆W = m∆V ",
							7.234: "Equipotential surfaces.",
							7.235:
								" Idea that no work is done when moving along an equipotential surface.",
							7.236: " V in a radial field given by V = − GM /r ",
							7.237: "Significance of the negative sign. ",
							7.238:
								"Graphical representations of variations of g and V with r. ",
							7.239: "V related to g by: g = − ∆V /∆ r ",
							7.231: "∆V from area under graph of g against r.",
						},
					},
					7.24: {
						"sub-topic": "Orbits of planets and satellites (A-level only)",
						spec: {
							7.241:
								"Orbital period and speed related to radius of circular orbit; derivation of T 2 ∝ r 3 ",
							7.242: "Energy considerations for an orbiting satellite.",
							7.243: "Total energy of an orbiting satellite.",
							7.244: "  Escape velocity.",
							7.245: " Synchronous orbits.",
							7.246:
								" Use of satellites in low orbits and geostationary orbits, to include plane and radius of geostationary orbit.",
						},
					},
				},
			},
			7.3: {
				"sub-topic": "Electric fields (A-level only)",
				spec: {
					7.31: {
						"sub-topic": "Coulomb's law (A-level only)",
						spec: {
							7.311: "Force between point charges in a vacuum: formula 3.7.3.1",
							7.312: " Permittivity of free space, ε0",
							7.313:
								" Appreciation that air can be treated as a vacuum when calculating force between charges.",
							7.314:
								" For a charged sphere, charge may be considered to be at the centre. ",
							7.315:
								"Comparison of magnitude of gravitational and electrostatic forces between subatomic particles.",
						},
					},
					7.32: {
						"sub-topic": "Electric field strength (A-level only)",
						spec: {
							7.321:
								"Representation of electric fields by electric field lines. ",
							7.322: "Electric field strength.",
							7.323: "E as force per unit charge defined by E = F /Q ",
							7.324: "Magnitude of E in a uniform field given by E = V /d ",
							7.325:
								"Derivation from work done moving charge between plates: Fd = QΔV",
							7.326:
								" Trajectory of moving charged particle entering a uniform electric field initially at right angles.",
							7.327:
								" Magnitude of E in a radial field given by formula 3.7.3.2",
						},
					},
					7.33: {
						"sub-topic": "Electric potential (A-level only)",
						spec: {
							7.331:
								"Understanding of definition of absolute electric potential, including zero value at infinity, and of electric potential difference.",
							7.332: "Work done in moving charge Q given by ∆ W = Q∆V ",
							7.333: "Equipotential surfaces.",
							7.334:
								" No work done moving charge along an equipotential surface.",
							7.335:
								" Magnitude of V in a radial field given by formula 3.7.3.3",
							7.336:
								"Graphical representations of variations of E and V with r.",
							7.337: " V related to E by E = ∆V /∆ r",
							7.338: " ∆V from the area under graph of E against r.",
						},
					},
				},
			},
			7.4: {
				"sub-topic": "Capacitance (A-level only)",
				spec: {
					7.41: {
						"sub-topic": "Capacitance (A-level only)",
						spec: {
							7.411: "Definition of capacitance: C =Q/V",
						},
					},
					7.42: {
						"sub-topic": "Parallel plate capacitor (A-level only)",
						spec: {
							7.421: "Dielectric action in a capacitor C = ",
							7.422: "Relative permittivity and dielectric constant. ",
							7.423:
								"Students should be able to describe the action of a simple polar molecule that rotates in the presence of an electric field.",
						},
					},
					7.43: {
						"sub-topic": "Energy stored by a capacitor (A-level only)",
						spec: {
							7.431:
								"Interpretation of the area under a graph of charge against pd.",
							7.432: "Formula 3.7.4.3",
						},
					},
					7.44: {
						"sub-topic": "Capacitor charge and discharge (A-level only)",
						spec: {
							7.441:
								"Graphical representation of charging and discharging of capacitors through resistors. Corresponding graphs for Q, V and I against time for charging and discharging. ",
							7.442:
								"Interpretation of gradients and areas under graphs where appropriate.",
							7.443: " Time constant RC.",
							7.444:
								" Calculation of time constants including their determination from graphical data.",
							7.445: " Time to halve, T½ = 0.69RC ",
							7.446:
								"Quantitative treatment of capacitor discharge, formula 3.7.4.4",
							7.447: " Use of the corresponding equations for V and  I",
							7.448:
								" Quantitative treatment of capacitor charge, formula 3.7.4.4",
						},
					},
				},
			},
			7.5: {
				"sub-topic": "Magnetic fields (A-level only)",
				spec: {
					7.51: {
						"sub-topic": "Magnetic flux density (A-level only)",
						spec: {
							7.511:
								"Force on a current-carrying wire in a magnetic field: F = BIl when field is perpendicular to current.",
							7.512: " Fleming’s left hand rule. ",
							7.513: "Magnetic flux density B and definition of the tesla.",
						},
					},
					7.52: {
						"sub-topic": "Moving charges in a magnetic field (A-level only)",
						spec: {
							7.521:
								"Force on charged particles moving in a magnetic field, F = BQv when the field is perpendicular to velocity. ",
							7.522:
								"Direction of force on positive and negative charged particles. ",
							7.523:
								"Circular path of particles; application in devices such as the cyclotron.",
						},
					},
					7.53: {
						"sub-topic": "Magnetic flux and flux linkage (A-level only)",
						spec: {
							7.531: "Magnetic flux defined by Φ = BA where B is normal to A.",
							7.532:
								" Flux linkage as NΦ where N is the number of turns cutting the flux. ",
							7.533:
								"Flux and flux linkage passing through a rectangular coil rotated in a magnetic field:",
							7.534: " flux linkage NΦ = BANcosθ",
						},
					},
					7.54: {
						"sub-topic": "Electromagnetic induction (A-level only)",
						spec: {
							7.541: "Simple experimental phenomena.",
							7.542: " Faraday’s and Lenz’s laws. ",
							7.543:
								"Magnitude of induced emf = rate of change of flux linkage ε = N ∆Φ /∆ t ",
							7.544:
								"Applications such as a straight conductor moving in a magnetic field. ",
							7.545:
								"emf induced in a coil rotating uniformly in a magnetic field: ε = BANωsin ωt",
						},
					},
					7.55: {
						"sub-topic": "Alternating currents (A-level only)",
						spec: {
							7.551:
								"Sinusoidal voltages and currents only; root mean square, peak and peak-to-peak values for sinusoidal waveforms only.",
							7.552: "formula 3.7.5.5",
							7.553:
								"Application to the calculation of mains electricity peak and peak-to-peak voltage values. ",
							7.554:
								"Use of an oscilloscope as a dc and ac voltmeter, to measure time intervals and frequencies, and to display ac waveforms.",
							7.555:
								" No details of the structure of the instrument are required but familiarity with the operation of the controls is expected.",
						},
					},
					7.56: {
						"sub-topic": "The operation of a transformer (A-level only)",
						spec: {
							7.561: "The transformer equation:",
							7.562: " Transformer efficiency",
							7.563: " Production of eddy currents.",
							7.564: " Causes of inefficiencies in a transformer.",
							7.565:
								" Transmission of electrical power at high voltage including calculations of power loss in transmission lines.",
						},
					},
				},
			},
		},
	},
	8: {
		topic: "Nuclear physics (A-level only)",
		topicQuestions: "",
		subs: {
			8.1: {
				"sub-topic": "Radioactivity (A-level only)",
				spec: {
					8.11: {
						"sub-topic": "Rutherford scattering (A-level only)",
						spec: {
							8.111: "Qualitative study of Rutherford scattering.",
							8.112:
								" Appreciation of how knowledge and understanding of the structure of the nucleus has changed over time.",
						},
					},
					8.12: {
						"sub-topic": "α, β and γ radiation (A-level only)",
						spec: {
							8.121:
								"Their properties and experimental identification using simple absorption experiments; applications eg to relative hazards of exposure to humans. ",
							8.122:
								"Applications also include thickness measurements of aluminium foil paper and steel. ",
							8.123: "Inverse-square law for γ radiation: I =",
							8.124: " Experimental verification of inverse-square law. ",
							8.125:
								"Applications eg to safe handling of radioactive sources. ",
							8.126:
								"Background radiation; examples of its origins and experimental elimination from calculations. ",
							8.127:
								"Appreciation of balance between risk and benefits in the uses of radiation in medicine.",
						},
					},
					8.13: {
						"sub-topic": "Radioactive decay (A-level only)",
						spec: {
							8.131:
								"Random nature of radioactive decay; constant decay probability of a given nucleus;",
							8.132: "formula 3.8.1.3",
							8.133: " Use of activity, A = λN",
							8.134: " Modelling with constant decay probability.",
							8.135:
								" Questions may be set which require students to use formula 3.8.1.3",
							8.136:
								" Questions may also involve use of molar mass or the Avogadro constant. ",
							8.137: "Half-life equation: T½ = ln2/λ ",
							8.138:
								"Determination of half-life from graphical decay data including decay curves and log graphs.",
							8.139:
								" Applications eg relevance to storage of radioactive waste, radioactive dating etc.",
						},
					},
					8.14: {
						"sub-topic": "Nuclear instability (A-level only)",
						spec: {
							8.141: "Graph of N against Z for stable nuclei. ",
							8.142:
								"Possible decay modes of unstable nuclei including α, β + , β − and electron capture.",
							8.143:
								"Changes in N and Z caused by radioactive decay and representation in simple decay equations. ",
							8.144: "Questions may use nuclear energy level diagrams.",
							8.145:
								" Existence of nuclear excited states; γ ray emission; application eg use of technetium-99m as a γ source in medical diagnosis",
						},
					},
					8.15: {
						"sub-topic": "Nuclear radius (A-level only)",
						spec: {
							8.151:
								"Estimate of radius from closest approach of alpha particles and determination of radius from electron diffraction. ",
							8.152: "Knowledge of typical values for nuclear radius. ",
							8.153:
								"Students will need to be familiar with the Coulomb equation for the closest approach estimate.",
							8.154: " Dependence of radius on nucleon number: ",
							8.155: "R = R0A 1/3 derived from experimental data.",
							8.156:
								" Interpretation of equation as evidence for constant density of nuclear material. ",
							8.157: "Calculation of nuclear density.",
							8.158:
								" Students should be familiar with the graph of intensity against angle for electron diffraction by a nucleus",
						},
					},
					8.16: {
						"sub-topic": "Mass and energy (A-level only)",
						spec: {
							8.161:
								"Appreciation that E = mc 2 applies to all energy changes, ",
							8.162:
								"Simple calculations involving mass difference and binding energy. ",
							8.163: "Atomic mass unit, u. ",
							8.164: "Conversion of units; 1 u = 931.5 MeV.",
							8.165: " Fission and fusion processes.",
							8.166:
								" Simple calculations from nuclear masses of energy released in fission and fusion reactions. ",
							8.167:
								"Graph of average binding energy per nucleon against nucleon number. ",
							8.168:
								"Students may be expected to identify, on the plot, the regions where nuclei will release energy when undergoing fission/fusion. ",
							8.169:
								"Appreciation that knowledge of the physics of nuclear energy allows society to use science to inform decision making.",
						},
					},
					8.17: {
						"sub-topic": "Induced fission (A-level only)",
						spec: {
							8.171:
								"Fission induced by thermal neutrons; possibility of a chain reaction; critical mass.",
							8.172:
								" The functions of the moderator, control rods, and coolant in a thermal nuclear reactor. ",
							8.173: "Details of particular reactors are not required. ",
							8.174:
								"Students should have studied a simple mechanical model of moderation by elastic collisions. ",
							8.175:
								"Factors affecting the choice of materials for the moderator, control rods and coolant. Examples of materials used for these functions.",
						},
					},
					8.18: {
						"sub-topic": "Safety aspects (A-level only)",
						spec: {
							8.181:
								"Fuel used, remote handling of fuel, shielding, emergency shut-down.",
							8.182:
								" Production, remote handling, and storage of radioactive waste materials.",
							8.183:
								" Appreciation of balance between risk and benefits in the development of nuclear power.",
						},
					},
				},
			},
		},
	},
	9: {
		topic: "Astrophysics (A-level only)",
		topicQuestions: "",
		subs: {
			9.1: {
				"sub-topic": "Telescopes (A-level only)",
				spec: {
					9.11: {
						"sub-topic":
							"Astronomical telescope consisting of two converging lenses (A-level only)",
						spec: {
							9.111:
								"Ray diagram to show the image formation in normal adjustment.",
							9.112: " Angular magnification in normal adjustment.",
							9.113:
								" M = angle subtended by image at eye/ angle subtended by object at unaided eye",
							9.114: " Focal lengths of the lenses formula 3.9.1.1",
						},
					},
					9.12: {
						"sub-topic": "Reflecting telescopes (A-level only)",
						spec: {
							9.121:
								"Cassegrain arrangement using a parabolic concave primary mirror and convex secondary mirror.",
							9.122:
								"Ray diagram to show path of rays through the telescope up to the eyepiece. ",
							9.123:
								"Relative merits of reflectors and refractors including a qualitative treatment of spherical and chromatic aberration.",
						},
					},
					9.13: {
						"sub-topic":
							"Single dish radio telescopes, I-R, U-V and X-ray telescopes (A-level only)",
						spec: {
							9.131:
								"Similarities and differences of radio telescopes compared to optical telescopes. Discussion should include structure, positioning and use, together with comparisons of resolving and collecting powers.",
						},
					},
					9.14: {
						"sub-topic":
							"Advantages of large diameter telescopes (A-level only)",
						spec: {
							9.141: "Minimum angular resolution of telescope. ",
							9.142: "Rayleigh criterion, θ ≈ λ /D ",
							9.143: "Collecting power is proportional to diameter2 . ",
							9.144:
								"Students should be familiar with the rad as the unit of angle.",
							9.145:
								" Comparison of the eye and CCD as detectors in terms of quantum efficiency, resolution, and convenience of use.",
							9.146: " No knowledge of the structure of the CCD is required.",
						},
					},
				},
			},
			9.2: {
				"sub-topic": "Classification of stars (A-level only)",
				spec: {
					9.21: {
						"sub-topic": "Classification by luminosity (A-level only)",
						spec: {
							9.211: "Apparent magnitude, m.",
							9.212: " The Hipparcos scale. ",
							9.213: "Dimmest visible stars have a magnitude of 6. ",
							9.214:
								"Relation between brightness and apparent magnitude. Difference of 1 on magnitude scale is equal to an intensity ratio of 2.51.",
							9.215: " Brightness is a subjective scale of measurement",
						},
					},
					9.22: {
						"sub-topic": "Absolute magnitude, M (A-level only)",
						spec: {
							9.221: "Parsec and light year.",
							9.222: " Definition of M, relation to m: m – M = 3.9.2.2",
						},
					},
					9.23: {
						"sub-topic":
							"Classification by temperature, black-body radiation (A-level only)",
						spec: {
							9.231: "Stefan’s law and Wien’s displacement law.",
							9.232:
								" General shape of black-body curves, use of Wien’s displacement law to estimate black-body temperature of sources.",
							9.233: " Experimental verification is not required.",
							9.234: "λmaxT = constant = 2.9 × 10−3 m K ",
							9.235: "Assumption that a star is a black body. ",
							9.236: "Inverse square law, assumptions in its application.",
							9.237:
								" Use of Stefan’s law to compare the power output, temperature and size of stars ",
							9.238: "P = σAT4",
						},
					},
					9.24: {
						"sub-topic":
							"Principles of the use of stellar spectral classes (A-level only)",
						spec: {
							9.241: "Description of the main classes: Table 3.9.2.4",
							9.242:
								"Temperature related to absorption spectra limited to Hydrogen Balmer absorption lines: requirement for atoms in an n = 2 state",
						},
					},
					9.25: {
						"sub-topic": "The Hertzsprung-Russell (HR) diagram (A-level only)",
						spec: {
							9.251: "General shape: main sequence, dwarfs and giants.",
							9.252:
								" Axis scales range from –10 to +15 (absolute magnitude) and 50 000 K to 2 500 K (temperature) or OBAFGKM (spectral class). ",
							9.253:
								"Students should be familiar with the position of the Sun on the HR diagram. ",
							9.254:
								"Stellar evolution: path of a star similar to our Sun on the HR diagram from formation to white dwarf.",
						},
					},
					9.26: {
						"sub-topic":
							"Supernovae, neutron stars and black holes (A-level only)",
						spec: {
							9.261:
								"Defining properties: rapid increase in absolute magnitude of supernovae; composition and density of neutron stars; escape velocity > c for black holes. ",
							9.262:
								"Gamma ray bursts due to the collapse of supergiant stars to form neutron stars or black holes.",
							9.263:
								" Comparison of energy output with total energy output of the Sun. ",
							9.264:
								"Use of type 1a supernovae as standard candles to determine distances. Controversy concerning accelerating Universe and dark energy.",
							9.265:
								" Students should be familiar with the light curve of typical type 1a supernovae. ",
							9.266: "Supermassive black holes at the centre of galaxies.",
							9.267:
								" Calculation of the radius of the event horizon for a black hole, Schwarzschild radius Rs Formula 3.9.2.6",
						},
					},
				},
			},
			9.3: {
				"sub-topic": "Cosmology (A-level only)",
				spec: {
					9.31: {
						"sub-topic": "Doppler effect (A-level only)",
						spec: {
							9.311:
								"formula 3.9.3.1 for v ≪ c applied to optical and radio frequencies. ",
							9.312:
								"Calculations on binary stars viewed in the plane of orbit. ",
							9.313: "Galaxies and quasars.",
						},
					},
					9.32: {
						"sub-topic": "Hubble's law (A-level only)",
						spec: {
							9.321: "Red shift v = Hd ",
							9.322:
								"Simple interpretation as expansion of universe; estimation of age of universe, assuming H is constant.",
							9.323:
								" Qualitative treatment of Big Bang theory including evidence from cosmological microwave background radiation, and relative abundance of hydrogen and helium. ",
						},
					},
					9.33: {
						"sub-topic": "Quasars (A-level only)",
						spec: {
							9.331: "Quasars as the most distant measurable objects. ",
							9.332: "Discovery of quasars as bright radio sources.",
							9.333:
								" Quasars show large optical red shifts; estimation involving distance and power output. ",
							9.334:
								"Formation of quasars from active supermassive black holes.",
						},
					},
					9.34: {
						"sub-topic": "Detection of exoplanets (A-level only)",
						spec: {
							9.341: "Difficulties in the direct detection of exoplanets.",
							9.342:
								" Detection techniques will be limited to variation in Doppler shift (radial velocity method) and the transit method. ",
							9.343: "Typical light curve.",
						},
					},
				},
			},
		},
	},
	10: {
		topic: "",
		topicQuestions: "",
		subs: {
			10.1: {
				"sub-topic": "",
				spec: {
					10.11: {
						"sub-topic": "Physics of vision (A-level only)",
						spec: {
							10.111:
								"The eye as an optical refracting system, including ray diagrams of image formation. ",
							10.112:
								"Sensitivity of the eye; spectral response as a photodetector.",
							10.113:
								" Spatial resolution of the eye; explanation in terms of the behaviour of rods and cones.",
						},
					},
					10.12: {
						"sub-topic":
							"Defects of vision and their correction using lenses (A-level only)",
						spec: {
							10.121:
								"Properties of converging and diverging lenses; principal focus, focal length and power,",
							10.122: "",
							10.123: " Myopia, hypermetropia, astigmatism. ",
							10.124:
								"Ray diagrams and calculations of powers (in dioptres) of correcting lenses for myopia and hypermetropia.",
							10.125: " The format of prescriptions for astigmatism.",
						},
					},
				},
			},
			10.2: {
				"sub-topic": "Physics of the ear (A-level only)",
				spec: {
					10.21: {
						"sub-topic": " Ear as a sound detection system (A-level only)",
						spec: {
							10.211: "Simple structure of the ear, transmission processes",
						},
					},
					10.22: {
						"sub-topic": "Sensitivity and frequency response (A-level only)",
						spec: {
							10.221:
								"Production and interpretation of equal loudness curves. ",
							10.222:
								"Human perception of relative intensity levels and the need for a logarithmic scale to reflect this.",
							10.223: " Definition of intensity.",
							10.224:
								" Intensity level = 10 log I/ I0 where the threshold of hearing I0 = 1.0 × 10−12 W m −2 ",
							10.225:
								"Measurement of sound intensity levels and the use of dB and dBA scales; relative intensity levels of sounds.",
						},
					},
					10.23: {
						"sub-topic": "Defects of hearing (A-level only)",
						spec: {
							10.231:
								"The effect on equal loudness curves and the changes experienced in terms of hearing loss due to injury resulting from exposure to excessive noise or deterioration with age (excluding physiological changes).",
						},
					},
				},
			},
			10.3: {
				"sub-topic": "Biological measurement (A-level only)",
				spec: {
					10.31: {
						"sub-topic":
							"Simple ECG machines and the normal ECG waveform (A-level only)",
						spec: {
							10.311:
								"Principles of operation for obtaining the ECG waveform; explanation of the characteristic shape of a normal ECG waveform.",
						},
					},
				},
			},
			10.4: {
				"sub-topic": "Non-ionising imaging (A-level only)",
				spec: {
					10.41: {
						"sub-topic": "Ultrasound imaging (A-level only)",
						spec: {
							10.411:
								"Reflection and transmission characteristics of sound waves at tissue boundaries, acoustic impedance, Z, and attenuation.",
							10.412:
								" Advantages and disadvantages of ultrasound imaging in comparison with alternatives including safety issues and resolution. ",
							10.413: "Piezoelectric devices ",
							10.414:
								"Principles of generation and detection of ultrasound pulses. ",
							10.415: "A-scans and B-scans. ",
							10.416: "Examples of applications. ",
							10.417: "Use of the equations 3.10.4.1",
						},
					},
					10.42: {
						"sub-topic": "Fibre optics and endoscopy (A-level only)",
						spec: {
							10.421:
								"Properties of fibre optics and applications in medical physics; including total internal reflection at the core–cladding interface.",
							10.422:
								" Physical principles of the optical system of a flexible endoscope; the use of coherent and non-coherent fibre bundles; examples of use for internal imaging and related advantages",
						},
					},
					10.43: {
						"sub-topic": "Magnetic resonance (MR) scanner (A-level only)",
						spec: {
							10.431:
								"Basic principles of MR scanner;\n • cross-section of patient scanned using magnetic fields\n • protons initially aligned with spins parallel\n • spinning hydrogen nuclei (protons) precess about the magnetic field lines of a superconducting magnet\n • 'gradient' field coils used to scan cross-section\n • short radio frequency (RF) pulses cause excitation and change of spin state in successive small regions\n • protons excited during the scan emit RF signals as they de-excite\n • RF signals detected and the resulting signals are processed by a computer to produce a visual image.",
							10.432:
								" Students will not be asked about the production of magnetic fields used in an MR scanner, or about de-excitation relaxation times",
						},
					},
				},
			},
			10.5: {
				"sub-topic": "X-ray imaging (A-level only)",
				spec: {
					10.51: {
						"sub-topic": "The physics of diagnostic X-rays(A-level only)",
						spec: {
							10.511:
								"Physical principles of the production of X-rays; maximum photon energy, energy spectrum; continuous spectrum and characteristic spectrum. ",
							10.512:
								"Rotating-anode X-ray tube; methods of controlling the beam intensity, the photon energy, the image sharpness and contrast, and the patient dose. ",
						},
					},
					10.52: {
						"sub-topic": "Image detection and enhancement (A-level only)",
						spec: {
							10.521:
								"Flat panel (FTP) detector including X-ray scintillator, photodiode pixels, electronic scanning.",
							10.522:
								" Advantages of FTP detector compared with photographic detection.",
							10.523:
								" Contrast enhancement; use of X-ray opaque material as illustrated by the barium meal technique. ",
							10.524:
								"Photographic detection with intensifying screen and fluoroscopic image intensification; reasons for using these.",
						},
					},
					10.53: {
						"sub-topic": "Absorption of X-rays (A-level only)",
						spec: {
							10.531: "Exponential attenuation. ",
							10.532:
								"Linear coefficient μ, mass attenuation coefficient μm, half-value thickness ",
							10.533: "formula 3.10.5.3",
							10.534:
								"Differential tissue absorption of X-rays excluding details of the absorption processes",
						},
					},
					10.54: {
						"sub-topic": "CT scanner (A-level only)",
						spec: {
							10.541:
								" Basic principles of CT scanner:\n • movement of X-ray tube\n • narrow, monochromatic X-ray beam\n • array of detectors\n • computer used to process the signals and produce a visual image.",
							10.542:
								" Comparisons will be limited to advantages and disadvantages of image resolution, cost and safety issues. Students will not be asked about the construction or operation of the detectors.",
						},
					},
				},
			},
			10.6: {
				"sub-topic": "Radionuclide imaging and therapy (A-level only)",
				spec: {
					10.61: {
						"sub-topic": "Imaging techniques (A-level only)",
						spec: {
							10.611:
								"Use of a gamma-emitting radioisotope as a tracer; technetium-99m, iodine-131 and indium-111 and their relevant properties. ",
							10.612:
								"The properties should include the radiation emitted, the half-life, the energy of the gamma radiation, the ability for it to be labelled with a compound with an affinity for a particular organ.",
							10.613:
								"The Molybdenum-Technetium generator, its basic use and importance. ",
							10.614: " PET scans. ",
						},
					},
					10.62: {
						"sub-topic": "Half-life (A-level only)",
						spec: {
							10.621:
								"Physical, biological and effective half-lives; formula 3.10.6.2; definitions of each term.",
						},
					},
					10.63: {
						"sub-topic": "Gamma camera (A-level only)",
						spec: {
							10.631:
								"Basic structure and workings of a photomultiplier tube and gamma camera",
						},
					},
					10.64: {
						"sub-topic": "Use of high-energy X-rays (A-level only)",
						spec: {
							10.641:
								"External treatment using high-energy X-rays. Methods used to limit exposure to healthy cells.",
						},
					},
					10.65: {
						"sub-topic": "Use of radioactive implants (A-level only)",
						spec: {
							10.651: "Internal treatment using beta emitting implants.",
						},
					},
					10.66: {
						"sub-topic": "Imaging comparisons (A-level only)",
						spec: {
							10.661:
								" Students will be required to make comparisons between imaging techniques. Questions will be limited to consideration of image resolution, convenience and safety issues.",
						},
					},
				},
			},
		},
	},
	11: {
		topic: "",
		topicQuestions: "",
		subs: {
			11.1: {
				"sub-topic": "",
				spec: {
					11.11: {
						"sub-topic": "Concept of moment of inertia (A-level only)",
						spec: {
							11.111: "formula 3.11.1.1 for an extended object.",
							11.112:
								" Qualitative knowledge of the factors that affect the moment of inertia of a rotating object.",
							11.113:
								" Expressions for moment of inertia will be given where necessary.",
						},
					},
					11.12: {
						"sub-topic": "Rotational kinetic energy (A-level only)",
						spec: {
							11.121: "formula 3.11.1.2",
							11.122:
								"Factors affecting the energy storage capacity of a flywheel.",
							11.123: " Use of flywheels in machines. ",
							11.124:
								"Use of flywheels for smoothing torque and speed, and for storing energy in vehicles, and in machines used for production processes.",
						},
					},
					11.13: {
						"sub-topic": "Rotational motion (A-level only)",
						spec: {
							11.131:
								"Angular displacement, angular speed, angular velocity, angular acceleration, formula 3.11.1.3 ",
							11.132:
								"Representation by graphical methods of uniform and non-uniform angular acceleration.",
							11.133:
								" Equations for uniform angular acceleration; formula 3.11.1.3 ",
							11.134:
								"Students should be aware of the analogy between rotational and translational dynamics.",
						},
					},
					11.14: {
						"sub-topic": "Torque and angular acceleration (A-level only)",
						spec: {
							11.141: "T = Fr",
							11.142: " T = Iα ",
						},
					},
					11.15: {
						"sub-topic": "Angular momentum (A-level only)",
						spec: {
							11.151: "angular momentum = Iω ",
							11.152: "Conservation of angular momentum. ",
							11.153:
								"Angular impulse = change in angular momentum; T ∆ t = ∆ Iω where T is constant.",
							11.154: " Applications may include examples from sport.",
						},
					},
					11.16: {
						"sub-topic": "Work and power (A-level only)",
						spec: {
							11.161: "W = Tθ; P = Tω ",
							11.162:
								"Awareness that frictional torque has to be taken into account in rotating machinery. ",
						},
					},
				},
			},
			11.2: {
				"sub-topic": "Thermodynamics and engines (A-level only)",
				spec: {
					11.21: {
						"sub-topic": "First law of thermodynamics (A-level only)",
						spec: {
							11.211:
								"Quantitative treatment of first law of thermodynamics, Q = ∆U + W",
							11.212:
								" where Q is energy transferred to the system by heating, ∆U is increase in internal energy and W is work done by the system. ",
							11.213: "Applications of first law of thermodynamics.",
						},
					},
					11.22: {
						"sub-topic": "Non-flow processes (A-level only)",
						spec: {
							11.221:
								"Isothermal, adiabatic, constant pressure and constant volume changes.",
							11.222: " pV = nRT",
							11.223: " adiabatic change : pVγ = constant",
							11.224: " isothermal change : pV = constant",
							11.225: " at constant pressure W = pΔV",
							11.226:
								" Application of first law of thermodynamics to the above processes.",
						},
					},
					11.23: {
						"sub-topic": "The p–V diagram (A-level only)",
						spec: {
							11.231: "Representation of processes on p–V diagram.",
							11.232:
								" Estimation of work done in terms of area below the graph. ",
							11.233:
								"Extension to cyclic processes: work done per cycle = area of loop ",
							11.234:
								"Expressions for work done are not required except for the constant pressure case, W = pΔV",
						},
					},
					11.24: {
						"sub-topic": "Engine cycles (A-level only)",
						spec: {
							11.241:
								"Understanding of a four-stroke petrol engine cycle and a diesel engine cycle, and of the corresponding indicator diagrams. ",
							11.242:
								"Comparison with the theoretical diagrams for these cycles; use of indicator diagrams for predicting and measuring power and efficiency",
							11.243: "input power = calorific value × fuel flow rate ",
							11.244:
								" Indicated power as area of p−V loop × no. of cycles per second × no. of cylinders",
							11.245: "Output or brake power, P = Tω ",
							11.246: "friction power = indicated power – brake power",
							11.247:
								" Engine efficiency; overall, thermal and mechanical efficiencies.",
							11.248: "Overall efficiency = brake power /input power ",
							11.249: "Thermal efficiency = indicated power /input power ",
							11.241: "Mechanical efficiency = brake power /indicated power",
							11.2411:
								" A knowledge of engine constructional details is not required. ",
							11.2412:
								"Questions may be set on other cycles, but they will be interpretative and all essential information will be given.",
						},
					},
					11.25: {
						"sub-topic": "Second Law and engines (A-level only)",
						spec: {
							11.251:
								"Impossibility of an engine working only by the First Law.",
							11.252:
								" Second Law of Thermodynamics expressed as the need for a heat engine to operate between a source and a sink.",
							11.253: "formula 3.11.2.5",
							11.254: "diagram 3.11.2.5",
							11.255:
								"Reasons for the lower efficiencies of practical engines.",
							11.256:
								" Maximising use of W and QH for example in combined heat and power schemes.",
						},
					},
					11.26: {
						"sub-topic": "Reversed heat engines (A-level only)",
						spec: {
							11.261:
								"Basic principles and uses of heat pumps and refrigerators. ",
							11.262:
								"A knowledge of practical heat pumps or refrigerator cycles and devices is not required",
							11.263: "diagram 3.11.2.6",
							11.264: "formula 3.11.2.6",
						},
					},
				},
			},
		},
	},
	12: {
		topic: "Turning points in physics (A-level only)",
		topicQuestions: "",
		subs: {
			12.1: {
				"sub-topic": "The discovery of the electron (A-level only)",
				spec: {
					12.11: {
						"sub-topic": "Cathode rays (A-level only)",
						spec: {
							12.111: "Production of cathode rays in a discharge tube",
						},
					},
					12.12: {
						"sub-topic": "Thermionic emission of electrons (A-level only)",
						spec: {
							12.121: "The principle of thermionic emission.",
							12.122:
								" Work done on an electron accelerated through a pd formula 3.12.1.2",
						},
					},
					12.13: {
						"sub-topic": "Specific charge of the electron (A-level only)",
						spec: {
							12.131:
								"Determination of the specific charge of an electron, e/me , by any one method.",
							12.132: "Significance of Thomson’s determination of e/me ",
							12.133:
								"Comparison with the specific charge of the hydrogen ion.",
						},
					},
					12.14: {
						"sub-topic":
							"Principle of Millikan’s determination of the electronic charge, e (A-level only)",
						spec: {
							12.141:
								"Condition for holding a charged oil droplet, of charge Q, stationary between oppositely charged parallel plates.",
							12.142: " QV/ d = mg",
							12.143:
								"Motion of a falling oil droplet with and without an electric field; terminal speed to determine the mass and the charge of the droplet. ",
							12.144:
								"Stokes’ Law for the viscous force on an oil droplet used to calculate the droplet radius.",
							12.145: " F = 6πηrv ",
							12.146: "Significance of Millikan’s results. ",
							12.147: "Quantisation of electric charge.",
						},
					},
				},
			},
			12.2: {
				"sub-topic": "Wave-particle duality (A-level only)",
				spec: {
					12.21: {
						"sub-topic": "Newton’s corpuscular theory of light (A-level only)",
						spec: {
							12.211: "Comparison with Huygens’ wave theory in general terms. ",
							12.212: "The reasons why Newton’s theory was preferred.",
						},
					},
					12.22: {
						"sub-topic":
							"Significance of Young’s double slits experiment (A-level only)",
						spec: {
							12.221:
								" Explanation for fringes in general terms, no calculations are expected. ",
							12.222: "Delayed acceptance of Huygens’ wave theory of light.",
						},
					},
					12.23: {
						"sub-topic": "Electromagnetic waves (A-level only)",
						spec: {
							12.231: "Nature of electromagnetic waves.",
							12.232:
								" Maxwell’s formula for the speed of electromagnetic waves in a vacuum c = 1 μ0ε0 where μ0 is the permeability of free space and ε0 is the permittivity of free space. ",
							12.233:
								"Students should appreciate that ε0 relates to the electric field strength due to a charged object in free space and μ0 relates to the magnetic flux density due to a current-carrying wire in free space.",
							12.234:
								" Hertz’s discovery of radio waves including measurements of the speed of radio waves.",
							12.235:
								" Fizeau’s determination of the speed of light and its implications.",
						},
					},
					12.24: {
						"sub-topic": "The discovery of photoelectricity (A-level only)",
						spec: {
							12.241: "The ultraviolet catastrophe and black-body radiation. ",
							12.242: "Planck’s interpretation in terms of quanta.",
							12.243:
								" The failure of classical wave theory to explain observations on photoelectricity.",
							12.244:
								"Einstein’s explanation of photoelectricity and its significance in terms of the nature of electromagnetic radiation.",
						},
					},
					12.25: {
						"sub-topic": "Wave–particle duality (A-level only)",
						spec: {
							12.251: "de Broglie’s hypothesis: p = h/ λ ; ",
							12.252: "formula 3.12.2.5",
							12.253:
								"Low-energy electron diffraction experiments; qualitative explanation of the effect of a change of electron speed on the diffraction pattern.",
						},
					},
					12.26: {
						"sub-topic": "Electron microscopes (A-level only)",
						spec: {
							12.261:
								"Estimate of anode voltage needed to produce wavelengths of the order of the size of the atom. ",
							12.262:
								"Principle of operation of the transmission electron microscope (TEM). Principle of operation of the scanning tunnelling microscope (STM).",
							12.263:
								"Principle of operation of the transmission electron microscope (TEM). Principle of operation of the scanning tunnelling microscope (STM).",
						},
					},
				},
			},
			12.3: {
				"sub-topic": "Special relativity (A-level only)",
				spec: {
					12.31: {
						"sub-topic": "The Michelson-Morley experiment (A-level only)",
						spec: {
							12.311: "Principle of the Michelson-Morley interferometer. ",
							12.312:
								"Outline of the experiment as a means of detecting absolute motion.",
							12.313: " Significance of the failure to detect absolute motion.",
							12.314: " The invariance of the speed of light",
						},
					},
					12.32: {
						"sub-topic":
							"Einstein’s theory of special relativity (A-level only)",
						spec: {
							12.321: " The concept of an inertial frame of reference.",
							12.322:
								" The two postulates of Einstein’s theory of special relativity:\n 1. physical laws have the same form in all inertial frames\n 2. the speed of light in free space is invariant.",
						},
					},
					12.33: {
						"sub-topic": "Time dilation (A-level only)",
						spec: {
							12.331:
								"Proper time and time dilation as a consequence of special relativity.",
							12.332: " Time dilation: formula 3.12.3.3",
							12.333: " Evidence for time dilation from muon decay. ",
						},
					},
					12.34: {
						"sub-topic": "Length contraction (A-level only)",
						spec: {
							12.341: "Length of an object having a speed v formula 3.12.3.4",
						},
					},
					12.35: {
						"sub-topic": "Mass and energy (A-level only)",
						spec: {
							12.351: "Equivalence of mass and energy,formula 3.12.3.5 ",
							12.352:
								"Graphs of variation of mass and kinetic energy with speed. ",
							12.353:
								"Bertozzi’s experiment as direct evidence for the variation of kinetic energy with speed.",
						},
					},
				},
			},
		},
	},
	13: {
		topic: "Electronics (A-level only)",
		topicQuestions: "",
		subs: {
			13.1: {
				"sub-topic": "Discrete semiconductor devices (A-level only)",
				spec: {
					13.11: {
						"sub-topic":
							"MOSFET (metal-oxide semiconducting field-effect transistor) (A-level only)",
						spec: {
							13.111: "Simplified structure, behaviour and characteristics. ",
							13.112: "Drain, source and gate.",
							13.113: " VDS, VGS, IDSS, and Vth",
							13.114:
								" Use as a switch, use as a device with a very high input resistance.",
							13.115: " Use in N-channel, enhancement mode only is required. ",
						},
					},
					13.12: {
						"sub-topic": "Zener diode (A-level only)",
						spec: {
							13.121:
								"Characteristic curve showing zener breakdown voltage and typical minimum operating current.",
							13.122: " Anode and cathode.",
							13.123: " Use with a resistor as a constant voltage source.",
							13.124: "Use to provide a reference voltage. ",
							13.125: "Use as a stabiliser is not required.",
						},
					},
					13.13: {
						"sub-topic": "Photodiode (A-level only)",
						spec: {
							13.131: "Characteristic curves and spectral response curves.",
							13.132:
								" Use in photo-conductive mode as a detector in optical systems. ",
							13.133: "Use with scintillator to detect atomic particles. ",
						},
					},
					13.14: {
						"sub-topic": "Hall effect sensor (A-level only)",
						spec: {
							13.141: "Use as magnetic field sensor to monitor attitude. ",
							13.142: "Use in tachometer.",
							13.143: " Principles of operation are not required.",
						},
					},
				},
			},
			13.2: {
				"sub-topic": "Analogue and digital signals (A-level only)",
				spec: {
					13.21: {
						"sub-topic":
							"Difference between analogue and digital signals (A-level only)",
						spec: {
							13.211: "Bits, bytes. ",
							13.212:
								"Analogue-to-digital conversion:\n • sampling audio signals for transmission in digital form\n • conversion of analogue signals into digital data using two voltage levels\n • quantisation\n • sampling rate\n • effect of sampling rate and number of bits per sample on quality of conversion\n • advantages and disadvantages of digital sampling\n • process of recovery of original data from noisy signal\n • effect of noise in communication systems. ",
							13.213: "Pulse code modulation. ",
							13.214:
								"Students should appreciate the use of a variety of sensors to collect analogue data. ",
							13.215:
								"The ability to carry out binary arithmetic is not required. Knowledge of binary numbers 1 to 10 is adequate.",
						},
					},
				},
			},
			13.3: {
				"sub-topic": "Analogue signal processing (A-level only)",
				spec: {
					13.31: {
						"sub-topic": "LC resonance filters (A-level only)",
						spec: {
							13.311: "Resonant frequency, f formula 3.13.3.1 ",
							13.312: "Only parallel resonance arrangements are required.",
							13.313: " Analogy between LC circuit and mass–spring system. ",
							13.314: "Inductance as mass analogy.",
							13.315: " Capacitance as spring analogy. ",
							13.316: "Derivation of the equation is not required",
							13.317: "Energy (voltage) response curve. ",
							13.318: "The response curve for current is not required.",
							13.319: " Q factor,  formula 3.13.3.1",
							13.311:
								"f B is the bandwidth of the filter at the 50% energy points",
						},
					},
					13.32: {
						"sub-topic": "The ideal operational amplifier (A-level only)",
						spec: {
							13.321:
								"Operation and characteristics of an ideal operational amplifier:\n • power supply and signal connections\n • infinite open-loop gain\n • infinite input resistance.",
							13.322:
								" Open-loop transfer function for a real operational amplifier, Vout = AOL V+ − V− ",
							13.323: "Use as a comparator. ",
							13.324:
								"The operational amplifier should be treated as an important system building block.",
						},
					},
				},
			},
			13.4: {
				"sub-topic": "Operational amplifier in:",
				spec: {
					13.41: {
						"sub-topic": "inverting amplifier configuration (A-level only)",
						spec: {
							13.411: "Derivation of formula 3.13.4.1",
							13.412: "Meaning of virtual earth, virtual-earth analysis.",
						},
					},
					13.42: {
						"sub-topic": "non-inverting amplifier configuration (A-level only)",
						spec: {
							13.421: "formula 3.13.4.2",
							13.422: "Derivation is not required",
						},
					},
					13.43: {
						"sub-topic": "summing amplifier configuration (A-level only)",
						spec: {
							13.431: "formula 3.13.4.3",
							13.432: "Difference amplifier configuration.",
							13.433: "Derivation is not required.",
							13.434: "formula 3.13.4.3",
							13.435: "Derivation is not required.",
						},
					},
					13.44: {
						"sub-topic": "Real operational amplifiers (A-level only)",
						spec: {
							13.441: "Limitations of real operational amplifiers.",
							13.442: "Frequency response curve.",
							13.443: "gain × bandwidth = constant for a given device.",
						},
					},
				},
			},
			13.5: {
				"sub-topic": "Digital signal processing (A-level only)",
				spec: {
					13.51: {
						"sub-topic": "Combinational logic (A-level only)",
						spec: {
							13.511:
								"Use of Boolean algebra related to truth tables and logic gates.",
							13.512: " A − = not A",
							13.513: " A ∙ B = A and B",
							13.514: "A + B = A or B ",
							13.515:
								"Identification and use of AND, NAND, OR, NOR, NOT and EOR gates in combination in logic circuits. ",
							13.516:
								"Construction and deduction of a logic circuit from a truth table. ",
							13.517:
								"The gates should be treated as building blocks. The internal structure or circuit of the gates is not required.",
						},
					},
					13.52: {
						"sub-topic": "Sequential logic (A-level only)",
						spec: {
							13.521:
								"Counting circuits:\n • Binary counter\n • BCD counter\n • Johnson counter.",
							13.522: "Inputs to the circuits, clock, reset, up/down.",
							13.523: "Outputs from the circuits. ",
							13.524:
								"Modulo- n counter from basic counter with the logic driving a reset pin.",
							13.525:
								" The gates should be treated as building blocks. The internal structure or circuit of the gates is not required.",
						},
					},
					13.53: {
						"sub-topic": "Astables (A-level only)",
						spec: {
							13.531: "The astable as an oscillator to provide a clock pulse.",
							13.532:
								" Clock (pulse) rate (frequency), pulse width, period, duty cycle, mark-to-space ratio. ",
							13.533:
								"Variation of running frequency using an external RC network. ",
							13.534:
								"Knowledge of a particular circuit or a specific device (eg 555 chip) will not be required",
						},
					},
				},
			},
			13.6: {
				"sub-topic": "",
				spec: {
					13.61: {
						"sub-topic": "Principles of communication systems (A-level only)",
						spec: {
							13.611:
								"Communication systems, block diagram of 'real time' communication system. ",
							13.612: "diagram 3.13.6.1",
							13.613: "Only the purpose of each stage is required. ",
						},
					},
					13.62: {
						"sub-topic": "Transmission media (A-level only)",
						spec: {
							13.621:
								"Transmission-path media: metal wire, optic fibre, electromagnetic (radio, microwave).",
							13.622:
								" Ground wave, refraction and reflection of sky waves, diffraction of long-wavelength radiation around the Earth’s surface. ",
							13.623:
								"Satellite systems and typical transmission frequencies. ",
							13.624:
								"Students should recognise that up- and down-links require different frequencies so that the receivers are not de-sensed. ",
							13.625:
								"Advantages and disadvantages of various transmission media. Students should consider data transmission rate, cost, and security issues.",
						},
					},
					13.63: {
						"sub-topic": "Time-division multiplexing (A-level only)",
						spec: {
							13.631: "Basic principles of time-division multiplexing.",
						},
					},
					13.64: {
						"sub-topic":
							"Amplitude (AM) and frequency modulation (FM) techniques (A-level only)",
						spec: {
							13.641: "Principles of modulation; bandwidth.",
							13.642: " Carrier wave and information signal. ",
							13.643:
								"Details of modulation circuits for modulating a carrier signal with the information signal will not be required.",
							13.644:
								"Graphical representation of both AM and FM modulated signals.",
							13.645: "A detailed mathematical treatment is not required.",
							13.646:
								"Students will be expected to identify the carrier frequency and the information frequency from a graph of the variation of signal voltage with time. ",
							13.647: "Bandwidth requirements of simple AM and FM: ",
							13.648: "bandwidth = 2 f M for AM ",
							13.649: "bandwidth = 2 ∆ f + f M for FM ",
							13.641: "Data capacity of a channel.",
							13.6411:
								" Comparison of bandwidth availability for various media.",
						},
					},
				},
			},
		},
	},
};
