/** @format */

export const GCSEBiologyAQA = {
	//SUBJECT CONTENT

	//CELL BIOLOGY
	1: {
		topic: "Cell Structure",
		topicQuestions: "",
		//specLink: "https://www.aqa.org.uk/subjects/mathematics/gcse/mathematics-8300/specification-at-a-glance",
		code: "GCSEBiologyAQA",
		name: "GCSE Biology AQA",
		subject: "Biology",
		board: "AQA",
		level: "GCSE",

		subs: {
			//CELL STRUCTURE
			1.1: {
				"sub-topic": "Eukaryotes and prokaryotes",
				spec: {
					1.11: "Plant and animal cells (eukaryotic cells) have a cell membrane, cytoplasm and genetic material enclosed in a nucleus",
					1.12: "Bacterial cells (prokaryotic cells) are much smaller in comparison. They have cytoplasm and a cell membrane surrounded by a cell wall. The genetic material is not enclosed in a nucleus. It is a single DNA loop and there may be one or more small rings of DNA called plasmids.",
					1.13: "Students should be able to demonstrate an understanding of the scaleand size of cells and be able to make order of magnitude calculations,including the use of standard form.",
				},
			},
			1.2: {
				"sub-topic": "Animal and plant cells",
				spec: {
					1.21: "Students should be able to explain how the main sub-cellular structures, including the nucleus, cell membranes, mitochondria, chloroplasts in plant cells and plasmids in bacterial cells are related to their functions. \n Most animal cells have the following parts:\n • a nucleus\n • cytoplasm\n • a cell membrane\n • mitochondria\n • ribosomes.\n In addition to the parts found in animal cells, plant cells often have:\n • chloroplasts\n • a permanent vacuole filled with cell sap.\n Plant and algal cells also have a cell wall made of cellulose, which strengthens the cell.",
					1.22: "Students should be able to use estimations and explain when theyshould be used to judge the relative size or area of sub-cellularstructures.",
				},
			},
			1.3: {
				"sub-topic": "Cell specialisation",
				spec: {
					1.31: "Students should be able to, when provided with appropriate information, explain how the structure of different types of cell relate to their function in a tissue, an organ or organ system, or the whole organism.\n Cells may be specialised to carry out a particular function:\n • sperm cells, nerve cells and muscle cells in animals\n • root hair cells, xylem and phloem cells in plants.",

				}
			},
			1.4: {
				"sub-topic": "Cell differentiation",
				spec: {
					1.41: "Students should be able to explain the importance of cell differentiation.\n As an organism develops, cells differentiate to form different types of cells.\n • Most types of animal cell differentiate at an early stage.\n • Many types of plant cells retain the ability to differentiate throughout life.\n In mature animals, cell division is mainly restricted to repair and replacement. As a cell differentiates it acquires different sub-cellular structures to enable it to carry out a certain function. It has become a specialised cell.",

				}
			},
			1.5: {
				"sub-topic": "Microscopy",
				spec: {
					1.51: "Students should be able to:\n • understand how microscopy techniques have developed over time\n • explain how electron microscopy has increased understanding of sub-cellular structures.\n Limited to the differences in magnification and resolution.\n An electron microscope has much higher magnification and resolving power than a light microscope. This means that it can be used to study cells in much finer detail. This has enabled biologists to see and understand many more sub-cellular structures.",
					1.52: "Students should be able to carry out calculations involving magnification, real size and image size using the formula: \n magnification = size of image size of real object\n Students should be able to express answers in standard form if appropriate.",

				}
			},
			1.6: {
				"sub-topic": "Culturing microorganisms (biology only)",
				spec: {
					1.61: "Bacteria multiply by simple cell division (binary fission) as often as once every 20 minutes if they have enough nutrients and a suitable temperature.\n Bacteria can be grown in a nutrient broth solution or as colonies on an agar gel plate.\n Uncontaminated cultures of microorganisms are required for investigating the action of disinfectants and antibiotics.",
					1.62: "Students should be able to describe how to prepare an uncontaminated culture using aseptic technique.\n They should be able to explain why:\n • Petri dishes and culture media must be sterilised before use\n • inoculating loops used to transfer microorganisms to the media must be sterilised by passing them through a flame\n • the lid of the Petri dish should be secured with adhesive tape and stored upside down\n • in school laboratories, cultures should generally be incubated at 25°C.",
					1.63: "Students should be able to calculate cross-sectional areas of colonies or clear areas around colonies using πr²",
					1.64: "Students should be able to calculate the number of bacteria in a population after a certain time if given the mean division time.",
					1.65: "(HT only) Students should be able to express the answer in standard form.",
				}
			},
		},
	},
	2: {
		topic: "Cell Divison",
		topicQuestions: "",
		subs: {
			2.1: {
				"sub-topic": "Chromosomes",
				spec: {
					2.11: "The nucleus of a cell contains chromosomes made of DNA molecules. Each chromosome carries a large number of genes.\n In body cells the chromosomes are normally found in pairs.",
				},
			},
			2.2: {
				"sub-topic": "Mitosis and the cell cycle",
				spec: {
					2.21: "Cells divide in a series of stages called the cell cycle. Students should be able to describe the stages of the cell cycle, including mitosis.\n During the cell cycle the genetic material is doubled and then divided into two identical cells.\n Before a cell can divide it needs to grow and increase the number of sub-cellular structures such as ribosomes and mitochondria. The DNA replicates to form two copies of each chromosome.\n In mitosis one set of chromosomes is pulled to each end of the cell and the nucleus divides.\n Finally the cytoplasm and cell membranes divide to form two identical cells.\n Students need to understand the three overall stages of the cell cycle but do not need to know the different phases of the mitosis stage.\n Cell division by mitosis is important in the growth and development of multicellular organisms.\n Students should be able to recognise and describe situations in given contexts where mitosis is occurring.",
				},
			},
			2.3: {
				"sub-topic": "Stem cells",
				spec: {
					2.31: "A stem cell is an undifferentiated cell of an organism which is capable of giving rise to many more cells of the same type, and from which certain other cells can arise from differentiation.\n Students should be able to describe the function of stem cells in embryos, in adult animals and in the meristems in plants.\n Stem cells from human embryos can be cloned and made to differentiate into most different types of human cells.\n Stem cells from adult bone marrow can form many types of cells including blood cells.\n Meristem tissue in plants can differentiate into any type of plant cell, throughout the life of the plant.\n Knowledge and understanding of stem cell techniques are not required.\n Treatment with stem cells may be able to help conditions such as diabetes and paralysis.",
					2.32: "In therapeutic cloning an embryo is produced with the same genes as the patient. Stem cells from the embryo are not rejected by the patient’s body so they may be used for medical treatment.\n The use of stem cells has potential risks such as transfer of viral infection, and some people have ethical or religious objections.\n Stem cells from meristems in plants can be used to produce clones of plants quickly and economically.\n • Rare species can be cloned to protect from extinction.\n • Crop plants with special features such as disease resistance can be cloned to produce large numbers of identical plants for farmers.",
				},
			},
		},
	},
	3: {
		topic: "Transport in cells",
		topicQuestions: "",
		subs: {
			3.1: {
				"sub-topic": "Diffusion",
				spec: {
					3.11: "Substances may move into and out of cells across the cell membranes via diffusion.\n Diffusion is the spreading out of the particles of any substance in solution, or particles of a gas, resulting in a net movement from an area of higher concentration to an area of lower concentration.\n Some of the substances transported in and out of cells by diffusion are oxygen and carbon dioxide in gas exchange, and of the waste product urea from cells into the blood plasma for excretion in the kidney.\n Students should be able to explain how different factors affect the rate of diffusion.\n Factors which affect the rate of diffusion are:\n • the difference in concentrations (concentration gradient)\n • the temperature\n • the surface area of the membrane.\n A single-celled organism has a relatively large surface area to volume ratio. This allows sufficient transport of molecules into and out of the cell to meet the needs of the organism.",
					3.12: "Students should be able to calculate and compare surface area to volume ratios.\n Students should be able to explain the need for exchange surfaces and a transport system in multicellular organisms in terms of surface area to volume ratio.\n Students should be able to explain how the small intestine and lungs in mammals, gills in fish, and the roots and leaves in plants, are adapted for exchanging materials.\n In multicellular organisms, surfaces and organ systems are specialised for exchanging materials. This is to allow sufficient molecules to be transported into and out of cells for the organism’s needs. The effectiveness of an exchange surface is increased by:\n • having a large surface area\n • a membrane that is thin, to provide a short diffusion path\n • (in animals) having an efficient blood supply\n • (in animals, for gaseous exchange) being ventilated.",

				},
			},
			3.2: {
				"sub-topic": "Osmosis",
				spec: {
					3.21: "Water may move across cell membranes via osmosis. Osmosis is the diffusion of water from a dilute solution to a concentrated solution through a partially permeable membrane.",
					3.22: "Students should be able to:\n • use simple compound measures of rate of water uptake\n • use percentages\n • calculate percentage gain and loss of mass of plant tissue.",
					3.23: "Students should be able to plot, draw and interpret appropriate graphs.",
				},
			},
			3.3: {
				"sub-topic": "Active Transport",
				spec: {
					3.31: "Active transport moves substances from a more dilute solution to a more concentrated solution (against a concentration gradient). This requires energy from respiration.\n Active transport allows mineral ions to be absorbed into plant root hairs from very dilute solutions in the soil. Plants require ions for healthy growth.\n It also allows sugar molecules to be absorbed from lower concentrations in the gut into the blood which has a higher sugar concentration. Sugar molecules are used for cell respiration.\n Students should be able to:\n • describe how substances are transported into and out of cells by diffusion, osmosis and active transport\n • explain the differences between the three processes.",
				},
			},
		},
	},
	//ORGANISATION
	4: {
		topic: "Principles of organisation",
		topicQuestions: "",
		subs: {
			4.1: {
				"sub-topic": "Principles of organisation",
				spec: {
					4.11: "Cells are the basic building blocks of all living organisms.\nA tissue is a group of cells with a similar structure and function.\nOrgans are aggregations of tissues performing specific functions.\nOrgans are organised into organ systems, which work together to form organisms.",
				},
			},

		},
	},
	5: {
		topic: "Animal tissues, organs and organ systems",
		topicQuestions: "",
		subs: {
			5.1: {
				"sub-topic": "The human digestive system",
				spec: {
					5.11: "This section assumes knowledge of the digestive system studied in Key Stage 3 science.\n The digestive system is an example of an organ system in which several organs work together to digest and absorb food.\n Students should be able to relate knowledge of enzymes to Metabolism.\n Students should be able to describe the nature of enzyme molecules and relate their activity to temperature and pH changes.",
					5.12: "Students should be able to carry out rate calculations for chemical reactions.\n Enzymes catalyse specific reactions in living organisms due to the shape of their active site.",
					5.13: "Students should be able to use the ‘lock and key theory’ as a simplified model to explain enzyme action.\n Students should be able to recall the sites of production and the action of amylase, proteases and lipases.\n Students should be able to understand simple word equations but no chemical symbol equations are required.\n Digestive enzymes convert food into small soluble molecules that can be absorbed into the bloodstream.\n Carbohydrases break down carbohydrates to simple sugars. Amylase is a carbohydrase which breaks down starch.\n Proteases break down proteins to amino acids.\n Lipases break down lipids (fats) to glycerol and fatty acids.\n The products of digestion are used to build new carbohydrates, lipids and proteins. Some glucose is used in respiration.\n Bile is made in the liver and stored in the gall bladder. It is alkaline to neutralise hydrochloric acid from the stomach. It also emulsifies fat to form small droplets which increases the surface area. The alkaline conditions and large surface area increase the rate of fat breakdown by lipase.",

				},
			},
			5.2: {
				"sub-topic": "The heart and blood vessels",
				spec: {
					5.21: "Students should know the structure and functioning of the human heart and lungs, including how lungs are adapted for gaseous exchange.\n The heart is an organ that pumps blood around the body in a double circulatory system. The right ventricle pumps blood to the lungs where gas exchange takes place. The left ventricle pumps blood around the rest of the body.\n Knowledge of the blood vessels associated with the heart is limited to the aorta, vena cava, pulmonary artery, pulmonary vein and coronary arteries. Knowledge of the names of the heart valves is not required.\n Knowledge of the lungs is restricted to the trachea, bronchi, alveoli and the capillary network surrounding the alveoli.\n The natural resting heart rate is controlled by a group of cells located in the right atrium that act as a pacemaker. Artificial pacemakers are electrical devices used to correct irregularities in the heart rate.\n The body contains three different types of blood vessel:\n • arteries\n • veins\n • capillaries.\n Students should be able to explain how the structure of these vessels relates to their functions.",
					5.22: "Students should be able to use simple compound measures such as rate and carry out rate calculations for blood flow.",
				},
			},
			5.3: {
				"sub-topic": "Blood",
				spec: {
					5.31: "Blood is a tissue consisting of plasma, in which the red blood cells, white blood cells and platelets are suspended.\n Students should know the functions of each of these blood components.",
					5.32: "Students should be able to recognise different types of blood cells in a photograph or diagram, and explain how they are adapted to their functions.",
				},
			},
			5.4: {
				"sub-topic": "Coronary heart disease: a non-communicable disease",
				spec: {
					5.41: "Students should be able to evaluate the advantages and disadvantages of treating cardiovascular diseases by drugs, mechanical devices or transplant.\n In coronary heart disease layers of fatty material build up inside the coronary arteries, narrowing them. This reduces the flow of blood through the coronary arteries, resulting in a lack of oxygen for the heart muscle. Stents are used to keep the coronary arteries open. Statins are widely used to reduce blood cholesterol levels which slows down the rate of fatty material deposit.\n In some people heart valves may become faulty, preventing the valve from opening fully, or the heart valve might develop a leak. Students should understand the consequences of faulty valves. Faulty heart valves can be replaced using biological or mechanical valves.\n In the case of heart failure a donor heart, or heart and lungs can be transplanted. Artificial hearts are occasionally used to keep patients alive whilst waiting for a heart transplant, or to allow the heart to rest as an aid to recovery.",
				},
			},
			5.5: {
				"sub-topic": "Health issues",
				spec: {
					5.51: "Students should be able to describe the relationship between health and disease and the interactions between different types of disease.\n Health is the state of physical and mental well-being.\n Diseases, both communicable and non-communicable, are major causes of ill health. Other factors including diet, stress and life situations may have a profound effect on both physical and mental health.\n Different types of disease may interact.\n • Defects in the immune system mean that an individual is more likely to suffer from infectious diseases.\n • Viruses living in cells can be the trigger for cancers.\n • Immune reactions initially caused by a pathogen can trigger allergies such as skin rashes and asthma.\n • Severe physical ill health can lead to depression and other mental illness.",
					5.52: "Students should be able to translate disease incidence information between graphical and numerical forms, construct and interpret frequency tables and diagrams, bar charts and histograms, and use a scatter diagram to identify a correlation between two variables.",
					5.53: "Students should understand the principles of sampling as applied to scientific data, including epidemiological data.",
				},
			},
			5.6: {
				"sub-topic": "The effect of lifestyle on some non-communicable diseases",
				spec: {
					5.61: "Students should be able to:\n • discuss the human and financial cost of these non-communicable diseases to an individual, a local community, a nation or globally\n • explain the effect of lifestyle factors including diet, alcohol and smoking on the incidence of non-communicable diseases at local, national and global levels.",
					5.62: "Risk factors are linked to an increased rate of a disease.\n They can be:\n • aspects of a person’s lifestyle\n • substances in the person’s body or environment.\n A causal mechanism has been proven for some risk factors, but not in others.\n • The effects of diet, smoking and exercise on cardiovascular disease.\n • Obesity as a risk factor for Type 2 diabetes.\n • The effect of alcohol on the liver and brain function.\n • The effect of smoking on lung disease and lung cancer.\n • The effects of smoking and alcohol on unborn babies.\n • Carcinogens, including ionising radiation, as risk factors in cancer.\n Many diseases are caused by the interaction of a number of factors.",
					5.63: "Students should be able to understand the principles of sampling as applied to scientific data in terms of risk factors.",
					5.64: "Students should be able to translate information between graphical and numerical forms; and extract and interpret information from charts, graphs and tables in terms of risk factors.",
					5.65: "Students should be able to use a scatter diagram to identify a correlation between two variables in terms of risk factors.",
				},
			},
			5.7: {
				"sub-topic": "Cancer",
				spec: {
					5.71: "Students should be able to describe cancer as the result of changes in cells that lead to uncontrolled growth and division.\n Benign tumours are growths of abnormal cells which are contained in one area, usually within a membrane. They do not invade other parts of the body.\n Malignant tumour cells are cancers. They invade neighbouring tissues and spread to different parts of the body in the blood where they form secondary tumours.\n Scientists have identified lifestyle risk factors for various types of cancer. There are also genetic risk factors for some cancers.",

				},
			},
		},
	},
	6: {
		topic: "Plant tissues, organs and systems",
		topicQuestions: "",
		subs: {
			6.1: {
				"sub-topic": "Plant tissues",
				spec: {
					6.11: "Students should be able to explain how the structures of plant tissues are related to their functions.\nPlant tissues include:\n• epidermal tissues\n• palisade mesophyll\n• spongy mesophyll\n• xylem and phloem\n• meristem tissue found at the growing tips of shoots and roots.\nThe leaf is a plant organ. Knowledge limited to epidermis, palisade and spongy mesophyll, xylem and phloem, and guard cells surrounding stomata.",
					6.12: "Students should be able to explain how the structure of root hair cells, xylem and phloem are adapted to their functions.\nStudents should be able to explain the effect of changing temperature, humidity, air movement and light intensity on the rate of transpiration.",
					6.13: "Students should be able to understand and use simple compound measures such as the rate of transpiration.",
					6.14: "Students should be able to:\n• translate information between graphical and numerical form\n• plot and draw appropriate graphs, selecting appropriate scales for axes\n• extract and interpret information from graphs, charts and tables.",
					6.15: "The roots, stem and leaves form a plant organ system for transport of substances around the plant.\nStudents should be able to describe the process of transpiration and translocation, including the structure and function of the stomata.\nRoot hair cells are adapted for the efficient uptake of water by osmosis, and mineral ions by active transport.\nXylem tissue transports water and mineral ions from the roots to the stems and leaves. It is composed of hollow tubes strengthened by lignin adapted for the transport of water in the transpiration stream.\nThe role of stomata and guard cells are to control gas exchange and water loss.\nPhloem tissue transports dissolved sugars from the leaves to the rest of the plant for immediate use or storage. The movement of food molecules through phloem tissue is called translocation.\nPhloem is composed of tubes of elongated cells. Cell sap can move from one phloem cell to the next through pores in the end walls.\nDetailed structure of phloem tissue or the mechanism of transport is not required.",
				},
			},
			6.2: {
				"sub-topic": "Plant organ system",
				spec: {
					6.21: "Students should be able to explain how the structure of root hair cells, xylem and phloem are adapted to their functions.\nStudents should be able to explain the effect of changing temperature, humidity, air movement and light intensity on the rate of transpiration.",
					6.22: "Students should be able to understand and use simple compound measures such as the rate of transpiration.",
					6.23: "Students should be able to:\n• translate information between graphical and numerical form\n• plot and draw appropriate graphs, selecting appropriate scales for axes\n• extract and interpret information from graphs, charts and tables.",
					6.24: "The roots, stem and leaves form a plant organ system for transport of substances around the plant.\nStudents should be able to describe the process of transpiration and translocation, including the structure and function of the stomata.\nRoot hair cells are adapted for the efficient uptake of water by osmosis, and mineral ions by active transport.\nXylem tissue transports water and mineral ions from the roots to the stems and leaves. It is composed of hollow tubes strengthened by lignin adapted for the transport of water in the transpiration stream.\nThe role of stomata and guard cells are to control gas exchange and water loss.\nPhloem tissue transports dissolved sugars from the leaves to the rest of the plant for immediate use or storage. The movement of food molecules through phloem tissue is called translocation.\nPhloem is composed of tubes of elongated cells. Cell sap can move from one phloem cell to the next through pores in the end walls.\nDetailed structure of phloem tissue or the mechanism of transport is not required.",

				},
			},
		},
	},
	//Infection and response
	7: {
		topic: "Communicable diseases",
		topicQuestions: "",
		subs: {
			7.1: {
				"sub-topic": "Communicable (infectious) disease",
				spec: {
					7.11: "Students should be able to explain how diseases caused by viruses, bacteria, protists and fungi are spread in animals and plants.\nStudents should be able to explain how the spread of diseases can be reduced or prevented.\nPathogens are microorganisms that cause infectious disease. Pathogens may be viruses, bacteria, protists or fungi. They may infect plants or animals and can be spread by direct contact, by water or by air.\nBacteria and viruses may reproduce rapidly inside the body.\nBacteria may produce poisons (toxins) that damage tissues and make us feel ill.\nViruses live and reproduce inside cells, causing cell damage.",

				},
			},
			7.2: {
				"sub-topic": "Viral diseases",
				spec: {
					7.21: "Measles is a viral disease showing symptoms of fever and a red skin rash. Measles is a serious illness that can be fatal if complications arise. For this reason most young children are vaccinated against measles. The measles virus is spread by inhalation of droplets from sneezes and coughs.\nHIV initially causes a flu-like illness. Unless successfully controlled with antiretroviral drugs the virus attacks the body’s immune cells. Late stage HIV infection, or AIDS, occurs when the body’s immune system becomes so badly damaged it can no longer deal with other infections or cancers. HIV is spread by sexual contact or exchange of body fluids such as blood which occurs when drug users share needles.\nTobacco mosaic virus (TMV) is a widespread plant pathogen affecting many species of plants including tomatoes. It gives a distinctive ‘mosaic’ pattern of discolouration on the leaves which affects the growth of the plant due to lack of photosynthesis.",

				},
			},
			7.3: {
				"sub-topic": "Bacterial diseases",
				spec: {
					7.31: "Salmonella food poisoning is spread by bacteria ingested in food, or on food prepared in unhygienic conditions. In the UK, poultry are vaccinated against Salmonella to control the spread. Fever, abdominal cramps, vomiting and diarrhoea are caused by the bacteria and the toxins they secrete.\n Gonorrhoea is a sexually transmitted disease (STD) with symptoms of a thick yellow or green discharge from the vagina or penis and pain on urinating. It is caused by a bacterium and was easily treated with the antibiotic penicillin until many resistant strains appeared. Gonorrhoea is spread by sexual contact. The spread can be controlled by treatment with antibiotics or the use of a barrier method of contraception such as a condom.",

				},
			},
			7.4: {
				"sub-topic": "Fungal diseases",
				spec: {
					7.41: "Rose black spot is a fungal disease where purple or black spots develop on leaves, which often turn yellow and drop early. It affects the growth of the plant as photosynthesis is reduced. It is spread in the environment by water or wind. Rose black spot can be treated by using fungicides and/or removing and destroying the affected leaves.",

				},
			},
			7.5: {
				"sub-topic": "Protist diseases",
				spec: {
					7.51: "The pathogens that cause malaria are protists.\nThe malarial protist has a life cycle that includes the mosquito. Malaria causes recurrent episodes of fever and can be fatal. The spread of malaria is controlled by preventing the vectors, mosquitos, from breeding and by using mosquito nets to avoid being bitten.",

				},
			},
			7.6: {
				"sub-topic": " Human defence systems",
				spec: {
					7.61: "Students should be able to describe the non-specific defence systems of the human body against pathogens, including the:\n• skin\n• nose\n• trachea and bronchi\n• stomach.\nStudents should be able to explain the role of the immune system in the defence against disease.\nIf a pathogen enters the body the immune system tries to destroy the pathogen.\nWhite blood cells help to defend against pathogens by:\n• phagocytosis\n• antibody production\n• antitoxin production.\n",

				},
			},
			7.7: {
				"sub-topic": "Vaccination",
				spec: {
					7.71: "Students should be able to explain how vaccination will prevent illness in an individual, and how the spread of pathogens can be reduced by immunising a large proportion of the population.\nVaccination involves introducing small quantities of dead or inactive forms of a pathogen into the body to stimulate the white blood cells to produce antibodies. If the same pathogen re-enters the body the white blood cells respond quickly to produce the correct antibodies, preventing infection.\nStudents do not need to know details of vaccination schedules and side effects associated with specific vaccines.",

				},
			},
			7.8: {
				"sub-topic": "Antibiotics and painkillers",
				spec: {
					7.81: "Students should be able to explain the use of antibiotics and other medicines in treating disease.\nAntibiotics, such as penicillin, are medicines that help to cure bacterial disease by killing infective bacteria inside the body. It is important that specific bacteria should be treated by specific antibiotics.",
					7.82: "The use of antibiotics has greatly reduced deaths from infectious bacterial diseases. However, the emergence of strains resistant to antibiotics is of great concern.",
					7.83: "Antibiotics cannot kill viral pathogens.\nPainkillers and other medicines are used to treat the symptoms of disease but do not kill pathogens.\nIt is difficult to develop drugs that kill viruses without also damaging the body’s tissues.",

				},
			},
			7.9: {
				"sub-topic": "Discovery and development of drugs",
				spec: {
					7.91: "Students should be able to describe the process of discovery and development of potential new medicines, including preclinical and clinical testing.\nTraditionally drugs were extracted from plants and microorganisms.\n• The heart drug digitalis originates from foxgloves.\n• The painkiller aspirin originates from willow.\n• Penicillin was discovered by Alexander Fleming from the Penicilliummould.\nMost new drugs are synthesised by chemists in the pharmaceutical industry. However, the starting point may still be a chemical extracted from a plant.",
					7.92: "New medical drugs have to be tested and trialled before being used to check that they are safe and effective.\nNew drugs are extensively tested for toxicity, efficacy and dose.\nPreclinical testing is done in a laboratory using cells, tissues and live animals.\nClinical trials use healthy volunteers and patients.\n• Very low doses of the drug are given at the start of the clinical trial.\n• If the drug is found to be safe, further clinical trials are carried out to find the optimum dose for the drug.\n• In double blind trials, some patients are given a placebo",

				},
			},
		},
	},
	8: {
		topic: "Monoclonal antibodies (biology only) (HT only)",
		topicQuestions: "",
		subs: {
			8.1: {
				"sub-topic": "Producing monoclonal antibodies",
				spec: {
					8.11: "Students should be able to describe how monoclonal antibodies are produced.\nMonoclonal antibodies are produced from a single clone of cells. The antibodies are specific to one binding site on one protein antigen and so are able to target a specific chemical or specific cells in the body.\nThey are produced by stimulating mouse lymphocytes to make a particular antibody. The lymphocytes are combined with a particular kind of tumour cell to make a cell called a hybridoma cell. The hybridoma cell can both divide and make the antibody. Single hybridoma cells are cloned to produce many identical cells that all produce the same antibody. A large amount of the antibody can be collected and purified.",

				},
			},
			8.2: {
				"sub-topic": "Uses of monoclonal antibodies",
				spec: {
					8.21: "Students should be able to describe some of the ways in which monoclonal antibodies can be used.\nSome examples include:\n• for diagnosis such as in pregnancy tests\n• in laboratories to measure the levels of hormones and other chemicals in blood, or to detect pathogens\n• in research to locate or identify specific molecules in a cell or tissue by binding to them with a fluorescent dye\n• to treat some diseases: for cancer the monoclonal antibody can be bound to a radioactive substance, a toxic drug or a chemical which stops cells growing and dividing. It delivers the substance to the cancer cells without harming other cells in the body.\nStudents are not expected to recall any specific tests or treatments but given appropriate information they should be able to explain how they work.",
					8.22: "Monoclonal antibodies create more side effects than expected. They are not yet as widely used as everyone hoped when they were first developed.",

				},
			},

		},
	},
	9: {
		topic: "Plant disease (biology only)",
		topicQuestions: "",
		subs: {
			9.1: {
				"sub-topic": "Detection and identification of plant diseases",
				spec: {
					9.11: "(HT only) Plant diseases can be detected by:\n• stunted growth\n• spots on leaves\n• areas of decay (rot)\n• growths\n• malformed stems or leaves\n• discolouration\n• the presence of pests.\n(HT only) Identification can be made by:\n• reference to a gardening manual or website\n• taking infected plants to a laboratory to identify the pathogen\n• using testing kits that contain monoclonal antibodies",
					9.12: "Plants can be infected by a range of viral, bacterial and fungal pathogens as well as by insects.\nKnowledge of plant diseases is restricted to tobacco mosaic virus as a viral disease, black spot as a fungal disease and aphids as insects.\nPlants can be damaged by a range of ion deficiency conditions:\n• stunted growth caused by nitrate deficiency\n• chlorosis caused by magnesium deficiency.\nKnowledge of ions is limited to nitrate ions needed for protein synthesis and therefore growth, and magnesium ions needed to make chlorophyll.",

				},
			},
			9.2: {
				"sub-topic": "Plant defence responses",
				spec: {
					9.21: "Students should be able to describe physical and chemical plant defence responses.\nPhysical defence responses to resist invasion of microorganisms.\n• Cellulose cell walls.\n• Tough waxy cuticle on leaves.\n• Layers of dead cells around stems (bark on trees) which fall off.\nChemical plant defence responses.\n• Antibacterial chemicals.\n• Poisons to deter herbivores.\nMechanical adaptations.\n• Thorns and hairs deter animals.\n• Leaves which droop or curl when touched.\n• Mimicry to trick animals.",


				},
			},

		},
	},
	//Bioenergetics
	10: {
		topic: "Photosynthesis",
		topicQuestions: "",
		subs: {
			10.1: {
				"sub-topic": "Photosynthetic reaction",
				spec: {
					10.11: "Photosynthesis is represented by the equation:\ncarbon dioxide + water light glucose + oxygenStudents should recognise the chemical symbols: \nCO2, H2O, O2 and C6H12O6",
					10.12: "Students should be able to describe photosynthesis as an endothermic reaction in which energy is transferred from the environment to the chloroplasts by light.",

				},
			},
			10.2: {
				"sub-topic": "Rate of photosynthesis",
				spec: {
					10.21: "Students should be able to explain the effects of temperature, light intensity, carbon dioxide concentration, and the amount of chlorophyll on the rate of photosynthesis",
					10.22: "Students should be able to:\n• measure and calculate rates of photosynthesis\n• extract and interpret graphs of photosynthesis rate involving one limiting factor\n• plot and draw appropriate graphs selecting appropriate scale for axes\n• translate information between graphical and numeric form.",
					10.23: "(HT only) These factors interact and any one of them may be the factor that limits photosynthesis.\n(HT only) Students should be able to explain graphs of photosynthesis rate involving two or three factors and decide which is the limiting factor.",
					10.24: "(HT only) Students should understand and use inverse proportion – the inverse square law and light intensity in the context of photosynthesis.\n(HT only) Limiting factors are important in the economics of enhancing the conditions in greenhouses to gain the maximum rate of photosynthesis while still maintaining profit.",

				},
			},
			10.3: {
				"sub-topic": "Uses of glucose from photosynthesis",
				spec: {
					10.31: "The glucose produced in photosynthesis may be:\n• used for respiration\n• converted into insoluble starch for storage\n• used to produce fat or oil for storage\n• used to produce cellulose, which strengthens the cell wall\n• used to produce amino acids for protein synthesis.\nTo produce proteins, plants also use nitrate ions that are absorbed from the soil",

				},
			},
		},
	},
	11: {
		topic: "Respiration",
		topicQuestions: "",
		subs: {
			11.1: {
				"sub-topic": "Aerobic and anaerobic respiration",
				spec: {
					11.11: "Students should be able to describe cellular respiration as an exothermic reaction which is continuously occurring in living cells.\nThe energy transferred supplies all the energy needed for living processes.\nRespiration in cells can take place aerobically (using oxygen) or anaerobically (without oxygen), to transfer energy.\nStudents should be able to compare the processes of aerobic and anaerobic respiration with regard to the need for oxygen, the differing products and the relative amounts of energy transferred.\nOrganisms need energy for:\n• chemical reactions to build larger molecules\n• movement\n• keeping warm.\nAerobic respiration is represented by the equation:\nglucose + oxygen carbon dioxide + water\nStudents should recognise the chemical symbols: \nC6H12O6, O2, CO2 and H2O.\nAnaerobic respiration in muscles is represented by the equation:\nglucose lactic acid\nAs the oxidation of glucose is incomplete in anaerobic respiration much less energy is transferred than in aerobic respiration.\nAnaerobic respiration in plant and yeast cells is represented by the equation:\nglucose ethanol + carbon dioxide\nAnaerobic respiration in yeast cells is called fermentation and has economic importance in the manufacture of bread and alcoholic drinks.",

				},
			},
			11.2: {
				"sub-topic": "Response to exercise",
				spec: {
					11.21: "During exercise the human body reacts to the increased demand for energy.\nThe heart rate, breathing rate and breath volume increase during exercise to supply the muscles with more oxygenated blood.\nIf insufficient oxygen is supplied anaerobic respiration takes place in muscles. The incomplete oxidation of glucose causes a build up of lactic acid and creates an oxygen debt. During long periods of vigorous activity muscles become fatigued and stop contracting efficiently.",
					11.22: "(HT only) Blood flowing through the muscles transports the lactic acid to the liver where it is converted back into glucose. Oxygen debt is the amount of extra oxygen the body needs after exercise to react with the accumulated lactic acid and remove it from the cells.",

				},
			},
			11.3: {
				"sub-topic": "Metabolism",
				spec: {
					11.31: "Students should be able to explain the importance of sugars, amino acids, fatty acids and glycerol in the synthesis and breakdown of carbohydrates, proteins and lipids.\nMetabolism is the sum of all the reactions in a cell or the body.\nThe energy transferred by respiration in cells is used by the organism for the continual enzyme controlled processes of metabolism that synthesise new molecules.\nMetabolism includes:\n• conversion of glucose to starch, glycogen and cellulose\n• the formation of lipid molecules from a molecule of glycerol and three molecules of fatty acids\n• the use of glucose and nitrate ions to form amino acids which in turn are used to synthesise proteins\n• respiration\n• breakdown of excess proteins to form urea for excretion.\nAll of these aspects are covered in more detail in the relevant specification section but are linked together here.",
				},
			},

		},
	},

	//Homeostasis and response
	12: {
		topic: "Homeostasis",
		topicQuestions: "",
		subs: {
			12.1: {
				"sub-topic": "",
				spec: {
					12.11: "Students should be able to explain that homeostasis is the regulation of the internal conditions of a cell or organism to maintain optimum conditions for function in response to internal and external changes.\nHomeostasis maintains optimal conditions for enzyme action and all cell functions.\nIn the human body, these include control of:\n• blood glucose concentration\n• body temperature\n• water levels.\nThese automatic control systems may involve nervous responses or chemical responses.\nAll control systems include:\n• cells called receptors, which detect stimuli (changes in the environment)\n• coordination centres (such as the brain, spinal cord and pancreas) that receive and process information from receptors\n• effectors, muscles or glands, which bring about responses which restore optimum levels.",

				},
			},
		},
	},
	13: {
		topic: "The human nervous system",
		topicQuestions: "",
		subs: {
			13.1: {
				"sub-topic": "Structure and function",
				spec: {
					13.11: "Students should be able to explain how the structure of the nervous system is adapted to its functions.\nThe nervous system enables humans to react to their surroundings and to coordinate their behaviour.\nInformation from receptors passes along cells (neurones) as electrical impulses to the central nervous system (CNS). The CNS is the brain and spinal cord. The CNS coordinates the response of effectors which may be muscles contracting or glands secreting hormones.\nstimulus receptor coordinator effector response\nStudents should be able to explain how the various structures in a reflex arc – including the sensory neurone, synapse, relay neurone and motor neurone – relate to their function. Students should understand why reflex actions are important.\nReflex actions are automatic and rapid; they do not involve the conscious part of the brain.",
					13.12: "Students should be able to extract and interpret data from graphs, charts and tables, about the functioning of the nervous system.",
					13.13: "Students should be able to extract and interpret data from graphs, charts and tables, about the functioning of the nervous system.",

				},
			},
			13.2: {
				"sub-topic": "The brain (biology only)",
				spec: {
					13.21: "The brain controls complex behaviour. It is made of billions of interconnected neurones and has different regions that carry out different functions.\nStudents should be able to identify the cerebral cortex, cerebellum and medulla on a diagram of the brain, and describe their functions.",
					13.22: "(HT only) Students should be able to explain some of the difficulties of investigating brain function and treating brain damage and disease.\n(HT only) Neuroscientists have been able to map the regions of the brain to particular functions by studying patients with brain damage, electrically stimulating different parts of the brain and using MRI scanning techniques. The complexity and delicacy of the brain makes investigating and treating brain disorders very difficult",

				},
			},
			13.3: {
				"sub-topic": "The eye (biology only)",
				spec: {
					13.31: "Students should be able to relate the structures of the eye to their functions. This includes:\n• accommodation to focus on near or distant objects\n• adaptation to dim light.\nThe eye is a sense organ containing receptors sensitive to light intensity and colour",
					13.32: "Students should be able to identify the following structures on a diagram of the eye and explain how their structure is related to their function:\n• retina\n• optic nerve\n• sclera\n• cornea\n• iris\n• ciliary muscles\n• suspensory ligaments.\nAccommodation is the process of changing the shape of the lens to focus on near or distant objects.\nTo focus on a near object:\n• the ciliary muscles contract\n• the suspensory ligaments loosen\n• the lens is then thicker and refracts light rays strongly.\nTo focus on a distant object:\n• the ciliary muscles relax\n• the suspensory ligaments are pulled tight\n• the lens is then pulled thin and only slightly refracts light rays.\nTwo common defects of the eyes are myopia (short sightedness) and hyperopia (long sightedness) in which rays of light do not focus on the retina.\n• Generally these defects are treated with spectacle lenses which refract the light rays so that they do focus on the retina.\n• New technologies now include hard and soft contact lenses, laser surgery to change the shape of the cornea and a replacement lens in the eye.",
					13.33: "Students should be able to interpret ray diagrams, showing these two common defects of the eye and demonstrate how spectacle lenses correct them",

				},
			},
			13.4: {
				"sub-topic": "Control of body temperature (biology only)",
				spec: {
					13.41: "Body temperature is monitored and controlled by the thermoregulatory centre in the brain. The thermoregulatory centre contains receptors sensitive to the temperature of the blood. The skin contains temperature receptors and sends nervous impulses to the thermoregulatory centre.\nIf the body temperature is too high, blood vessels dilate (vasodilation) and sweat is produced from the sweat glands. Both these mechanisms cause a transfer of energy from the skin to the environment.\nIf the body temperature is too low, blood vessels constrict (vasoconstriction), sweating stops and skeletal muscles contract (shiver).",
					13.42: "(HT only) Students should be able to explain how these mechanisms lower or raise body temperature in a given context",

				},
			},
		},
	},

	14: {
		topic: "Hormonal coordination in humans",
		topicQuestions: "",
		subs: {
			14.1: {
				"sub-topic": "Human endocrine system",
				spec: {
					14.11: "Students should be able to describe the principles of hormonal coordination and control by the human endocrine system.\nThe endocrine system is composed of glands which secrete chemicals called hormones directly into the bloodstream. The blood carries the hormone to a target organ where it produces an effect. Compared to the nervous system the effects are slower but act for longer.\nThe pituitary gland in the brain is a ‘master gland’ which secretes several hormones into the blood in response to body conditions. These hormones in turn act on other glands to stimulate other hormones to be released to bring about effects.\nStudents should be able to identify the position of the following on a diagram of the human body:• pituitary gland\n• pancreas\n• thyroid\n• adrenal gland\n• ovary\n• testes",

				},
			},
			14.2: {
				"sub-topic": "Control of blood glucose concentration",
				spec: {
					14.21: "Blood glucose concentration is monitored and controlled by the pancreas.\nIf the blood glucose concentration is too high, the pancreas produces the hormone insulin that causes glucose to move from the blood into the cells. In liver and muscle cells excess glucose is converted to glycogen for storage.\nStudents should be able to explain how insulin controls blood glucose (sugar) levels in the body.\nType 1 diabetes is a disorder in which the pancreas fails to produce sufficient insulin. It is characterised by uncontrolled high blood glucose levels and is normally treated with insulin injections.\nIn Type 2 diabetes the body cells no longer respond to insulin produced by the pancreas. A carbohydrate controlled diet and an exercise regime are common treatments. Obesity is a risk factor for Type 2 diabetes.\nStudents should be able to compare Type 1 and Type 2 diabetes and explain how they can be treated.",
					14.22: "Students should be able to extract information and interpret data from graphs that show the effect of insulin in blood glucose levels in both people with diabetes and people without diabetes.",
					14.23: "(HT only) If the blood glucose concentration is too low, the pancreas produces the hormone glucagon that causes glycogen to be converted into glucose and released into the blood.\n(HT only) Students should be able to explain how glucagon interacts with insulin in a negative feedback cycle to control blood glucose (sugar) levels in the body.",

				},
			},
			14.3: {
				"sub-topic": "Maintaining water and nitrogen balance in the body (biology only)",
				spec: {
					14.31: "Students should be able to explain the effect on cells of osmotic changes in body fluids.\nWater leaves the body via the lungs during exhalation.\nWater, ions and urea are lost from the skin in sweat.\nThere is no control over water, ion or urea loss by the lungs or skin.\nExcess water, ions and urea are removed via the kidneys in the urine.\nIf body cells lose or gain too much water by osmosis they do not function efficiently",
					14.32: "(HT only) The digestion of proteins from the diet results in excess amino acids which need to be excreted safely. In the liver these amino acids are deaminated to form ammonia. Ammonia is toxic and so it is immediately converted to urea for safe excretion",
					14.33: "Students should be able to describe the function of kidneys in maintaining the water balance of the body.\nThe kidneys produce urine by filtration of the blood and selective reabsorption of useful substances such as glucose, some ions and water.\nKnowledge of other parts of the urinary system, the structure of the kidney and the structure of a nephron is not required",
					14.34: "Students should be able to translate tables and bar charts of glucose, ions and urea before and after filtration",
					14.35: "(HT only) Students should be able to describe the effect of ADH on the permeability of the kidney tubules.\n(HT only) The water level in the body is controlled by the hormone ADH which acts on the kidney tubules. ADH is released by the pituitary gland when the blood is too concentrated and it causes more water to be reabsorbed back into the blood from the kidney tubules. This is controlled by negative feedback",
					14.36: "People who suffer from kidney failure may be treated by organ transplant or by using kidney dialysis. Students should know the basic principles of dialysis",
				},
			},
			14.4: {
				"sub-topic": "Hormones in human reproduction",
				spec: {
					14.41: "Students should be able to describe the roles of hormones in human reproduction, including the menstrual cycle.\nDuring puberty reproductive hormones cause secondary sex characteristics to develop.\nOestrogen is the main female reproductive hormone produced in the ovary. At puberty eggs begin to mature and one is released approximately every 28 days. This is called ovulation.\nTestosterone is the main male reproductive hormone produced by the testes and it stimulates sperm production.\nSeveral hormones are involved in the menstrual cycle of a woman.\n• Follicle stimulating hormone (FSH) causes maturation of an egg in the ovary.\n• Luteinising hormone (LH) stimulates the release of the egg.\n• Oestrogen and progesterone are involved in maintaining the uterus lining",
					14.42: "(HT only) Students should be able to explain the interactions of FSH, oestrogen, LH and progesterone, in the control of the menstrual cycle.",
					14.43: "(HT only) Students should be able to extract and interpret data from graphs showing hormone levels during the menstrual cycle.",
				},
			},
			14.5: {
				"sub-topic": "Contraception",
				spec: {
					14.51: "Students should be able to evaluate the different hormonal and nonhormonal methods of contraception.\nFertility can be controlled by a variety of hormonal and non-hormonal methods of contraception.\nThese include:\n• oral contraceptives that contain hormones to inhibit FSH production so that no eggs mature\n• injection, implant or skin patch of slow release progesterone to inhibit the maturation and release of eggs for a number of months or years\n• barrier methods such as condoms and diaphragms which prevent the sperm reaching an egg\n• intrauterine devices which prevent the implantation of an embryo or release a hormone\n• spermicidal agents which kill or disable sperm\n• abstaining from intercourse when an egg may be in the oviduct\n• surgical methods of male and female sterilisation",

				},
			},
			14.6: {
				"sub-topic": "The use of hormones to treat infertility (HT only)",
				spec: {
					14.61: "Students should be able to explain the use of hormones in modern reproductive technologies to treat infertility.\nThis includes giving FSH and LH in a ‘fertility drug’ to a woman. She may then become pregnant in the normal way.\nIn Vitro Fertilisation (IVF) treatment.\n• IVF involves giving a mother FSH and LH to stimulate the maturation of several eggs.\n• The eggs are collected from the mother and fertilised by sperm from the father in the laboratory.\n• The fertilised eggs develop into embryos.\n• At the stage when they are tiny balls of cells, one or two embryos are inserted into the mother’s uterus (womb)",
					14.62: "Although fertility treatment gives a woman the chance to have a baby of her own:\n• it is very emotionally and physically stressful\n• the success rates are not high\n• it can lead to multiple births which are a risk to both the babies and the mother",
				},
			},
			14.7: {
				"sub-topic": "Negative feedback (HT only)",
				spec: {
					14.71: "Students should be able to explain the roles of thyroxine and adrenaline in the body.\nAdrenaline is produced by the adrenal glands in times of fear or stress. It increases the heart rate and boosts the delivery of oxygen and glucose to the brain and muscles, preparing the body for ‘flight or fight’.\nThyroxine from the thyroid gland stimulates the basal metabolic rate. It plays an important role in growth and development.",
					14.72: "Thyroxine levels are controlled by negative feedback.",

				},
			},
		},
	},

	15: {
		topic: "Plant hormones (biology only)",
		topicQuestions: "",
		subs: {
			15.1: {
				"sub-topic": " Control and coordination",
				spec: {
					15.11: "Plants produce hormones to coordinate and control growth and responses to light (phototropism) and gravity (gravitropism or geotropism). Unequal distributions of auxin cause unequal growth rates in plant roots and shoots",
					15.12: "(HT only) Gibberellins are important in initiating seed germination.\n(HT only) Ethene controls cell division and ripening of fruits.\n(HT only) The mechanisms of how gibberellins and ethene work are not required",

				},
			},
			15.2: {
				"sub-topic": "Use of plant hormones (HT only)",
				spec: {
					15.21: "Students should be able to describe the effects of some plant hormones and the different ways people use them to control plant growth.\nPlant growth hormones are used in agriculture and horticulture.\nAuxins are used:\n• as weed killers\n• as rooting powders\n• for promoting growth in tissue culture.\nEthene is used in the food industry to control ripening of fruit during storage and transport.\nGibberellins can be used to:\n• end seed dormancy\n• promote flowering\n• increase fruit size\n",

				},
			},

		},
	},

	//Inheritance, variation and evolution
	16: {
		topic: "Reproduction",
		topicQuestions: "",
		subs: {
			16.1: {
				"sub-topic": "Sexual and asexual reproduction",
				spec: {
					16.11: "Students should understand that meiosis leads to non-identical cells being formed while mitosis leads to identical cells being formed.\nSexual reproduction involves the joining (fusion) of male and female gametes:\n• sperm and egg cells in animals\n• pollen and egg cells in flowering plants.\nIn sexual reproduction there is mixing of genetic information which leads to variety in the offspring. The formation of gametes involves meiosis.\nAsexual reproduction involves only one parent and no fusion of gametes. There is no mixing of genetic information. This leads to genetically identical offspring (clones). Only mitosis is involved",

				},
			},
			16.2: {
				"sub-topic": "Meiosis",
				spec: {
					16.21: "Students should be able to explain how meiosis halves the number of chromosomes in gametes and fertilisation restores the full number of chromosomes.\nCells in reproductive organs divide by meiosis to form gametes.\nWhen a cell divides to form gametes:\n• copies of the genetic information are made\n• the cell divides twice to form four gametes, each with a single set of chromosomes\n• all gametes are genetically different from each other.\nGametes join at fertilisation to restore the normal number of chromosomes. The new cell divides by mitosis. The number of cells increases. As the embryo develops cells differentiate.\nKnowledge of the stages of meiosis is not required",

				},
			},
			16.3: {
				"sub-topic": "Advantages and disadvantages of sexual and asexual reproduction (biology only)",
				spec: {
					16.31: "Advantages of sexual reproduction:\n• produces variation in the offspring\n• if the environment changes variation gives a survival advantage by natural selection\n• natural selection can be speeded up by humans in selective breeding to increase food production.\nAdvantages of asexual reproduction:\n• only one parent needed\n• more time and energy efficient as do not need to find a mate\n• faster than sexual reproduction\n• many identical offspring can be produced when conditions are favourable",
					16.32: "Some organisms reproduce by both methods depending on the circumstances.\n• Malarial parasites reproduce asexually in the human host, but sexually in the mosquito.\n• Many fungi reproduce asexually by spores but also reproduce sexually to give variation.\n• Many plants produce seeds sexually, but also reproduce asexually by runners such as strawberry plants, or bulb division such as daffodils.\nKnowledge of reproduction in organisms is restricted to those mentioned.\nStudents are expected to be able to explain the advantages and disadvantages of asexual and sexual reproduction for any organism if given appropriate information.",

				},
			},
			16.4: {
				"sub-topic": "DNA and the genome",
				spec: {
					16.41: "Students should be able to describe the structure of DNA and define genome.\nThe genetic material in the nucleus of a cell is composed of a chemical called DNA. DNA is a polymer made up of two strands forming a double helix. The DNA is contained in structures called chromosomes.\nA gene is a small section of DNA on a chromosome. Each gene codes for a particular sequence of amino acids, to make a specific protein.\nThe genome of an organism is the entire genetic material of that organism. The whole human genome has now been studied and this will have great importance for medicine in the future",
					16.42: "Students should be able to discuss the importance of understanding the human genome.\nThis is limited to the:\n• search for genes linked to different types of disease\n• understanding and treatment of inherited disorders\n• use in tracing human migration patterns from the past.",

				},
			},
			16.5: {
				"sub-topic": "DNA structure (biology only)",
				spec: {
					16.51: "Students should be able to describe DNA as a polymer made from four different nucleotides. Each nucleotide consists of a common sugar and phosphate group with one of four different bases attached to the sugar.\nDNA contains four bases, A, C, G and T.\nA sequence of three bases is the code for a particular amino acid. The order of bases controls the order in which amino acids are assembled to produce a particular protein",
					16.52: "The long strands of DNA consist of alternating sugar and phosphate sections. Attached to each sugar is one of the four bases.\nThe DNA polymer is made up of repeating nucleotide units.",
					16.53: "(HT only) Students should be able to:\n• recall a simple description of protein synthesis\n• explain simply how the structure of DNA affects the protein made\n• describe how genetic variants may influence phenotype: a) in coding DNA by altering the activity of a protein: and b) in non-coding DNA by altering how genes are expressed.\n(HT only) In the complementary strands a C is always linked to a G on the opposite strand and a T to an A.\n(HT only) Students are not expected to know or understand the structure of mRNA, tRNA, or the detailed structure of amino acids or proteins.\n(HT only) Students should be able to explain how a change in DNA structure may result in a change in the protein synthesised by a gene.\n(HT only) Proteins are synthesised on ribosomes, according to a template. Carrier molecules bring specific amino acids to add to the growing protein chain in the correct order.\n(HT only) When the protein chain is complete it folds up to form a unique shape. This unique shape enables the proteins to do their job as enzymes, hormones or forming structures in the body such as collagen.",
					16.54: "(HT only) Mutations occur continuously. Most do not alter the protein, or only alter it slightly so that its appearance or function is not changed.\n(HT only) A few mutations code for an altered protein with a different shape. An enzyme may no longer fit the substrate binding site or a structural protein may lose its strength.\n(HT only) Not all parts of DNA code for proteins. Non-coding parts of DNA can switch genes on and off, so variations in these areas of DNA may affect how genes are expressed",

				},
			},
			16.6: {
				"sub-topic": "Genetic inheritance",
				spec: {
					16.61: "Students should be able to explain the terms:\n• gamete\n• chromosome\n• gene\n• allele\n• dominant\n• recessive\n• homozygous\n• heterozygous\n• genotype\n• phenotype.\nSome characteristics are controlled by a single gene, such as: fur colour in mice; and red-green colour blindness in humans. Each gene may have different forms called alleles.\nThe alleles present, or genotype, operate at a molecular level to develop characteristics that can be expressed as a phenotype.\nA dominant allele is always expressed, even if only one copy is present. A recessive allele is only expressed if two copies are present (therefore no dominant allele present).\nIf the two alleles present are the same the organism is homozygous for that trait, but if the alleles are different they are heterozygous.\nMost characteristics are a result of multiple genes interacting, rather than a single gene",
					16.62: "Students should be able to understand the concept of probability in predicting the results of a single gene cross, but recall that most phenotype features are the result of multiple genes rather than single gene inheritance.",
					16.63: "Students should be able to use direct proportion and simple ratios to express the outcome of a genetic cross.",
					16.64: "Students should be able to complete a Punnett square diagram and extract and interpret information from genetic crosses and family trees.",
					16.65: "(HT only) Students should be able to construct a genetic cross by Punnett square diagram and use it to make predictions using the theory of probability",
				},
			},
			16.7: {
				"sub-topic": "Inherited disorders",
				spec: {
					16.71: "Some disorders are inherited. These disorders are caused by the inheritance of certain alleles.\n• Polydactyly (having extra fingers or toes) is caused by a dominant allele.\n• Cystic fibrosis (a disorder of cell membranes) is caused by a recessive allele.\nStudents should make informed judgements about the economic, social and ethical issues concerning embryo screening, given appropriate information",

				},
			},
			16.8: {
				"sub-topic": "Sex determination",
				spec: {
					16.81: "Ordinary human body cells contain 23 pairs of chromosomes.\n22 pairs control characteristics only, but one of the pairs carries the genes that determine sex.\n• In females the sex chromosomes are the same (XX).\n• In males the chromosomes are different (XY)",
					16.82: "Students should be able to carry out a genetic cross to show sex inheritance.\nStudents should understand and use direct proportion and simple ratios in genetic crosses.",

				},
			},
		},
	},
	17: {
		topic: "Variation and evolution",
		topicQuestions: "",
		subs: {
			17.1: {
				"sub-topic": "Variation",
				spec: {
					17.11: "Students should be able to describe simply how the genome and its interaction with the environment influence the development of the phenotype of an organism.\nDifferences in the characteristics of individuals in a population is called variation and may be due to differences in:\n• the genes they have inherited (genetic causes)\n• the conditions in which they have developed (environmental causes)\n• a combination of genes and the environment",
					17.12: "Students should be able to:\n• state that there is usually extensive genetic variation within a population of a species\n• recall that all variants arise from mutations and that: most have no effect on the phenotype; some influence phenotype; very few determine phenotype.\nMutations occur continuously. Very rarely a mutation will lead to a new phenotype. If the new phenotype is suited to an environmental change it can lead to a relatively rapid change in the species",

				},
			},
			17.2: {
				"sub-topic": "Evolution",
				spec: {
					17.21: "Students should be able to describe evolution as a change in the inherited characteristics of a population over time through a process of natural selection which may result in the formation of a new species.\nThe theory of evolution by natural selection states that all species of living things have evolved from simple life forms that first developed more than three billion years ago.\nStudents should be able to explain how evolution occurs through natural selection of variants that give rise to phenotypes best suited to their environment.\nIf two populations of one species become so different in phenotype that they can no longer interbreed to produce fertile offspring they have formed two new species",

				},
			},
			17.3: {
				"sub-topic": "Selective breeding",
				spec: {
					17.31: "Students should be able to explain the impact of selective breeding of food plants and domesticated animals.\nSelective breeding (artificial selection) is the process by which humans breed plants and animals for particular genetic characteristics. Humans have been doing this for thousands of years since they first bred food crops from wild plants and domesticated animals.\nSelective breeding involves choosing parents with the desired characteristic from a mixed population. They are bred together. From the offspring those with the desired characteristic are bred together. This continues over many generations until all the offspring show the desired characteristic.\nThe characteristic can be chosen for usefulness or appearance:\n• Disease resistance in food crops.\n• Animals which produce more meat or milk.\n• Domestic dogs with a gentle nature.\n• Large or unusual flowers.\nSelective breeding can lead to ‘inbreeding’ where some breeds are particularly prone to disease or inherited defects",

				},
			},
			17.4: {
				"sub-topic": "Genetic engineering",
				spec: {
					17.41: "Students should be able to describe genetic engineering as a process which involves modifying the genome of an organism by introducing a gene from another organism to give a desired characteristic.\nPlant crops have been genetically engineered to be resistant to diseases or to produce bigger better fruits",
					17.42: "Bacterial cells have been genetically engineered to produce useful substances such as human insulin to treat diabetes",
					17.43: "Students should be able to explain the potential benefits and risks of genetic engineering in agriculture and in medicine and that some people have objections.\nIn genetic engineering, genes from the chromosomes of humans and other organisms can be ‘cut out’ and transferred to cells of other organisms.\nCrops that have had their genes modified in this way are called genetically modified (GM) crops. GM crops include ones that are resistant to insect attack or to herbicides. GM crops generally show increased yields.\nConcerns about GM crops include the effect on populations of wild flowers and insects. Some people feel the effects of eating GM crops on human health have not been fully explored.\nModern medical research is exploring the possibility of genetic modification to overcome some inherited disorders",
					17.44: "(HT only) Students should be able to describe the main steps in the process of genetic engineering.\n(HT only) In genetic engineering:\n• enzymes are used to isolate the required gene; this gene is inserted into a vector, usually a bacterial plasmid or a virus\n• the vector is used to insert the gene into the required cells\n• genes are transferred to the cells of animals, plants or microorganisms at an early stage in their development so that they develop with desired characteristics",

				},
			},
			17.5: {
				"sub-topic": "Cloning (biology only)",
				spec: {
					17.51: "Tissue culture: using small groups of cells from part of a plant to grow identical new plants. This is important for preserving rare plant species or commercially in nurseries.\nCuttings: an older, but simple, method used by gardeners to produce many identical new plants from a parent plant.\nEmbryo transplants: splitting apart cells from a developing animal embryo before they become specialised, then transplanting the identical embryos into host mothers.\nAdult cell cloning: \n• The nucleus is removed from an unfertilised egg cell.\n• The nucleus from an adult body cell, such as a skin cell, is inserted into the egg cell.\n• An electric shock stimulates the egg cell to divide to form an embryo.\n• These embryo cells contain the same genetic information as the adult skin cell.\n• When the embryo has developed into a ball of cells, it is inserted into the womb of an adult female to continue its development.",

				},
			},
		},
	},
	18: {
		topic: "The development of understanding of genetics and evolution",
		topicQuestions: "",
		subs: {
			18.1: {
				"sub-topic": "Theory of evolution (biology only)",
				spec: {
					18.11: "Charles Darwin, as a result of observations on a round the world expedition, backed by years of experimentation and discussion and linked to developing knowledge of geology and fossils, proposed the theory of evolution by natural selection.\n• Individual organisms within a particular species show a wide range of variation for a characteristic.\n• Individuals with characteristics most suited to the environment are more likely to survive to breed successfully.\n• The characteristics that have enabled these individuals to survive are then passed on to the next generation.\nDarwin published his ideas in On the Origin of Species (1859). There was much controversy surrounding these revolutionary new ideas.\nThe theory of evolution by natural selection was only gradually accepted because:\n• the theory challenged the idea that God made all the animals and plants that live on Earth\n• there was insufficient evidence at the time the theory was published to convince many scientists\n• the mechanism of inheritance and variation was not known until 50 years after the theory was published.\nOther theories, including that of Jean-Baptiste Lamarck, are based mainly on the idea that changes that occur in an organism during its lifetime can be inherited. We now know that in the vast majority of cases this type of inheritance cannot occur.\nA study of creationism is not required",

				},
			},
			18.2: {
				"sub-topic": "Speciation (biology only)",
				spec: {
					18.21: "Students should be able to:\n• describe the work of Darwin and Wallace in the development of the theory of evolution by natural selection\n• explain the impact of these ideas on biology.\nAlfred Russel Wallace independently proposed the theory of evolution by natural selection. He published joint writings with Darwin in 1858 which prompted Darwin to publish On the Origin of Species (1859) the following year.\nWallace worked worldwide gathering evidence for evolutionary theory. He is best known for his work on warning colouration in animals and his theory of speciation.\nAlfred Wallace did much pioneering work on speciation but more evidence over time has led to our current understanding of the theory of speciation.\nStudents should be able to describe the steps which give rise to new species.",

				},
			},
			18.3: {
				"sub-topic": "The understanding of genetics (biology only)",
				spec: {
					18.31: "Students should be able to:\n• describe the development of our understanding of genetics including the work of Mendel\n• understand why the importance of Mendel’s discovery was not recognised until after his death.\nIn the mid-19th century Gregor Mendel carried out breeding experiments on plants. One of his observations was that the inheritance of each characteristic is determined by ‘units’ that are passed on to descendants unchanged.\nIn the late 19th century behaviour of chromosomes during cell division was observed",
					18.32: "In the early 20th century it was observed that chromosomes and Mendel’s ‘units’ behaved in similar ways. This led to the idea that the ‘units’, now called genes, were located on chromosomes.\nIn the mid-20th century the structure of DNA was determined and the mechanism of gene function worked out.\nThis scientific work by many scientists led to the gene theory being developed",

				},
			},
			18.4: {
				"sub-topic": "Evidence for evolution",
				spec: {
					18.41: "Students should be able to describe the evidence for evolution including fossils and antibiotic resistance in bacteria.\nThe theory of evolution by natural selection is now widely accepted.\nEvidence for Darwin’s theory is now available as it has been shown that characteristics are passed on to offspring in genes. There is further evidence in the fossil record and the knowledge of how resistance to antibiotics evolves in bacteria",

				},
			},
			18.5: {
				"sub-topic": "Fossils",
				spec: {
					18.51: "Fossils are the ‘remains’ of organisms from millions of years ago, which are found in rocks.\nFossils may be formed:\n• from parts of organisms that have not decayed because one or more of the conditions needed for decay are absent\n• when parts of the organism are replaced by minerals as they decay\n• as preserved traces of organisms, such as footprints, burrows and rootlet traces.",
					18.52: "Many early forms of life were soft-bodied, which means that they have left few traces behind. What traces there were have been mainly destroyed by geological activity. This is why scientists cannot be certain about how life began on Earth",
					18.53: "We can learn from fossils how much or how little different organisms have changed as life developed on Earth",
					18.54: "Students should be able to extract and interpret information from charts, graphs and tables such as evolutionary trees",

				},
			},
			18.6: {
				"sub-topic": "Extinction",
				spec: {
					18.61: "Extinctions occur when there are no remaining individuals of a species still alive.\nStudents should be able to describe factors which may contribute to the extinction of a species",

				},
			},
			18.7: {
				"sub-topic": "Resistant bacteria",
				spec: {
					18.71: "Bacteria can evolve rapidly because they reproduce at a fast rate.\nMutations of bacterial pathogens produce new strains. Some strains might be resistant to antibiotics, and so are not killed. They survive and reproduce, so the population of the resistant strain rises. The resistant strain will then spread because people are not immune to it and there is no effective treatment",
					18.72: "MRSA is resistant to antibiotics.\nTo reduce the rate of development of antibiotic resistant strains:\n• doctors should not prescribe antibiotics inappropriately, such as treating non-serious or viral infections\n• patients should complete their course of antibiotics so all bacteria are killed and none survive to mutate and form resistant strains\n• the agricultural use of antibiotics should be restricted.\nThe development of new antibiotics is costly and slow. It is unlikely to keep up with the emergence of new resistant strains",

				},
			},
		},
	},
	19: {
		topic: "Classification of living organisms",
		topicQuestions: "",
		subs: {
			19.1: {
				"sub-topic": "",
				spec: {
					19.11: "Traditionally living things have been classified into groups depending on their structure and characteristics in a system developed by Carl Linnaeus.\nLinnaeus classified living things into kingdom, phylum, class, order, family, genus and species. Organisms are named by the binomial system of genus and species",
					19.12: "Students should be able to use information given to show understanding of the Linnaean system.\nStudents should be able to describe the impact of developments in biology on classification systems.\nAs evidence of internal structures became more developed due to improvements in microscopes, and the understanding of biochemical processes progressed, new models of classification were proposed.\nDue to evidence available from chemical analysis there is now a ‘threedomain system’ developed by Carl Woese. In this system organisms are divided into:\n• archaea (primitive bacteria usually living in extreme environments)\n• bacteria (true bacteria)\n• eukaryota (which includes protists, fungi, plants and animals).",
					19.13: "Evolutionary trees are a method used by scientists to show how they believe organisms are related. They use current classification data for living organisms and fossil data for extinct organisms",

				},
			},
		},
	},
	//Ecology
	20: {
		topic: "Adaptations, interdependence and competition",
		topicQuestions: "",
		subs: {
			20.1: {
				"sub-topic": "Communities",
				spec: {
					20.11: "Students should be able to describe:\n• different levels of organisation in an ecosystem from individual organisms to the whole ecosystem\n• the importance of interdependence and competition in a community.Students should be able to, when provided with appropriate information:\n• suggest the factors for which organisms are competing in a given habitat\n• suggest how organisms are adapted to the conditions in which they live.\nAn ecosystem is the interaction of a community of living organisms (biotic) with the non-living (abiotic) parts of their environment.\nTo survive and reproduce, organisms require a supply of materials from their surroundings and from the other living organisms there.\nPlants in a community or habitat often compete with each other for light and space, and for water and mineral ions from the soil. Animals often compete with each other for food, mates and territory.\nWithin a community each species depends on other species for food, shelter, pollination, seed dispersal etc. If one species is removed it can affect the whole community. This is called interdependence. A stable community is one where all the species and environmental factors are in balance so that population sizes remain fairly constant",
					20.12: "Students should be able to extract and interpret information from charts, graphs and tables relating to the interaction of organisms within a community",

				},
			},
			20.2: {
				"sub-topic": "Abiotic factors",
				spec: {
					20.21: "Students should be able to explain how a change in an abiotic factor would affect a given community given appropriate data or context.\nAbiotic (non-living) factors which can affect a community are:\n• light intensity\n• temperature\n• moisture levels\n• soil pH and mineral content\n• wind intensity and direction\n• carbon dioxide levels for plants\n• oxygen levels for aquatic animals.",
					20.22: "Students should be able to extract and interpret information from charts, graphs and tables relating to the effect of abiotic factors on organisms within a community",

				},
			},
			20.3: {
				"sub-topic": "Biotic factors",
				spec: {
					20.31: "Students should be able to explain how a change in a biotic factor might affect a given community given appropriate data or context.\nBiotic (living) factors which can affect a community are:\n• availability of food\n• new predators arriving\n• new pathogens\n• one species outcompeting another so the numbers are no longer sufficient to breed",
					20.32: "Students should be able to extract and interpret information from charts, graphs and tables relating to the effect of biotic factors on organisms within a community",

				},
			},
			20.4: {
				"sub-topic": "Adaptations",
				spec: {
					20.41: "Students should be able to explain how organisms are adapted to live in their natural environment, given appropriate information. \n Organisms have features (adaptations) that enable them to survive in the conditions in which they normally live. These adaptations may be structural, behavioural or functional.",
					20.42: "Some organisms live in environments that are very extreme, such as at high temperature, pressure, or salt concentration. These organisms are called extremophiles. Bacteria living in deep sea vents are extremophiles",

				},
			},
		},
	},
	21: {
		topic: "Organisation of an ecosystem",
		topicQuestions: "",
		subs: {
			21.1: {
				"sub-topic": "Levels of organisation",
				spec: {
					21.11: "Students should understand that photosynthetic organisms are the producers of biomass for life on Earth.\nFeeding relationships within a community can be represented by food chains. All food chains begin with a producer which synthesises molecules. This is usually a green plant or alga which makes glucose by photosynthesis.\nA range of experimental methods using transects and quadrats are used by ecologists to determine the distribution and abundance of species in an ecosystem.",
					21.12: "In relation to abundance of organisms students should be able to:\n• understand the terms mean, mode and median\n• calculate arithmetic means\n• plot and draw appropriate graphs selecting appropriate scales for the axes",
					21.13: "Producers are eaten by primary consumers, which in turn may be eaten by secondary consumers and then tertiary consumers",
					21.14: "Consumers that kill and eat other animals are predators, and those eaten are prey. In a stable community the numbers of predators and prey rise and fall in cycles.",
					21.15: "Students should be able to interpret graphs used to model these cycles.",
				},
			},
			21.2: {
				"sub-topic": "How materials are cycled",
				spec: {
					21.21: "Students should:\n• recall that many different materials cycle through the abiotic and biotic components of an ecosystem\n• explain the importance of the carbon and water cycles to living organisms.\nAll materials in the living world are recycled to provide the building blocks for future organisms.\nThe carbon cycle returns carbon from organisms to the atmosphere as carbon dioxide to be used by plants in photosynthesis.\nThe water cycle provides fresh water for plants and animals on land before draining into the seas. Water is continuously evaporated and precipitated.\nStudents are not expected to study the nitrogen cycle.\nStudents should be able to explain the role of microorganisms in cycling materials through an ecosystem by returning carbon to the atmosphere as carbon dioxide and mineral ions to the soil",

				},
			},
			21.3: {
				"sub-topic": "Decomposition (biology only)",
				spec: {
					21.31: "Students should be able to explain how temperature, water and availability of oxygen affect the rate of decay of biological material.",
					21.32: "Students should be able to:\n• calculate rate changes in the decay of biological material\n• translate information between numerical and graphical form\n• plot and draw appropriate graphs selecting appropriate scales for the axes",
					21.33: "Gardeners and farmers try to provide optimum conditions for rapid decay of waste biological material. The compost produced is used as a natural fertiliser for growing garden plants or crops.\nAnaerobic decay produces methane gas. Biogas generators can be used to produce methane gas as a fuel.",

				},
			},
			21.4: {
				"sub-topic": "Impact of environmental change (biology only) (HT only)",
				spec: {
					21.41: "Students should be able to evaluate the impact of environmental changes on the distribution of species in an ecosystem given appropriate information.\nEnvironmental changes affect the distribution of species in an ecosystem. These changes include:\n• temperature\n• availability of water\n• composition of atmospheric gases.\nThe changes may be seasonal, geographic or caused by human interaction",

				},
			},

		},

	},
	22: {
		topic: "Biodiversity and the effect of human interaction on ecosystems",
		topicQuestions: "",
		subs: {
			22.1: {
				"sub-topic": "Biodiversity",
				spec: {
					22.11: "Biodiversity is the variety of all the different species of organisms on earth, or within an ecosystem.\nA great biodiversity ensures the stability of ecosystems by reducing the dependence of one species on another for food, shelter and the maintenance of the physical environment.\nThe future of the human species on Earth relies on us maintaining a good level of biodiversity. Many human activities are reducing biodiversity and only recently have measures been taken to try to stop this reduction.",

				},
			},
			22.2: {
				"sub-topic": "Waste management",
				spec: {
					22.21: "Rapid growth in the human population and an increase in the standard of living mean that increasingly more resources are used and more waste is produced. Unless waste and chemical materials are properly handled, more pollution will be caused.\nPollution can occur:\n• in water, from sewage, fertiliser or toxic chemicals\n• in air, from smoke and acidic gases\n• on land, from landfill and from toxic chemicals.\nPollution kills plants and animals which can reduce biodiversity.",

				},
			},
			22.3: {
				"sub-topic": "Land use",
				spec: {
					22.31: "Humans reduce the amount of land available for other animals and plants by building, quarrying, farming and dumping waste",
					22.32: "The destruction of peat bogs, and other areas of peat to produce garden compost, reduces the area of this habitat and thus the variety of different plant, animal and microorganism species that live there (biodiversity).\nThe decay or burning of the peat releases carbon dioxide into the atmosphere",

				},
			},
			22.4: {
				"sub-topic": "Deforestation",
				spec: {
					22.41: "Large-scale deforestation in tropical areas has occurred to:\n• provide land for cattle and rice fields\n• grow crops for biofuels",

				},
			},
			22.5: {
				"sub-topic": "Global warming",
				spec: {
					22.51: "Students should be able to describe some of the biological consequences of global warming.\nLevels of carbon dioxide and methane in the atmosphere are increasing, and contribute to ‘global warming’",

				},
			},
			22.6: {
				"sub-topic": " Maintaining biodiversity",
				spec: {
					22.61: "Students should be able to describe both positive and negative human interactions in an ecosystem and explain their impact on biodiversity.Scientists and concerned citizens have put in place programmes to reduce the negative effects of humans on ecosystems and biodiversity.\nThese include:\n• breeding programmes for endangered species\n• protection and regeneration of rare habitats\n• reintroduction of field margins and hedgerows in agricultural areas where farmers grow only one type of crop\n• reduction of deforestation and carbon dioxide emissions by some governments\n• recycling resources rather than dumping waste in landfill",

				},
			},

		},

	},
	23: {
		topic: "Trophic levels in an ecosystem (biology only)",
		topicQuestions: "",
		subs: {
			23.1: {
				"sub-topic": "Trophic levels",
				spec: {
					23.11: "Students should be able to describe the differences between the trophic levels of organisms within an ecosystem.\nTrophic levels can be represented by numbers, starting at level 1 with plants and algae. Further trophic levels are numbered subsequently according to how far the organism is along the food chain.\nLevel 1: Plants and algae make their own food and are called producers.\nLevel 2: Herbivores eat plants/algae and are called primary consumers.\nLevel 3: Carnivores that eat herbivores are called secondary consumers.\nLevel 4: Carnivores that eat other carnivores are called tertiary consumers. Apex predators are carnivores with no predators.\nDecomposers break down dead plant and animal matter by secreting enzymes into the environment. Small soluble food molecules then diffuse into the microorganism.",

				},
			},
			23.2: {
				"sub-topic": "Pyramids of biomass",
				spec: {
					23.21: "Pyramids of biomass can be constructed to represent the relative amount of biomass in each level of a food chain. Trophic level 1 is at the bottom of the pyramid",
					23.22: "Students should be able to construct accurate pyramids of biomass from appropriate data",

				},
			},
			23.3: {
				"sub-topic": "Transfer of biomass",
				spec: {
					23.31: "Students should be able to:\n• describe pyramids of biomass\n• explain how biomass is lost between the different trophic levels.\nProducers are mostly plants and algae which transfer about 1% of the incident energy from light for photosynthesis",
					23.32: "Only approximately 10% of the biomass from each trophic level is transferred to the level above it.\nLosses of biomass are due to:\n• not all the ingested material is absorbed, some is egested as faeces\n• some absorbed material is lost as waste, such as carbon dioxide and water in respiration and water and urea in urine.\nLarge amounts of glucose are used in respiration",
					23.33: "Students should be able to calculate the efficiency of biomass transfers between trophic levels by percentages or fractions of mass.\nStudents should be able to explain how this affects the number of organisms at each trophic level.",

				},
			},
		},

	},
	24: {
		topic: "Food production (biology only)",
		topicQuestions: "",
		subs: {
			24.1: {
				"sub-topic": "Factors affecting food security",
				spec: {
					24.11: "Students should be able to describe some of the biological factors affecting levels of food security.\nFood security is having enough food to feed a population.\nBiological factors which are threatening food security include:\n• the increasing birth rate has threatened food security in some countries\n• changing diets in developed countries means scarce food resources are transported around the world\n• new pests and pathogens that affect farming\n• environmental changes that affect food production, such as widespread famine occurring in some countries if rains fail\n• the cost of agricultural inputs\n• conflicts that have arisen in some parts of the world which affect the availability of water or food.\nSustainable methods must be found to feed all people on Earth.",

				},
			},
			24.2: {
				"sub-topic": "Farming techniques",
				spec: {
					24.21: "The efficiency of food production can be improved by restricting energy transfer from food animals to the environment. This can be done by limiting their movement and by controlling the temperature of their surroundings.\nSome animals are fed high protein foods to increase growth.",

				},
			},
			24.3: {
				"sub-topic": "Sustainable fisheries",
				spec: {
					24.31: "Fish stocks in the oceans are declining. It is important to maintain fish stocks at a level where breeding continues or certain species may disappear altogether in some areas.\nControl of net size and the introduction of fishing quotas play important roles in conservation of fish stocks at a sustainable level.",

				},
			},
			24.4: {
				"sub-topic": "Role of biotechnology",
				spec: {
					24.41: "Students should be able to describe and explain some possible biotechnical and agricultural solutions, including genetic modification, to the demands of the growing human population.\nModern biotechnology techniques enable large quantities of microorganisms to be cultured for food.\nThe fungus Fusarium is useful for producing mycoprotein, a protein-rich food suitable for vegetarians. The fungus is grown on glucose syrup, in aerobic conditions, and the biomass is harvested and purified.\nA genetically modified bacterium produces human insulin. When harvested and purified this is used to treat people with diabetes.\nGM crops could provide more food or food with an improved nutritional value such as golden rice",
					
				},
			},

		},

	},
};


