const nameTransfer = {
    "GCSEMathsAQA": "GCSE Maths AQA", 
    "GCSEBiologyAQA": "GCSE Biology AQA", 
    "GCSEPhysicsAQA": "GCSE Physics AQA", 
    "GCSEChemistryAQA": "GCSE Chemistry AQA",
    "AlevelMathsAQA": "A-level Maths AQA", 
    "AlevelPhysicsAQA": "A-level Physics AQA",
    "AlevelChemistryAQA": "A-level Chemistry AQA",
    "AlevelBiologyAQA": "A-level Biology AQA"
};

export default nameTransfer;