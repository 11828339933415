/** @format */

export const GCSEChemistryAQA = {
	1: {
		topic: "Atomic structure and the periodic table",
		nested: true,
		topicQuestions: "",
		specLink: "",
		code: "GCSEChemistryAQA",
		name: "GCSE Chemistry AQA",
		subject: "Chemistry",
		board: "AQA",
		level: "GCSE",
		subs: {
			1.1: {
				"sub-topic": "A simple model of the atom, symbols, relative atomic mass, electronic charge and isotopes",
				spec: {
					1.11: {
						"sub-topic": "Atoms, elements and compounds",
						spec: {
							1.111: "All substances are made of atoms. An atom is the smallest part of an element that can exist.",
							1.112:"Atoms of each element are represented by a chemical symbol, eg o represents an atom of oxygen, na represents an atom of sodium.",
							1.113:"There are about 100 different elements. Elements are shown in the periodic table.",
							1.114:"Compounds are formed from elements by chemical reactions. Chemical reactions always involve the formation of one or more new substances, and often involve a detectable energy change. Compounds contain two or more elements chemically combined in fixed proportions and can be represented by formulae using the symbols of the atoms from which they were formed. Compounds can only be separated into elements by chemical reactions.",
							1.115:"Chemical reactions can be represented by word equations or equations using symbols and formulae.",
							1.116:"Students will be supplied with a periodic table for the exam and should be able to:\n • use the names and symbols of the first 20 elements in the periodic table, the elements in groups 1 and 7, and other elements in this specification\n • name compounds of these elements from given formulae or symbol equations\n • write word equations for the reactions in this specification\n • write formulae and balanced chemical equations for the reactions in this specification.\n (ht only) write balanced half equations and ionic equations where appropriate.",
						},
					},
					1.12: {
						"sub-topic": "Mixtures",
						spec: {
							1.121: "A mixture consists of two or more elements or compounds not chemically combined together. The chemical properties of each substance in the mixture are unchanged.",
							1.122: "Mixtures can be separated by physical processes such as filtration, crystallisation, simple distillation, fractional distillation and chromatography. These physical processes do not involve chemical reactions and no new substances are made.",
							1.123: "Students should be able to:\n • describe, explain and give examples of the specified processes of separation\n • suggest suitable separation and purification techniques for mixtures when given appropriate information.",
						},
					},
					1.13: {
						"sub-topic": "The development of the model of the atom (common content with physics)",
						spec: {
							1.131: "New experimental evidence may lead to a scientific model being changed or replaced.",
							1.132:"Before the discovery of the electron, atoms were thought to be tiny spheres that could not be divided.",
							1.133:"The discovery of the electron led to the plum pudding model of the atom. The plum pudding model suggested that the atom is a ball of positive charge with negative electrons embedded in it.",
							1.134:"The results from the alpha particle scattering experiment led to the conclusion that the mass of an atom was concentrated at the centre (nucleus) and that the nucleus was charged. This nuclear model replaced the plum pudding model.",
							1.135:"Niels bohr adapted the nuclear model by suggesting that electrons orbit the nucleus at specific distances. The theoretical calculations of bohr agreed with experimental observations.",
							1.136:"Later experiments led to the idea that the positive charge of any nucleus could be subdivided into a whole number of smaller particles, each particle having the same amount of positive charge. The name proton was given to these particles.",
							1.137:"The experimental work of james chadwick provided the evidence to show the existence of neutrons within the nucleus. This was about 20 years after the nucleus became an accepted scientific idea.",
							1.138:"Students should be able to describe:\n• why the new evidence from the scattering experiment led to a change in the atomic model\n • the difference between the plum pudding model of the atom and the nuclear model of the atom.",
							
						},
					},
					1.14: {
						"sub-topic": "Relative electrical charges of subatomic particles",
						spec: {
							1.141: "The relative electrical charges of the particles in atoms are:\n Table 4.1.1.4",
							1.142: "In an atom, the number of electrons is equal to the number of protons in the nucleus. Atoms have no overall electrical charge.",
							1.143: "The number of protons in an atom of an element is its atomic number. All atoms of a particular element have the same number of protons. Atoms of different elements have different numbers of protons.",
							1.144: "Students should be able to use the nuclear model to describe atoms.",
							
						},
					},
					1.15: {
						"sub-topic": "Size and mass of atoms",
						spec: {
							1.151: "Atoms are very small, having a radius of about 0.1 nm (1 x 10-10 m).",
							1.152: "The radius of a nucleus is less than 1/10 000 of that of the atom (about 1 x 10-14 m).",
							1.153: "Almost all of the mass of an atom is in the nucleus.",
							1.154: "The relative masses of protons, neutrons and electrons are:\n table 4.1.1.5	",
							1.155: "The sum of the protons and neutrons in an atom is its mass number.",
							1.156: "Atoms of the same element can have different numbers of neutrons; these atoms are called isotopes of that element.",
							1.157: "Atoms can be represented as shown in this example:\n  formula4.1.1.5",
							1.158: "Students should be able to calculate the numbers of protons, neutrons and electrons in an atom or ion, given its atomic number and mass number.",
							1.151: "Students should be able to relate size and scale of atoms to objects in the physical world.",
						},
					},
					1.16: {
						"sub-topic": "Relative atomic mass",
						spec: {
							1.161: "The relative atomic mass of an element is an average value that takes account of the abundance of the isotopes of the element.",
							1.162: "Students should be able to calculate the relative atomic mass of an element given the percentage abundance of its isotopes.",
						},
					},
					1.17: {
						"sub-topic": "Electronic structure",
						spec: {
							1.171: "The electrons in an atom occupy the lowest available energy levels (innermost available shells). The electronic structure of an atom can be represented by numbers or by a diagram. For example, the electronic structure of sodium is 2,8,1 or showing two electrons in the lowest energy level, eight in the second energy level and one in the third energy level.  diagram 4.1.1.7",
							1.172:"Students may answer questions in terms of either energy levels or shells.",
						},
					},

				},
			},
			1.2: {
				"sub-topic": "The periodic table",
				spec: {
					1.21: {
						"sub-topic": "The periodic table",
						spec: {
							1.211: "The elements in the periodic table are arranged in order of atomic (proton) number and so that elements with similar properties are in columns, known as groups. The table is called a periodic table because similar properties occur at regular intervals.",
							1.212:"Elements in the same group in the periodic table have the same number of electrons in their outer shell (outer electrons) and this gives them similar chemical properties.",
							1.213:"Students should be able to:\n • explain how the position of an element in the periodic table is related to the arrangement of electrons in its atoms and hence to its atomic number\n • predict possible reactions and probable reactivity of elements from their positions in the periodic table.",
						},
					},
					1.22: {
						"sub-topic": "Development of the periodic table",
						spec: {
							1.221: "Before the discovery of protons, neutrons and electrons, scientists attempted to classify the elements by arranging them in order of their atomic weights.",
							1.222: "The early periodic tables were incomplete and some elements were placed in inappropriate groups if the strict order of atomic weights was followed.",
							1.223: "Mendeleev overcame some of the problems by leaving gaps for elements that he thought had not been discovered and in some places changed the order based on atomic weights.",
							1.224: "Elements with properties predicted by mendeleev were discovered and filled the gaps. Knowledge of isotopes made it possible to explain why the order based on atomic weights was not always correct.",
							1.225: "Students should be able to describe these steps in the development of the periodic table.",
						},
					},
					1.23: {
						"sub-topic": "Metals and non-metals",
						spec: {
							1.231: "Elements that react to form positive ions are metals.",
							1.232: "Elements that do not form positive ions are non-metals.",
							1.233: "The majority of elements are metals. Metals are found to the left and towards the bottom of the periodic table. Non-metals are found towards the right and top of the periodic table.",
							1.234: "Students should be able to:\n • explain the differences between metals and non-metals on the basis of their characteristic physical and chemical properties. This links to group 0 (page 24), group 1 (page 24), group 7 (page 25) and bonding, structure and the properties of matter (page 26)\n • explain how the atomic structure of metals and non-metals relates to their position in the periodic table\n • explain how the reactions of elements are related to the arrangement of electrons in their atoms and hence to their atomic number.",
						
						},
					},
					1.24: {
						"sub-topic": "Group 0",
						spec: {
							1.241: "The elements in group 0 of the periodic table are called the noble gases. They are unreactive and do not easily form molecules because their atoms have stable arrangements of electrons. The noble gases have eight electrons in their outer shell, except for helium, which has only two electrons.",
							1.242:"The boiling points of the noble gases increase with increasing relative atomic mass (going down the group).",
							1.243:"Students should be able to:\n • explain how properties of the elements in group 0 depend on the outer shell of electrons of the atoms\n • predict properties from given trends down the group.",
						},
					},
					1.25: {
						"sub-topic": "Group 1",
						spec: {
							1.251: "The elements in group 1 of the periodic table are known as the alkali metals and have characteristic properties because of the single electron in their outer shell",
							1.252:"Students should be able to describe the reactions of the first three alkali metals with oxygen, chlorine and water.",
							1.253:"In group 1, the reactivity of the elements increases going down the group.",
							1.254:"Students should be able to:\n • explain how properties of the elements in group 1 depend on the outer shell of electrons of the atoms\n • predict properties from given trends down the group",

						},
					},
					1.26: {
						"sub-topic": "Group 7",
						spec: {
							1.261: "The elements in group 7 of the periodic table are known as the halogens and have similar reactions because they all have seven electrons in their outer shell. The halogens are non-metals and consist of molecules made of pairs of atoms.",
							1.262:"Students should be able to describe the nature of the compounds formed when chlorine, bromine and iodine react with metals and non-metals.",
							1.263:"In group 7, the further down the group an element is the higher its relative molecular mass, melting point and boiling point.",
							1.264:"In group 7, the reactivity of the elements decreases going down the group.",
							1.265:"A more reactive halogen can displace a less reactive halogen from an aqueous solution of its salt.",
							1.266:"Students should be able to:\n • explain how properties of the elements in group 7 depend on the outer shell of electrons of the atoms\n • predict properties from given trends down the group.",

						},
					},
				},
			},
			1.3: {
				"sub-topic": "Properties of transition metals (chemistry only)",
				spec: {
					1.31: {
						"sub-topic": "Comparison with Group 1 elements",
						spec: {
							1.311: "The transition elements are metals with similar properties which are different from those of the elements in group 1.",
							1.312: "Students should be able to describe the difference compared with group 1 in melting points, densities, strength, hardness and reactivity with oxygen, water and halogens.",
							1.313: "Students should be able to exemplify these general properties by reference to cr, mn, fe, co, ni, cu.",
							
						},
					},
					1.32: {
						"sub-topic": "Typical properties",
						spec: {
							1.321: "Many transition elements have ions with different charges, form coloured compounds and are useful as catalysts.",
							1.322: "Students should be able to exemplify these general properties by reference to compounds of cr, mn, fe, co, ni, cu.",
							
						},
					},
				},
			},
		},
	},
	2: {
		topic: "Bonding, structure, and the properties of matter",
		topicQuestions: "",
		subs: {
			2.1: {
				"sub-topic": "Chemical bonds, ionic, covalent and metallic",
				spec: {
					2.11: {
						"sub-topic": "Chemical bonds",
						spec: {
							2.111: "There are three types of strong chemical bonds: ionic, covalent and metallic. For ionic bonding the particles are oppositely charged ions. For covalent bonding the particles are atoms which share pairs of electrons. For metallic bonding the particles are atoms which share delocalised electrons. ",
							2.112: "Ionic bonding occurs in compounds formed from metals combined with non-metals. ",
							2.113: "Covalent bonding occurs in most non-metallic elements and in compounds of non-metals.",
							2.114: " Metallic bonding occurs in metallic elements and alloys.",
							2.115: " Students should be able to explain chemical bonding in terms of electrostatic forces and the transfer or sharing of electrons.",
						},
					},
					2.12: {
						"sub-topic": "Ionic bonding",
						spec: {
							2.121: "When a metal atom reacts with a non-metal atom electrons in the outer shell of the metal atom are transferred. Metal atoms lose electrons to become positively charged ions. Non-metal atoms gain electrons to become negatively charged ions. The ions produced by metals in groups 1 and 2 and by non-metals in groups 6 and 7 have the electronic structure of a noble gas (group 0). ",
							2.122:"The electron transfer during the formation of an ionic compound can be represented by a dot and cross diagram, eg for sodium chloride equation 4.2.1.2",
							2.123:"Students should be able to draw dot and cross diagrams for ionic compounds formed by metals in groups 1 and 2 with non-metals in groups 6 and 7. ",
							2.124:"The charge on the ions produced by metals in groups 1 and 2 and by non-metals in groups 6 and 7 relates to the group number of the element in the periodic table.",
							2.125:"Students should be able to work out the charge on the ions of metals and non-metals from the group number of the element, limited to the metals in groups 1 and 2, and non-metals in groups 6 and 7.",
						},
					},
					2.13: {
						"sub-topic": "Ionic compounds",
						spec: {
							2.131: "An ionic compound is a giant structure of ions. Ionic compounds are held together by strong electrostatic forces of attraction between oppositely charged ions. These forces act in all directions in the lattice and this is called ionic bonding.",
							2.132: "The structure of sodium chloride can be represented in the following forms: diagram 4.2.1.3",
							2.133: "Students should be able to:\n • deduce that a compound is ionic from a diagram of its structure in one of the specified forms\n • describe the limitations of using dot and cross, ball and stick, two and three-dimensional diagrams to represent a giant ionic structure\n • work out the empirical formula of an ionic compound from a given model or diagram that shows the ions in the structure.",
							2.134: " Students should be familiar with the structure of sodium chloride but do not need to know the structures of other ionic compounds.",
							
						},
					},
					2.14: {
						"sub-topic": "Covalent bonding",
						spec: {
							2.141: "When atoms share pairs of electrons, they form covalent bonds. These bonds between atoms are strong.",
							2.142: "Covalently bonded substances may consist of small molecules.",
							2.143: "Students should be able to recognise common substances that consist of small molecules from their chemical formula.",
							2.144: "Some covalently bonded substances have very large molecules, such as polymers.",
							2.145: "Some covalently bonded substances have giant covalent structures, such as diamond and silicon dioxide.",
							2.146:"The covalent bonds in molecules and giant structures can be represented in the following forms: diagram 4.2.1.4",
							2.147:"Students should be able to:\n • draw dot and cross diagrams for the molecules of hydrogen, chlorine, oxygen, nitrogen, hydrogen chloride, water, ammonia and methane\n • represent the covalent bonds in small molecules, in the repeating units of polymers and in part of giant covalent structures, using a line to represent a single bond\n • describe the limitations of using dot and cross, ball and stick, two and three-dimensional diagrams to represent molecules or giant structures\n • deduce the molecular formula of a substance from a given model or diagram in these forms showing the atoms and bonds in the molecule.",
						

						},
					},
					2.15: {
						"sub-topic": "Metallic bonding",
						spec: {
							2.151: "Metals consist of giant structures of atoms arranged in a regular pattern.",
							2.152:"The electrons in the outer shell of metal atoms are delocalised and so are free to move through the whole structure. The sharing of delocalised electrons gives rise to strong metallic bonds. The bonding in metals may be represented in the following form: diagram 4.2.1.5",
							
						},
					},
				},
			},
			2.2: {
				"sub-topic": "How bonding and structure are related to the properties of substances",
				spec: {
					2.21: {
						"sub-topic": "The three states of matter",
						spec: {
							2.211: "The three states of matter are solid, liquid and gas. Melting and freezing take place at the melting point, boiling and condensing take place at the boiling point.",
							2.212: "The three states of matter can be represented by a simple model. In this model, particles are represented by small solid spheres. Particle theory can help to explain melting, boiling, freezing and condensing.",
							2.213:"diagram 4.2.2.1 ",
							2.214: " The amount of energy needed to change state from solid to liquid and from liquid to gas depends on the strength of the forces between the particles of the substance. The nature of the particles involved depends on the type of bonding and the structure of the substance. The stronger the forces between the particles the higher the melting point and boiling point of the substance. ",
							2.215: "(ht only) limitations of the simple model above include that in the model there are no forces, that all particles are represented as spheres and that the spheres are solid.",
							2.216:"Students should be able to:\n • predict the states of substances at different temperatures given appropriate data\n • explain the different temperatures at which changes of state occur in terms of energy transfers and types of bonding\n • recognise that atoms themselves do not have the bulk properties of materials\n • (ht only) explain the limitations of the particle theory in relation to changes of state when particles are represented by solid inelastic spheres which have no forces between them.",
						
						},
					},
					2.22: {
						"sub-topic": "State symbols",
						spec: {
							2.221: "N chemical equations, the three states of matter are shown as (s), (l) and (g), with (aq) for aqueous solutions.",
							2.222: "Students should be able to include appropriate state symbols in chemical equations for the reactions in this specification.",
							
						},
					},
					2.23: {
						"sub-topic": "Properties of ionic compounds",
						spec: {
							2.231: "Ionic compounds have regular structures (giant ionic lattices) in which there are strong electrostatic forces of attraction in all directions between oppositely charged ions. ",
							2.232:"These compounds have high melting points and high boiling points because of the large amounts of energy needed to break the many strong bonds.",
							2.233:"When melted or dissolved in water, ionic compounds conduct electricity because the ions are free to move and so charge can flow.",
							2.234:"Knowledge of the structures of specific ionic compounds other than sodium chloride is not required.",

						},
					},
					2.24: {
						"sub-topic": "Properties of small molecules",
						spec: {
							2.241: "Substances that consist of small molecules are usually gases or liquids that have relatively low melting points and boiling points.",
							2.242: "These substances have only weak forces between the molecules (intermolecular forces). It is these intermolecular forces that are overcome, not the covalent bonds, when the substance melts or boils.",
							2.243: "The intermolecular forces increase with the size of the molecules, so larger molecules have higher melting and boiling points.",
							2.244: "These substances do not conduct electricity because the molecules do not have an overall electric charge.",
							2.245:"Students should be able to use the idea that intermolecular forces are weak compared with covalent bonds to explain the bulk properties of molecular substances.",
						},
					},
					2.25: {
						"sub-topic": "Polymers",
						spec: {
							2.251: "Polymers have very large molecules. The atoms in the polymer molecules are linked to other atoms by strong covalent bonds. The intermolecular forces between polymer molecules are relatively strong and so these substances are solids at room temperature.",
							2.252: "Students should be able to recognise polymers from diagrams showing their bonding and structure.",
						},
					},
					2.26: {
						"sub-topic": "Giant covalent structures",
						spec: {
							2.261: "Substances that consist of giant covalent structures are solids with very high melting points. All of the atoms in these structures are linked to other atoms by strong covalent bonds. These bonds must be overcome to melt or boil these substances. Diamond and graphite (forms of carbon) and silicon dioxide (silica) are examples of giant covalent structures.",
							2.262: "Students should be able to recognise giant covalent structures from diagrams showing their bonding and structure.",
						},
					},
					2.27: {
						"sub-topic": "Properties of metals and alloys",
						spec: {
							2.271: "Metals have giant structures of atoms with strong metallic bonding. This means that most metals have high melting and boiling points. ",
							2.272: "In pure metals, atoms are arranged in layers, which allows metals to be bent and shaped. Pure metals are too soft for many uses and so are mixed with other metals to make alloys which are harder.",
							2.273: " Students should be able to explain why alloys are harder than pure metals in terms of distortion of the layers of atoms in the structure of a pure metal.",
						},
					},
					2.28: {
						"sub-topic": "Metals as conductors",
						spec: {
							2.281: "Metals are good conductors of electricity because the delocalised electrons in the metal carry electrical charge through the metal. Metals are good conductors of thermal energy because energy is transferred by the delocalised electrons.",
						},
					},
				},
			},
			2.3: {
				"sub-topic": "Structure and bonding of carbon",
				spec: {
					2.31: {
						"sub-topic": "Diamond",
						spec: {
							2.311: "In diamond, each carbon atom forms four covalent bonds with other carbon atoms in a giant covalent structure, so diamond is very hard, has a very high melting point and does not conduct electricity.",
							2.312:"Students should be able to explain the properties of diamond in terms of its structure and bonding.",
						},
					},
					2.32: {
						"sub-topic": "Graphite",
						spec: {
							2.321: "In graphite, each carbon atom forms three covalent bonds with three other carbon atoms, forming layers of hexagonal rings which have no covalent bonds between the layers.",
							2.322:"In graphite, one electron from each carbon atom is delocalised.							",
							2.323:"Students should be able to explain the properties of graphite in terms of its structure and bonding. ",
							2.324:"Students should know that graphite is similar to metals in that it has delocalised electrons.",

						},
					},
					2.33: {
						"sub-topic": "Graphene and fullerenes",
						spec: {
							2.331: "Graphene is a single layer of graphite and has properties that make it useful in electronics and composites. ",
							2.332: "Students should be able to explain the properties of graphene in terms of its structure and bonding.",
							2.333: " Fullerenes are molecules of carbon atoms with hollow shapes. The structure of fullerenes is based on hexagonal rings of carbon atoms but they may also contain rings with five or seven carbon atoms. The first fullerene to be discovered was buckminsterfullerene (c60) which has a spherical shape.",
							2.334: "Carbon nanotubes are cylindrical fullerenes with very high length to diameter ratios. Their properties make them useful for nanotechnology, electronics and materials.",
							2.335: "Students should be able to:\n • recognise graphene and fullerenes from diagrams and descriptions of their bonding and structure\n • give examples of the uses of fullerenes, including carbon nanotubes.",
						},
					},
				},
			},
			2.4: {
				"sub-topic": "Bulk and surface properties of matter including nanoparticles (chemistry only)",
				spec: {
					2.41: {
						"sub-topic": "Sizes of particles and their properties",
						spec: {
							2.411: "Nanoscience refers to structures that are 1–100 nm in size, of the order of a few hundred atoms. Nanoparticles, are smaller than fine particles (pm2.5), which have diameters between 100 and 2500 nm (1 x 10-7 m and 2.5 x 10-6 m). Coarse particles (pm10) have diameters between 1 x 10-5 m and 2.5 x 10-6 m. Coarse particles are often referred to as dust. ",
							2.412: "As the side of cube decreases by a factor of 10 the surface area to volume ratio increases by a factor of 10.",
							2.413: " Nanoparticles may have properties different from those for the same materials in bulk because of their high surface area to volume ratio. It may also mean that smaller quantities are needed to be effective than for materials with normal particle sizes.",
							2.414: "Students should be able to compare ‘nano’ dimensions to typical dimensions of atoms and molecules.",
						},
					},
					2.42: {
						"sub-topic": " Uses of nanoparticles",
						spec: {
							2.421: "Nanoparticles have many applications in medicine, in electronics, in cosmetics and sun creams, as deodorants, and as catalysts. New applications for nanoparticulate materials are an important area of research. ",
							2.422: "Students should consider advantages and disadvantages of the applications of these nanoparticulate materials, but do not need to know specific examples or properties other than those specified. ",
							2.423: "Students should be able to:\n • given appropriate information, evaluate the use of nanoparticles for a specified purpose\n • explain that there are possible risks associated with the use of nanoparticles.",
						},
					},
					
				},
			},			
		},
	},
	3: {
		topic: "Quantitative chemistry",
		topicQuestions: "",
		subs: {
			3.1: {
				"sub-topic": "Chemical measurements, conservation of mass and the quantitative interpretation of chemical equations",
				spec: {
					3.11: {
						"sub-topic": "Conservation of mass and balanced chemical equations",
						spec: {
							3.111: "The law of conservation of mass states that no atoms are lost or made during a chemical reaction so the mass of the products equals the mass of the reactants. ",
							3.112: "This means that chemical reactions can be represented by symbol equations which are balanced in terms of the numbers of atoms of each element involved on both sides of the equation. ",
							3.113: "Students should understand the use of the multipliers in equations in normal script before a formula and in subscript within a formula.",
						},
					},
					3.12: {
						"sub-topic": "Relative formula mass",
						spec: {
							3.121: "The relative formula mass (mr ) of a compound is the sum of the relative atomic masses of the atoms in the numbers shown in the formula. ",
							3.122: "In a balanced chemical equation, the sum of the relative formula masses of the reactants in the quantities shown equals the sum of the relative formula masses of the products in the quantities shown.",
							3.123: " Students should be able to calculate the percentage by mass in a compound given the relative formula mass and the relative atomic masses.",
						},
					},
					3.13: {
						"sub-topic": "Mass changes when a reactant or product is a gas",
						spec: {
							3.131: "Some reactions may appear to involve a change in mass but this can usually be explained because a reactant or product is a gas and its mass has not been taken into account. For example: when a metal reacts with oxygen the mass of the oxide produced is greater than the mass of the metal or in thermal decompositions of metal carbonates carbon dioxide is produced and escapes into the atmosphere leaving the metal oxide as the only solid product. ",
							3.132: "Students should be able to explain any observed changes in mass in non-enclosed systems during a chemical reaction given the balanced symbol equation for the reaction and explain these changes in terms of the particle model.",
						},
					},
					3.14: {
						"sub-topic": "Chemical measurements",
						spec: {
							3.141: "Whenever a measurement is made there is always some uncertainty about the result obtained. ",
							3.142: "Students should be able to:\n • represent the distribution of results and make estimations of uncertainty\n • use the range of a set of measurements about the mean as a measure of uncertainty.",
						},
					},
				},
			},
			3.2: {
				"sub-topic": "Use of amount of substance in relation to masses of pure substances",
				spec: {
					3.21: {
						"sub-topic": "Moles (HT only)",
						spec: {
							3.211: "Chemical amounts are measured in moles. The symbol for the unit mole is mol.",
							3.212:" The mass of one mole of a substance in grams is numerically equal to its relative formula mass. ",
							3.213:"One mole of a substance contains the same number of the stated particles, atoms, molecules or ions as one mole of any other substance. ",
							3.214:"The number of atoms, molecules or ions in a mole of a given substance is the avogadro constant. The value of the avogadro constant is 6.02 x 1023 per mole.",
							3.215:" Students should understand that the measurement of amounts in moles can apply to atoms, molecules, ions, electrons, formulae and equations, for example that in one mole of carbon (c) the number of atoms is the same as the number of molecules in one mole of carbon dioxide (co2).",

						},
					},
					3.22: {
						"sub-topic": "Amounts of substances in equations (HT only)",
						spec: {
							3.221: "The masses of reactants and products can be calculated from balanced symbol equations.",
							3.222: "Chemical equations can be interpreted in terms of moles. For example: equation 4.3.2.2   shows that one mole of magnesium reacts with two moles of hydrochloric acid to produce one mole of magnesium chloride and one mole of hydrogen gas.",
							3.223: "Students should be able to:\n • calculate the masses of substances shown in a balanced symbol equation\n • calculate the masses of reactants and products from the balanced symbol equation and the mass of a given reactant or product.",
						},
					},
					3.23: {
						"sub-topic": "Using moles to balance equations (HT only)",
						spec: {
							3.231: "The balancing numbers in a symbol equation can be calculated from the masses of reactants and products by converting the masses in grams to amounts in moles and converting the numbers of moles to simple whole number ratios. ",
							3.232: "Students should be able to balance an equation given the masses of reactants and products. ",
							3.233: "Students should be able to change the subject of a mathematical equation.",
						},
					},
					3.24: {
						"sub-topic": "Limiting reactants (HT only)",
						spec: {
							3.241: "In a chemical reaction involving two reactants, it is common to use an excess of one of the reactants to ensure that all of the other reactant is used. The reactant that is completely used up is called the limiting reactant because it limits the amount of products.",
							3.242: "Students should be able to explain the effect of a limiting quantity of a reactant on the amount of products it is possible to obtain in terms of amounts in moles or masses in grams.",							
						},
					},
					3.25: {
						"sub-topic": "Concentration of solutions",
						spec: {
							3.251: "Many chemical reactions take place in solutions. The concentration of a solution can be measured in mass per given volume of solution, eg grams per dm3 (g/dm3 ).",
							3.252:" Students should be able to:\n • calculate the mass of solute in a given volume of solution of known concentration in terms of mass per given volume of solution\n • (ht only) explain how the mass of a solute and the volume of a solution is related to the concentration of the solution.",
							
						},
					},
				},
			},
			3.3: {
				"sub-topic": "Yield and atom economy of chemical reactions (chemistry only)",
				spec: {
					3.31: {
						"sub-topic": "Percentage yield",
						spec: {
							3.311: "Even though no atoms are gained or lost in a chemical reaction, it is not always possible to obtain the calculated amount of a product because:\n • the reaction may not go to completion because it is reversible\n • some of the product may be lost when it is separated from the reaction mixture\n • some of the reactants may react in ways different to the expected reaction.",
							3.312:"The amount of a product obtained is known as the yield. When compared with the maximum theoretical amount as a percentage, it is called the percentage yield.",
							3.313:"formula 4.3.3.1 ",
							3.314:"Students should be able to:\n • calculate the percentage yield of a product from the actual yield of a reaction\n • (ht only) calculate the theoretical mass of a product from a given mass of reactant and the balanced equation for the reaction.",
						},
					},
					3.32: {
						"sub-topic": "Atom economy",
						spec: {
							3.321: "The atom economy (atom utilisation) is a measure of the amount of starting materials that end up as useful products. It is important for sustainable development and for economic reasons to use reactions with high atom economy.",
							3.322:" The percentage atom economy of a reaction is calculated using the balanced equation for the reaction as follows:  formula 4.3.3.2",
							3.323:"Students should be able to:\n • calculate the atom economy of a reaction to form a desired product from the balanced equation\n • (ht only) explain why a particular reaction pathway is chosen to produce a specified product given appropriate data such as atom economy (if not calculated), yield, rate, equilibrium position and usefulness of by-products.",
						},
					},
				},
			},
			3.4: {
				"sub-topic": "Using concentrations of solutions in mol/dm3 (chemistry only) (HT only)",
				spec: {
					3.41: {
						"sub-topic": "Using concentrations of solutions in mol/dm3 (chemistry only) (HT only)",
						spec: {
							3.411:"The concentration of a solution can be measured in mol/dm3 . ",
							3.412:"The amount in moles of solute or the mass in grams of solute in a given volume of solution can be calculated from its concentration in mol/dm3 . ",
							3.413:"If the volumes of two solutions that react completely are known and the concentration of one solution is known, the concentration of the other solution can be calculated.",
							3.414:" Students should be able to explain how the concentration of a solution in mol/dm3 is related to the mass of the solute and the volume of the solution.",
						}
					}
					
				},
			},
			3.5: {
				"sub-topic": "Use of amount of substance in relation to volumes of gases (chemistry only) (HT only)",
				spec: {
					3.51: {
						"sub-topic": "Use of amount of substance in relation to volumes of gases (chemistry only) (HT only)",
						spec: {
							3.511:"Equal amounts in moles of gases occupy the same volume under the same conditions of temperature and pressure.",
							3.512:" The volume of one mole of any gas at room temperature and pressure (20oc and 1 atmosphere pressure) is 24 dm3 . ",
							3.513:"The volumes of gaseous reactants and products can be calculated from the balanced equation for the reaction.",
							3.514:" Students should be able to:\n • calculate the volume of a gas at room temperature and pressure from its mass and relative formula mass\n • calculate volumes of gaseous reactants and products from a balanced equation and a given volume of a gaseous reactant or product\n • change the subject of a mathematical equation.",
						}
					}
					
					
				},
			},
		},
	},
	4: {
		topic: "Chemical changes",
		topicQuestions: "",
		subs: {
			4.1: {
				"sub-topic": "Reactivity of metals",
				spec: {
					4.11: {
						"sub-topic": "Metal oxides",
						spec: {
							4.111: "Metals react with oxygen to produce metal oxides. The reactions are oxidation reactions because the metals gain oxygen.",
							4.112: " Students should be able to explain reduction and oxidation in terms of loss or gain of oxygen.",
							
						},
					},
					4.12: {
						"sub-topic": "The reactivity series",
						spec: {
							4.121: "When metals react with other substances the metal atoms form positive ions. The reactivity of a metal is related to its tendency to form positive ions. Metals can be arranged in order of their reactivity in a reactivity series. The metals potassium, sodium, lithium, calcium, magnesium, zinc, iron and copper can be put in order of their reactivity from their reactions with water and dilute acids.",
							4.122: " The non-metals hydrogen and carbon are often included in the reactivity series.",
							4.123: "A more reactive metal can displace a less reactive metal from a compound. ",
							4.124: "Students should be able to:\n • recall and describe the reactions, if any, of potassium, sodium, lithium, calcium, magnesium, zinc, iron and copper with water or dilute acids and where appropriate, to place these metals in order of reactivity\n • explain how the reactivity of metals with water or dilute acids is related to the tendency of the metal to form its positive ion\n • deduce an order of reactivity of metals based on experimental results. ",
							4.125: " The reactions of metals with water and acids are limited to room temperature and do not include reactions with steam.",
						},
					},
					4.13: {
						"sub-topic": "Extraction of metals and reduction",
						spec: {
							4.131: "Unreactive metals such as gold are found in the earth as the metal itself but most metals are found as compounds that require chemical reactions to extract the metal.",
							4.132: "Metals less reactive than carbon can be extracted from their oxides by reduction with carbon. ",
							4.133: "Reduction involves the loss of oxygen.",
							4.134: " Knowledge and understanding are limited to the reduction of oxides using carbon. ",
							4.135: "Knowledge of the details of processes used in the extraction of metals is not required.",
							4.136: " Students should be able to:\n • interpret or evaluate specific metal extraction processes when given appropriate information\n • identify the substances which are oxidised or reduced in terms of gain or loss of oxygen.",
						},
					},
					4.14: {
						"sub-topic": "Oxidation and reduction in terms of electrons (HT only)",
						spec: {
							4.141: "Oxidation is the loss of electrons and reduction is the gain of electrons. ",
							4.142: "Student should be able to:\n • write ionic equations for displacement reactions\n • identify in a given reaction, symbol equation or half equation which species are oxidised and which are reduced.",
						},
					},
				},
			},
			4.2: {
				"sub-topic": "Reactions of acids",
				spec: {
					4.21: {
						"sub-topic": "Reactions of acids with metals",
						spec: {
							4.211: "Acids react with some metals to produce salts and hydrogen. (ht only) students should be able to:\n • explain in terms of gain or loss of electrons, that these are redox reactions\n • identify which species are oxidised and which are reduced in given chemical equations.",
							4.212: " knowledge of reactions limited to those of magnesium, zinc and iron with hydrochloric and sulfuric acids.",
						},
					},
					4.22: {
						"sub-topic": "Neutralisation of acids and salt production",
						spec: {
							4.221: "Acids are neutralised by alkalis (eg soluble metal hydroxides) and bases (eg insoluble metal hydroxides and metal oxides) to produce salts and water, and by metal carbonates to produce salts, water and carbon dioxide.",
							4.222: " The particular salt produced in any reaction between an acid and a base or alkali depends on:\n • the acid used (hydrochloric acid produces chlorides, nitric acid produces nitrates, sulfuric acid produces sulfates)\n • the positive ions in the base, alkali or carbonate.",
							4.223: " Students should be able to:\n • predict products from given reactants\n • use the formulae of common ions to deduce the formulae of salts.",
						},
					},
					4.23: {
						"sub-topic": "Soluble salts",
						spec: {
							4.231: "Soluble salts can be made from acids by reacting them with solid insoluble substances, such as metals, metal oxides, hydroxides or carbonates. The solid is added to the acid until no more reacts and the excess solid is filtered off to produce a solution of the salt.",
							4.232: " Salt solutions can be crystallised to produce solid salts. ",
							4.233: "Students should be able to describe how to make pure, dry samples of named soluble salts from information provided.",
						},
					},
					4.24: {
						"sub-topic": "The pH scale and neutralisation",
						spec: {
							4.241: "Acids produce hydrogen ions (h+ ) in aqueous solutions.",
							4.242: "Aqueous solutions of alkalis contain hydroxide ions (oh– ). ",
							4.243: "The ph scale, from 0 to 14, is a measure of the acidity or alkalinity of a solution, and can be measured using universal indicator or a ph probe.",
							4.244: "A solution with ph 7 is neutral. Aqueous solutions of acids have ph values of less than 7 and aqueous solutions of alkalis have ph values greater than 7.",
							4.245: "In neutralisation reactions between an acid and an alkali, hydrogen ions react with hydroxide ions to produce water.",
							4.246: "This reaction can be represented by the equation: 4.4.2.4",
							4.247: "students should be able to:\n • describe the use of universal indicator or a wide range indicator to measure the approximate ph of a solution\n • use the ph scale to identify acidic or alkaline solutions.",
						},
					},
					4.25: {
						"sub-topic": " Titrations (chemistry only)",
						spec: {
							4.251: "The volumes of acid and alkali solutions that react with each other can be measured by titration using a suitable indicator. ",
							4.252:"Students should be able to:\n • describe how to carry out titrations using strong acids and strong alkalis only (sulfuric, hydrochloric and nitric acids only) to find the reacting volumes accurately\n • (ht only) calculate the chemical quantities in titrations involving concentrations in mol/dm3 and in g/dm3. ",
						},
					},
					4.26: {
						"sub-topic": " Strong and weak acids (HT only)",
						spec: {
							4.261: "A strong acid is completely ionised in aqueous solution. Examples of strong acids are hydrochloric, nitric and sulfuric acids. ",
							4.262: "A weak acid is only partially ionised in aqueous solution. Examples of weak acids are ethanoic, citric and carbonic acids.",
							4.263: "For a given concentration of aqueous solutions, the stronger an acid, the lower the ph. ",
							4.264: " As the ph decreases by one unit, the hydrogen ion concentration of the solution increases by a factor of 10.",
							4.265: " Students should be able to:\n • use and explain the terms dilute and concentrated (in terms of amount of substance), and weak and strong (in terms of the degree of ionisation) in relation to acids\n describe neutrality and relative acidity in terms of the effect on hydrogen ion concentration and the numerical value of ph (whole numbers only).",
							
						},
					},
				},
			},
			4.3: {
				"sub-topic": "Electrolysis",
				spec: {
					4.31: {
						"sub-topic": "The process of electrolysis",
						spec: {
							4.311: "When an ionic compound is melted or dissolved in water, the ions are free to move about within the liquid or solution. These liquids and solutions are able to conduct electricity and are called electrolytes.",
							4.312:" Passing an electric current through electrolytes causes the ions to move to the electrodes. Positively charged ions move to the negative electrode (the cathode), and negatively charged ions move to the positive electrode (the anode). Ions are discharged at the electrodes producing elements. This process is called electrolysis.",
							4.313:"(ht only) throughout section 4.4.3 higher tier students should be able to write half equations for the reactions occurring at the electrodes during electrolysis, and may be required to complete and balance supplied half equations.",
						},
					},
					4.32: {
						"sub-topic": " Electrolysis of molten ionic compounds",
						spec: {
							4.321: "When a simple ionic compound (eg lead bromide) is electrolysed in the molten state using inert electrodes, the metal (lead) is produced at the cathode and the non-metal (bromine) is produced at the anode. ",
							4.322: "Students should be able to predict the products of the electrolysis of binary ionic compounds in the molten state.",
						},
					},
					4.33: {
						"sub-topic": "Using electrolysis to extract metal",
						spec: {
							4.331: "Metals can be extracted from molten compounds using electrolysis. Electrolysis is used if the metal is too reactive to be extracted by reduction with carbon or if the metal reacts with carbon. Large amounts of energy are used in the extraction process to melt the compounds and to produce the electrical current.",
							4.332: "Aluminium is manufactured by the electrolysis of a molten mixture of aluminium oxide and cryolite using carbon as the positive electrode (anode). ",
							4.333: "Students should be able to:\n • explain why a mixture is used as the electrolyte\n • explain why the positive electrode must be continually replaced.",
						},
					},
					4.34: {
						"sub-topic": "Electrolysis of aqueous solutions",
						spec: {
							4.341: "The ions discharged when an aqueous solution is electrolysed using inert electrodes depend on the relative reactivity of the elements involved.",
							4.342: " At the negative electrode (cathode), hydrogen is produced if the metal is more reactive than hydrogen.",
							4.343: "At the positive electrode (anode), oxygen is produced unless the solution contains halide ions when the halogen is produced. ",
							4.344: " This happens because in the aqueous solution water molecules break down producing hydrogen ions and hydroxide ions that are discharged.",
						},
					},
					4.35: {
						"sub-topic": "Representation of reactions at electrodes as half equations (HT only)",
						spec: {
							4.351: "During electrolysis, at the cathode (negative electrode), positively charged ions gain electrons and so the reactions are reductions. ",
							4.352: "At the anode (positive electrode), negatively charged ions lose electrons and so the reactions are oxidations. ",
							4.353: "Reactions at electrodes can be represented by half equations, for example:  equations 4.4.3.5",
						},
					},
				},
			},
		},
	},
	5: {
		topic: "Energy changes",
		topicQuestions: "",
		subs: {
			5.1: {
				"sub-topic": "Exothermic and endothermic reactions",
				spec: {
					5.11: {
						"sub-topic": "Energy transfer during exothermic and endothermic reactions",
						spec: {
							5.111: "Energy is conserved in chemical reactions. The amount of energy in the universe at the end of a chemical reaction is the same as before the reaction takes place. If a reaction transfers energy to the surroundings the product molecules must have less energy than the reactants, by the amount transferred.",
							5.112: "An exothermic reaction is one that transfers energy to the surroundings so the temperature of the surroundings increases.",
							5.113: "Exothermic reactions include combustion, many oxidation reactions and neutralisation.",
							5.114: "  Everyday uses of exothermic reactions include self-heating cans and hand warmers.",
							5.115: "An endothermic reaction is one that takes in energy from the surroundings so the temperature of the surroundings decreases.",
							5.116: "  Endothermic reactions include thermal decompositions and the reaction of citric acid and sodium hydrogencarbonate. Some sports injury packs are based on endothermic reactions.",
							5.117: "Students should be able to:\n • distinguish between exothermic and endothermic reactions on the basis of the temperature change of the surroundings\n • evaluate uses and applications of exothermic and endothermic reactions given appropriate information.",
							5.118:" Limited to measurement of temperature change. Calculation of energy changes or δh is not required.",
						},
					},
					5.12: {
						"sub-topic": "Reaction profiles",
						spec: {
							5.121: "Chemical reactions can occur only when reacting particles collide with each other and with sufficient energy. The minimum amount of energy that particles must have to react is called the activation energy. ",
							5.122: "Reaction profiles can be used to show the relative energies of reactants and products, the activation energy and the overall energy change of a reaction. ",
							5.123: "Students should be able to:\n • draw simple reaction profiles (energy level diagrams) for exothermic and endothermic reactions showing the relative energies of reactants and products, the activation energy and the overall energy change, with a curved line to show the energy as the reaction proceeds\n • use reaction profiles to identify reactions as exothermic or endothermic\n • explain that the activation energy is the energy needed for a reaction to occur.",
						},
					},
					5.13: {
						"sub-topic": "The energy change of reactions (HT only)",
						spec: {
							5.131: "During a chemical reaction:\n • energy must be supplied to break bonds in the reactants\n • energy is released when bonds in the products are formed. ",
							5.132:"The energy needed to break bonds and the energy released when bonds are formed can be calculated from bond energies.",
							5.133:"The difference between the sum of the energy needed to break bonds in the reactants and the sum of the energy released when bonds in the products are formed is the overall energy change of the reaction.",
							5.134:"  In an exothermic reaction, the energy released from forming new bonds is greater than the energy needed to break existing bonds.",
							5.135:" In an endothermic reaction, the energy needed to break existing bonds is greater than the energy released from forming new bonds.",
							5.136:"Students should be able to calculate the energy transferred in chemical reactions using bond energies supplied.",

						},
					},
				},
			},
			5.2: {
				"sub-topic": "Chemical cells and fuel cells (chemistry only)",
				spec: {
					5.21: {
						"sub-topic": "Cells and batteries",
						spec: {
							5.211: "Cells contain chemicals which react to produce electricity. ",
							5.212: "The voltage produced by a cell is dependent upon a number of factors including the type of electrode and electrolyte.",
							5.213: " A simple cell can be made by connecting two different metals in contact with an electrolyte. ",
							5.214: "Batteries consist of two or more cells connected together in series to provide a greater voltage.",
							5.215: " In non-rechargeable cells and batteries the chemical reactions stop when one of the reactants has been used up. Alkaline batteries are non-rechargeable.",
							5.216: " Rechargeable cells and batteries can be recharged because the chemical reactions are reversed when an external electrical current is supplied. ",
							5.217: "Students should be able to interpret data for relative reactivity of different metals and evaluate the use of cells. ",
							5.218: "Students do not need to know details of cells and batteries other than those specified.",
							
						},
					},
					5.22: {
						"sub-topic": "Fuel cells",
						spec: {
							5.221: "Fuel cells are supplied by an external source of fuel (eg hydrogen) and oxygen or air. The fuel is oxidised electrochemically within the fuel cell to produce a potential difference.",
							5.222: "The overall reaction in a hydrogen fuel cell involves the oxidation of hydrogen to produce water.",
							5.223: "Hydrogen fuel cells offer a potential alternative to rechargeable cells and batteries. ",
							5.224: " Students should be able to:\n • evaluate the use of hydrogen fuel cells in comparison with rechargeable cells and batteries\n • (ht only) write the half equations for the electrode reactions in the hydrogen fuel cell.",
						},
					},
				},
			},
		},
	},
	6: {
		topic: "The rate and extent of chemical change",
		topicQuestions: "",
		subs: {
			6.1: {
				"sub-topic": "Rate of reaction",
				spec: {
					6.11: {
						"sub-topic": "Calculating rates of reactions",
						spec: {
							6.111: "The rate of a chemical reaction can be found by measuring the quantity of a reactant used or the quantity of product formed over time: formula 4.6.1.1 ",
							6.112:"The quantity of reactant or product can be measured by the mass in grams or by a volume in cm3 .",
							6.113:"The units of rate of reaction may be given as g/s or cm3 /s. ",
							6.114:"For the higher tier, students are also required to use quantity of reactants in terms of moles and units for rate of reaction in mol/s. ",
							6.115:" Students should be able to:\n • calculate the mean rate of a reaction from given information about the quantity of a reactant used or the quantity of a product formed and the time taken\n • draw, and interpret, graphs showing the quantity of product formed or quantity of reactant used up against time\n • draw tangents to the curves on these graphs and use the slope of the tangent as a measure of the rate of reaction\n • (ht only) calculate the gradient of a tangent to the curve on these graphs as a measure of rate of reaction at a specific time.",

						},
					},
					6.12: {
						"sub-topic": "Factors which affect the rates of chemical reactions",
						spec: {
							6.121: "Factors which affect the rates of chemical reactions include: the concentrations of reactants in solution, the pressure of reacting gases, the surface area of solid reactants, the temperature and the presence of catalysts.",
							6.122: " Students should be able to recall how changing these factors affects the rate of chemical reactions.",
						},
					},
					6.13: {
						"sub-topic": "Collision theory and activation energy",
						spec: {
							6.131: "Collision theory explains how various factors affect rates of reactions. According to this theory, chemical reactions can occur only when reacting particles collide with each other and with sufficient energy. ",
							6.132: "The minimum amount of energy that particles must have to react is called the activation energy. Increasing the concentration of reactants in solution, the pressure of reacting gases, and the surface area of solid reactants increases the frequency of collisions and so increases the rate of reaction. ",
							6.133: "Increasing the temperature increases the frequency of collisions and makes the collisions more energetic, and so increases the rate of reaction.",
							6.134: "Students should be able to :\n • predict and explain using collision theory the effects of changing conditions of concentration, pressure and temperature on the rate of a reaction\n • predict and explain the effects of changes in the size of pieces of a reacting solid in terms of surface area to volume ratio\n • use simple ideas about proportionality when using collision theory to explain the effect of a factor on the rate of a reaction.",
						},
					},
					6.14: {
						"sub-topic": "Catalysts",
						spec: {
							6.141: "Catalysts change the rate of chemical reactions but are not used up during the reaction. Different reactions need different catalysts. Enzymes act as catalysts in biological systems. ",
							6.142: "Catalysts increase the rate of reaction by providing a different pathway for the reaction that has a lower activation energy. ",
							6.143: "A reaction profile for a catalysed reaction can be drawn in the following form: graph 4.6.1.4",
							6.144: "Students should be able to identify catalysts in reactions from their effect on the rate of reaction and because they are not included in the chemical equation for the reaction. ",
							6.145: "Students should be able to explain catalytic action in terms of activation energy. ",
							6.146: "Students do not need to know the names of catalysts other than those specified in the subject content.",
						},
					},
				},
			},
			6.2: {
				"sub-topic": "Reversible reactions and dynamic equilibrium",
				spec: {
					6.21: {
						"sub-topic": "Reversible reactions",
						spec: {
							6.211: "In some chemical reactions, the products of the reaction can react to produce the original reactants. Such reactions are called reversible reactions and are represented: reaction 4.6.2.1",
							6.212: "the direction of reversible reactions can be changed by changing the conditions. For example: reaction 4.6.2.1",
						},
					},
					6.22: {
						"sub-topic": "Energy changes and reversible reactions",
						spec: {
							6.221: "If a reversible reaction is exothermic in one direction, it is endothermic in the opposite direction. The same amount of energy is transferred in each case. For example: reaction 4.6.2.2",
						},
					},
					6.23: {
						"sub-topic": "Equilibrium",
						spec: {
							6.231: "When a reversible reaction occurs in apparatus which prevents the escape of reactants and products, equilibrium is reached when the forward and reverse reactions occur at exactly the same rate.",
						},
					}, 
					6.24: {
						"sub-topic": "The effect of changing conditions on equilibrium (HT only)",
						spec: {
							6.241: "The relative amounts of all the reactants and products at equilibrium depend on the conditions of the reaction.",
							6.242: "If a system is at equilibrium and a change is made to any of the conditions, then the system responds to counteract the change.",
							6.243: "  The effects of changing conditions on a system at equilibrium can be predicted using le chatelier’s principle.",
							6.244: " Students should be able to make qualitative predictions about the effect of changes on systems at equilibrium when given appropriate information.",
						},
					},
					6.25: {
						"sub-topic": "The effect of changing concentration (HT only)",
						spec: {
							6.251: "If the concentration of one of the reactants or products is changed, the system is no longer at equilibrium and the concentrations of all the substances will change until equilibrium is reached again. ",
							6.252:"If the concentration of a reactant is increased, more products will be formed until equilibrium is reached again.",
							6.253:" If the concentration of a product is decreased, more reactants will react until equilibrium is reached again. ",
							6.254:"Students should be able to interpret appropriate given data to predict the effect of a change in concentration of a reactant or product on given reactions at equilibrium.",
						},
					}, 
					6.26: {
						"sub-topic": "The effect of temperature changes on equilibrium (HT only)",
						spec: {
							6.261: "If the temperature of a system at equilibrium is increased:\n • the relative amount of products at equilibrium increases for an endothermic reaction\n • the relative amount of products at equilibrium decreases for an exothermic reaction.",
							6.262: " If the temperature of a system at equilibrium is decreased:\n • the relative amount of products at equilibrium decreases for an endothermic reaction\n • the relative amount of products at equilibrium increases for an exothermic reaction. ",
							6.263: "Students should be able to interpret appropriate given data to predict the effect of a change in temperature on given reactions at equilibrium.",
						},
					}, 
					6.27: {
						"sub-topic": "The effect of pressure changes on equilibrium (HT only)",
						spec: {
							6.271: "For gaseous reactions at equilibrium:\n • an increase in pressure causes the equilibrium position to shift towards the side with the smaller number of molecules as shown by the symbol equation for that reaction\n • a decrease in pressure causes the equilibrium position to shift towards the side with the larger number of molecules as shown by the symbol equation for that reaction. ",
							6.272: "Students should be able to interpret appropriate given data to predict the effect of pressure changes on given reactions at equilibrium.",
						},
					},
					
				},
			},
		},
	},
	7: {
		topic: "Organic chemistry",
		topicQuestions: "",
		subs: {
			7.1: {
				"sub-topic": "Carbon compounds as fuels and feedstock",
				spec: {
					7.11: {
						"sub-topic": "Crude oil, hydrocarbons and alkanes",
						spec: {
							7.111: "Crude oil is a finite resource found in rocks. Crude oil is the remains of an ancient biomass consisting mainly of plankton that was buried in mud. ",
							7.112: "Crude oil is a mixture of a very large number of compounds. Most of the compounds in crude oil are hydrocarbons, which are molecules made up of hydrogen and carbon atoms only.",
							7.113: " Most of the hydrocarbons in crude oil are hydrocarbons called alkanes. The general formula for the homologous series of alkanes is cnh2n+2 ",
							7.114: "the first four members of the alkanes are methane, ethane, propane and butane. ",
							7.115: "Alkane molecules can be represented in the following forms: c2h6 or",
							7.116: "Students should be able to recognise substances as alkanes given their formulae in these forms. ",
							7.117: "Students do not need to know the names of specific alkanes other than methane, ethane, propane and butane.",
						},
					},
					7.12: {
						"sub-topic": "Fractional distillation and petrochemicals",
						spec: {
							7.121: "The many hydrocarbons in crude oil may be separated into fractions, each of which contains molecules with a similar number of carbon atoms, by fractional distillation. ",
							7.122: "The fractions can be processed to produce fuels and feedstock for the petrochemical industry. ",
							7.123: "Many of the fuels on which we depend for our modern lifestyle, such as petrol, diesel oil, kerosene, heavy fuel oil and liquefied petroleum gases, are produced from crude oil. ",
							7.124: "Many useful materials on which modern life depends are produced by the petrochemical industry, such as solvents, lubricants, polymers, detergents.",
							7.125: " The vast array of natural and synthetic carbon compounds occur due to the ability of carbon atoms to form families of similar compounds. ",
							7.126: "Students should be able to explain how fractional distillation works in terms of evaporation and condensation. ",
							7.127: "Knowledge of the names of other specific fractions or fuels is not required.",
						},
					},
					7.13: {
						"sub-topic": "Properties of hydrocarbons",
						spec: {
							7.131: "Some properties of hydrocarbons depend on the size of their molecules, including boiling point, viscosity and flammability. These properties influence how hydrocarbons are used as fuels.",
							7.132: "Students should be able to recall how boiling point, viscosity and flammability change with increasing molecular size.",
							7.133: "  The combustion of hydrocarbon fuels releases energy. During combustion, the carbon and hydrogen in the fuels are oxidised. The complete combustion of a hydrocarbon produces carbon dioxide and water.",
							7.134: "Students should be able to write balanced equations for the complete combustion of hydrocarbons with a given formula. ",
							7.135: " Knowledge of trends in properties of hydrocarbons is limited to:\n • boiling points\n • viscosity\n • flammability.",
						},
					},
					7.14: {
						"sub-topic": "Cracking and alkenes",
						spec: {
							7.141: "Hydrocarbons can be broken down (cracked) to produce smaller, more useful molecules. ",
							7.142: "Cracking can be done by various methods including catalytic cracking and steam cracking. ",
							7.143: "Students should be able to describe in general terms the conditions used for catalytic cracking and steam cracking. ",
							7.144: "The products of cracking include alkanes and another type of hydrocarbon called alkenes. ",
							7.145: "Alkenes are more reactive than alkanes and react with bromine water, which is used as a test for alkenes. ",
							7.146: "Students should be able to recall the colour change when bromine water reacts with an alkene.",
							7.147: " There is a high demand for fuels with small molecules and so some of the products of cracking are useful as fuels.",
							7.148: " Alkenes are used to produce polymers and as starting materials for the production of many other chemicals.",
							7.149: " Students should be able to balance chemical equations as examples of cracking given the formulae of the reactants and products. ",
							7.1410: "Students should be able to give examples to illustrate the usefulness of cracking. They should also be able to explain how modern life depends on the uses of hydrocarbons.",
							7.1411: " (for combined science: trilogy and synergy students do not need to know the formulae or names of individual alkenes.)",


						},
					},
					
				},
			},
			7.2: {
				"sub-topic": "Reactions of alkenes and alcohols (chemistry only)",
				spec: {
					7.21: {
						"sub-topic": "Structure and formulae of alkenes",
						spec: {
							7.211: "Alkenes are hydrocarbons with a double carbon-carbon bond. The general formula for the homologous series of alkenes is CnH2n ",
							7.212: "Alkene molecules are unsaturated because they contain two fewer hydrogen atoms than the alkane with the same number of carbon atoms.",
							7.213: " The first four members of the homologous series of alkenes are ethene, propene, butene and pentene.",
							7.214: "Alkene molecules can be represented in the following forms: C3H6 or ",
							7.215: " Students do not need to know the names of individual alkenes other than ethene, propene, butene and pentene.",
						},
					},
					7.22: {
						"sub-topic": "Reactions of alkenes",
						spec: {
							7.221: "Alkenes are hydrocarbons with the functional group C=C.",
							7.222: " It is the generality of reactions of functional groups that determine the reactions of organic compounds. ",
							7.223: "Alkenes react with oxygen in combustion reactions in the same way as other hydrocarbons, but they tend to burn in air with smoky flames because of incomplete combustion.",
							7.224: " Alkenes react with hydrogen, water and the halogens, by the addition of atoms across the carbon-carbon double bond so that the double bond becomes a single carbon-carbon bond. ",
							7.225: "Students should be able to:\n • describe the reactions and conditions for the addition of hydrogen, water and halogens to alkenes\n • draw fully displayed structural formulae of the first four members of the alkenes and the products of their addition reactions with hydrogen, water, chlorine, bromine and iodine.",
						},
					},
					7.23: {
						"sub-topic": "Alcohols",
						spec: {
							7.231: "Alcohols contain the functional group –OH. ",
							7.232: "Methanol, ethanol, propanol and butanol are the first four members of a homologous series of alcohols.",
							7.233: " Alcohols can be represented in the following forms: CH3CH2OH",
							7.234: "Students should be able to:\n • describe what happens when any of the first four alcohols react with sodium, burn in air, are added to water, react with an oxidising agent\n • recall the main uses of these alcohols.",
							7.235: " Aqueous solutions of ethanol are produced when sugar solutions are fermented using yeast.",
							7.236: " Students should know the conditions used for fermentation of sugar using yeast.",
							7.237: " Students should be able to recognise alcohols from their names or from given formulae. ",
							7.238: "Students do not need to know the names of individual alcohols other than methanol, ethanol, propanol and butanol. ",
							7.239: "Students are not expected to write balanced chemical equations for the reactions of alcohols other than for combustion reactions.",
						},
					},
					7.24: {
						"sub-topic": "Carboxylic acids",
						spec: {
							7.241: "Carboxylic acids have the functional group –COOH.",
							7.242: " The first four members of a homologous series of carboxylic acids are methanoic acid, ethanoic acid, propanoic acid and butanoic acid.",
							7.243: " The structures of carboxylic acids can be represented in the following forms: CH3COOH ",
							7.244: "Students should be able to:\n • describe what happens when any of the first four carboxylic acids react with carbonates, dissolve in water, react with alcohols\n • (HT only) explain why carboxylic acids are weak acids in terms of ionisation and pH (see Strong and weak acids (HT only) (page 48)). ",
							7.245: "Students should be able to recognise carboxylic acids from their names or from given formulae.",
							7.246: " Students do not need to know the names of individual carboxylic acids other than methanoic acid, ethanoic acid, propanoic acid and butanoic acid. ",
							7.247: "Students are not expected to write balanced chemical equations for the reactions of carboxylic acids.",
							7.248: " Students do not need to know the names of esters other than ethyl ethanoate.",
							
						},
					},
				},
			},
			7.3: {
				"sub-topic": "Synthetic and naturally occurring polymers (chemistry only)",
				spec: {
					7.31: {
						"sub-topic": "Addition polymerisation",
						spec: {
							7.311: "Alkenes can be used to make polymers such as poly(ethene) and poly(propene) by addition polymerisation. ",
							7.312:"In addition polymerisation reactions, many small molecules (monomers) join together to form very large molecules (polymers). ",
							7.313:"For example:  diagram 4.7.3.1",
							7.314:"In addition polymers the repeating unit has the same atoms as the monomer because no other molecule is formed in the reaction. ",
							7.315:" Students should be able to:\n • recognise addition polymers and monomers from diagrams in the forms shown and from the presence of the functional group C=C in the monomers\n • draw diagrams to represent the formation of a polymer from a given alkene monomer\n • relate the repeating unit to the monomer.",

						},
					},
					7.32: {
						"sub-topic": "Condensation polymerisation (HT only)",
						spec: {
							7.321: "Condensation polymerisation involves monomers with two functional groups. When these types of monomers react they join together, usually losing small molecules such as water, and so the reactions are called condensation reactions. ",
							7.322: "The simplest polymers are produced from two different monomers with two of the same functional groups on each monomer.",
							7.323: "For example:  equations 4.7.3.2",
							7.324: "Students should be able to explain the basic principles of condensation polymerisation by reference to the functional groups in the monomers and the repeating units in the polymers.",
						},
					},
					7.33: {
						"sub-topic": "Amino acids (HT only)",
						spec: {
							7.331: "Amino acids have two different functional groups in a molecule. Amino acids react by condensation polymerisation to produce polypeptides.",
							7.332: "For example: glycine is H2NCH2COOH and polymerises to produce the polypeptide",
							7.333: "Different amino acids can be combined in the same chain to produce proteins.",
						},
					},
					7.34: {
						"sub-topic": "DNA (deoxyribonucleic acid) and other naturally occurring polymers",
						spec: {
							7.341: "DNA (deoxyribonucleic acid) is a large molecule essential for life. ",
							7.342: "DNA encodes genetic instructions for the development and functioning of living organisms and viruses.",
							7.343: " Most DNA molecules are two polymer chains, made from four different monomers called nucleotides, in the form of a double helix. Other naturally occurring polymers important for life include proteins, starch and cellulose. ",
							7.344: "Students should be able to name the types of monomers from which these naturally occurring polymers are made.",
						
						},
					},
				},
			},
		},
	},
	8: {
		topic: "Chemical analysis",
		topicQuestions: "",
		subs: {
			8.1: {
				"sub-topic": "Purity, formulations and chromatography",
				spec: {
					8.11: {
						"sub-topic": "Pure substances",
						spec: {
							8.111: "In chemistry, a pure substance is a single element or compound, not mixed with any other substance. ",
							8.112:"Pure elements and compounds melt and boil at specific temperatures. Melting point and boiling point data can be used to distinguish pure substances from mixtures.",
							8.113:" In everyday language, a pure substance can mean a substance that has had nothing added to it, so it is unadulterated and in its natural state, eg pure milk. ",
							8.114:"Students should be able to use melting point and boiling point data to distinguish pure from impure substances.",

						},
					},
					8.12: {
						"sub-topic": "Formulations",
						spec: {
							8.121: "A formulation is a mixture that has been designed as a useful product. Many products are complex mixtures in which each chemical has a particular purpose. Formulations are made by mixing the components in carefully measured quantities to ensure that the product has the required properties. Formulations include fuels, cleaning agents, paints, medicines, alloys, fertilisers and foods.",
							8.122: " Students should be able to identify formulations given appropriate information.",
							8.123: " Students do not need to know the names of components in proprietary products.",
						},
					},
					8.13: {
						"sub-topic": "Chromatography",
						spec: {
							8.131: "Chromatography can be used to separate mixtures and can give information to help identify substances. Chromatography involves a stationary phase and a mobile phase. Separation depends on the distribution of substances between the phases. ",
							8.132: "The ratio of the distance moved by a compound (centre of spot from origin) to the distance moved by the solvent can be expressed as its Rf value:  formula 4.8.1.3 ",
							8.133: "Different compounds have different Rf values in different solvents, which can be used to help identify the compounds. The compounds in a mixture may separate into different spots depending on the solvent but a pure compound will produce a single spot in all solvents.",
							8.134: "Students should be able to:\n • explain how paper chromatography separates mixtures\n • suggest how chromatographic methods can be used for distinguishing pure substances from impure substances\n • interpret chromatograms and determine Rf values from chromatograms\n • provide answers to an appropriate number of significant figures",
							
						},
					},					
				},
			},
			8.2: {
				"sub-topic": "Identification of common gases",
				spec: {
					8.21: {
						"sub-topic": "Test for hydrogen",
						spec: {
							8.211: "The test for hydrogen uses a burning splint held at the open end of a test tube of the gas. Hydrogen burns rapidly with a pop sound.",
						},
					},
					8.22: {
						"sub-topic": "Test for oxygen",
						spec: {
							8.221: "The test for oxygen uses a glowing splint inserted into a test tube of the gas. The splint relights in oxygen.",
						},
					},
					8.23: {
						"sub-topic": "Test for carbon dioxide",
						spec: {
							8.231: "The test for carbon dioxide uses an aqueous solution of calcium hydroxide (lime water). When carbon dioxide is shaken with or bubbled through limewater the limewater turns milky (cloudy).",
						},
					},
					8.24: {
						"sub-topic": "Test for chlorine",
						spec: {
							8.241: "The test for chlorine uses litmus paper. When damp litmus paper is put into chlorine gas the litmus paper is bleached and turns white.",
						},
					},
				},
			},			
			8.3: {
				"sub-topic": "Identification of ions by chemical and spectroscopic means (chemistry only)",
				spec: {
					8.31: {
						"sub-topic": "Flame tests",
						spec: {
							8.311: "Flame tests can be used to identify some metal ions (cations). Lithium, sodium, potassium, calcium and copper compounds produce distinctive colours in flame tests:\n • lithium compounds result in a crimson flame\n • sodium compounds result in a yellow flame\n • potassium compounds result in a lilac flame\n • calcium compounds result in an orange-red flame\n • copper compounds result in a green flame.",
							8.312: "If a sample containing a mixture of ions is used some flame colours can be masked.",
							8.313: "Students should be able to identify species from the results of the tests in 4.8.3.1 to 4.8.3.5. ",
							8.314: "Flame colours of other metal ions are not required knowledge.",
						},
					},
					8.32: {
						"sub-topic": "Metal hydroxides",
						spec: {
							8.321: "Sodium hydroxide solution can be used to identify some metal ions (cations). ",
							8.322: "Solutions of aluminium, calcium and magnesium ions form white precipitates when sodium hydroxide solution is added but only the aluminium hydroxide precipitate dissolves in excess sodium hydroxide solution.",
							8.323: " Solutions of copper(II), iron(II) and iron(III) ions form coloured precipitates when sodium hydroxide solution is added. ",
							8.324: "Copper(II) forms a blue precipitate, iron(II) a green precipitate and iron(III) a brown precipitate.",
							8.325: "Students should be able to write balanced equations for the reactions to produce the insoluble hydroxides. ",
							8.326: "Students are not expected to write equations for the production of sodium aluminate.",
						},
					},
					8.33: {
						"sub-topic": "Carbonates",
						spec: {
							8.331: "Carbonates react with dilute acids to form carbon dioxide gas. Carbon dioxide can be identified with limewater.",
						},
					},
					8.34: {
						"sub-topic": "Halides",
						spec: {
							8.341: "Halide ions in solution produce precipitates with silver nitrate solution in the presence of dilute nitric acid. Silver chloride is white, silver bromide is cream and silver iodide is yellow.",
						},
					},
					8.35: {
						"sub-topic": "Sulfates",
						spec: {
							8.351: "Sulfate ions in solution produce a white precipitate with barium chloride solution in the presence of dilute hydrochloric acid",
						},
					},
					8.36: {
						"sub-topic": "Instrumental methods",
						spec: {
							8.361: "Elements and compounds can be detected and identified using instrumental methods. Instrumental methods are accurate, sensitive and rapid. ",
							8.362: "Students should be able to state advantages of instrumental methods compared with the chemical tests in this specification.",
						},
					},
					8.37: {
						"sub-topic": "Flame emission spectroscopy",
						spec: {
							8.371: "Flame emission spectroscopy is an example of an instrumental method used to analyse metal ions in solutions.",
							8.372: " The sample is put into a flame and the light given out is passed through a spectroscope. The output is a line spectrum that can be analysed to identify the metal ions in the solution and measure their concentrations. ",
							8.373: "Students should be able to interpret an instrumental result given appropriate data in chart or tabular form, when accompanied by a reference set in the same form, limited to flame emission spectroscopy.",
						},
					},
				},
			},			
		},
	},
	9: {
		topic: "Chemistry of the atmosphere",
		topicQuestions: "",
		subs: {
			9.1: {
				"sub-topic": "The composition and evolution of the Earth's atmosphere",
				spec: {
					9.11: {
                        "sub-topic": "The proportions of different gases in the atmosphere",
                        spec: {
                            9.111: "For 200 million years, the proportions of different gases in the atmosphere have been much the same as they are today:\n • about four-fifths (approximately 80%) nitrogen\n • about one-fifth (approximately 20%) oxygen\n • small proportions of various other gases, including carbon dioxide, water vapour and noble gases.",
                        },
                    },
                    9.12: {
                        "sub-topic": "The Earth's early atmosphere",
                        spec: {
                            9.121: "Theories about what was in the Earth’s early atmosphere and how the atmosphere was formed have changed and developed over time. Evidence for the early atmosphere is limited because of the time scale of 4.6 billion years.",
							9.122: " One theory suggests that during the first billion years of the Earth’s existence there was intense volcanic activity that released gases that formed the early atmosphere and water vapour that condensed to form the oceans. At the start of this period the Earth’s atmosphere may have been like the atmospheres of Mars and Venus today, consisting of mainly carbon dioxide with little or no oxygen gas. ",
							9.123: "Volcanoes also produced nitrogen which gradually built up in the atmosphere and there may have been small proportions of methane and ammonia. ",
							9.124: "When the oceans formed carbon dioxide dissolved in the water and carbonates were precipitated producing sediments, reducing the amount of carbon dioxide in the atmosphere. No knowledge of other theories is required. ",
							9.125: "Students should be able to, given appropriate information, interpret evidence and evaluate different theories about the Earth’s early atmosphere.",
                        },
                    },
                    9.13: {
                        "sub-topic": "How oxygen increased",
                        spec: {
                            9.131: "Algae and plants produced the oxygen that is now in the atmosphere by photosynthesis, which can be represented by the equation: 4.9.1.3",
							9.132: " Algae first produced oxygen about 2.7 billion years ago and soon after this oxygen appeared in the atmosphere. Over the next billion years plants evolved and the percentage of oxygen gradually increased to a level that enabled animals to evolve.",
                        },
                    },
					9.14: {
                        "sub-topic": "How carbon dioxide decreased",
                        spec: {
                            9.141: "Algae and plants decreased the percentage of carbon dioxide in the atmosphere by photosynthesis.",
							9.142: " Carbon dioxide was also decreased by the formation of sedimentary rocks and fossil fuels that contain carbon. ",
							9.143: "Students should be able to:\n • describe the main changes in the atmosphere over time and some of the likely causes of these changes\n • describe and explain the formation of deposits of limestone, coal, crude oil and natural gas.",
                        },
                    },
				},
			},
			9.2: {
				"sub-topic": "Carbon dioxide and methane as greenhouse gases",
				spec: {
					9.21: {
                        "sub-topic": "Greenhouse gases",
                        spec: {
                            9.211: "Greenhouse gases in the atmosphere maintain temperatures on Earth high enough to support life. Water vapour, carbon dioxide and methane are greenhouse gases.",
							9.212: " Students should be able to describe the greenhouse effect in terms of the interaction of short and long wavelength radiation with matter.",
                        },
                    },
                    9.22: {
                        "sub-topic": "Human activities which contribute to an increase in greenhouse gases in the atmosphere",
                        spec: {
                            9.221: "Some human activities increase the amounts of greenhouse gases in the atmosphere. These include:\n • carbon dioxide\n • methane. ",
							9.222: "Students should be able to recall two human activities that increase the amounts of each of the greenhouse gases carbon dioxide and methane.",
							9.223: "Based on peer-reviewed evidence, many scientists believe that human activities will cause the temperature of the Earth’s atmosphere to increase at the surface and that this will result in global climate change.",
							9.224: "  However, it is difficult to model such complex systems as global climate change. This leads to simplified models, speculation and opinions presented in the media that may be based on only parts of the evidence and which may be biased.",
							9.225: " Students should be able to:\n • evaluate the quality of evidence in a report about global climate change given appropriate information\n • describe uncertainties in the evidence base\n • recognise the importance of peer review of results and of communicating results to a wide range of audiences.",
                        },
                    },
                    9.23: {
                        "sub-topic": "Global climate change",
                        spec: {
                            9.231: "An increase in average global temperature is a major cause of climate change. ",
							9.232: "There are several potential effects of global climate change.",
							9.233: " Students should be able to:\n • describe briefly four potential effects of global climate change\n • discuss the scale, risk and environmental implications of global climate change.",
                        },
                    },
					9.24: {
                        "sub-topic": "The carbon footprint and its reduction",
                        spec: {
                            9.241: "The carbon footprint is the total amount of carbon dioxide and other greenhouse gases emitted over the full life cycle of a product, service or event. ",
							9.242: "The carbon footprint can be reduced by reducing emissions of carbon dioxide and methane.",
							9.243: "The carbon footprint can be reduced by reducing emissions of carbon dioxide and methane. Students should be able to:\n • describe actions to reduce emissions of carbon dioxide and methane\n • give reasons why actions may be limited.",
							
                        },
                    },
				},
			},
			9.3: {
				"sub-topic": "Common atmospheric pollutants and their sources",
				spec: {
					9.31: {
                        "sub-topic": "Atmospheric pollutants from fuels",
                        spec: {
                            9.311: "The combustion of fuels is a major source of atmospheric pollutants. ",
							9.312: "Most fuels, including coal, contain carbon and/or hydrogen and may also contain some sulfur. ",
							9.313: "The gases released into the atmosphere when a fuel is burned may include carbon dioxide, water vapour, carbon monoxide, sulfur dioxide and oxides of nitrogen. Solid particles and unburned hydrocarbons may also be released that form particulates in the atmosphere.",
							9.314: " Students should be able to:\n • describe how carbon monoxide, soot (carbon particles), sulfur dioxide and oxides of nitrogen are produced by burning fuels\n • predict the products of combustion of a fuel given appropriate information about the composition of the fuel and the conditions in which it is used.",
                        },
                    },
                    9.32: {
                        "sub-topic": "Properties and effects of atmospheric pollutants",
                        spec: {
                            9.321: "Carbon monoxide is a toxic gas. It is colourless and odourless and so is not easily detected.",
							9.322: " Sulfur dioxide and oxides of nitrogen cause respiratory problems in humans and cause acid rain. ",
							9.323: "Particulates cause global dimming and health problems for humans.",
							9.324: " Students should be able to describe and explain the problems caused by increased amounts of these pollutants in the air.",
                        },
                    },
                   
				},
			},
		},
	},
	10: {
		topic: "Using resources",
		topicQuestions: "",
		subs: {
			10.1: {
				"sub-topic": "Using the Earth's resources and obtaining potable water",
				spec: {
					10.11: {
                        "sub-topic": "Using the Earth's resources and sustainable development",
                        spec: {
                            10.111: "Humans use the Earth’s resources to provide warmth, shelter, food and transport.",
							10.112: "Natural resources, supplemented by agriculture, provide food, timber, clothing and fuels.",
							10.113: "Finite resources from the Earth, oceans and atmosphere are processed to provide energy and materials. ",
							10.114: "Chemistry plays an important role in improving agricultural and industrial processes to provide new products and in sustainable development, which is development that meets the needs of current generations without compromising the ability of future generations to meet their own needs. ",
							10.115: "Students should be able to:\n • state examples of natural products that are supplemented or replaced by agricultural and synthetic products\n • distinguish between finite and renewable resources given appropriate information. ",
							10.116: "Students should be able to:\n • extract and interpret information about resources from charts, graphs and tables\n • use orders of magnitude to evaluate the significance of data.",
                        },
                    },
                    10.12: {
                        "sub-topic": "Potable water",
                        spec: {
                            10.121: "Water of appropriate quality is essential for life. For humans, drinking water should have sufficiently low levels of dissolved salts and microbes. Water that is safe to drink is called potable water. Potable water is not pure water in the chemical sense because it contains dissolved substances.",
							10.122: " The methods used to produce potable water depend on available supplies of water and local conditions. ",
							10.123: "In the United Kingdom (UK), rain provides water with low levels of dissolved substances (fresh water) that collects in the ground and in lakes and rivers, and most potable water is produced by:\n • choosing an appropriate source of fresh water\n • passing the water through filter beds\n • sterilising.",
							10.124: " Sterilising agents used for potable water include chlorine, ozone or ultraviolet light. ",
							10.125: "If supplies of fresh water are limited, desalination of salty water or sea water may be required. Desalination can be done by distillation or by processes that use membranes such as reverse osmosis. These processes require large amounts of energy. ",
							10.126: "Students should be able to:\n • distinguish between potable water and pure water\n • describe the differences in treatment of ground water and salty water\n • give reasons for the steps used to produce potable water.",
                        },
                    },
					10.13: {
                        "sub-topic": "Waste water treatment",
                        spec: {
                            10.131: "Urban lifestyles and industrial processes produce large amounts of waste water that require treatment before being released into the environment. Sewage and agricultural waste water require removal of organic matter and harmful microbes. Industrial waste water may require removal of organic matter and harmful chemicals. ",
							10.132: "Sewage treatment includes:\n • screening and grit removal\n • sedimentation to produce sewage sludge and effluent\n • anaerobic digestion of sewage sludge\n • aerobic biological treatment of effluent.",
							10.133: " Students should be able to comment on the relative ease of obtaining potable water from waste, ground and salt water.",
                        },
                    },
					10.14: {
                        "sub-topic": "Alternative methods of extracting metals (HT only)",
                        spec: {
                            10.141: "The Earth’s resources of metal ores are limited. ",
							10.142: "Copper ores are becoming scarce and new ways of extracting copper from low-grade ores include phytomining, and bioleaching. These methods avoid traditional mining methods of digging, moving and disposing of large amounts of rock.",
							10.143: "Phytomining uses plants to absorb metal compounds. The plants are harvested and then burned to produce ash that contains metal compounds. ",
							10.144: "Bioleaching uses bacteria to produce leachate solutions that contain metal compounds.",
							10.145: " The metal compounds can be processed to obtain the metal. For example, copper can be obtained from solutions of copper compounds by displacement using scrap iron or by electrolysis.",
							10.146: " Students should be able to evaluate alternative biological methods of metal extraction, given appropriate information.",
                        },
                    },
				},
			},
			10.2: {
				"sub-topic": "Life cycle assessment and recycling",
				spec: {
					10.21: {
                        "sub-topic": "Life cycle assessment",
                        spec: {
                            10.211: "Life cycle assessments (LCAs) are carried out to assess the environmental impact of products in each of these stages:\n • extracting and processing raw materials\n • manufacturing and packaging\n • use and operation during its lifetime\n • disposal at the end of its useful life, including transport and distribution at each stage.",
							10.212: " Use of water, resources, energy sources and production of some wastes can be fairly easily quantified. Allocating numerical values to pollutant effects is less straightforward and requires value judgements, so LCA is not a purely objective process. ",
							10.213: "Selective or abbreviated LCAs can be devised to evaluate a product but these can be misused to reach pre-determined conclusions, eg in support of claims for advertising purposes.",
							10.214: " Students should be able to carry out simple comparative LCAs for shopping bags made from plastic and paper.",
                        },
                    },
                    10.22: {
                        "sub-topic": "Ways of reducing the use of resources",
                        spec: {
                            10.221: "The reduction in use, reuse and recycling of materials by end users reduces the use of limited resources, use of energy sources, waste and environmental impacts. ",
							10.222: "Metals, glass, building materials, clay ceramics and most plastics are produced from limited raw materials. Much of the energy for the processes comes from limited resources. Obtaining raw materials from the Earth by quarrying and mining causes environmental impacts.",
							10.223: "Some products, such as glass bottles, can be reused. Glass bottles can be crushed and melted to make different glass products. Other products cannot be reused and so are recycled for a different use.",
							10.224: "Metals can be recycled by melting and recasting or reforming into different products. The amount of separation required for recycling depends on the material and the properties required of the final product. For example, some scrap steel can be added to iron from a blast furnace to reduce the amount of iron that needs to be extracted from iron ore.",
							10.225: "  Students should be able to evaluate ways of reducing the use of limited resources, given appropriate information.",
                        },
                    },
				},
			},
			10.3: {
				"sub-topic": "Using materials (chemistry only)",
				spec: {
					10.31: {
                        "sub-topic": "Corrosion and its prevention",
                        spec: {
                            10.311: "Corrosion is the destruction of materials by chemical reactions with substances in the environment. Rusting is an example of corrosion. Both air and water are necessary for iron to rust.",
							10.312: "Corrosion can be prevented by applying a coating that acts as a barrier, such as greasing, painting or electroplating. Aluminium has an oxide coating that protects the metal from further corrosion. ",
							10.313: "Some coatings are reactive and contain a more reactive metal to provide sacrificial protection, eg zinc is used to galvanise iron. ",
							10.314: "Students should be able to: • describe experiments and interpret results to show that both air and water are necessary for rusting • explain sacrificial protection in terms of relative reactivity.",
                        },
                    },
					10.32: {
                        "sub-topic": "Alloys as useful materials",
                        spec: {
                            10.321: "Most metals in everyday use are alloys.",
							10.322: "Bronze is an alloy of copper and tin. Brass is an alloy of copper and zinc.",
							10.323: "Gold used as jewellery is usually an alloy with silver, copper and zinc. The proportion of gold in the alloy is measured in carats. 24 carat being 100% (pure gold), and 18 carat being 75% gold.",
							10.324: "  Steels are alloys of iron that contain specific amounts of carbon and other metals. High carbon steel is strong but brittle. Low carbon steel is softer and more easily shaped. Steels containing chromium and nickel (stainless steels) are hard and resistant to corrosion. ",
							10.325: "Aluminium alloys are low density. ",
							10.326: "Students should be able to:\n • recall a use of each of the alloys specified\n • interpret and evaluate the composition and uses of alloys other than those specified given appropriate information.",
                        },
                    },
					10.33: {
                        "sub-topic": "Ceramics, polymers and composites",
                        spec: {
                            10.331: "Most of the glass we use is soda-lime glass, made by heating a mixture of sand, sodium carbonate and limestone. Borosilicate glass, made from sand and boron trioxide, melts at higher temperatures than soda-lime glass.",
							10.332: " Clay ceramics, including pottery and bricks, are made by shaping wet clay and then heating in a furnace. ",
							10.333: "The properties of polymers depend on what monomers they are made from and the conditions under which they are made. For example, low density (LD) and high density (HD) poly(ethene) are produced from ethene. ",
							10.334: "Thermosoftening polymers melt when they are heated. Thermosetting polymers do not melt when they are heated. ",
							10.335: "Students should be able to:\n • explain how low density and high density poly(ethene) are both produced from ethene\n • explain the difference between thermosoftening and thermosetting polymers in terms of their structures. ",
							10.336: "Most composites are made of two materials, a matrix or binder surrounding and binding together fibres or fragments of the other material, which is called the reinforcement. ",
							10.337: "Students should be able to recall some examples of composites.",
							10.338: " Students should be able to, given appropriate information:\n • compare quantitatively the physical properties of glass and clay ceramics, polymers, composites and metals\n • explain how the properties of materials are related to their uses and select appropriate materials.",
                        },
                    },
                    
				},
			},
			10.4: {
				"sub-topic": "The Haber process and the use of NPK fertilisers (chemistry only)",
				spec: {
					10.41: {
                        "sub-topic": "The Haber process",
                        spec: {
                            10.411: "The Haber process is used to manufacture ammonia, which can be used to produce nitrogen-based fertilisers. ",
							10.411: "The raw materials for the Haber process are nitrogen and hydrogen.",
							10.411: "Students should be able to recall a source for the nitrogen and a source for the hydrogen used in the Haber process. ",
							10.411: "The purified gases are passed over a catalyst of iron at a high temperature (about 450°C) and a high pressure (about 200 atmospheres). Some of the hydrogen and nitrogen reacts to form ammonia. The reaction is reversible so some of the ammonia produced breaks down into nitrogen and hydrogen: ",
							10.411: "On cooling, the ammonia liquefies and is removed. The remaining hydrogen and nitrogen are recycled.",
							10.411: "(HT only) Students should be able to:\n • interpret graphs of reaction conditions versus rate\n • apply the principles of dynamic equilibrium in Reversible reactions and dynamic equilibrium (page 59) to the Haber process\n • explain the trade-off between rate of production and position of equilibrium\n • explain how the commercially used conditions for the Haber process are related to the availability and cost of raw materials and energy supplies, control of equilibrium position and rate.",
							
                        },
                    },
                    10.42: {
                        "sub-topic": "Production and uses of NPK fertilisers",
                        spec: {
                            10.421: "Compounds of nitrogen, phosphorus and potassium are used as fertilisers to improve agricultural productivity. NPK fertilisers contain compounds of all three elements. ",
							10.422: "Industrial production of NPK fertilisers can be achieved using a variety of raw materials in several integrated processes. NPK fertilisers are formulations of various salts containing appropriate percentages of the elements.",
							10.423: "Ammonia can be used to manufacture ammonium salts and nitric acid. ",
							10.424: "Potassium chloride, potassium sulfate and phosphate rock are obtained by mining, but phosphate rock cannot be used directly as a fertiliser. ",
							10.425: "Phosphate rock is treated with nitric acid or sulfuric acid to produce soluble salts that can be used as fertilisers. ",
							10.426: "Students should be able to:\n • recall the names of the salts produced when phosphate rock is treated with nitric acid, sulfuric acid and phosphoric acid\n • compare the industrial production of fertilisers with laboratory preparations of the same compounds, given appropriate information.",
                        },
                    },
				},	
			},
		},
	},	
};
