/** @format */

export const GCSEPhysicsAQA = {
	1: {
		topic: "Energy",
		nested: true,
		topicQuestions: "",
		specLink: "https://filestore.aqa.org.uk/resources/physics/specifications/AQA-8463-SP-2016.PDF",
		code: "GCSEPhysicsAQA",
		name: "GCSE Physics AQA",
		subject: "Physics",
		board: "AQA",
		level: "GCSE",
		subs: {
			1.1: {
				"sub-topic": "Energy changes in a system, and the ways energy is stored before and after such changes",
				spec: {
					1.11: {
						"sub-topic": "Energy stores and systems",
						spec: {
							1.111: "A system is an object or group of objects.",
							1.112: "There are changes in the way energy is stored when a systemchanges.",
							1.113: "Students should be able to describe all the changes involved in theway energy is stored when a system changes, for commonsituations. For example:\n• an object projected upwards\n• a moving object hitting an obstacle\n• an object accelerated by a constant force\n• a vehicle slowing down\n• bringing water to a boil in an electric kettle.",
							1.114: "Throughout this section on Energy students should be able tocalculate the changes in energy involved when a system is changedby:\n• heating\n• work done by forces\n• work done when a current flows\n• use calculations to show on a common scale how the overallenergy in a system is redistributed when the system ischanged.",

						},
					},
					1.12: {
						"sub-topic": "Changes in energy",
						spec: {
							1.121: "Students should be able to calculate the amount of energyassociated with a moving object, a stretched spring and an objectraised above ground level.",
							1.122: "The kinetic energy of a moving object can be calculated using the equation:\n 4.1.1.2 kinetic energy, Ek, in joules, Jmass, m, in kilograms, kgspeed, v, in metres per second, m/s",
							1.123: "The amount of elastic potential energy stored in a stretched spring can be calculated using the equation: \n 4.1.1.2  (assuming the limit of proportionality has not been exceeded) elastic potential energy, Ee, in joules, J spring constant, k, in newtons per metre, N/m extension, e, in metres, m",
							1.124: "The amount of gravitational potential energy gained by an object raised above ground level can be calculated using the equation: \n  4.1.1.2   gravitational potential energy, Ep, in joules, J mass, m, in kilograms, kg gravitational field strength, g, in newtons per kilogram, N/kg (In any calculation the value of the gravitational field strength (g) will be given). height, h, in metres, m",
						},
					},
					1.13: {
						"sub-topic": "Energy changes in systems",
						spec: {
							1.131: "The amount of energy stored in or released from a system as its temperature changes can be calculated using the equation:",
							1.132: "change in thermal energy = mass × specific heat capacity × temperature change ∆ E = m c ∆ θ change in thermal energy, ∆E, in joules, J mass, m, in kilograms, kg specific heat capacity, c, in joules per kilogram per degree Celsius, J/kg °C temperature change, ∆θ, in degrees Celsius, °C ",
						},
					},
					1.14: {
						"sub-topic": "Power",
						spec: {
							1.141: "Power is defined as the rate at which energy is transferred or the rate at which work is done. formula 4.1.1.4  power, P, in watts, W\n energy transferred, E, in joules, J\n time, t, in seconds, s\n work done, W, in joules, J",
							1.142: "An energy transfer of 1 joule per second is equal to a power of 1 watt.",
							1.143: " Students should be able to give examples that illustrate the definition of power eg comparing two electric motors that both lift the same weight through the same height but one does it faster than the other.",
						},
					},
				},
			},
			1.2: {
				"sub-topic": "Conservation and dissipation of energy",
				spec: {
					1.21: {
						"sub-topic": "Energy transfers in a system",
						spec: {
							1.211: "Energy can be transferred usefully, stored or dissipated, but cannot be created or destroyed.",
							1.212: " Students should be able to describe with examples where there are energy transfers in a closed system, that there is no net change to the total energy. ",
							1.213: "Students should be able to describe, with examples, how in all system changes energy is dissipated, so that it is stored in less useful ways. This energy is often described as being ‘wasted’.",
							1.214: "Students should be able to explain ways of reducing unwanted energy transfers, for example through lubrication and the use of thermal insulation. ",
							1.215: "The higher the thermal conductivity of a material the higher the rate of energy transfer by conduction across the material. ",
							1.216: "Students should be able to describe how the rate of cooling of a building is affected by the thickness and thermal conductivity of its walls.",
							1.217: " Students do not need to know the definition of thermal conductivity.",
						},
					},
					1.22: {
						"sub-topic": "Efficiency",
						spec: {
							1.221: "The energy efficiency for any energy transfer can be calculated using the equation:\n  formula 4.1.2.2",
							1.222: "Efficiency may also be calculated using the equation: \n formula 4.1.2.2",
							1.223: "(HT only) Students should be able to describe ways to increase the efficiency of an intended energy transfer.",
						},
					},
				},
			},
			1.3: {
				"sub-topic": "National and global energy resources",
				spec: {
					1.31: {
						"sub-topic": "National and global energy resources",
						spec: {
							1.311: "The main energy resources available for use on Earth include: fossil fuels (coal, oil and gas), nuclear fuel, bio-fuel, wind, hydroelectricity, geothermal, the tides, the Sun and water waves.",
							1.312: " A renewable energy resource is one that is being (or can be) replenished as it is used. ",
							1.313: "The uses of energy resources include: transport, electricity generation and heating. ",
							1.314: "Students should be able to:\n • describe the main energy sources available\n • distinguish between energy resources that are renewable and energy resources that are non-renewable\n • compare ways that different energy resources are used, the uses to include transport, electricity generation and heating\n • understand why some energy resources are more reliable than others • describe the environmental impact arising from the use of different energy resources\n • explain patterns and trends in the use of energy resources. ",
							1.315: "Descriptions of how energy resources are used to generate electricity are not required. ",
							1.316: "Students should be able to:\n • consider the environmental issues that may arise from the use of different energy resources\n • show that science has the ability to identify environmental issues arising from the use of energy resources but not always the power to deal with the issues because of political, social, ethical or economic considerations.",
						}
					}
					


				},
			},
		},
	},
	2: {
		topic: "Electricity",
		topicQuestions: "",
		subs: {
			2.1: {
				"sub-topic": "Current, potential difference and resistance",
				spec: {
					2.11: {
						"sub-topic": "Standard circuit diagram symbols",
						spec: {
							2.111: "standard symbols 4.2.1.1",
						},
					},
					2.12: {
						"sub-topic": "Electrical charge and current",
						spec: {
							2.121: "For electrical charge to flow through a closed circuit the circuit must include a source of potential difference.",
							2.122: "Electric current is a flow of electrical charge. The size of the electric current is the rate of flow of electrical charge. Charge flow, current and time are linked by the equation: charge flow = current × time\n Q = I* t\n charge flow, Q, in coulombs, C\n current, I, in amperes, A (amp is acceptable for ampere)\n time, t, in seconds, s",
							2.123: "A current has the same value at any point in a single closed loop.",
						},
					},
					2.13: {
						"sub-topic": "Current, resistance and potential difference",
						spec: {
							2.131: "The current (I) through a component depends on both the resistance (R) of the component and the potential difference (V) across the component. The greater the resistance of the component the smaller the current for a given potential difference (pd) across the component. ",
							2.132: "Questions will be set using the term potential difference. Students will gain credit for the correct use of either potential difference or voltage. ",
							2.133: "Current, potential difference or resistance can be calculated using the equation:\n potential difference = current × resistance\n V = I x R\n potential difference, V, in volts, V\n current, I, in amperes, A (amp is acceptable for ampere)\n resistance, R, in ohms, Ω",
						},
					},
					2.14: {
						"sub-topic": "Resistors",
						spec: {
							2.141: "Students should be able to explain that, for some resistors, the value of R remains constant but that in others it can change as the current changes. ",
							2.142: "The current through an ohmic conductor (at a constant temperature) is directly proportional to the potential difference across the resistor. This means that the resistance remains constant as the current changes.",
							2.143: "The resistance of components such as lamps, diodes, thermistors and LDRs is not constant; it changes with the current through the component",
							2.144: "The resistance of a filament lamp increases as the temperature of the filament increases.",
							2.145: "The current through a diode flows in one direction only. The diode has a very high resistance in the reverse direction.",
							2.146: "The resistance of a thermistor decreases as the temperature increases.",
							2.147: " The applications of thermistors in circuits eg a thermostat is required. ",
							2.148: "The resistance of an LDR decreases as light intensity increases. ",
							2.149: "The application of LDRs in circuits eg switching lights on when it gets dark is required.",
							2.1410: " Students should be able to:\n • explain the design and use of a circuit to measure the resistance of a component by measuring the current through, and potential difference across, the component\n • draw an appropriate circuit diagram using correct circuit symbols. ",
							2.1411: "Students should be able to use graphs to explore whether circuit elements are linear or non-linear and relate the curves produced to their function and properties.",
						},
					},
				},
			},
			2.2: {
				"sub-topic": "Series and parallel circuits",
				spec: {
					2.21: {
						"sub-topic": "Series and parallel circuits",
						spec: {
							2.211: "There are two ways of joining electrical components, in series and in parallel. Some circuits include both series and parallel parts. ",
							2.212: "For components connected in series:\n • there is the same current through each component\n • the total potential difference of the power supply is shared between the components\n • the total resistance of two components is the sum of the resistance of each component. ",
							2.213: "Rtotal = R1 + R2 resistance, R, in ohms, Ω ",
							2.214: "For components connected in parallel:\n • the potential difference across each component is the same\n • the total current through the whole circuit is the sum of the currents through the separate components\n • the total resistance of two resistors is less than the resistance of the smallest individual resistor.",
							2.215: " Students should be able to:\n • use circuit diagrams to construct and check series and parallel circuits that include a variety of common circuit components\n • describe the difference between series and parallel circuits\n • explain qualitatively why adding resistors in series increases the total resistance whilst adding resistors in parallel decreases the total resistance\n • explain the design and use of dc series circuits for measurement and testing purposes\n • calculate the currents, potential differences and resistances in dc series circuits\n • solve problems for circuits which include resistors in series using the concept of equivalent resistance.",
							2.216: "Students are not required to calculate the total resistance of two resistors joined in parallel.",
						}
					}
					
				},
			},
			2.3: {
				"sub-topic": "Domestic uses and safety",
				spec: {
					2.31: {
						"sub-topic": "Direct and alternating potential difference",
						spec: {
							2.311: "Mains electricity is an ac supply. In the United Kingdom the domestic electricity supply has a frequency of 50 Hz and is about 230 V. ",
							2.312: "Students should be able to explain the difference between direct and alternating potential difference",

						},
					},
					2.32: {
						"sub-topic": "Mains electricity",
						spec: {
							2.321: "Most electrical appliances are connected to the mains using three-core cable",
							2.332: "The insulation covering each wire is colour coded for easy identification:\n live wire – brown\n neutral wire – blue\n earth wire – green and yellow stripes.",
							2.333: "The live wire carries the alternating potential difference from the supply. The neutral wire completes the circuit. The earth wire is a safety wire to stop the appliance becoming live.",
							2.334: " The potential difference between the live wire and earth (0 V) is about 230 V. The neutral wire is at, or close to, earth potential (0 V). The earth wire is at 0 V, it only carries a current if there is a fault. ",
							2.335: "Students should be able to explain:\n • that a live wire may be dangerous even when a switch in the mains circuit is open\n • the dangers of providing any connection between the live wire and earth.",
						},
					},
				},
			},
			2.4: {
				"sub-topic": "Energy transfers",
				spec: {
					2.41: {
						"sub-topic": "Power",
						spec: {
							2.411: "Students should be able to explain how the power transfer in any circuit device is related to the potential difference across it and the current through it, and to the energy changes over time: formula 4.2.4.1",
							2.412: "power, P, in watts, W\n potential difference, V, in volts, V\n current, I, in amperes, A (amp is acceptable for ampere)\n resistance, R, in ohms, Ω",

						},
					},
					2.42: {
						"sub-topic": "Energy transfers in everyday appliances",
						spec: {
							2.421: "Everyday electrical appliances are designed to bring about energy transfers.",
							2.422: " The amount of energy an appliance transfers depends on how long the appliance is switched on for and the power of the appliance.",
							2.423: " Students should be able to describe how different domestic appliances transfer energy from batteries or ac mains to the kinetic energy of electric motors or the energy of heating devices.",
							2.424: " Work is done when charge flows in a circuit.",
							2.425: " The amount of energy transferred by electrical work can be calculated using the equation:  4.2.4.2 energy transferred, E, in joules, J\n power, P, in watts, W\n time, t, in seconds, s\n charge flow, Q, in coulombs, C\n potential difference, V, in volts, V",
							2.426: "Students should be able to explain how the power of a circuit device is related to:\n • the potential difference across it and the current through it\n • the energy transferred over a given time.",
							2.427: " Students should be able to describe, with examples, the relationship between the power ratings for domestic electrical appliances and the changes in stored energy when they are in use.",

						},
					},
					2.43: {
						"sub-topic": "The National Grid",
						spec: {
							2.431: "The National Grid is a system of cables and transformers linking power stations to consumers.",
							2.432: "Electrical power is transferred from power stations to consumers using the National Grid.",
							2.433: " Step-up transformers are used to increase the potential difference from the power station to the transmission cables then step-down transformers are used to decrease, to a much lower value, the potential difference for domestic use. ",
							2.434: "Students should be able to explain why the National Grid system is an efficient way to transfer energy.",
						},
					},
				},
			},
			2.5: {
				"sub-topic": "Static electricity (physics only)",
				spec: {
					2.51: {
						"sub-topic": "Static charge",
						spec: {
							2.511: "When certain insulating materials are rubbed against each other they become electrically charged. Negatively charged electrons are rubbed off one material and on to the other. The material that gains electrons becomes negatively charged. The material that loses electrons is left with an equal positive charge. ",
							2.512: "When two electrically charged objects are brought close together they exert a force on each other. Two objects that carry the same type of charge repel. Two objects that carry different types of charge attract. Attraction and repulsion between two charged objects are examples of non-contact force. ",
							2.513: "Students should be able to:\n • describe the production of static electricity, and sparking, by rubbing surfaces\n • describe evidence that charged objects exert forces of attraction or repulsion on one another when not in contact\n • explain how the transfer of electrons between objects can explain the phenomena of static electricity.",
						},
					},
					2.52: {
						"sub-topic": "Electric fields",
						spec: {
							2.521: "A charged object creates an electric field around itself. The electric field is strongest close to the charged object. The further away from the charged object, the weaker the field.",
							2.522: "A second charged object placed in the field experiences a force. The force gets stronger as the distance between the objects decreases.",
							2.523: "Students should be able to:\n • draw the electric field pattern for an isolated charged sphere\n • explain the concept of an electric field\n • explain how the concept of an electric field helps to explain the non-contact force between charged objects as well as other electrostatic phenomena such as sparking.",
						},
					},
				},
			},
		},
	},
	3: {
		topic: "Particle model of matter",
		topicQuestions: "",
		subs: {
			3.1: {
				"sub-topic": "Changes of state and the particle model",
				spec: {
					3.11: {
						"sub-topic": "Density of materials",
						spec: {
							3.111: "The density of a material is defined by the equation:\n    formula 4.3.1.1  density, ρ, in kilograms per metre cubed, kg/m3\n mass, m, in kilograms, kg\n volume, V, in metres cubed, m3 ",
							3.112: "The particle model can be used to explain\n • the different states of matter\n • differences in density.",
							3.113: "Students should be able to recognise/draw simple diagrams to model the difference between solids, liquids and gases.",
							3.114: " Students should be able to explain the differences in density between the different states of matter in terms of the arrangement of atoms or molecules.",
						},
					},
					3.12: {
						"sub-topic": "Changes of state",
						spec: {
							3.121: "Students should be able to describe how, when substances change state (melt, freeze, boil, evaporate, condense or sublimate), mass is conserved. ",
							3.122: "Changes of state are physical changes which differ from chemical changes because the material recovers its original properties if the change is reversed.",
						},
					},
				},
			},
			3.2: {
				"sub-topic": "Internal energy and energy transfers",
				spec: {
					3.21: {
						"sub-topic": "Internal energy",
						spec: {
							3.211: "Energy is stored inside a system by the particles (atoms and molecules) that make up the system. This is called internal energy. ",
							3.212: "Internal energy is the total kinetic energy and potential energy of all the particles (atoms and molecules) that make up a system. ",
							3.213: "Heating changes the energy stored within the system by increasing the energy of the particles that make up the system. This either raises the temperature of the system or produces a change of state.",
						},
					},
					3.22: {
						"sub-topic": "Temperature changes in a system and specific heat capacity",
						spec: {
							3.221: "If the temperature of the system increases, the increase in temperature depends on the mass of the substance heated, the type of material and the energy input to the system. ",
							3.222: "The following equation applies: change in thermal energy = mass × specific heat capacity × temperature change ∆ E = m c ∆ θ\n change in thermal energy, ∆E, in joules, J\n mass, m, in kilograms, kg\n specific heat capacity, c, in joules per kilogram per degree Celsius, J/kg °C\n temperature change, ∆θ, in degrees Celsius, °C.",
							3.223: "The specific heat capacity of a substance is the amount of energy required to raise the temperature of one kilogram of the substance by one degree Celsius.",
						},
					},
					3.23: {
						"sub-topic": "Changes of state and specific latent heat",
						spec: {
							3.231: "If a change of state happens:\n The energy needed for a substance to change state is called latent heat. When a change of state occurs, the energy supplied changes the energy stored (internal energy) but not the temperature. ",
							3.232: "The specific latent heat of a substance is the amount of energy required to change the state of one kilogram of the substance with no change in temperature.",
							3.233: "energy f or a change o f state = mass × specific latent heat\n E = m L\n energy, E, in joules, J\n mass, m, in kilograms, kg\n specific latent heat, L, in joules per kilogram, J/kg\n Specific latent heat of fusion – change of state from solid to liquid\n Specific latent heat of vaporisation – change of state from liquid to vapour",
							3.234: "Students should be able to interpret heating and cooling graphs that include changes of state.",
							3.235: " Students should be able to distinguish between specific heat capacity and specific latent heat.",
						},
					},
				},
			},
			3.3: {
				"sub-topic": "Particle model and pressure",
				spec: {
					3.31: {
						"sub-topic": "Particle motion in gases",
						spec: {
							3.311: "The molecules of a gas are in constant random motion. The temperature of the gas is related to the average kinetic energy of the molecules.",
							3.312: "Changing the temperature of a gas, held at constant volume, changes the pressure exerted by the gas.",
							3.313: "  Students should be able to:\n • explain how the motion of the molecules in a gas is related to both its temperature and its pressure\n • explain qualitatively the relation between the temperature of a gas and its pressure at constant volume.",
						},
					},
					3.32: {
						"sub-topic": "Pressure in gases (physics only)",
						spec: {
							3.321: "A gas can be compressed or expanded by pressure changes. The pressure produces a net force at right angles to the wall of the gas container (or any surface).",
							3.322: " Students should be able to use the particle model to explain how increasing the volume in which a gas is contained, at constant temperature, can lead to a decrease in pressure.",
							3.323: " For a fixed mass of gas held at a constant temperature:\n pressure × volume = constant\n p V = constant\n pressure, p, in pascals, Pa\n volume, V, in metres cubed, m3 ",
							3.324: "Students should be able to calculate the change in the pressure of a gas or the volume of a gas (a fixed mass held at constant temperature) when either the pressure or volume is increased or decreased.",
						},
					},
					3.33: {
						"sub-topic": "Increasing the pressure of a gas (physics only) (HT only)",
						spec: {
							3.331: "Work is the transfer of energy by a force.",
							3.332: " Doing work on a gas increases the internal energy of the gas and can cause an increase in the temperature of the gas. ",
							3.333: "Students should be able to explain how, in a given situation eg a bicycle pump, doing work on an enclosed gas leads to an increase in the temperature of the gas.",

						},
					},
				},
			},
		},
	},
	4: {
		topic: "Atomic structure",
		topicQuestions: "",
		subs: {
			4.1: {
				"sub-topic": "Atoms and isotopes",
				spec: {
					4.11: {
						"sub-topic": "The structure of an atom",
						spec: {
							4.111: "Atoms are very small, having a radius of about 1 × 10-10 metres.",
							4.112: " The basic structure of an atom is a positively charged nucleus composed of both protons and neutrons surrounded by negatively charged electrons. ",
							4.113: "The radius of a nucleus is less than 1/10 000 of the radius of an atom. Most of the mass of an atom is concentrated in the nucleus. ",
							4.114: "The electrons are arranged at different distances from the nucleus (different energy levels). The electron arrangements may change with the absorption of electromagnetic radiation (move further from the nucleus; a higher energy level) or by the emission of electromagnetic radiation (move closer to the nucleus; a lower energy level).",
						},
					},
					4.12: {
						"sub-topic": "Mass number, atomic number and isotopes",
						spec: {
							4.121: "In an atom the number of electrons is equal to the number of protons in the nucleus. Atoms have no overall electrical charge.",
							4.122: " All atoms of a particular element have the same number of protons. The number of protons in an atom of an element is called its atomic number.  ",
							4.123: "The total number of protons and neutrons in an atom is called its mass number.",
							4.124: "Atoms can be represented as shown in this example:  4.4.1.2",
							4.125: "Atoms of the same element can have different numbers of neutrons; these atoms are called isotopes of that element. ",
							4.126: "Atoms turn into positive ions if they lose one or more outer electron(s).",
							4.126: " Students should be able to relate differences between isotopes to differences in conventional representations of their identities, charges and masses.",

						},
					},
					4.13: {
						"sub-topic": "The development of the model of the atom (common content with chemistry)",
						spec: {
							4.131: "New experimental evidence may lead to a scientific model being changed or replaced. ",
							4.132: "Before the discovery of the electron, atoms were thought to be tiny spheres that could not be divided.",
							4.133: "The discovery of the electron led to the plum pudding model of the atom. The plum pudding model suggested that the atom is a ball of positive charge with negative electrons embedded in it. ",
							4.134: " The results from the alpha particle scattering experiment led to the conclusion that the mass of an atom was concentrated at the centre (nucleus) and that the nucleus was charged. This nuclear model replaced the plum pudding model.",
							4.135: "Niels Bohr adapted the nuclear model by suggesting that electrons orbit the nucleus at specific distances. The theoretical calculations of Bohr agreed with experimental observations.",
							4.136: "Later experiments led to the idea that the positive charge of any nucleus could be subdivided into a whole number of smaller particles, each particle having the same amount of positive charge. The name proton was given to these particles. ",
							4.137: "The experimental work of James Chadwick provided the evidence to show the existence of neutrons within the nucleus. This was about 20 years after the nucleus became an accepted scientific idea.",
							4.138: "  Students should be able to describe:\n • why the new evidence from the scattering experiment led to a change in the atomic model\n • the difference between the plum pudding model of the atom and the nuclear model of the atom. ",
							4.139: " Details of experimental work supporting the Bohr model are not required. ",
							4.1310: "Details of Chadwick’s experimental work are not required.",
						},
					},
				},
			},
			4.2: {
				"sub-topic": "Atoms and nuclear radiation",
				spec: {
					4.21: {
						"sub-topic": "Radioactive decay and nuclear radiation",
						spec: {
							4.211: "Some atomic nuclei are unstable. The nucleus gives out radiation as it changes to become more stable. This is a random process called radioactive decay. ",
							4.212: "Activity is the rate at which a source of unstable nuclei decays.",
							4.213: " Activity is measured in becquerel (Bq) ",
							4.214: "Count-rate is the number of decays recorded each second by a detector (eg Geiger-Muller tube).",
							4.215: "The nuclear radiation emitted may be:\n • an alpha particle (α) – this consists of two neutrons and two protons, it is the same as a helium nucleus\n • a beta particle (β) – a high speed electron ejected from the nucleus as a neutron turns into a proton\n • a gamma ray (γ) – electromagnetic radiation from the nucleus\n • a neutron (n). ",
							4.216: "Required knowledge of the properties of alpha particles, beta particles and gamma rays is limited to their penetration through materials, their range in air and ionising power. ",
							4.217: "Students should be able to apply their knowledge to the uses of radiation and evaluate the best sources of radiation to use in a given situation.",
						},
					},
					4.22: {
						"sub-topic": "Nuclear equations",
						spec: {
							4.221: "Nuclear equations are used to represent radioactive decay. ",
							4.222: "In a nuclear equation an alpha particle may be represented by the symbol:  4.4.2.2",
							4.223: "and a beta particle by the symbol:  4.4.2.2",
							4.224: " The emission of the different types of nuclear radiation may cause a change in the mass and /or the charge of the nucleus. For example:  reaction  4.4.2.2",
							4.225: "So alpha decay causes both the mass and charge of the nucleus to decrease. reaction 4.4.2.2",
							4.226: " So beta decay does not cause the mass of the nucleus to change but does cause the charge of the nucleus to increase.",
							4.227: "Students are not required to recall these two examples. ",
							4.228: "Students should be able to use the names and symbols of common nuclei and particles to write balanced equations that show single alpha (α) and beta (β) decay. This is limited to balancing the atomic numbers and mass numbers. The identification of daughter elements from such decays is not required. ",
							4.229: " The emission of a gamma ray does not cause the mass or the charge of the nucleus to change.",
						},
					},
					4.23: {
						"sub-topic": "Half-lives and the random nature of radioactive decay",
						spec: {
							4.231: "Radioactive decay is random.",
							4.232: " The half-life of a radioactive isotope is the time it takes for the number of nuclei of the isotope in a sample to halve, or the time it takes for the count rate (or activity) from a sample containing the isotope to fall to half its initial level. ",
							4.233: "Students should be able to explain the concept of half-life and how it is related to the random nature of radioactive decay. ",
							4.234: "Students should be able to determine the half-life of a radioactive isotope from given information. ",
							4.235: "(HT only) Students should be able to calculate the net decline, expressed as a ratio, in a radioactive emission after a given number of half-lives.",

						},
					},
					4.24: {
						"sub-topic": "Radioactive contamination",
						spec: {
							4.241: "Radioactive contamination is the unwanted presence of materials containing radioactive atoms on other materials. The hazard from contamination is due to the decay of the contaminating atoms. The type of radiation emitted affects the level of hazard.",
							4.242: " Irradiation is the process of exposing an object to nuclear radiation. The irradiated object does not become radioactive. ",
							4.243: "Students should be able to compare the hazards associated with contamination and irradiation.",
							4.244: "Suitable precautions must be taken to protect against any hazard that the radioactive source used in the process of irradiation may present. ",
							4.245: " Students should understand that it is important for the findings of studies into the effects of radiation on humans to be published and shared with other scientists so that the findings can be checked by peer review.",
						},
					},
				},
			},
			4.3: {
				"sub-topic": "Hazards and uses of radioactive emissions and of background radiation (physics only)",
				spec: {
					4.31: {
						"sub-topic": "Background radiation",
						spec: {
							4.311: "Background radiation is around us all of the time. It comes from:\n • natural sources such as rocks and cosmic rays from space\n • man-made sources such as the fallout from nuclear weapons testing and nuclear accidents. ",
							4.312: "The level of background radiation and radiation dose may be affected by occupation and/or location.",
							4.313: "Radiation dose is measured in sieverts (Sv)\n 1000 millisieverts (mSv) = 1 sievert (Sv) ",
							4.314: "Students will not need to recall the unit of radiation dose.",
						},
					},
					4.32: {
						"sub-topic": "Different half-lives of radioactive isotopes",
						spec: {
							4.321: "Radioactive isotopes have a very wide range of half-life values.",
							4.322: " Students should be able to explain why the hazards associated with radioactive material differ according to the half-life involved.",

						},
					},
					4.33: {
						"sub-topic": "Uses of nuclear radiation",
						spec: {
							4.331: "Nuclear radiations are used in medicine for the:\n • exploration of internal organs\n • control or destruction of unwanted tissue.",
							4.332: " Students should be able to:\n • describe and evaluate the uses of nuclear radiations for exploration of internal organs, and for control or destruction of unwanted tissue\n • evaluate the perceived risks of using nuclear radiations in relation to given data and consequences.",
						},
					},
				},
			},
			4.4: {
				"sub-topic": "Nuclear fission and fusion (physics only)",
				spec: {
					4.41: {
						"sub-topic": "Nuclear fission",
						spec: {
							4.411: "Nuclear fission is the splitting of a large and unstable nucleus (eg uranium or plutonium). ",
							4.412: "Spontaneous fission is rare. Usually, for fission to occur the unstable nucleus must first absorb a neutron.",
							4.413: "The nucleus undergoing fission splits into two smaller nuclei, roughly equal in size, and emits two or three neutrons plus gamma rays. Energy is released by the fission reaction. ",
							4.414: "All of the fission products have kinetic energy.",
							4.415: "The neutrons may go on to start a chain reaction.",
							4.416: "The chain reaction is controlled in a nuclear reactor to control the energy released. The explosion caused by a nuclear weapon is caused by an uncontrolled chain reaction.",
							4.417: " Students should be able to draw/interpret diagrams representing nuclear fission and how a chain reaction may occur.",
						},
					},
					4.42: {
						"sub-topic": "Nuclear fusion",
						spec: {
							4.421: "Nuclear fusion is the joining of two light nuclei to form a heavier nucleus. In this process some of the mass may be converted into the energy of radiation.",
						},
					},
				},
			},
		},
	},
	5: {
		topic: "Forces",
		topicQuestions: "",
		subs: {
			5.1: {
				"sub-topic": "Forces and their interactions",
				spec: {
					5.11: {
						"sub-topic": "Scalar and vector quantities",
						spec: {
							5.111: "Scalar quantities have magnitude only. ",
							5.112: "Vector quantities have magnitude and an associated direction.",
							5.113: " A vector quantity may be represented by an arrow. The length of the arrow represents the magnitude, and the direction of the arrow the direction of the vector quantity.",
						},
					},
					5.12: {
						"sub-topic": "Contact and non-contact forces",
						spec: {
							5.121: "A force is a push or pull that acts on an object due to the interaction with another object. All forces between objects are either:\n • contact forces – the objects are physically touching\n • non-contact forces – the objects are physically separated. ",
							5.122: "Examples of contact forces include friction, air resistance, tension and normal contact force. ",
							5.123: "Examples of non-contact forces are gravitational force, electrostatic force and magnetic force.",
							5.124: " Force is a vector quantity.",
							5.125: "Students should be able to describe the interaction between pairs of objects which produce a force on each object. The forces to be represented as vectors.",
						},
					},
					5.13: {
						"sub-topic": "Gravity",
						spec: {
							5.131: "Weight is the force acting on an object due to gravity. The force of gravity close to the Earth is due to the gravitational field around the Earth. ",
							5.132: "The weight of an object depends on the gravitational field strength at the point where the object is. ",
							5.133: "The weight of an object can be calculated using the equation:\n weight = mass × gravitational field strength\n W = m g \n weight, W, in newtons, N\n mass, m, in kilograms, kg\n gravitational field strength, g, in newtons per kilogram, N/kg (In any calculation the value of the gravitational field strength (g) will be given.)",
							5.134: "The weight of an object may be considered to act at a single point referred to as the object’s ‘centre of mass’.",
							5.135: "  The weight of an object and the mass of an object are directly proportional.",
							5.136: " Weight is measured using a calibrated spring-balance (a newtonmeter).",
						},
					},
					5.14: {
						"sub-topic": "Resultant forces",
						spec: {
							5.141: "A number of forces acting on an object may be replaced by a single force that has the same effect as all the original forces acting together. This single force is called the resultant force.",
							5.142: "Students should be able to calculate the resultant of two forces that act in a straight line. ",
							5.143: "(HT only) Students should be able to:\n • describe examples of the forces acting on an isolated object or system\n • use free body diagrams to describe qualitatively examples where several forces lead to a resultant force on an object, including balanced forces when the resultant force is zero.",
							5.144: " (HT only) A single force can be resolved into two components acting at right angles to each other. The two component forces together have the same effect as the single force.",
							5.145: " (HT only) Students should be able to use vector diagrams to illustrate resolution of forces, equilibrium situations and determine the resultant of two forces, to include both magnitude and direction (scale drawings only).",
						},
					},
				},
			},
			5.2: {
				"sub-topic": "Work done and energy transfer",
				spec: {
					5.21: {
						"sub-topic": "Work done and energy transfer",
						spec: {
							5.211: "When a force causes an object to move through a distance work is done on the object. So a force does work on an object when the force causes a displacement of the object.",
							5.212: " The work done by a force on an object can be calculated using the equation:\n work done = force × distance \n moved along the line of action of the force\n W = F s \n work done, W, in joules, J\n force, F, in newtons, N\n distance, s, in metres",
							5.213: "One joule of work is done when a force of one newton causes a displacement of one metre.\n 1 joule = 1 newton-metre ",
							5.214: "Students should be able to describe the energy transfer involved when work is done. ",
							5.215: "Students should be able to convert between newton-metres and joules.",
							5.216: " Work done against the frictional forces acting on an object causes a rise in the temperature of the object.",
						}
					}
					
				},
			},
			5.3: {
				"sub-topic": "Forces and elasticity",
				spec: {
					5.31: {
						"sub-topic": "Forces and elasticity",
						spec: {
							5.311: "Students should be able to:\n • give examples of the forces involved in stretching, bending or compressing an object\n • explain why, to change the shape of an object (by stretching, bending or compressing), more than one force has to be applied – this is limited to stationary objects only\n • describe the difference between elastic deformation and inelastic deformation caused by stretching forces.",
							5.312: "The extension of an elastic object, such as a spring, is directly proportional to the force applied, provided that the limit of proportionality is not exceeded.",
							5.313: "force = spring constant × extension\n F = k e \n force, F, in newtons, N\n spring constant, k, in newtons per metre, N/m\n extension, e, in metres, m",
							5.314: "This relationship also applies to the compression of an elastic object, where ‘e’ would be the compression of the object.",
							5.315: " A force that stretches (or compresses) a spring does work and elastic potential energy is stored in the spring. Provided the spring is not inelastically deformed, the work done on the spring and the elastic potential energy stored are equal.",
							5.316: " Students should be able to:\n • describe the difference between a linear and non-linear relationship between force and extension\n • calculate a spring constant in linear cases\n • interpret data from an investigation of the relationship between force and extension\n • calculate work done in stretching (or compressing) a spring (up to the limit of proportionality) using the equation:\n 4.5.3",
							5.317: "Students should be able to calculate relevant values of stored energy and energy transfers.",
						}
					}
					
				},
			},
			5.4: {
				"sub-topic": "Moments, levers and gears (physics only)",
				spec: {
					5.41: {
						"sub-topic": "Moments, levers and gears (physics only)",
						spec: {
							5.411: "A force or a system of forces may cause an object to rotate. ",
							5.412: "Students should be able to describe examples in which forces cause rotation. ",
							5.413: "The turning effect of a force is called the moment of the force. The size of the moment is defined by the equation: \n moment o f a force = force × distance \n M = F d\n moment of a force, M, in newton-metres, Nm\n force, F, in newtons, N\n distance, d, is the perpendicular distance from the pivot to the line of action of the force, in metres, m",
							5.414: "If an object is balanced, the total clockwise moment about a pivot equals the total anticlockwise moment about that pivot. ",
							5.415: "Students should be able to calculate the size of a force, or its distance from a pivot, acting on an object that is balanced.",
							5.416: " A simple lever and a simple gear system can both be used to transmit the rotational effects of forces.",
							5.417: " Students should be able to explain how levers and gears transmit the rotational effects of forces.",
						}
					}
					
				},
			},
			5.5: {
				"sub-topic": " Pressure and pressure differences in fluids (physics only)",
				spec: {
					5.51: {
						"sub-topic": "Pressure in a fluid",
						spec: {
							5.511: {
								"sub-topic": "Pressure in a fluid 1",
								spec: {
									5.5111: "A fluid can be either a liquid or a gas.",
									5.5112: "The pressure in fluids causes a force normal (at right angles) to any surface.",
									5.5113: "The pressure at the surface of a fluid can be calculated using the equation:\n  4.5.5.1.1 pressure, p, in pascals, Pa\n force, F, in newtons, N\n area, A, in metres squared, m2",
								},
							},
							5.512: {
								"sub-topic": "Pressure in a fluid 2 (HT only)",
								spec: {
									5.5121: "The pressure due to a column of liquid can be calculated using the equation:\n pressure = height of the column × density of the liquid × gravitational field strength\n [ p = h ρ g ]\n pressure, p, in pascals, Pa\n height of the column, h, in metres, m\n density, ρ, in kilograms per metre cubed, kg/m3\n gravitational field strength, g, in newtons per kilogram, N/kg (In any calculation the value of the gravitational field strength (g) will be given.) ",
									5.5122: "Students should be able to explain why, in a liquid, pressure at a point increases with the height of the column of liquid above that point and with the density of the liquid.",
									5.5123: "Students should be able to calculate the differences in pressure at different depths in a liquid.",
									5.5124: "A partially (or totally) submerged object experiences a greater pressure on the bottom surface than on the top surface. This creates a resultant force upwards. This force is called the upthrust.",
									5.5125: "  Students should be able to describe the factors which influence floating and sinking.",
								},
							},
						},
					},
					5.52: {
						"sub-topic": "Atmospheric pressure",
						spec: {
							5.521: "The atmosphere is a thin layer (relative to the size of the Earth) of air round the Earth. The atmosphere gets less dense with increasing altitude.",
							5.522: " Air molecules colliding with a surface create atmospheric pressure. The number of air molecules (and so the weight of air) above a surface decreases as the height of the surface above ground level increases. So as height increases there is always less air above a surface than there is at a lower height. So atmospheric pressure decreases with an increase in height.",
							5.523: " Students should be able to:\n • describe a simple model of the Earth’s atmosphere and of atmospheric pressure\n • explain why atmospheric pressure varies with height above a surface",
						},
					},
				},
			},
			5.6: {
				"sub-topic": "Forces and motion",
				spec: {
					5.61: {
						"sub-topic": "Describing motion along a line",
						spec: {
							5.611: {
								"sub-topic": "Distance and displacement",
								spec: {
									5.6111: "Distance is how far an object moves. Distance does not involve direction. Distance is a scalar quantity.",
									5.6112: "Displacement includes both the distance an object moves, measured in a straight line from the start point to the finish point and the direction of that straight line. Displacement is a vector quantity.",
									5.6113: "  Students should be able to express a displacement in terms of both the magnitude and direction.",
								},
							},
							5.612: {
								"sub-topic": "Speed",
								spec: {
									5.6121: "Speed does not involve direction. Speed is a scalar quantity.",
									5.6122: " The speed of a moving object is rarely constant. When people walk, run or travel in a car their speed is constantly changing. ",
									5.6123: "The speed at which a person can walk, run or cycle depends on many factors including: age, terrain, fitness and distance travelled. ",
									5.6124: "Typical values may be taken as:\n walking ̴ 1.5 m/s\n running ̴ 3 m/s\n cycling ̴ 6 m/s. ",
									5.6125: "Students should be able to recall typical values of speed for a person walking, running and cycling as well as the typical values of speed for different types of transportation systems.",
									5.6126: " It is not only moving objects that have varying speed. The speed of sound and the speed of the wind also vary.",
									5.6127: "A typical value for the speed of sound in air is 330 m/s. ",
									5.6128: "Students should be able to make measurements of distance and time and then calculate speeds of objects.",
									5.6129: "For an object moving at constant speed the distance travelled in a specific time can be calculated using the equation: distance travelled = speed × time\n s = v t\n distance, s, in metres, m\n speed, v, in metres per second, m/s\n time, t, in seconds, s",
									5.61210: "Students should be able to calculate average speed for non-uniform motion.",

								},
							},
							5.613: {
								"sub-topic": "Velocity",
								spec: {
									5.6131: "The velocity of an object is its speed in a given direction. Velocity is a vector quantity.",
									5.6132: " Students should be able to explain the vector–scalar distinction as it applies to displacement, distance, velocity and speed. ",
									5.6133: "(HT only) Students should be able to explain qualitatively, with examples, that motion in a circle involves constant speed but changing velocity.",
								},
							},
							5.614: {
								"sub-topic": "The distance–time relationship",
								spec: {
									5.6141: "If an object moves along a straight line, the distance travelled can be represented by a distance–time graph.",
									5.6142: "The speed of an object can be calculated from the gradient of its distance–time graph. ",
									5.6143: "(HT only) If an object is accelerating, its speed at any particular time can be determined by drawing a tangent and measuring the gradient of the distance–time graph at that time.",
									5.6144: " Students should be able to draw distance–time graphs from measurements and extract and interpret lines and slopes of distance–time graphs, translating information between graphical and numerical form.",
									5.6145: " Students should be able to determine speed from a distance–time graph.",
								},
							},
							5.615: {
								"sub-topic": "Acceleration",
								spec: {
									5.6151: "The average acceleration of an object can be calculated using the equation:acceleration = change in velocity time taken\n acceleration, a, in metres per second squared, m/s2 change in velocity, ∆v, in metres per second, m/s time, t, in seconds, s",
									5.6152: " An object that slows down is decelerating.",
									5.6153: " Students should be able to estimate the magnitude of everyday accelerations.",
									5.6154: "The acceleration of an object can be calculated from the gradient of a velocity–time graph. ",
									5.6155: "(HT only) The distance travelled by an object (or displacement of an object) can be calculated from the area under a velocity–time graph.",
									5.6156: "Students should be able to:\n • draw velocity–time graphs from measurements and interpret lines and slopes to determine acceleration\n • (HT only) interpret enclosed areas in velocity–time graphs to determine distance travelled (or displacement)\n • (HT only) measure, when appropriate, the area under a velocity–time graph by counting squares",
									5.6157: "The following equation applies to uniform acceleration:\n 4.5.6.1.5 final velocity, v, in metres per second, m/s\n initial velocity, u, in metres per second, m/s\n acceleration, a, in metres per second squared, m/s2\n distance, s, in metres, m",
									5.6158: " Near the Earth’s surface any object falling freely under gravity has an acceleration of about 9.8 m/s2 .",
									5.6159: "An object falling through a fluid initially accelerates due to the force of gravity. Eventually the resultant force will be zero and the object will move at its terminal velocity. ",
									5.61510: "(Physics only) Students should be able to:\n • draw and interpret velocity–time graphs for objects that reach terminal velocity\n • interpret the changing motion in terms of the forces acting.",
								},
							},
						},
					},
					5.62: {
						"sub-topic": "Forces, accelerations and Newton's Laws of motion",
						spec: {
							5.621: {
								"sub-topic": "Newton's First Law",
								spec: {
									5.6211: "Newton’s First Law:\n If the resultant force acting on an object is zero and:\n • the object is stationary, the object remains stationary\n • the object is moving, the object continues to move at the same speed and in the same direction. So the object continues to move at the same velocity. ",
									5.6212: "So, when a vehicle travels at a steady speed the resistive forces balance the driving force. ",
									5.6213: "So, the velocity (speed and/or direction) of an object will only change if a resultant force is acting on the object. ",
									5.6214: "Students should be able to apply Newton’s First Law to explain the motion of objects moving with a uniform velocity and objects where the speed and/or direction changes. ",
									5.6215: "(HT only) The tendency of objects to continue in their state of rest or of uniform motion is called inertia.",
								},
							},
							5.622: {
								"sub-topic": "Newton's Second Law",
								spec: {
									5.6221: "Newton’s Second Law:\n The acceleration of an object is proportional to the resultant force acting on the object, and inversely proportional to the mass of the object. ",
									5.6222: "As an equation:\n resultant f orce = mass × acceleration\n F = m a\n force, F, in newtons, N\n mass, m, in kilograms, kg\n acceleration, a, in metres per second squared, m/s2",
									5.6223: " (HT only) Students should be able to explain that:\n • inertial mass is a measure of how difficult it is to change the velocity of an object\n • inertial mass is defined as the ratio of force over acceleration.",
									5.6224: " Students should be able to estimate the speed, accelerations and forces involved in large accelerations for everyday road transport. ",
									5.6225: "Students should recognise and be able to use the symbol that indicates an approximate value or approximate answer, ̴",
								},
							},
							5.623: {
								"sub-topic": "Newton's Third Law",
								spec: {
									5.6231: "Newton’s Third Law:\n Whenever two objects interact, the forces they exert on each other are equal and opposite. ",
									5.6232: "Students should be able to apply Newton’s Third Law to examples of equilibrium situations.",
								},
							},
						},
					},
					5.63: {
						"sub-topic": "Forces and braking",
						spec: {
							5.631: {
								"sub-topic": "Stopping distance",
								spec: {
									5.6311: "The stopping distance of a vehicle is the sum of the distance the vehicle travels during the driver’s reaction time (thinking distance) and the distance it travels under the braking force (braking distance). For a given braking force the greater the speed of the vehicle, the greater the stopping distance. ",
									5.6312: "(Physics only) Students should be able to estimate how the distance for a vehicle to make an emergency stop varies over a range of speeds typical for that vehicle. ",
									5.6313: "(Physics only) Students will be required to interpret graphs relating speed to stopping distance for a range of vehicles.",
								},
							},
							5.632: {
								"sub-topic": "Reaction time",
								spec: {
									5.6321: "Reaction times vary from person to person. Typical values range from 0.2 s to 0.9 s. ",
									5.6322: "A driver’s reaction time can be affected by tiredness, drugs and alcohol. Distractions may also affect a driver’s ability to react.",
									5.6323: " Students should be able to:\n • explain methods used to measure human reaction times and recall typical results\n • interpret and evaluate measurements from simple methods to measure the different reaction times of students\n • evaluate the effect of various factors on thinking distance based on given data.",
								},
							},
							5.633: {
								"sub-topic": "Factors affecting braking distance 1",
								spec: {
									5.6331: "The braking distance of a vehicle can be affected by adverse road and weather conditions and poor condition of the vehicle. ",
									5.6332: "Adverse road conditions include wet or icy conditions. Poor condition of the vehicle is limited to the vehicle's brakes or tyres. ",
									5.6333: "Students should be able to:\n • explain the factors which affect the distance required for road transport vehicles to come to rest in emergencies, and the implications for safety\n • estimate how the distance required for road vehicles to stop in an emergency varies over a range of typical speeds.",
								},
							},
							5.634: {
								"sub-topic": "Factors affecting braking distance 2",
								spec: {
									5.6341: "When a force is applied to the brakes of a vehicle, work done by the friction force between the brakes and the wheel reduces the kinetic energy of the vehicle and the temperature of the brakes increases.",
									5.6342: " The greater the speed of a vehicle the greater the braking force needed to stop the vehicle in a certain distance.",
									5.6343: " The greater the braking force the greater the deceleration of the vehicle. Large decelerations may lead to brakes overheating and/or loss of control. ",
									5.6344: "Students should be able to:\n • explain the dangers caused by large decelerations \n • (HT only) estimate the forces involved in the deceleration of road vehicles in typical situations on a public road.",
								},
							},
						},
					},
				},
			},
			5.7: {
				"sub-topic": "Momentum (HT only)",
				spec: {
					5.71: {
						"sub-topic": "Momentum is a property of moving objects",
						spec: {
							5.711: "Momentum is defined by the equation:\n momentum = mass × velocity\n p = m v\n momentum, p, in kilograms metre per second, kg m/s\n mass, m, in kilograms, kg\n velocity, v, in metres per second, m/s",
						},
					},
					5.72: {
						"sub-topic": "Conservation of momentum",
						spec: {
							5.721: "In a closed system, the total momentum before an event is equal to the total momentum after the event. ",
							5.722: "This is called conservation of momentum.",
							5.723: " Students should be able to use the concept of momentum as a model to:\n • describe and explain examples of momentum in an event, such as a collision\n • (physics only) complete calculations involving an event, such as the collision of two objects.",
						},
					},
					5.73: {
						"sub-topic": "Changes in momentum (physics only)",
						spec: {
							5.731: "When a force acts on an object that is moving, or able to move, a change in momentum occurs.",
							5.732: " The equations \n 4.5.7.3 where m∆v = change in momentum\n ie force equals the rate of change of momentum.",
							5.733: "Students should be able to explain safety features such as: air bags, seat belts, gymnasium crash mats, cycle helmets and cushioned surfaces for playgrounds with reference to the concept of rate of change of momentum. ",
							5.734: "Students should be able to apply equations relating force, mass, velocity and acceleration to explain how the changes involved are inter-related.",
						},
					},
				},
			},
		},
	},
	6: {
		topic: "Waves",
		topicQuestions: "",
		subs: {
			6.1: {
				"sub-topic": " Waves in air, fluids and solids",
				spec: {
					6.11: {
						"sub-topic": "Transverse and longitudinal waves",
						spec: {
							6.111: "Waves may be either transverse or longitudinal.",
							6.112: " The ripples on a water surface are an example of a transverse wave. ",
							6.113: "Longitudinal waves show areas of compression and rarefaction. Sound waves travelling through air are longitudinal.",
							6.114: "Students should be able to describe the difference between longitudinal and transverse waves. ",
							6.115: "Students should be able to describe evidence that, for both ripples on a water surface and sound waves in air, it is the wave and not the water or air itself that travels.",
						},
					},
					6.12: {
						"sub-topic": "Properties of waves",
						spec: {
							6.121: "Students should be able to describe wave motion in terms of their amplitude, wavelength, frequency and period.",
							6.122: " The amplitude of a wave is the maximum displacement of a point on a wave away from its undisturbed position.",
							6.123: " The wavelength of a wave is the distance from a point on one wave to the equivalent point on the adjacent wave. ",
							6.124: "The frequency of a wave is the number of waves passing a point each second.",
							6.125: " formula 4.6.1.2 period, T, in seconds, s\n frequency, f, in hertz, Hz",
							6.126: "The wave speed is the speed at which the energy is transferred (or the wave moves) through the medium. ",
							6.127: "All waves obey the wave equation: wave s peed = f requency × wavelength\n v = f λ\n wave speed, v, in metres per second, m/s\n frequency, f, in hertz, Hz\n wavelength, λ, in metres, m ",
							6.128: "Students should be able to:\n • identify amplitude and wavelength from given diagrams\n • describe a method to measure the speed of sound waves in air\n • describe a method to measure the speed of ripples on a water surface. ",
							6.129: "(Physics only) Students should be able to show how changes in velocity, frequency and wavelength, in transmission of sound waves from one medium to another, are inter-related.",

						},
					},
					6.13: {
						"sub-topic": "Reflection of waves (physics only)",
						spec: {
							6.131: "Waves can be reflected at the boundary between two different materials.",
							6.132: " Waves can be absorbed or transmitted at the boundary between two different materials. ",
							6.133: "Students should be able to construct ray diagrams to illustrate the reflection of a wave at a surface. ",
							6.134: "Students should be able to describe the effects of reflection, transmission and absorption of waves at material interfaces.",
						},
					},
					6.14: {
						"sub-topic": "Sound waves (physics only) (HT only)",
						spec: {
							6.141: "Sound waves can travel through solids causing vibrations in the solid.",
							6.142: " Within the ear, sound waves cause the ear drum and other parts to vibrate which causes the sensation of sound. The conversion of sound waves to vibrations of solids works over a limited frequency range. This restricts the limits of human hearing. ",
							6.143: "Students should be able to:\n • describe, with examples, processes which convert wave disturbances between sound waves and vibrations in solids. Examples may include the effect of sound waves on the ear drum\n • explain why such processes only work over a limited frequency range and the relevance of this to human hearing. ",
							6.144: "Students should know that the range of normal human hearing is from 20 Hz to 20 kHz.",

						},
					},
					6.15: {
						"sub-topic": "Waves for detection and exploration (physics only) (HT only)",
						spec: {
							6.151: "Students should be able to explain in qualitative terms, how the differences in velocity, absorption and reflection between different types of wave in solids and liquids can be used both for detection and exploration of structures which are hidden from direct observation. ",
							6.152: "Ultrasound waves have a frequency higher than the upper limit of hearing for humans. Ultrasound waves are partially reflected when they meet a boundary between two different media. The time taken for the reflections to reach a detector can be used to determine how far away such a boundary is. This allows ultrasound waves to be used for both medical and industrial imaging. ",
							6.153: "Seismic waves are produced by earthquakes. P-waves are longitudinal, seismic waves. P-waves travel at different speeds through solids and liquids. S-waves are transverse, seismic waves. S-waves cannot travel through a liquid. P-waves and S-waves provide evidence for the structure and size of the Earth’s core.",
							6.154: "Echo sounding, using high frequency sound waves is used to detect objects in deep water and measure water depth. ",
							6.155: " Students should be aware that the study of seismic waves provided new evidence that led to discoveries about parts of the Earth which are not directly observable.",
						},
					},
				},
			},
			6.2: {
				"sub-topic": "Electromagnetic waves",
				spec: {
					6.21: {
						"sub-topic": "Types of electromagnetic waves",
						spec: {
							6.211: "Electromagnetic waves are transverse waves that transfer energy from the source of the waves to an absorber.",
							6.212: "Electromagnetic waves form a continuous spectrum and all types of electromagnetic wave travel at the same velocity through a vacuum (space) or air. ",
							6.213: " The waves that form the electromagnetic spectrum are grouped in terms of their wavelength and their frequency. Going from long to short wavelength (or from low to high frequency) the groups are: radio, microwave, infrared, visible light (red to violet), ultraviolet, X-rays and gamma rays. ",
							6.214: "diagram 4.6.2.1",
							6.215: "Our eyes only detect visible light and so detect a limited range of electromagnetic waves. ",
							6.216: "Students should be able to give examples that illustrate the transfer of energy by electromagnetic waves.",
						},
					},
					6.22: {
						"sub-topic": "Properties of electromagnetic waves 1",
						spec: {
							6.221: "(HT only) Different substances may absorb, transmit, refract or reflect electromagnetic waves in ways that vary with wavelength.",
							6.222: " (HT only) Some effects, for example refraction, are due to the difference in velocity of the waves in different substances. ",
							6.223: "Students should be able to construct ray diagrams to illustrate the refraction of a wave at the boundary between two different media.",
							6.224: " (HT only) Students should be able to use wave front diagrams to explain refraction in terms of the change of speed that happens when a wave travels from one medium to a different medium.",
						},
					},
					6.23: {
						"sub-topic": "Properties of electromagnetic waves 2",
						spec: {
							6.231: "(HT only) Radio waves can be produced by oscillations in electrical circuits. ",
							6.232: "(HT only) When radio waves are absorbed they may create an alternating current with the same frequency as the radio wave itself, so radio waves can themselves induce oscillations in an electrical circuit.",
							6.233: " Changes in atoms and the nuclei of atoms can result in electromagnetic waves being generated or absorbed over a wide frequency range. Gamma rays originate from changes in the nucleus of an atom. ",
							6.234: "Ultraviolet waves, X-rays and gamma rays can have hazardous effects on human body tissue. The effects depend on the type of radiation and the size of the dose. Radiation dose (in sieverts) is a measure of the risk of harm resulting from an exposure of the body to the radiation. ",
							6.235: "1000 millisieverts (mSv) = 1 sievert (Sv)",
							6.236: " Students will not be required to recall the unit of radiation dose. ",
							6.237: "Students should be able to draw conclusions from given data about the risks and consequences of exposure to radiation.",
							6.238: " Ultraviolet waves can cause skin to age prematurely and increase the risk of skin cancer. X-rays and gamma rays are ionising radiation that can cause the mutation of genes and cancer.",

						},
					},
					6.24: {
						"sub-topic": "Uses and applications of electromagnetic waves",
						spec: {
							6.241: "Electromagnetic waves have many practical applications. For example:\n • radio waves – television and radio\n • microwaves – satellite communications, cooking food\n • infrared – electrical heaters, cooking food, infrared cameras\n • visible light – fibre optic communications\n • ultraviolet – energy efficient lamps, sun tanning\n • X-rays and gamma rays – medical imaging and treatments.",
							6.242: "(HT only) Students should be able to give brief explanations why each type of electromagnetic wave is suitable for the practical application.",
						},
					},
					6.25: {
						"sub-topic": "Lenses (physics only)",
						spec: {
							6.251: "A lens forms an image by refracting light. In a convex lens, parallel rays of light are brought to a focus at the principal focus. The distance from the lens to the principal focus is called the focal length. Ray diagrams are used to show the formation of images by convex and concave lenses.",
							6.252: " The image produced by a convex lens can be either real or virtual. The image produced by a concave lens is always virtual. ",
							6.253: "Students should be able to construct ray diagrams to illustrate the similarities and differences between convex and concave lenses. ",
							6.254: "The magnification produced by a lens can be calculated using the equation: \n formula 4.6.2.5",
							6.255: "Magnification is a ratio and so has no units. ",
							6.256: "Image height and object height should both be measured in either mm or cm.",
							6.257: " In ray diagrams a convex lens will be represented by: 4.6.2.5",
							6.258: " A concave lens will be represented by: 4.6.2.5",
						},
					},
					6.26: {
						"sub-topic": "Visible light (physics only)",
						spec: {
							6.261: "Each colour within the visible light spectrum has its own narrow band of wavelength and frequency. ",
							6.262: "Reflection from a smooth surface in a single direction is called specular reflection. Reflection from a rough surface causes scattering: this is called diffuse reflection.",
							6.263: " Colour filters work by absorbing certain wavelengths (and colour) and transmitting other wavelengths (and colour).",
							6.264: " The colour of an opaque object is determined by which wavelengths of light are more strongly reflected. Wavelengths that are not reflected are absorbed. If all wavelengths are reflected equally the object appears white. If all wavelengths are absorbed the objects appears black. ",
							6.265: "Objects that transmit light are either transparent or translucent.",
							6.266: " Students should be able to explain:\n • how the colour of an object is related to the differential absorption, transmission and reflection of different wavelengths of light by the object\n • the effect of viewing objects through filters or the effect on light of passing through filters\n • why an opaque object has a particular colour.",
						},
					},
				},
			},
			6.3: {
				"sub-topic": "Black body radiation (physics only)",
				spec: {
					6.31: {
						"sub-topic": "Emission and absorption of infrared radiation",
						spec: {
							6.311: "All bodies (objects), no matter what temperature, emit and absorb infrared radiation. The hotter the body, the more infrared radiation it radiates in a given time. ",
							6.312: "A perfect black body is an object that absorbs all of the radiation incident on it. A black body does not reflect or transmit any radiation. Since a good absorber is also a good emitter, a perfect black body would be the best possible emitter.",
						},
					},
					6.32: {
						"sub-topic": "Perfect black bodies and radiation",
						spec: {
							6.321: "Students should be able to explain:\n • that all bodies (objects) emit radiation\n • that the intensity and wavelength distribution of any emission depends on the temperature of the body. ",
							6.322: "(HT only) A body at constant temperature is absorbing radiation at the same rate as it is emitting radiation. The temperature of a body increases when the body absorbs radiation faster than it emits radiation. ",
							6.323: "(HT only) The temperature of the Earth depends on many factors including: the rates of absorption and emission of radiation, reflection of radiation into space. ",
							6.324: "(HT only) Students should be able to explain how the temperature of a body is related to the balance between incoming radiation absorbed and radiation emitted, using everyday examples to illustrate this balance, and the example of the factors which determine the temperature of the Earth. ",
							6.325: "(HT only) Students should be able to use information, or draw/ interpret diagrams to show how radiation affects the temperature of the Earth’s surface and atmosphere.",
						},
					},
				},
			},
		},
	},
	7: {
		topic: "Magnetism and electromagnetism",
		topicQuestions: "",
		subs: {
			7.1: {
				"sub-topic": "Permanent and induced magnetism, magnetic forces and fields",
				spec: {
					7.11: {
						"sub-topic": "Poles of a magnet",
						spec: {
							7.111: "The poles of a magnet are the places where the magnetic forces are strongest. When two magnets are brought close together they exert a force on each other. Two like poles repel each other. Two unlike poles attract each other. Attraction and repulsion between two magnetic poles are examples of non-contact force. ",
							7.112: "A permanent magnet produces its own magnetic field. An induced magnet is a material that becomes a magnet when it is placed in a magnetic field. Induced magnetism always causes a force of attraction. When removed from the magnetic field an induced magnet loses most/all of its magnetism quickly. ",
							7.113: "Students should be able to describe:\n • the attraction and repulsion between unlike and like poles for permanent magnets\n • the difference between permanent and induced magnets.",
						},
					},
					7.12: {
						"sub-topic": "Magnetic fields",
						spec: {
							7.121: "The region around a magnet where a force acts on another magnet or on a magnetic material (iron, steel, cobalt and nickel) is called the magnetic field. ",
							7.122: "The force between a magnet and a magnetic material is always one of attraction. ",
							7.123: "The strength of the magnetic field depends on the distance from the magnet. The field is strongest at the poles of the magnet. ",
							7.124: "The direction of the magnetic field at any point is given by the direction of the force that would act on another north pole placed at that point. The direction of a magnetic field line is from the north (seeking) pole of a magnet to the south(seeking) pole of the magnet.",
							7.125: " A magnetic compass contains a small bar magnet. The Earth has a magnetic field. ",
							7.126: "The compass needle points in the direction of the Earth’s magnetic field. Students should be able to:\n • describe how to plot the magnetic field pattern of a magnet using a compass\n • draw the magnetic field pattern of a bar magnet showing how strength and direction change from one point to another\n • explain how the behaviour of a magnetic compass is related to evidence that the core of the Earth must be magnetic.",
						},
					},

				},
			},
			7.2: {
				"sub-topic": "The motor effect",
				spec: {
					7.21: {
						"sub-topic": "Electromagnetism",
						spec: {
							7.211: "When a current flows through a conducting wire a magnetic field is produced around the wire. The strength of the magnetic field depends on the current through the wire and the distance from the wire.",
							7.212: " Shaping a wire to form a solenoid increases the strength of the magnetic field created by a current through the wire. The magnetic field inside a solenoid is strong and uniform.",
							7.213: " The magnetic field around a solenoid has a similar shape to that of a bar magnet. Adding an iron core increases the strength of the magnetic field of a solenoid. An electromagnet is a solenoid with an iron core. ",
							7.214: "Students should be able to:\n • describe how the magnetic effect of a current can be demonstrated\n • draw the magnetic field pattern for a straight wire carrying a current and for a solenoid (showing the direction of the field)\n • explain how a solenoid arrangement can increase the magnetic effect of the current. ",
							7.215: "(Physics only) Students should be able to interpret diagrams of electromagnetic devices in order to explain how they work.",
						},
					},
					7.22: {
						"sub-topic": "Fleming's left-hand rule (HT only)",
						spec: {
							7.221: "When a conductor carrying a current is placed in a magnetic field the magnet producing the field and the conductor exert a force on each other. This is called the motor effect.",
							7.222: " Students should be able to show that Fleming's left-hand rule represents the relative orientation of the force, the current in the conductor and the magnetic field.",
							7.223: " Students should be able to recall the factors that affect the size of the force on the conductor. ",
							7.224: "For a conductor at right angles to a magnetic field and carrying a current:\n f orce = magnetic f lux density × current × length\n F = B I l\n force, F, in newtons, N\n magnetic flux density, B, in tesla, T\n current, I, in amperes, A (amp is acceptable for ampere)\n length, l, in metres, m",
						},
					},
					7.23: {
						"sub-topic": "Electric motors (HT only)",
						spec: {
							7.231: "A coil of wire carrying a current in a magnetic field tends to rotate. This is the basis of an electric motor. ",
							7.232: "Students should be able to explain how the force on a conductor in a magnetic field causes the rotation of the coil in an electric motor.",
						},
					},
					7.24: {
						"sub-topic": "Loudspeakers (physics only) (HT only)",
						spec: {
							7.241: "Loudspeakers and headphones use the motor effect to convert variations in current in electrical circuits to the pressure variations in sound waves. ",
							7.242: "Students should be able to explain how a moving-coil loudspeaker and headphones work.",
						},
					},
				},
			},
			7.3: {
				"sub-topic": "Induced potential, transformers and the National Grid (physics only) (HT only)",
				spec: {
					7.31: {
						"sub-topic": "Induced potential (HT only)",
						spec: {
							7.311: "If an electrical conductor moves relative to a magnetic field or if there is a change in the magnetic field around a conductor, a potential difference is induced across the ends of the conductor. If the conductor is part of a complete circuit, a current is induced in the conductor. This is called the generator effect.",
							7.312: " An induced current generates a magnetic field that opposes the original change, either the movement of the conductor or the change in magnetic field. ",
							7.313: "Students should be able to recall the factors that affect the size of the induced potential difference/induced current. ",
							7.314: "Students should be able to recall the factors that affect the direction of the induced potential difference/induced current. ",
							7.315: "Students should be able to apply the principles of the generator effect in a given context",
						},
					},
					7.32: {
						"sub-topic": "Uses of the generator effect (HT only)",
						spec: {
							7.321: "The generator effect is used in an alternator to generate ac and in a dynamo to generate dc. ",
							7.322: "Students should be able to:\n • explain how the generator effect is used in an alternator to generate ac and in a dynamo to generate dc\n • draw/interpret graphs of potential difference generated in the coil against time.",
						},
					},
					7.33: {
						"sub-topic": "Microphones (HT only)",
						spec: {
							7.331: "Microphones use the generator effect to convert the pressure variations in sound waves into variations in current in electrical circuits. ",
							7.332: "Students should be able to explain how a moving-coil microphone works.",
						},
					},
					7.34: {
						"sub-topic": "Transformers (HT only)",
						spec: {
							7.341: "A basic transformer consists of a primary coil and a secondary coil wound on an iron core.",
							7.342: "Iron is used as it is easily magnetised.",
							7.343: "  Knowledge of laminations and eddy currents in the core is not required.",
							7.344: " The ratio of the potential differences across the primary and secondary coils of a transformer Vp and Vs depends on the ratio of the number of turns on each coil, np and ns .",
							7.345: "potential difference, Vp and Vs in volts, V",
							7.346: "In a step-up transformer Vs > Vp",
							7.347: "In a step-down transformer Vs < V",
							7.348: "If transformers were 100% efficient, the electrical power output would equal the electrical power input.",
							7.349: "Vs × Is = V p × I p Where Vs × Is is the power output (secondary coil) and Vp × Ip is the power input (primary coil). power input and output, in watts, W",
							7.3410: "Students should be able to:\n • explain how the effect of an alternating current in one coil in inducing a current in another is used in transformers\n • explain how the ratio of the potential differences across the two coils depends on the ratio of the number of turns on each\n • calculate the current drawn from the input supply to provide a particular power output\n • apply the equation linking the p.d.s and number of turns in the two coils of a transformer to the currents and the power transfer involved, and relate these to the advantages of power transmission at high potential differences.",

						},
					},
				},
			},
		},
	},
	8: {
		topic: "Space physics (physics only)",
		topicQuestions: "",
		subs: {
			8.1: {
				"sub-topic": "Solar system; stability of orbital motions; satellites (physics only)",
				spec: {
					8.11: {
						"sub-topic": "Our solar system",
						spec: {
							8.111: "Within our solar system there is one star, the Sun, plus the eight planets and the dwarf planets that orbit around the Sun. Natural satellites, the moons that orbit planets, are also part of the solar system.",
							8.112: "Our solar system is a small part of the Milky Way galaxy. ",
							8.113: " The Sun was formed from a cloud of dust and gas (nebula) pulled together by gravitational attraction. ",
							8.114: "Students should be able to explain:\n • how, at the start of a star's life cycle, the dust and gas drawn together by gravity causes fusion reactions\n • that fusion reactions lead to an equilibrium between the gravitational collapse of a star and the expansion of a star due to fusion energy.",
						},
					},
					8.12: {
						"sub-topic": "The life cycle of a star",
						spec: {
							8.121: "A star goes through a life cycle. The life cycle is determined by the size of the star. ",
							8.122: "Students should be able to describe the life cycle of a star:\n • the size of the Sun\n • much more massive than the Sun. ",
							8.123: "diagram",
							8.124: "Fusion processes in stars produce all of the naturally occurring elements. Elements heavier than iron are produced in a supernova.",
							8.125: " The explosion of a massive star (supernova) distributes the elements throughout the universe. ",
							8.126: "Students should be able to explain how fusion processes lead to the formation of new elements.",

						},
					},
					8.13: {
						"sub-topic": "Orbital motion, natural and artificial satellites",
						spec: {
							8.131: "Gravity provides the force that allows planets and satellites (both natural and artificial) to maintain their circular orbits. ",
							8.132: "Students should be able to describe the similarities and distinctions between the planets, their moons, and artificial satellites. ",
							8.133: "(HT only) Students should be able to explain qualitatively how:\n • (HT only) for circular orbits, the force of gravity can lead to changing velocity but unchanged speed\n • (HT only) for a stable orbit, the radius must change if the speed changes.",
						},
					},

				},
			},
			8.2: {
				"sub-topic": "Red-shift (physics only)",
				spec: {
					8.21: {
						"sub-topic": "Red-shift (physics only)",
						spec: {
							8.211: "There is an observed increase in the wavelength of light from most distant galaxies. The further away the galaxies, the faster they are moving and the bigger the observed increase in wavelength. This effect is called red-shift. ",
							8.212: "The observed red-shift provides evidence that space itself (the universe) is expanding and supports the Big Bang theory. ",
							8.213: "The Big Bang theory suggests that the universe began from a very small region that was extremely hot and dense.",
							8.214: "Since 1998 onwards, observations of supernovae suggest that distant galaxies are receding ever faster. ",
							8.215: " Students should be able to explain:\n • qualitatively the red-shift of light from galaxies that are receding\n • that the change of each galaxy’s speed with distance is evidence of an expanding universe\n • how red-shift provides evidence for the Big Bang model\n • how scientists are able to use observations to arrive at theories such as the Big Bang theory\n • that there is still much about the universe that is not understood, for example dark mass and dark energy.",
						}
					}
					

				},
			},
		},
	},
};
