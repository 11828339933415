/** @format */

export const GCSEMathsAQA = {
	1: {
		topic: "Number",
		topicQuestions: "https://www.physicsandmathstutor.com/maths-revision/gcse-number/questions-aqa/",
		specLink: "https://www.aqa.org.uk/subjects/mathematics/gcse/mathematics-8300/specification-at-a-glance",
		code: "GCSEMathsAQA",
		name: "GCSE Maths AQA",
		subject: "Maths",
		board: "AQA",
		level: "GCSE",
		subs: {
			1.1: {
				"sub-topic": "Structure and Calculations",
				spec: {
					1.11: "Order positive and negative integers, decimals and fractions",
					1.12: "Apply the four operations, including formal written methods, to integers, decimals and simple fractions (proper and improper), and mixed numbers all both positive and negative",
					1.13: "Recognise and use relationships between operations, including inverse operations (eg cancellation to simplify calculations and expressions)",
					1.14: "Use the concepts and vocabulary of prime numbers, factors (divisors), multiples, common factors, common multiples, highest common factor, lowest common multiple, prime factorisation, including using product notation and the unique factorisation theorem",
					1.15: "Apply systematic listing strategies",
					1.16: "Use positive integer powers and associated real roots (square, cube and higher), recognise powers of 2, 3, 4, 5",
					1.17: "Calculate with fractional indices",
					1.18: "Calculate exactly with surds simplify surd expressions involving squares (eg 12 = 4×3= 4 × 3=2 3 ) and rationalise denominators",
					1.19: "Calculate with and interpret standard form A × 10n , where 1 ≤ A < 10 and n is an integer",
				},
			},
			1.2: {
				"sub-topic": "Fractions, decimals and percentages",
				spec: {
					1.21: "Work interchangeably with terminating decimals and their corresponding fractions (such as 3.5 and 7/2 or 0.375 and 3/8 )",
					1.22: "Identify and work with fractions in ratio problems",
					1.23: "Interpret fractions and percentages as operators",
				},
			},
			1.3: {
				"sub-topic": "Measures and accuracy",
				spec: {
					1.31: "Use standard units of mass, length, time, money and other measures (including standard compound measures) using decimal quantities where appropriate",
					1.32: "Estimate answers check calculations using approximation and estimation, including answers obtained using technology",
					1.33: "Round numbers and measures to an appropriate degree of accuracy (eg to a specified number of decimal places or significant figures)",
				},
			},
		},
	},
	2: {
		topic: "Algebra",
		topicQuestions: "https://www.physicsandmathstutor.com/maths-revision/gcse-algebra/questions-aqa/",
		subs: {
			2.1: {
				"sub-topic": "Notation, vocabulary and manipulation",
				spec: {
					2.11: "• Use and interpret algebraic notation, including: \n • ab in place of a × b \n • 3y in place of y + y + y and 3 × y \n • a2 in place of a × a, a3 in place of a × a × a, a2 b in placeof a × a × b \n • ab in place of a ÷ b \n • coefficients written as fractions rather than as decimals \n• brackets",
					2.12: "Substitute numerical values into formulae and expressions, including scientific formulae",
					2.13: "Understand and use the concepts and vocabulary of expressions, equations, formulae, inequalities, terms and factors",
					2.14: "Simplify and manipulate algebraic expressions by: • collecting like terms • multiplying a single term over a bracket • taking out common factors • simplifying expressions involving sums, products and powers, including the laws of indices • expanding products of two or	more binomials • factorising quadratic		expressions of the form ax2 + bx + c",
					2.15: "Understand and use standard mathematical formulae rearrange formulae to change the subjects",
					2.16: "Argue mathematically to show algebraic expressions are equivalent, and use algebra to support and construct arguments",
					2.17: "Where appropriate, interpret simple expressions as functions with inputs and outputs",
					2.18: "Interpret the reverse process as the‘inverse function’ interpret the succession of two functions as a ‘composite function’",
					2.19: "Understanding and use of f x , fg x and f−1 x notation is expected at Higher tier.",
				},
			},
			2.2: {
				"sub-topic": "Graphs",
				spec: {
					2.21: "Work with coordinates in all four quadrants",
					2.22: "Plot graphs of equations that correspond to straight-line graphs in the coordinate plane",
					2.23: "Identify and interpret gradients and intercepts of linear functions graphically and algebraically",
					2.24: "Identify and interpret roots, intercepts and turning points of quadratic functions graphically. Deduce turning points by completing the square",
					2.25: "Recognise, sketch and interpret graphs of linear functions and quadratic functions. Including exponential functions y = kx for positive values of k, and the trigonometric functions (with arguments in degrees) y = sin x, y = cos x and y = tan x for angles of any size",
					2.26: "Sketch translations and reflections of a given function",
					2.27: "Plot and interpret graphs, and graphs of non-standard functions in real contexts, to find approximate solutions to problems such as simple kinematic problems involving distance, speed and acceleration",
					2.28: "Calculate or estimate gradients of graphs and areas under graphs (including quadratic and other nonlinear graphs), and interpret results in cases such as distance-time graphs, velocity-time graphs and graphs in financial contexts",
					2.29: "Recognise and use the equation of a circle with centre at the origin find the equation of a tangent to a circle at a given point",
				},
			},
			2.3: {
				"sub-topic": "Solving equations and inequalities",
				spec: {
					2.31: "Solve linear equations in one unknown algebraically find approximate solutions using a graph",
					2.32: "Solve quadratic equations algebraically by factorising, including those that require rearrangement including completing the square and by using the quadratic formula",
					2.33: "Solve two simultaneous equations in two variables (linear/linear) algebraically find approximate solutions using a graph",
					2.34: "Find approximate solutions to equations numerically using iteration",
					2.35: "Translate simple situations or procedures into algebraic expressions or formulae derive an equation (or two simultaneous equations), solve the equation(s) and interpret the solution",
					2.36: "Solve linear inequalities in one or two variable(s), and quadratic inequalities in one variable",
					2.37: "Represent the solution set on a number line, using set notation and on a graph",
				},
			},
			2.4: {
				"sub-topic": "Sequences",
				spec: {
					2.41: "Generate terms of a sequence from either a term-to-term or a position-to-term rule",
					2.42: "Recognise and use sequences of triangular, square and cube numbers and simple arithmetic progressions",
					2.43: "Including fibonacci-type sequences, quadratic sequences, and simple geometric progressions rn where n is an integer and r isa rational number > 0)",
					2.44: "Deduce expressions to calculate the nth term of linear sequences, including quadratic sequences",
				},
			},
		},
	},
	3: {
		topic: "Ratio, proportion and rates of change",
		topicQuestions: "https://www.physicsandmathstutor.com/maths-revision/gcse-ratio-proportion-and-rates-of-change/questions-aqa/",
		subs: {
			3.1: {
				"sub-topic": "Ratio",
				spec: {
					3.11: "Change freely between related standard units (eg time, length, area, volume/capacity, mass) and compound units (eg speed, rates of pay, prices) in numerical contexts",
					3.12: "Use scale factors, scale	diagrams and maps",
					3.13: "Express one quantity as a fraction of another, where the fraction is less than 1 or greater than 1",
					3.14: "Use ratio notation, including reduction to simplest form",
					3.15: "Divide a given quantity into two parts in a given part : part or	part : whole ratio express the division of a quantity into two parts as a ratio apply ratio to real contexts and problems (such as those involving conversion, comparison, scaling, mixing, concentrations)",
					3.16: "Express a multiplicative relationship between two quantities as a ratio or a fraction",
					3.17: "understand and use proportion as equality of ratios",
					3.18: "Relate ratios to fractions and to linear functions",
				},
			},
			3.2: {
				"sub-topic": "Proportions",
				spec: {
					3.21: "Define percentage as ‘number of parts per hundred’. Interpret percentages and percentage changes as a fraction or a decimal, and interpret these multiplicatively. Express one quantity as a percentage of another. Compare two quantities using percentages work with percentages greaterthan 100%. Solve problems involving percentage change, including percentage increase/decrease and original value problems,	and simple interest including in financial mathematics",
					3.22: "Solve problems involving direct and inverse proportion, including graphical and algebraic representations",
					3.23: "Use compound units such as speed, rates of pay, unit pricing",
					3.24: "Compare lengths, areas and volumes using ratio notation scale factors",
					3.25: "Understand that X is inversely proportional to Y is equivalent to X is proportional to 1/Y",
					3.26: "Construct and interpret equations that describe direct and inverse proportion",
					3.27: "Interpret the gradient of a straight-line graph as a rate of change recognise and interpret graphs that illustrate direct and inverse proportion",
					3.28: "Interpret the gradient at a point on a curve as the instantaneous rateof change apply the concepts of average and instantaneous rate of change (gradients of chords and tangents) in numerical, algebraic and graphical contexts",
					3.29: "Set up, solve and interpret the answers in growth and decay problems, including compound interest.  Work with general iterative processes",
				},
			},
		},
	},
	4: {
		topic: "Geometry and measures",
		topicQuestions: "https://www.physicsandmathstutor.com/maths-revision/gcse-geometry-and-measures/questions-aqa/",
		subs: {
			4.1: {
				"sub-topic": "Properties",
				spec: {
					4.11: "Use conventional terms and notations: points, lines, vertices, edges, planes, parallel lines, perpendicular lines, right angles, polygons, regular polygons and polygons with reflection and/or rotation symmetries use the standard conventions for labelling and referring to the sides and angles of triangles draw diagrams from written description",
					4.12: "Use the standard ruler and compass constructions (perpendicular bisector of a line segment, constructing a perpendicular to a given line from/at a given point, bisecting a given angle) use these to construct given figures and solve loci problems know that the perpendicular distance from a point to a line is the shortest distance to the line",
					4.13: "Apply the properties of angles at a point, angles at a point on a straight line, vertically opposite angles understand and use alternate and corresponding angles on parallel lines derive and use the sum of angles in a triangle (eg to deduce and use the angle sum in any polygon, and to derive properties of regular polygons)",
					4.14: "Derive and apply the properties and definitions of: special types of quadrilaterals, including square, rectangle, parallelogram, trapezium, kite and rhombus and triangles and other plane figures using appropriate language",
				},
			},
			4.2: {
				"sub-topic": "Constructions",
				spec: {
					4.21: "Use the basic congruence criteria for triangles (sss, sas, asa, rhs)",
					4.22: "Apply angle facts, triangle congruence, similarity and properties of quadrilaterals to conjecture and derive results about angles and sides, including pythagoras theorem and the fact that the base angles of an isosceles triangle are equal, and use known results to obtain simple proofs",
					4.23: "Identify, describe and construct congruent and similar shapes, including on coordinate axes, by considering rotation, reflection, translation and enlargement. Including fractional scale factors. Including negative scale factors",
					4.24: "Describe the changes and invariance achieved by combinations of rotations, reflections and translations",
					4.25: "Identify and apply circle definitions and properties, including: centre, radius, chord, diameter, circumference. Including: tangent, arc, sector and segment",
					4.26: "Apply and prove the standard circle theorems concerning angles, radii, tangents and chords, and use them to prove related results",
					4.27: "Solve geometrical problems on coordinate axes",
					4.28: "Identify properties of the faces, surfaces, edges and vertices of: cubes, cuboids, prisms, cylinders, pyramids, cones and spheres",
					4.29: "Interpret plans and elevations of 3d shapes. Construct and interpret plans and elevations of 3d shapes",
				},
			},
			4.3: {
				"sub-topic": "Mensuration and calculation",
				spec: {
					4.31: "Use standard units of measure and related concepts (length, area, volume/capacity, mass, time, money etc. )",
					4.32: "Measure line segments and angles in geometric figures, including interpreting maps and scale drawings and use of bearings",
					4.33: "Know and apply formulae to calculate: area of triangles, parallelograms, trapezia; volume of cuboids and other right prisms (including cylinders)",
					4.34: "Know the formulae: circumference of a circle = 2r = d area of a circle = r 2 calculate perimeters of 2d shapes, including circles areas of circles and composite shapes",
					4.35: "Calculate arc lengths, angles and areas of sectors of circles",
					4.36: "Apply the concepts of congruence and similarity, including the relationships between lengths in similar figures. Including the relationships between lengths, areas and volumes in similar figures",
					4.37: "Know the formulae for: pythagoras theorem, a2 + b2 = c2 and the trigonometric ratios, sin = opposite/ hypotenuse , cos = adjacent/hypotenuse and tan = opposite/adjacent. Apply them to find angles and lengths in right-angled triangles and general triangles in two and three dimensional figures",
					4.38: "Know the exact values of sin and cos for  = 0, 30, 45 , 60 and 90 know the exact value of tan for  = 0, 30, 45 , 60",
					4.39: "Know and apply the sine rule, a/ sina = b/ sinb = c/ sinc and cosine rule, a2 = b2 + c2 2bccosa to find unknown lengths and angles. Area = 1/2 absinc to calculate the area, sides or angles of any triangle",
				},
			},
			4.4: {
				"sub-topic": "Vectors",
				spec: {
					4.41: "Describe translations as 2d vectors",
					4.42: "Apply addition and subtraction of vectors, multiplication of vectors by a scalar, and diagrammatic and column representations of vectors",
					4.43: "Use vectors to construct geometric arguments and proofs",

				
				},
			},
		},
	},
	5: {
		topic: "Probability",
		topicQuestions: "https://www.physicsandmathstutor.com/maths-revision/gcse-probability/questions-aqa/",
		subs: {
			5.1: {
				"sub-topic": "Probability",
				spec: {
					5.11: "Record, describe and analyse the frequency of outcomes of probability experiments using tables and frequency trees",
					5.12: "Apply ideas of randomness, fairness and equally likely events to calculate expected outcomes of multiple future experiments",
					5.13: "Relate relative expected frequencies to theoretical probability, using appropriate language and the 0 to 1 probability scale",
					5.14: "Apply the property that the probabilities of an exhaustive set of outcomes sum to 1 apply the property that the probabilities of an exhaustive set of mutually exclusive events sum to 1",
					5.15: "Understand that empirical unbiased samples tend towards theoretical probability distributions, with increasing sample size",
					5.16: "Enumerate sets and combinations of sets systematically, using tables, grids, venn diagrams. Including using tree diagrams",
					5.17: "Construct theoretical possibility spaces for single and combined experiments with equally likely outcomes and use these to calculate theoretical probabilities",
					5.18: "Calculate the probability of independent and dependent combined events, including using tree diagrams and other representations, and know the underlying assumptions",
					5.19: "Calculate and interpret conditional probabilities through representation using expected frequencies with two-way tables, tree diagrams and venn diagrams",
				},
			},
		},
	},
	6: {
		topic: "Statistics",
		topicQuestions: "https://www.physicsandmathstutor.com/maths-revision/gcse-statistics/questions-aqa/",
		subs: {
			6.1: {
				"sub-topic": "Statistics",
				spec: {
					6.11: "Infer properties of populations or distributions from a sample, whilst knowing the limitations of sampling",
					6.12: "Interpret and construct tables, charts and diagrams, including frequency tables, bar charts, pie charts and pictograms for categorical data, vertical line charts for ungrouped discrete numerical data, and know their appropriate use. Including tables and line graphs for time series data",
					6.13: "Construct and interpret diagrams for grouped discrete data and continuous data, ie histograms with equal and unequal class intervals and cumulative frequency graphs, and know their appropriate use",
					6.14: "Interpret, analyse and compare the distributions of data sets from univariate empirical distributions through: - appropriate graphical representation involving discrete, continuous and grouped data, including box plots. - appropriate measures of central tendency (median, mean, mode and modal class) and spread (range, including consideration of outliers), including quartiles and inter-quartile range",
					6.15: "Apply statistics to describe a population",
					6.16: "Use and interpret scatter graphs of bivariate data. Recognise correlationknow that it does not indicate causation, draw estimated lines of best fit, make predictions, interpolate and extrapolate, apparent trends whilst knowing, the dangers of so doing",
				},
			},
		},
	},
};
