/** @format */

export const AlevelBiologyAQA = {
	1: {
		topic: " Biological molecules",
		nested: true,
		topicQuestions: "",
		specLink: "",
		code: "AlevelBiologyAQA",
		name: "A-level Biology AQA",
		subject: "Biology",
		board: "AQA",
		level: "A-level",
		subs: {
			1.1: {
				"sub-topic": "Monomers and polymers",
				spec: {
					1.11: {
						"sub-topic": "Monomers and polymers",
						spec: {
							1.111:
								"The variety of life, both past and present, is extensive, but the biochemical basis of life is similar for all living things. ",
							1.112:
								"Monomers are the smaller units from which larger molecules are made. ",
							1.113:
								"Polymers are molecules made from a large number of monomers joined together. ",
							1.114:
								"Monosaccharides, amino acids and nucleotides are examples of monomers.",
							1.115:
								"A condensation reaction joins two molecules together with the formation of a chemical bond and involves the elimination of a molecule of water.",
							1.116:
								"A hydrolysis reaction breaks a chemical bond between two molecules and involves the use of a water molecule.",
						},
					},
				},
			},
			1.2: {
				"sub-topic": "Carbohydrates",
				spec: {
					1.21: {
						"sub-topic": "Carbohydrates",
						spec: {
							1.211:
								"Monosaccharides are the monomers from which larger carbohydrates are made. Glucose, galactose and fructose are common monosaccharides. ",
							1.212:
								"A condensation reaction between two monosaccharides forms a glycosidic bond.",
							1.213:
								"Disaccharides are formed by the condensation of two monosaccharides:\n • maltose is a disaccharide formed by condensation of two glucose molecules\n • sucrose is a disaccharide formed by condensation of a glucose molecule and a fructose molecule\n • lactose is a disaccharide formed by condensation of a glucose molecule and a galactose molecule. ",
							1.214:
								"Glucose has two isomers, α-glucose and β-glucose, with structures: diagram 3.1.2 ",
							1.215:
								"Polysaccharides are formed by the condensation of many glucose units.\n • Glycogen and starch are formed by the condensation of α-glucose.\n • Cellulose is formed by the condensation of β-glucose. ",
							1.216:
								"The basic structure and functions of glycogen, starch and cellulose. The relationship of structure to function of these substances in animal cells and plant cells.",
							1.217:
								"Biochemical tests using Benedict's solution for reducing sugars and non-reducing sugars and iodine/potassium iodide for starch.",
						},
					},
				},
			},
			1.3: {
				"sub-topic": "Lipids",
				spec: {
					1.31: {
						"sub-topic": "Lipids",
						spec: {
							1.311:
								"Triglycerides and phospholipids are two groups of lipid. ",
							1.312:
								"Triglycerides are formed by the condensation of one molecule of glycerol and three molecules of fatty acid.",
							1.313:
								"A condensation reaction between glycerol and a fatty acid (RCOOH) forms an ester bond.",
							1.314:
								"The R-group of a fatty acid may be saturated or unsaturated. ",
							1.315:
								"In phospholipids, one of the fatty acids of a triglyceride is substituted by a phosphate-containing group. ",
							1.316:
								"The different properties of triglycerides and phospholipids related to their different structures. ",
							1.317: "The emulsion test for lipids. ",
							1.318:
								"Students should be able to:\n • recognise, from diagrams, saturated and unsaturated fatty acids\n • explain the different properties of triglycerides and phospholipids.",
						},
					},
				},
			},
			1.4: {
				"sub-topic": "Proteins",
				spec: {
					1.41: {
						"sub-topic": "General properties of proteins",
						spec: {
							1.411:
								"Amino acids are the monomers from which proteins are made. The general structure of an amino acid as: where NH2 represents an amine group, COOH represents a carboxyl group and R represents a side chain. The twenty amino acids that are common in all organisms differ only in their side group. ",
							1.412:
								"A condensation reaction between two amino acids forms a peptide bond.\n • Dipeptides are formed by the condensation of two amino acids.\n • Polypeptides are formed by the condensation of many amino acids. ",
							1.413:
								"A functional protein may contain one or more polypeptides. ",
							1.414:
								"The role of hydrogen bonds, ionic bonds and disulfide bridges in the structure of proteins. ",
							1.415:
								"Proteins have a variety of functions within all living organisms. The relationship between primary, secondary, tertiary and quaternary structure, and protein function. ",
							1.416: "The biuret test for proteins. ",
							1.417:
								"Students should be able to relate the structure of proteins to properties of proteins named throughout the specification.",
						},
					},
					1.42: {
						"sub-topic": "Many proteins are enzymes",
						spec: {
							1.421:
								"Each enzyme lowers the activation energy of the reaction it catalyses. ",
							1.422: "The induced-fit model of enzyme action. ",
							1.423:
								"The properties of an enzyme relate to the tertiary structure of its active site and its ability to combine with complementary substrate(s) to form an enzyme-substrate complex. • The specificity of enzymes\n • The effects of the following factors on the rate of enzyme-controlled reactions – enzyme concentration, substrate concentration, concentration of competitive and of non-competitive inhibitors, pH and temperature. ",
							1.424:
								"Students should be able to:\n • appreciate how models of enzyme action have changed over time\n • appreciate that enzymes catalyse a wide range of intracellular and extracellular reactions that determine structures and functions from cellular to whole-organism level.",
						},
					},
				},
			},

			1.5: {
				"sub-topic":
					"Nucleic acids are important information-carrying molecules",
				spec: {
					1.51: {
						"sub-topic": "Structure of DNA and RNA",
						spec: {
							1.511:
								"Deoxyribonucleic acid (DNA) and ribonucleic acid (RNA) are important information-carrying molecules. In all living cells, DNA holds genetic information and RNA transfers genetic information from DNA to the ribosomes. ",
							1.512: "Ribosomes are formed from RNA and proteins. ",
							1.513:
								"Both DNA and RNA are polymers of nucleotides. Each nucleotide is formed from a pentose, a nitrogen-containing organic base and a phosphate group:\n  3.1.5.1  • The components of a DNA nucleotide are deoxyribose, a phosphate group and one of the organic bases adenine, cytosine, guanine or thymine.\n • The components of an RNA nucleotide are ribose, a phosphate group and one of the organic bases adenine, cytosine, guanine or uracil.\n • A condensation reaction between two nucleotides forms a phosphodiester bond. ",
							1.514:
								"A DNA molecule is a double helix with two polynucleotide chains held together by hydrogen bonds between specific complementary base pairs.",
							1.515:
								"An RNA molecule is a relatively short polynucleotide chain. ",
							1.516:
								"Students should be able to appreciate that the relative simplicity of DNA led many scientists to doubt that it carried the genetic code.",
						},
					},
					1.52: {
						"sub-topic": "DNA replication",
						spec: {
							1.521:
								"The semi-conservative replication of DNA ensures genetic continuity between generations of cells. ",
							1.522:
								"The process of semi-conservative replication of DNA in terms of:\n • unwinding of the double helix\n • breakage of hydrogen bonds between complementary bases in the polynucleotide strands\n • the role of DNA helicase in unwinding DNA and breaking its hydrogen bonds\n • attraction of new DNA nucleotides to exposed bases on template strands and base pairing\n • the role of DNA polymerase in the condensation reaction that joins adjacent nucleotides.",
							1.523:
								" Students should be able to evaluate the work of scientists in validating the Watson–Crick model of DNA replication.",
						},
					},
				},
			},
			1.6: {
				"sub-topic": "ATP",
				spec: {
					1.61: {
						"sub-topic": "ATP",
						spec: {
							1.611:
								"A single molecule of adenosine triphosphate (ATP) is a nucleotide derivative and is formed from a molecule of ribose, a molecule of adenine and three phosphate groups. ",
							1.612:
								"Hydrolysis of ATP to adenosine diphosphate (ADP) and an inorganic phosphate group (Pi ) is catalysed by the enzyme ATP hydrolase.\n • The hydrolysis of ATP can be coupled to energy-requiring reactions within cells.\n • The inorganic phosphate released during the hydrolysis of ATP can be used to phosphorylate other compounds, often making them more reactive. ",
							1.613:
								"ATP is resynthesised by the condensation of ADP and Pi . This reaction is catalysed by the enzyme ATP synthase during photosynthesis, or during respiration.",
						},
					},
				},
			},
			1.7: {
				"sub-topic": "Water",
				spec: {
					1.71: {
						"sub-topic": "Water",
						spec: {
							1.711:
								"Water is a major component of cells. It has several properties that are important in biology. In particular, water:\n • is a metabolite in many metabolic reactions, including condensation and hydrolysis reactions\n • is an important solvent in which metabolic reactions occur\n • has a relatively high heat capacity, buffering changes in temperature\n • has a relatively large latent heat of vaporisation, providing a cooling effect with little loss of water through evaporation\n • has strong cohesion between water molecules; this supports columns of water in the tube-like transport cells of plants and produces surface tension where water meets air.",
						},
					},
				},
			},
			1.8: {
				"sub-topic": "Inorganic ions",
				spec: {
					1.81: {
						"sub-topic": "Inorganic ions",
						spec: {
							1.811:
								"Onorganic ions occur in solution in the cytoplasm and body fluids of organisms, some in high concentrations and others in very low concentrations. ",
							1.812:
								"Each type of ion has a specific role, depending on its properties. ",
							1.813:
								"Students should be able to recognise the role of ions in the following topics: hydrogen ions and pH; iron ions as a component of haemoglobin; sodium ions in the co-transport of glucose and amino acids; and phosphate ions as components of DNA and of ATP.",
						},
					},
				},
			},
		},
	},
	2: {
		topic: "Cells",
		topicQuestions: "",
		subs: {
			2.1: {
				"sub-topic": " Cell structure",
				spec: {
					2.11: {
						"sub-topic": "Structure of eukaryotic cells",
						spec: {
							2.111:
								"The structure of eukaryotic cells, restricted to the structure and function of:\n • cell-surface membrane\n • nucleus (containing chromosomes, consisting of protein bound, linear DNA, and one or more nucleoli)\n • mitochondria\n • chloroplasts (in plants and algae)\n • Golgi apparatus and Golgi vesicles\n • lysosomes (a membrane-bound organelle that releases hydrolytic enzymes)\n • ribosomes\n • rough endoplasmic reticulum and smooth endoplasmic reticulum\n • cell wall (in plants, algae and fungi)\n • cell vacuole (in plants).",
							2.112:
								"In complex multicellular organisms, eukaryotic cells become specialised for specific functions. Specialised cells are organised into tissues, tissues into organs and organs into systems. ",
							2.113:
								"Students should be able to apply their knowledge of these features in explaining adaptations of eukaryotic cells.",
						},
					},
					2.12: {
						"sub-topic": "Structure of prokaryotic cells and of viruses",
						spec: {
							2.121:
								"Prokaryotic cells are much smaller than eukaryotic cells. They also differ from eukaryotic cells in having:\n • cytoplasm that lacks membrane-bound organelles\n • smaller ribosomes\n • no nucleus; instead they have a single circular DNA molecule that is free in the cytoplasm and is not associated with proteins\n • a cell wall that contains murein, a glycoprotein. ",
							2.122:
								"In addition, many prokaryotic cells have:\n • one or more plasmids\n • a capsule surrounding the cell\n • one or more flagella. ",
							2.123:
								"Details of these structural differences are not required.",
							2.124:
								" Viruses are acellular and non-living. The structure of virus particles to include genetic material, capsid and attachment protein.",
						},
					},
					2.13: {
						"sub-topic": "Methods of studying cells",
						spec: {
							2.131:
								"The principles and limitations of optical microscopes, transmission electron microscopes and scanning electron microscopes. ",
							2.132:
								"Measuring the size of an object viewed with an optical microscope. The difference between magnification and resolution. ",
							2.133:
								"Use of the formula: magnification = size of image/ size of real object ",
							2.134:
								"Principles of cell fractionation and ultracentrifugation as used to separate cell components. ",
							2.135:
								"Students should be able to appreciate that there was a considerable period of time during which the scientific community distinguished between artefacts and cell organelles.",
						},
					},
				},
			},
			2.2: {
				"sub-topic": "Cell Creation, Transport and Immune System",
				spec: {
					2.21: {
						"sub-topic": "All cells arise from other cells",
						spec: {
							2.211:
								"Within multicellular organisms, not all cells retain the ability to divide. ",
							2.212:
								"Eukaryotic cells that do retain the ability to divide show a cell cycle.\n • DNA replication occurs during the interphase of the cell cycle.\n • Mitosis is the part of the cell cycle in which a eukaryotic cell divides to produce two daughter cells, each with the identical copies of DNA produced by the parent cell during DNA replication. ",
							2.213:
								"The behaviour of chromosomes during interphase, prophase, metaphase, anaphase and telophase of mitosis. The role of spindle fibres attached to centromeres in the separation of chromatids. ",
							2.214:
								"Division of the cytoplasm (cytokinesis) usually occurs, producing two new cells.",
							2.215: "Meiosis is covered in section 3.4.3 ",
							2.216:
								"Students should be able to:\n • recognise the stages of the cell cycle: interphase, prophase, metaphase, anaphase and telophase (including cytokinesis)\n • explain the appearance of cells in each stage of mitosis.",
							2.217:
								"Mitosis is a controlled process. Uncontrolled cell division can lead to the formation of tumours and of cancers. Many cancer treatments are directed at controlling the rate of cell division. ",
							2.218:
								"Binary fission in prokaryotic cells involves:\n • replication of the circular DNA and of plasmids\n • division of the cytoplasm to produce two daughter cells, each with a single copy of the circular DNA and a variable number of copies of plasmids.",
							2.219:
								"Being non-living, viruses do not undergo cell division. Following injection of their nucleic acid, the infected host cell replicates the virus particles.",
							2.211:
								"Required practical 2: Preparation of stained squashes of cells from plant root tips; set-up and use of an optical microscope to identify the stages of mitosis in these stained squashes and calculation of a mitotic index.",
							2.2111:
								" Students should measure the apparent size of cells in the root tip and calculate their actual size using the formula: Actual size = size of image/ magnification",
						},
					},
					2.22: {
						"sub-topic": "Transport across cell membranes",
						spec: {
							2.221:
								"The basic structure of all cell membranes, including cell-surface membranes and the membranes around the cell organelles of eukaryotes, is the same. ",
							2.222:
								"The arrangement and any movement of phospholipids, proteins, glycoproteins and glycolipids in the fluid-mosaic model of membrane structure. Cholesterol may also be present in cell membranes where it restricts the movement of other molecules making up the membrane. ",
							2.223:
								"Movement across membranes occurs by:\n • simple diffusion (involving limitations imposed by the nature of the phospholipid bilayer)\n • facilitated diffusion (involving the roles of carrier proteins and channel proteins)\n • osmosis (explained in terms of water potential)\n • active transport (involving the role of carrier proteins and the importance of the hydrolysis of ATP)\n • co-transport (illustrated by the absorption of sodium ions and glucose by cells lining the mammalian ileum). ",
							2.224:
								"Cells may be adapted for rapid transport across their internal or external membranes by an increase in surface area of, or by an increase in the number of protein channels and carrier molecules in, their membranes. ",
							2.225:
								"Students should be able to:\n • explain the adaptations of specialised cells in relation to the rate of transport across their internal and external membranes\n • explain how surface area, number of channel or carrier proteins and differences in gradients of concentration or water potential affect the rate of movement across cell membranes. ",
							2.226:
								"Required practical 3: Production of a dilution series of a solute to produce a calibration curve with which to identify the water potential of plant tissue. ",
							2.227:
								"Required practical 4: Investigation into the effect of a named variable on the permeability of cell-surface membranes.",
						},
					},
					2.23: {
						"sub-topic": "Cell recognition and the immune system",
						spec: {
							2.231:
								"Each type of cell has specific molecules on its surface that identify it. These molecules include proteins and enable the immune system to identify:\n • pathogens\n • cells from other organisms of the same species\n • abnormal body cells\n • toxins.",
							2.232:
								"Definition of antigen. The effect of antigen variability on disease and disease prevention.",
							2.233:
								"Phagocytosis of pathogens. The subsequent destruction of ingested pathogens by lysozymes.",
							2.234:
								"he response of T lymphocytes to a foreign antigen (the cellular response).\n • The role of antigen-presenting cells in the cellular response.\n • The role of helper T cells (TH cells) in stimulating cytotoxic T cells (TC cells), B cells and phagocytes. The role of other T cells is not required. ",
							2.235:
								"The response of B lymphocytes to a foreign antigen, clonal selection and the release of monoclonal antibodies (the humoral response).\n • Definition of antibody.\n • Antibody structure.\n • The formation of an antigen-antibody complex, leading to the destruction of the antigen, limited to agglutination and phagocytosis of bacterial cells.\n • The roles of plasma cells and of memory cells in producing primary and secondary immune responses. ",
							2.236:
								"The use of vaccines to provide protection for individuals and populations against disease. ",
							2.237:
								"The concept of herd immunity. The differences between active and passive immunity.",
							2.238:
								"Structure of the human immunodeficiency virus (HIV) and its replication in helper T cells. ",
							2.239:
								"How HIV causes the symptoms of AIDS. Why antibiotics are ineffective against viruses. ",
							2.231:
								"The use of monoclonal antibodies in:\n • targeting medication to specific cell types by attaching a therapeutic drug to an antibody\n • medical diagnosis. ",
							2.2311:
								"Details of the commercial or scientific production of monoclonal antibodies are not required. ",
							2.2312:
								"Ethical issues associated with the use of vaccines and monoclonal antibodies. ",
							2.2313: "The use of antibodies in the ELISA test. ",
							2.2314:
								"Students should be able to:\n • discuss ethical issues associated with the use of vaccines and monoclonal antibodies\n • evaluate methodology, evidence and data relating to the use of vaccines and monoclonal antibodies.",
						},
					},
				},
			},
		},
	},
	3: {
		topic: "Organisms exchange substances with their environment",
		topicQuestions: "",
		subs: {
			3.1: {
				"sub-topic": "Organisms exchange substances with their environment",
				spec: {
					3.11: {
						"sub-topic": "Surface area to volume ratio",
						spec: {
							3.111:
								"The relationship between the size of an organism or structure and its surface area to volume ratio.",
							3.112:
								"Changes to body shape and the development of systems in larger organisms as adaptations that facilitate exchange as this ratio reduces. ",
							3.113:
								"Students should be able to appreciate the relationship between surface area to volume ratio and metabolic rate.",
						},
					},
					3.12: {
						"sub-topic": "Gas exchange",
						spec: {
							3.121:
								"Adaptations of gas exchange surfaces, shown by gas exchange:\n • across the body surface of a single-celled organism\n • in the tracheal system of an insect (tracheae, tracheoles and spiracles)\n • across the gills of fish (gill lamellae and filaments including the counter-current principle)\n • by the leaves of dicotyledonous plants (mesophyll and stomata). ",
							3.122:
								"Structural and functional compromises between the opposing needs for efficient gas exchange and the limitation of water loss shown by terrestrial insects and xerophytic plants. ",
							3.123:
								"The gross structure of the human gas exchange system limited to the alveoli, bronchioles, bronchi, trachea and lungs. ",
							3.124:
								"The essential features of the alveolar epithelium as a surface over which gas exchange takes place. ",
							3.125:
								"Ventilation and the exchange of gases in the lungs. The mechanism of breathing to include the role of the diaphragm and the antagonistic interaction between the external and internal intercostal muscles in bringing about pressure changes in the thoracic cavity. ",
							3.126:
								"Students should be able to:\n • interpret information relating to the effects of lung disease on gas exchange and/or ventilation\n • interpret data relating to the effects of pollution and smoking on the incidence of lung disease\n • analyse and interpret data associated with specific risk factors and the incidence of lung disease\n • evaluate the way in which experimental data led to statutory restrictions on the sources of risk factors\n • recognise correlations and causal relationships.",
						},
					},
					3.13: {
						"sub-topic": "Digestion and absorption",
						spec: {
							3.131:
								"During digestion, large biological molecules are hydrolysed to smaller molecules that can be absorbed across cell membranes. ",
							3.132:
								"Digestion in mammals of:\n • carbohydrates by amylases and membrane-bound disaccharidases\n • lipids by lipase, including the action of bile salts\n • proteins by endopeptidases, exopeptidases and membrane bound dipeptidases.",
							3.133:
								" Mechanisms for the absorption of the products of digestion by cells lining the ileum of mammals, to include:\n • co-transport mechanisms for the absorption of amino acids and of monosaccharides\n • the role of micelles in the absorption of lipids.",
						},
					},
				},
			},
			3.2: {
				"sub-topic": "Mass transport",
				spec: {
					3.21: {
						"sub-topic": "Mass transport in animals",
						spec: {
							3.211:
								"The haemoglobins are a group of chemically similar molecules found in many different organisms. Haemoglobin is a protein with a quaternary structure. ",
							3.212:
								"The role of haemoglobin and red blood cells in the transport of oxygen. The loading, transport and unloading of oxygen in relation to the oxyhaemoglobin dissociation curve. The cooperative nature of oxygen binding to show that the change in shape of haemoglobin caused by binding of the first oxygens makes the binding of further oxygens easier. The effects of carbon dioxide concentration on the dissociation of oxyhaemoglobin (the Bohr effect).",
							3.213:
								"Many animals are adapted to their environment by possessing different types of haemoglobin with different oxygen transport properties.",
							3.214:
								"The general pattern of blood circulation in a mammal. Names are required only of the coronary arteries and of the blood vessels entering and leaving the heart, lungs and kidneys. ",
							3.215:
								"The gross structure of the human heart. Pressure and volume changes and associated valve movements during the cardiac cycle that maintain a unidirectional flow of blood. ",
							3.216:
								"The structure of arteries, arterioles and veins in relation to their function.",
							3.217:
								"The structure of capillaries and the importance of capillary beds as exchange surfaces. The formation of tissue fluid and its return to the circulatory system.",
							3.218:
								"Students should be able to:\n • analyse and interpret data relating to pressure and volume changes during the cardiac cycle\n • analyse and interpret data associated with specific risk factors and the incidence of cardiovascular disease\n • evaluate conflicting evidence associated with risk factors affecting cardiovascular disease\n • recognise correlations and causal relationships.",
							3.219:
								"Required practical 5: Dissection of animal or plant gas exchange system or mass transport system or of organ within such a system.",
						},
					},
					3.22: {
						"sub-topic": "Mass transport in plants",
						spec: {
							3.221:
								"Xylem as the tissue that transports water in the stem and leaves of plants. The cohesion-tension theory of water transport in the xylem. ",
							3.222:
								"Phloem as the tissue that transports organic substances in plants. The mass flow hypothesis for the mechanism of translocation in plants. The use of tracers and ringing experiments to investigate transport in plants. ",
							3.223:
								"Students should be able to:\n • recognise correlations and causal relationships\n • interpret evidence from tracer and ringing experiments and to evaluate the evidence for and against the mass flow hypothesis.",
						},
					},
				},
			},
		},
	},
	4: {
		topic: "Genetic information, variation and relationships between organisms",
		topicQuestions: "",
		subs: {
			4.1: {
				"sub-topic": "DNA",
				spec: {
					4.11: {
						"sub-topic": "DNA, genes and chromosomes",
						spec: {
							4.111:
								"In prokaryotic cells, DNA molecules are short, circular and not associated with proteins. ",
							4.112:
								"In the nucleus of eukaryotic cells, DNA molecules are very long, linear and associated with proteins, called histones. Together a DNA molecule and its associated proteins form a chromosome. ",
							4.113:
								"The mitochondria and chloroplasts of eukaryotic cells also contain DNA which, like the DNA of prokaryotes, is short, circular and not associated with protein. A gene is a base sequence of DNA that codes for:\n • the amino acid sequence of a polypeptide\n • a functional RNA (including ribosomal RNA and tRNAs). ",
							4.114:
								"A gene occupies a fixed position, called a locus, on a particular DNA molecule. ",
							4.115:
								"A sequence of three DNA bases, called a triplet, codes for a specific amino acid. The genetic code is universal, non-overlapping and degenerate.",
							4.116:
								"In eukaryotes, much of the nuclear DNA does not code for polypeptides. There are, for example, non-coding multiple repeats of base sequences between genes. Even within a gene only some sequences, called exons, code for amino acid sequences. Within the gene, these exons are separated by one or more non-coding sequences, called introns.",
						},
					},
					4.12: {
						"sub-topic": "DNA and protein synthesis",
						spec: {
							4.121:
								"The concept of the genome as the complete set of genes in a cell and of the proteome as the full range of proteins that a cell is able to produce.",
							4.122:
								"The structure of molecules of messenger RNA (mRNA) and of transfer RNA (tRNA). ",
							4.123:
								"Transcription as the production of mRNA from DNA. The role of RNA polymerase in joining mRNA nucleotides. • In prokaryotes, transcription results directly in the production of mRNA from DNA.\n • In eukaryotes, transcription results in the production of premRNA; this is then spliced to form mRNA. ",
							4.124:
								"Translation as the production of polypeptides from the sequence of codons carried by mRNA. The roles of ribosomes, tRNA and ATP. ",
							4.125:
								"Students should be able to:\n • relate the base sequence of nucleic acids to the amino acid sequence of polypeptides, when provided with suitable data about the genetic code\n • interpret data from experimental work investigating the role of nucleic acids. ",
							4.126:
								"Students will not be required to recall in written papers specific codons and the amino acids for which they code.",
						},
					},
				},
			},
			4.2: {
				"sub-topic": "Genetic Diversity",
				spec: {
					4.21: {
						"sub-topic":
							"Genetic diversity can arise as a result of mutation or during meiosis",
						spec: {
							4.211:
								"Gene mutations involve a change in the base sequence of chromosomes. They can arise spontaneously during DNA replication and include base deletion and base substitution. Due to the degenerate nature of the genetic code, not all base substitutions cause a change in the sequence of encoded amino acids. Mutagenic agents can increase the rate of gene mutation. ",
							4.212:
								"Mutations in the number of chromosomes can arise spontaneously by chromosome non-disjunction during meiosis. ",
							4.213:
								"Meiosis produces daughter cells that are genetically different from each other. ",
							4.214:
								"The process of meiosis only in sufficient detail to show how:\n • two nuclear divisions result usually in the formation of four haploid daughter cells from a single diploid parent cell\n • genetically different daughter cells result from the independent segregation of homologous chromosomes\n • crossing over between homologous chromosomes results in further genetic variation among daughter cells. ",
							4.215:
								"Students should be able to:\n • complete diagrams showing the chromosome content of cells after the first and second meiotic division, when given the chromosome content of the parent cell\n • explain the different outcome of mitosis and meiosis\n • recognise where meiosis occurs when given information about an unfamiliar life cycle\n • explain how random fertilisation of haploid gametes further increases genetic variation within a species.",
						},
					},
					4.22: {
						"sub-topic": "Genetic diversity and adaptation",
						spec: {
							4.221:
								"Genetic diversity as the number of different alleles of genes in a population. ",
							4.222:
								"Genetic diversity is a factor enabling natural selection to occur. ",
							4.223:
								"The principles of natural selection in the evolution of populations. • Random mutation can result in new alleles of a gene.\n • Many mutations are harmful but, in certain environments, the new allele of a gene might benefit its possessor, leading to increased reproductive success.\n • The advantageous allele is inherited by members of the next generation.\n • As a result, over many generations, the new allele increases in frequency in the population.",
							4.224:
								"Directional selection, exemplified by antibiotic resistance in bacteria, and stabilising selection, exemplified by human birth weights. ",
							4.225:
								"Natural selection results in species that are better adapted to their environment. These adaptations may be anatomical, physiological or behavioural. ",
							4.226:
								"Students should be able to:\n • use unfamiliar information to explain how selection produces changes within a population of a species\n • interpret data relating to the effect of selection in producing change within populations\n • show understanding that adaptation and selection are major factors in evolution and contribute to the diversity of living organisms.",
							4.227:
								"Required practical 6: Use of aseptic techniques to investigate the effect of antimicrobial substances on microbial growth.",
						},
					},
				},
			},
			4.3: {
				"sub-topic": "Diversity",
				spec: {
					4.31: {
						"sub-topic": "Species and taxonomy",
						spec: {
							4.311:
								"Two organisms belong to the same species if they are able to produce fertile offspring. Courtship behaviour as a necessary precursor to successful mating. The role of courtship in species recognition. ",
							4.312:
								"A phylogenetic classification system attempts to arrange species into groups based on their evolutionary origins and relationships. It uses a hierarchy in which smaller groups are placed within larger groups, with no overlap between groups. Each group is called a taxon (plural taxa).",
							4.313:
								"One hierarchy comprises the taxa: domain, kingdom, phylum, class, order, family, genus and species.",
							4.314:
								"Each species is universally identified by a binomial consisting of the name of its genus and species, eg, Homo sapiens. ",
							4.315:
								"Recall of different taxonomic systems, such as the three domain or five kingdom systems, will not be required. ",
							4.316:
								"Students should be able to appreciate that advances in immunology and genome sequencing help to clarify evolutionary relationships between organisms.",
						},
					},
					4.32: {
						"sub-topic": "Biodiversity within a community",
						spec: {
							4.321:
								"Biodiversity can relate to a range of habitats, from a small local habitat to the Earth. ",
							4.322:
								"Species richness is a measure of the number of different species in a community.",
							4.323:
								"An index of diversity describes the relationship between the number of species in a community and the number of individuals in each species. ",
							4.324:
								"Calculation of an index of diversity ( d) from the formula 3.4.6 where N = total number of organisms of all species and n = total number of organisms of each species.",
							4.325:
								"Farming techniques reduce biodiversity. The balance between conservation and farming.",
						},
					},
					4.33: {
						"sub-topic": "Investigating diversity",
						spec: {
							4.331:
								"Genetic diversity within, or between species, can be made by comparing:\n • the frequency of measurable or observable characteristics\n • the base sequence of DNA\n • the base sequence of mRNA\n • the amino acid sequence of the proteins encoded by DNA and mRNA.",
							4.332:
								"Students should be able to:\n • interpret data relating to similarities and differences in the base sequences of DNA and in the amino acid sequences of proteins to suggest relationships between different organisms within a species and between species\n • appreciate that gene technology has caused a change in the methods of investigating genetic diversity; inferring DNA differences from measurable or observable characteristics has been replaced by direct investigation of DNA sequences. Knowledge of gene technologies will not be tested. ",
							4.333:
								"Quantitative investigations of variation within a species involve:\n • collecting data from random samples\n • calculating a mean value of the collected data and the standard deviation of that mean\n • interpreting mean values and their standard deviations. ",
							4.334:
								"Students will not be required to calculate standard deviations in written papers.",
						},
					},
				},
			},
		},
	},
	5: {
		topic: "Energy transfers in and between organisms (A-level only)",
		topicQuestions: "",
		subs: {
			5.1: {
				"sub-topic": "Energy transfers in and between organisms (A-level only)",
				spec: {
					5.11: {
						"sub-topic": "Photosynthesis (A-level only)",
						spec: {
							5.111:
								"The light-dependent reaction in such detail as to show that:\n • chlorophyll absorbs light, leading to photoionisation of chlorophyll\n • some of the energy from electrons released during photoionisation is conserved in the production of ATP and reduced NADP\n • the production of ATP involves electron transfer associated with the transfer of electrons down the electron transfer chain and passage of protons across chloroplast membranes and is catalysed by ATP synthase embedded in these membranes (chemiosomotic theory)\n • photolysis of water produces protons, electrons and oxygen. ",
							5.112:
								"The light-independent reaction uses reduced NADP from the light dependent reaction to form a simple sugar. The hydrolysis of ATP, also from the light-dependent reaction, provides the additional energy for this reaction. ",
							5.113:
								"The light-independent reaction in such detail as to show that: • carbon dioxide reacts with ribulose bisphosphate (RuBP) to form two molecules of glycerate 3-phosphate (GP). This reaction is catalysed by the enzyme rubisco • ATP and reduced NADP from the light-dependent reaction are used to reduce GP to triose phosphate\n • some of the triose phosphate is used to regenerate RuBP in the Calvin cycle\n • some of the triose phosphate is converted to useful organic substances. ",
							5.114:
								"Students should be able to:\n • identify environmental factors that limit the rate of photosynthesis\n • evaluate data relating to common agricultural practices used to overcome the effect of these limiting factors.",
							5.115:
								"Required practical 7: Use of chromatography to investigate the pigments isolated from leaves of different plants, eg, leaves from shade-tolerant and shade-intolerant plants or leaves of different colours.",
							5.116:
								" Required practical 8: Investigation into the effect of a named factor on the rate of dehydrogenase activity in extracts of chloroplasts.",
						},
					},
					5.12: {
						"sub-topic": "Respiration (A-level only)",
						spec: {
							5.121: "Respiration produces ATP.",
							5.122:
								"Glycolysis is the first stage of anaerobic and aerobic respiration. It occurs in the cytoplasm and is an anaerobic process.",
							5.123:
								"Glycolysis involves the following stages:\n • phosphorylation of glucose to glucose phosphate, using ATP\n • production of triose phosphate\n • oxidation of triose phosphate to pyruvate with a net gain of ATP and reduced NAD. ",
							5.124:
								"If respiration is only anaerobic, pyruvate can be converted to ethanol or lactate using reduced NAD. The oxidised NAD produced in this way can be used in further glycolysis.",
							5.125:
								"If respiration is aerobic, pyruvate from glycolysis enters the mitochondrial matrix by active transport.",
							5.126:
								"Aerobic respiration in such detail as to show that:\n • pyruvate is oxidised to acetate, producing reduced NAD in the process\n • acetate combines with coenzyme A in the link reaction to produce acetylcoenzyme A\n • acetylcoenzyme A reacts with a four-carbon molecule, releasing coenzyme A and producing a six-carbon molecule that enters the Krebs cycle\n • in a series of oxidation-reduction reactions, the Krebs cycle generates reduced coenzymes and ATP by substrate-level phosphorylation, and carbon dioxide is lost\n • synthesis of ATP by oxidative phosphorylation is associated with the transfer of electrons down the electron transfer chain and passage of protons across inner mitochondrial membranes and is catalysed by ATP synthase embedded in these membranes (chemiosomotic theory)\n • other respiratory substrates include the breakdown products of lipids and amino acids, which enter the Krebs cycle. ",
							5.127:
								"Required practical 9: Investigation into the effect of a named variable on the rate of respiration of cultures of single-celled organisms.",
						},
					},
					5.13: {
						"sub-topic": "Energy and ecosystems (A-level only)",
						spec: {
							5.131:
								"In any ecosystem, plants synthesise organic compounds from atmospheric, or aquatic, carbon dioxide. ",
							5.132:
								"Most of the sugars synthesised by plants are used by the plant as respiratory substrates. The rest are used to make other groups of biological molecules. These biological molecules form the biomass of the plants. ",
							5.133:
								"Biomass can be measured in terms of mass of carbon or dry mass of tissue per given area. The chemical energy store in dry biomass can be estimated using calorimetry. ",
							5.134:
								"Gross primary production ( GPP) is the chemical energy store in plant biomass, in a given area or volume. ",
							5.135:
								"Net primary production ( NPP) is the chemical energy store in plant biomass after respiratory losses to the environment have been taken into account,\n ie NPP = GPP – R where GPP represents gross production and R represents respiratory losses to the environment. ",
							5.136:
								"This net primary production is available for plant growth and reproduction. It is also available to other trophic levels in the ecosystem, such as herbivores and decomposers. ",
							5.137:
								"The net production of consumers ( N), such as animals, can be calculated as:\n N = I – ( F + R) where I represents the chemical energy store in ingested food, F represents the chemical energy lost to the environment in faeces and urine and R represents the respiratory losses to the environment. ",
							5.138:
								"Primary and secondary productivity is the rate of primary or secondary production, respectively. It is measured as biomass in a given area in a given time eg kJ ha–1 year–1 .",
							5.139:
								"Students should be able to appreciate the ways in which production is affected by farming practices designed to increase the efficiency of energy transfer by:\n • simplifying food webs to reduce energy losses to non-human food chains\n • reducing respiratory losses within a human food chain.",
						},
					},
					5.14: {
						"sub-topic": "Nutrient cycles (A-level only)",
						spec: {
							5.141:
								"Nutrients are recycled within natural ecosystems, exemplified by the nitrogen cycle and the phosphorus cycle. ",
							5.142:
								"Microorganisms play a vital role in recycling chemical elements such as phosphorus and nitrogen. • The role of saprobionts in decomposition.\n • The role of mycorrhizae in facilitating the uptake of water and inorganic ions by plants.\n • The role of bacteria in the nitrogen cycle in sufficient detail to illustrate the processes of saprobiotic nutrition, ammonification, nitrification, nitrogen fixation and denitrification.",
							5.143:
								"(The names of individual species of bacteria are not required). ",
							5.144:
								"The use of natural and artificial fertilisers to replace the nitrates and phosphates lost by harvesting plants and removing livestock.",
							5.145:
								"The environmental issues arising from the use of fertilisers including leaching and eutrophication.",
						},
					},
				},
			},
		},
	},
	6: {
		topic:
			"Organisms respond to changes in their internal and external environments (A-level only)",
		topicQuestions: "",
		subs: {
			6.1: {
				"sub-topic":
					"Stimuli, both internal and external, are detected and lead to a response (A-level only)",
				spec: {
					6.11: {
						"sub-topic": "Survival and response (A-level only)",
						spec: {
							6.111:
								"Organisms increase their chance of survival by responding to changes in their environment.",
							6.112:
								"In flowering plants, specific growth factors move from growing regions to other tissues, where they regulate growth in response to directional stimuli.",
							6.113:
								"The effect of different concentrations of indoleacetic acid (IAA) on cell elongation in the roots and shoots of flowering plants as an explanation of gravitropism and phototropism in flowering plants. ",
							6.114:
								"Taxes and kineses as simple responses that can maintain a mobile organism in a favourable environment. ",
							6.115:
								"The protective effect of a simple reflex, exemplified by a three neurone simple reflex. Details of spinal cord and dorsal and ventral roots are not required.",
							6.116:
								"Required practical 10: Investigation into the effect of an environmental variable on the movement of an animal using either a choice chamber or a maze.",
						},
					},
					6.12: {
						"sub-topic": "Receptors (A-level only)",
						spec: {
							6.121:
								"The Pacinian corpuscle should be used as an example of a receptor to illustrate that:\n • receptors respond only to specific stimuli\n • stimulation of a receptor leads to the establishment of a generator potential.",
							6.122: "The basic structure of a Pacinian corpuscle. ",
							6.123:
								"Deformation of stretch-mediated sodium ion channels in a Pacinian corpuscle leads to the establishment of a generator potential. ",
							6.124:
								"The human retina in sufficient detail to show how differences in sensitivity to light, sensitivity to colour and visual acuity are explained by differences in the optical pigments of rods and cones and the connections rods and cones make in the optic nerve.",
						},
					},
					6.13: {
						"sub-topic": "Control of heart rate (A-level only)",
						spec: {
							6.131:
								"Myogenic stimulation of the heart and transmission of a subsequent wave of electrical activity. The roles of the sinoatrial node (SAN), atrioventricular node (AVN) and Purkyne tissue in the bundle of His.",
							6.132:
								" The roles and locations of chemoreceptors and pressure receptors and the roles of the autonomic nervous system and effectors in controlling heart rate.",
						},
					},
				},
			},
			6.2: {
				"sub-topic": "Nervous coordination (A-level only)",
				spec: {
					6.21: {
						"sub-topic": "Nerve impulses (A-level only)",
						spec: {
							6.211: "The structure of a myelinated motor neurone. ",
							6.212:
								"The establishment of a resting potential in terms of differential membrane permeability, electrochemical gradients and the movement of sodium ions and potassium ions. ",
							6.213:
								"Changes in membrane permeability lead to depolarisation and the generation of an action potential. The all-or-nothing principle. ",
							6.214:
								"The passage of an action potential along non-myelinated and myelinated axons, resulting in nerve impulses. ",
							6.215:
								"The nature and importance of the refractory period in producing discrete impulses and in limiting the frequency of impulse transmission. ",
							6.216:
								"Factors affecting the speed of conductance: myelination and saltatory conduction; axon diameter; temperature.",
						},
					},
					6.22: {
						"sub-topic": "Synaptic transmission (A-level only)",
						spec: {
							6.221:
								"The detailed structure of a synapse and of a neuromuscular junction. ",
							6.222:
								"The sequence of events involved in transmission across a cholinergic synapse in sufficient detail to explain:\n • unidirectionality\n • temporal and spatial summation\n • inhibition by inhibitory synapses. ",
							6.223:
								"A comparison of transmission across a cholinergic synapse and across a neuromuscular junction. ",
							6.224:
								"Students should be able to use information provided to predict and explain the effects of specific drugs on a synapse. ",
							6.225:
								"(Recall of the names and mode of action of individual drugs will not be required.)",
						},
					},
				},
			},
			6.3: {
				"sub-topic":
					"Skeletal muscles are stimulated to contract by nerves and act as effectors (A-level only)",
				spec: {
					6.31: {
						"sub-topic":
							"Skeletal muscles are stimulated to contract by nerves and act as effectors (A-level only)",
						spec: {
							6.311:
								"Muscles act in antagonistic pairs against an incompressible skeleton.",
							6.312:
								"Gross and microscopic structure of skeletal muscle. The ultrastructure of a myofibril.",
							6.313:
								"The roles of actin, myosin, calcium ions and ATP in myofibril contraction.",
							6.314:
								"The roles of calcium ions and tropomyosin in the cycle of actinomyosin bridge formation. (The role of troponin is not required.)",
							6.315:
								"The roles of ATP and phosphocreatine in muscle contraction.",
							6.316:
								"The structure, location and general properties of slow and fast skeletal muscle fibres.",
						},
					},
				},
			},
			6.4: {
				"sub-topic":
					"Homeostasis is the maintenance of a stable internal environment (A-level only)",
				spec: {
					6.41: {
						"sub-topic":
							"Principles of homeostasis and negative feedback (A-level only)",
						spec: {
							6.411:
								"Homeostasis in mammals involves physiological control systems that maintain the internal environment within restricted limits. ",
							6.412:
								"The importance of maintaining a stable core temperature and stable blood pH in relation to enzyme activity.",
							6.413:
								"The importance of maintaining a stable blood glucose concentration in terms of availability of respiratory substrate and of the water potential of blood. ",
							6.414:
								"Negative feedback restores systems to their original level. ",
							6.415:
								"The possession of separate mechanisms involving negative feedback controls departures in different directions from the original state, giving a greater degree of control. ",
							6.416:
								"Students should be able to interpret information relating to examples of negative and positive feedback.",
						},
					},
					6.42: {
						"sub-topic":
							"Control of blood glucose concentration (A-level only)",
						spec: {
							6.421: "The factors that influence blood glucose concentration.",
							6.422:
								"The role of the liver in glycogenesis, glycogenolysis and gluconeogenesis. ",
							6.423:
								"The action of insulin by:\n • attaching to receptors on the surfaces of target cells\n • controlling the uptake of glucose by regulating the inclusion of channel proteins in the surface membranes of target cells\n • activating enzymes involved in the conversion of glucose to glycogen. ",
							6.424:
								"The action of glucagon by:\n • attaching to receptors on the surfaces of target cells\n • activating enzymes involved in the conversion of glycogen to glucose\n • activating enzymes involved in the conversion of glycerol and amino acids into glucose. ",
							6.425:
								"The role of adrenaline by:\n • attaching to receptors on the surfaces of target cells\n • activating enzymes involved in the conversion of glycogen to glucose. ",
							6.426:
								"The second messenger model of adrenaline and glucagon action, involving adenylate cyclase, cyclic AMP (cAMP) and protein kinase. ",
							6.427:
								"The causes of types I and II diabetes and their control by insulin and/or manipulation of the diet. ",
							6.428:
								"Students should be able to evaluate the positions of health advisers and the food industry in relation to the increased incidence of type II diabetes.",
							6.429:
								"Required practical 11: Production of a dilution series of a glucose solution and use of colorimetric techniques to produce a calibration curve with which to identify the concentration of glucose in an unknown ‘urine’ sample.",
						},
					},
					6.43: {
						"sub-topic": "Control of blood water potential (A-level only)",
						spec: {
							6.431:
								"Osmoregulation as control of the water potential of the blood. ",
							6.432:
								"The roles of the hypothalamus, posterior pituitary and antidiuretic hormone (ADH) in osmoregulation. ",
							6.433:
								"The structure of the nephron and its role in:\n • the formation of glomerular filtrate\n • reabsorption of glucose and water by the proximal convoluted tubule\n • maintaining a gradient of sodium ions in the medulla by the loop of Henle\n • reabsorption of water by the distal convoluted tubule and collecting ducts.",
						},
					},
				},
			},
		},
	},
	7: {
		topic: "Genetics, populations, evolution and ecosystems (A-level only)",
		topicQuestions: "",
		subs: {
			7.1: {
				"sub-topic":
					"Genetics, populations, evolution and ecosystems (A-level only)",
				spec: {
					7.11: {
						"sub-topic": "Inheritance (A-level only)",
						spec: {
							7.111:
								"The genotype is the genetic constitution of an organism. ",
							7.112:
								"The phenotype is the expression of this genetic constitution and its interaction with the environment. ",
							7.113: "There may be many alleles of a single gene. ",
							7.114: "Alleles may be dominant, recessive or codominant. ",
							7.115:
								"In a diploid organism, the alleles at a specific locus may be either homozygous or heterozygous. ",
							7.116:
								"The use of fully labelled genetic diagrams to interpret, or predict, the results of:\n • monohybrid and dihybrid crosses involving dominant, recessive and codominant alleles\n • crosses involving sex-linkage, autosomal linkage, multiple alleles and epistasis. ",
							7.117:
								"Use of the chi-squared ( X 2 ) test to compare the goodness of fit of observed phenotypic ratios with expected ratios.",
						},
					},
					7.12: {
						"sub-topic": "Populations (A-level only)",
						spec: {
							7.121: "Species exist as one or more populations.",
							7.122:
								"A population as a group of organisms of the same species occupying a particular space at a particular time that can potentially interbreed.",
							7.123: "The concepts of gene pool and allele frequency. ",
							7.124:
								"The Hardy–Weinberg principle provides a mathematical model, which predicts that allele frequencies will not change from generation to generation. The conditions under which the principle applies. ",
							7.125:
								"The frequency of alleles, genotypes and phenotypes in a population can be calculated using the Hardy–Weinberg equation: 3.7.2 where p is the frequency of one (usually the dominant) allele and q is the frequency of the other (usually recessive) allele of the gene.",
						},
					},
					7.13: {
						"sub-topic": "Evolution may lead to speciation (A-level only)",
						spec: {
							7.131:
								"Individuals within a population of a species may show a wide range of variation in phenotype. This is due to genetic and environmental factors. The primary source of genetic variation is mutation. Meiosis and the random fertilisation of gametes during sexual reproduction produce further genetic variation. ",
							7.132:
								"Predation, disease and competition for the means of survival result in differential survival and reproduction, ie natural selection. ",
							7.133:
								"Those organisms with phenotypes providing selective advantages are likely to produce more offspring and pass on their favourable alleles to the next generation. The effect of this differential reproductive success on the allele frequencies within a gene pool. ",
							7.134:
								"The effects of stabilising, directional and disruptive selection.",
							7.135:
								"Evolution as a change in the allele frequencies in a population. ",
							7.136:
								"Reproductive separation of two populations can result in the accumulation of difference in their gene pools. New species arise when these genetic differences lead to an inability of members of the populations to interbreed and produce fertile offspring. In this way, new species arise from existing species.",
							7.137: "Allopatric and sympatric speciation. ",
							7.138:
								"The importance of genetic drift in causing changes in allele frequency in small populations.",
							7.139:
								"Students should be able to:\n • explain why individuals within a population of a species may show a wide range of variation in phenotype\n • explain why genetic drift is important only in small populations\n • explain how natural selection and isolation may result in change in the allele and phenotype frequency and lead to the formation of a new species\n • explain how evolutionary change over a long period of time has resulted in a great diversity of species.",
						},
					},
					7.14: {
						"sub-topic": "Populations in ecosystems (A-level only)",
						spec: {
							7.141:
								"Populations of different species form a community. A community and the non-living components of its environment together form an ecosystem. Ecosystems can range in size from the very small to the very large.",
							7.142:
								"Within a habitat, a species occupies a niche governed by adaptation to both abiotic and biotic conditions.",
							7.143:
								"An ecosystem supports a certain size of population of a species, called the carrying capacity. This population size can vary as a result of:\n • the effect of abiotic factors\n • interactions between organisms: interspecific and intraspecific competition and predation. ",
							7.144:
								"The size of a population can be estimated using:\n • randomly placed quadrats, or quadrats along a belt transect, for slow-moving or non-motile organisms\n • the mark-release-recapture method for motile organisms. The assumptions made when using the mark-release-recapture method.",
							7.145: "Ecosystems are dynamic systems.",
							7.146:
								"Primary succession, from colonisation by pioneer species to climax community. ",
							7.147:
								"At each stage in succession, certain species may be recognised which change the environment so that it becomes more suitable for other species with different adaptations. The new species may change the environment in such a way that it becomes less suitable for the previous species.",
							7.148:
								"Changes that organisms produce in their abiotic environment can result in a less hostile environment and change biodiversity. ",
							7.149:
								"Conservation of habitats frequently involves management of succession. ",
							7.141:
								"Students should be able to:\n • show understanding of the need to manage the conflict between human needs and conservation in order to maintain the sustainability of natural resources\n • evaluate evidence and data concerning issues relating to the conservation of species and habitats and consider conflicting evidence\n • use given data to calculate the size of a population estimated using the mark-release-recapture method.",
						},
					},
				},
			},
		},
	},
	8: {
		topic: " The control of gene expression (A-level only)",
		topicQuestions: "",
		subs: {
			8.1: {
				"sub-topic":
					"Alteration of the sequence of bases in DNA can alter the structure of proteins (A-level only)",
				spec: {
					8.11: {
						"sub-topic":
							"Alteration of the sequence of bases in DNA can alter the structure of proteins (A-level only)",
						spec: {
							8.111:
								"Gene mutations might arise during DNA replication. They include addition, deletion, substitution, inversion, duplication and translocation of bases. ",
							8.112:
								"Gene mutations occur spontaneously. The mutation rate is increased by mutagenic agents. Mutations can result in a different amino acid sequence in the encoded polypeptide. • Some gene mutations change only one triplet code. Due to the degenerate nature of the genetic code, not all such mutations result in a change to the encoded amino acid.\n • Some gene mutations change the nature of all base triplets downstream from the mutation, ie result in a frame shift.",
							8.113:
								" Students should be able to relate the nature of a gene mutation to its effect on the encoded polypeptide.",
						},
					},
				},
			},
			8.2: {
				"sub-topic":
					"Gene expression is controlled by a number of features (A-level only)",
				spec: {
					8.21: {
						"sub-topic":
							"Most of a cell’s DNA is not translated (A-level only)",
						spec: {
							8.211:
								"Totipotent cells can divide and produce any type of body cell. ",
							8.212:
								"During development, totipotent cells translate only part of their DNA, resulting in cell specialisation. ",
							8.213:
								"Totipotent cells occur only for a limited time in early mammalian embryos. ",
							8.214:
								"Pluripotent cells are found in embryos; multipotent and unipotent cells are found in mature mammals and can divide to form a limited number of different cell types. • Pluripotent stem cells can divide in unlimited numbers and can be used in treating human disorders.\n • Unipotent cells, exemplified by the formation of cardiomyocytes.\n • Induced pluripotent stem cells (iPS cells) can be produced from adult somatic cells using appropriate protein transcription factors. ",
							8.215:
								"Students should be able to evaluate the use of stem cells in treating human disorders.",
						},
					},
					8.22: {
						"sub-topic":
							"Regulation of transcription and translation (A-level only)",
						spec: {
							8.221:
								"In eukaryotes, transcription of target genes can be stimulated or inhibited when specific transcriptional factors move from the cytoplasm into the nucleus. The role of the steroid hormone, oestrogen, in initiating transcription.",
							8.222: "Epigenetic control of gene expression in eukaryotes. ",
							8.223:
								"Epigenetics involves heritable changes in gene function, without changes to the base sequence of DNA. These changes are caused by changes in the environment that inhibit transcription by:\n • increased methylation of the DNA or\n • decreased acetylation of associated histones. ",
							8.224:
								"The relevance of epigenetics on the development and treatment of disease, especially cancer.",
							8.225:
								"In eukaryotes and some prokaryotes, translation of the mRNA produced from target genes can be inhibited by RNA interference (RNAi). ",
							8.226:
								"Students should be able to:\n • interpret data provided from investigations into gene expression\n • evaluate appropriate data for the relative influences of genetic and environmental factors on phenotype.",
						},
					},
					8.23: {
						"sub-topic": "Gene expression and cancer (A-level only)",
						spec: {
							8.231:
								"The main characteristics of benign and malignant tumours. ",
							8.232:
								"The role of the following in the development of tumours:\n • tumour suppressor genes and oncogenes\n • abnormal methylation of tumour suppressor genes and oncogenes\n • increased oestrogen concentrations in the development of some breast cancers. ",
							8.233:
								"Students should be able to:\n • evaluate evidence showing correlations between genetic and environmental factors and various forms of cancer\n • interpret information relating to the way in which an understanding of the roles of oncogenes and tumour suppressor genes could be used in the prevention, treatment and cure of cancer.",
						},
					},
					8.24: {
						"sub-topic": "Using genome projects (A-level only)",
						spec: {
							8.241:
								"Sequencing projects have read the genomes of a wide range of organisms, including humans. ",
							8.242:
								"Determining the genome of simpler organisms allows the sequences of the proteins that derive from the genetic code (the proteome) of the organism to be determined. This may have many applications, including the identification of potential antigens for use in vaccine production. ",
							8.243:
								"In more complex organisms, the presence of non-coding DNA and of regulatory genes means that knowledge of the genome cannot easily be translated into the proteome.",
							8.244:
								"Sequencing methods are continuously updated and have become automated.",
						},
					},
				},
			},
			8.3: {
				"sub-topic":
					"Gene technologies allow the study and alteration of gene function allowing a better understanding of organism function and the design of new industrial and medical processes (A-level only)",
				spec: {
					8.31: {
						"sub-topic": "Recombinant DNA technology (A-level only)",
						spec: {
							8.311:
								"Recombinant DNA technology involves the transfer of fragments of DNA from one organism, or species, to another. Since the genetic code is universal, as are transcription and translation mechanisms, the transferred DNA can be translated within cells of the recipient (transgenic) organism. ",
							8.312:
								"Fragments of DNA can be produced by several methods, including: • conversion of mRNA to complementary DNA (cDNA), using reverse transcriptase • using restriction enzymes to cut a fragment containing the desired gene from DNA • creating the gene in a ‘gene machine’. ",
							8.313:
								"Fragments of DNA can be amplified by in vitro and in vivo techniques.",
							8.314:
								"The principles of the polymerase chain reaction (PCR) as an in vitro method to amplify DNA fragments. ",
							8.315:
								"The culture of transformed host cells as an in vivo method to amplify DNA fragments. • The addition of promoter and terminator regions to the fragments of DNA.\n • The use of restriction endonucleases and ligases to insert fragments of DNA into vectors. Transformation of host cells using these vectors.\n • The use of marker genes to detect genetically modified (GM) cells or organisms. (Students will not be required to recall specific marker genes in a written paper.)",
							8.316:
								"Students should be able to:\n • interpret information relating to the use of recombinant DNA technology\n • evaluate the ethical, financial and social issues associated with the use and ownership of recombinant DNA technology in agriculture, in industry and in medicine\n • balance the humanitarian aspects of recombinant DNA technology with the opposition from environmentalists and anti-globalisation activists\n • relate recombinant DNA technology to gene therapy",
						},
					},
					8.32: {
						"sub-topic":
							"Differences in DNA between individuals of the same species can be exploited for identification and diagnosis of heritable conditions (A-level only)",
						spec: {
							8.321:
								"The use of labelled DNA probes and DNA hybridisation to locate specific alleles of genes. ",
							8.322:
								"The use of labelled DNA probes that can be used to screen patients for heritable conditions, drug responses or health risks. ",
							8.323:
								"The use of this information in genetic counselling and personalised medicine. ",
							8.324:
								"Students should be able to evaluate information relating to screening individuals for genetically determined conditions and drug responses.",
						},
					},
					8.33: {
						"sub-topic": "Genetic fingerprinting (A-level only)",
						spec: {
							8.331:
								"An organism’s genome contains many variable number tandem repeats (VNTRs). The probability of two individuals having the same VNTRs is very low. ",
							8.332:
								"The technique of genetic fingerprinting in analysing DNA fragments that have been cloned by PCR, and its use in determining genetic relationships and in determining the genetic variability within a population. ",
							8.333:
								"The use of genetic fingerprinting in the fields of forensic science, medical diagnosis, animal and plant breeding. ",
							8.334:
								"Students should be able to:\n • explain the biological principles that underpin genetic fingerprinting techniques\n • interpret data showing the results of gel electrophoresis to separate DNA fragments\n • explain why scientists might use genetic fingerprinting in the fields of forensic science, medical diagnosis, animal and plant breeding.",
						},
					},
				},
			},
		},
	},
};
