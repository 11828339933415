/** @format */

export const AlevelChemistryAQA = {
	1: {
		topic: "Physical chemistry",
		nested: true,
		topicQuestions: "",
		specLink: "",
		code: "AlevelChemistryAQA",
		name: "Alevel Chemistry AQA",
		subject: "Chemistry",
		board: "AQA",
		level: "Alevel",
		subs: {
			1.01: {
				"sub-topic": "Atomic structure",
				spec: {
					1.11: {
						"sub-topic": "Fundamental particles",
						spec: {
							1.111:
								"Appreciate that knowledge and understanding of atomic structure has evolved over time. ",
							1.112:
								"Protons, neutrons and electrons: relative charge and relative mass. ",
							1.113:
								"An atom consists of a nucleus containing protons and neutrons surrounded by electrons.",
						},
					},
					1.12: {
						"sub-topic": "Mass number and isotopes",
						spec: {
							1.121: "Mass number (A) and atomic (proton) number (Z). ",
							1.122:
								"Students should be able to:\n • determine the number of fundamental particles in atoms and ions using mass number, atomic number and charge\n • explain the existence of isotopes. ",
							1.123:
								"The principles of a simple time of flight (TOF) mass spectrometer, limited to ionisation, acceleration to give all ions constant kinetic energy, ion drift, ion detection, data analysis. ",
							1.124:
								"The mass spectrometer gives accurate information about relative isotopic mass and also about the relative abundance of isotopes. ",
							1.125: "Mass spectrometry can be used to identify elements. ",
							1.126:
								"Mass spectrometry can be used to determine relative molecular mass.",
							1.127:
								"Students should be able to:\n • interpret simple mass spectra of elements\n • calculate relative atomic mass from isotopic abundance, limited to mononuclear ions",
						},
					},
					1.13: {
						"sub-topic": "Electron configuration",
						spec: {
							1.131:
								"Electron configurations of atoms and ions up to Z = 36 in terms of shells and sub-shells (orbitals) s, p and d. ",
							1.132: "Ionisation energies. ",
							1.133:
								"Students should be able to:\n • define first ionisation energy\n • write equations for first and successive ionisation energies\n • explain how first and successive ionisation energies in Period 3 (Na–Ar) and in Group 2 (Be–Ba) give evidence for electron configuration in sub-shells and in shells.",
						},
					},
				},
			},
			1.2: {
				"sub-topic": "Amount of substance",
				spec: {
					1.21: {
						"sub-topic": "Relative atomic mass and relative molecular mass",
						spec: {
							1.211:
								"Relative atomic mass and relative molecular mass in terms of 12C. ",
							1.212:
								"The term relative formula mass will be used for ionic compounds. ",
							1.213:
								"Students should be able to:\n • define relative atomic mass (Ar )\n • define relative molecular mass (Mr )",
						},
					},
					1.22: {
						"sub-topic": "The mole and the Avogadro constant",
						spec: {
							1.221:
								"The Avogadro constant as the number of particles in a mole. ",
							1.222:
								"The mole as applied to electrons, atoms, molecules, ions, formulas and equations. ",
							1.223:
								"The concentration of a substance in solution, measured in mol dm–3 . ",
							1.224:
								"Students should be able to carry out calculations:\n • using the Avogadro constant\n • using mass of substance, Mr , and amount in moles\n • using concentration, volume and amount of substance in a solution.",
							1.225:
								" Students will not be expected to recall the value of the Avogadro constant.",
						},
					},
					1.23: {
						"sub-topic": "The ideal gas equation",
						spec: {
							1.231:
								"The ideal gas equation pV = nRT with the variables in SI units. ",
							1.232:
								"Students should be able to: • use the equation in calculations. ",
							1.233:
								"Students will not be expected to recall the value of the gas constant, R.",
						},
					},
					1.24: {
						"sub-topic": "Empirical and molecular formula",
						spec: {
							1.241:
								"Empirical formula is the simplest whole number ratio of atoms of each element in a compound. ",
							1.242:
								"Molecular formula is the actual number of atoms of each element in a compound.",
							1.243:
								" The relationship between empirical formula and molecular formula.",
							1.244:
								" Students should be able to:\n • calculate empirical formula from data giving composition by mass or percentage by mass\n • calculate molecular formula from the empirical formula and relative molecular mass.",
						},
					},
					1.25: {
						"sub-topic": "Balanced equations and associated calculations",
						spec: {
							1.251: "Equations (full and ionic). ",
							1.252: "Percentage atom economy is:",
							1.253:
								"Economic, ethical and environmental advantages for society and for industry of developing chemical processes with a high atom economy.",
							1.254:
								" Students should be able to:\n • write balanced equations for reactions studied\n • balance equations for unfamiliar reactions when reactants and products are specified.",
							1.255:
								" Students should be able to use balanced equations to calculate:\n • masses\n • volumes of gases\n • percentage yields\n • percentage atom economies\n • concentrations and volumes for reactions in solutions.",
						},
					},
				},
			},
			1.3: {
				"sub-topic": "Bonding",
				spec: {
					1.31: {
						"sub-topic": "Ionic bonding",
						spec: {
							1.311:
								"Ionic bonding involves electrostatic attraction between oppositely charged ions in a lattice. ",
							1.312:
								"The formulas of compound ions, eg sulfate, hydroxide, nitrate, carbonate and ammonium. ",
							1.313:
								"Students should be able to:\n • predict the charge on a simple ion using the position of the element in the Periodic Table\n • construct formulas for ionic compounds.",
						},
					},
					1.32: {
						"sub-topic": "Nature of covalent and dative covalent bonds",
						spec: {
							1.321:
								"A single covalent bond contains a shared pair of electrons.",
							1.322: " Multiple bonds contain multiple pairs of electrons.",
							1.323:
								"A co-ordinate (dative covalent) bond contains a shared pair of electrons with both electrons supplied by one atom. ",
							1.324:
								"Students should be able to represent:\n • a covalent bond using a line\n • a co-ordinate bond using an arrow.",
						},
					},
					1.33: {
						"sub-topic": "Metallic bonding",
						spec: {
							1.331:
								"Metallic bonding involves attraction between delocalised electrons and positive ions arranged in a lattice.",
						},
					},
					1.34: {
						"sub-topic": "Bonding and physical properties",
						spec: {
							1.341:
								"The four types of crystal structure:\n • ionic\n • metallic\n • macromolecular (giant covalent)\n • molecular. ",
							1.342:
								"The structures of the following crystals as examples of these four types of crystal structure:\n • diamond\n • graphite\n • ice\n • iodine\n • magnesium\n • sodium chloride. ",
							1.343:
								"Students should be able to:\n • relate the melting point and conductivity of materials to the type of structure and the bonding present\n • explain the energy changes associated with changes of state\n • draw diagrams to represent these structures involving specified numbers of particles.",
						},
					},
					1.35: {
						"sub-topic": "Shapes of simple molecules and ions",
						spec: {
							1.351:
								"Bonding pairs and lone (non-bonding) pairs of electrons as charge clouds that repel each other.",
							1.352:
								" Pairs of electrons in the outer shell of atoms arrange themselves as far apart as possible to minimise repulsion. ",
							1.353:
								"Lone pair–lone pair repulsion is greater than lone pair– bond pair repulsion, which is greater than bond pair–bond pair repulsion. ",
							1.354: "The effect of electron pair repulsion on bond angles. ",
							1.355:
								"Students should be able to:\n • explain the shapes of, and bond angles in, simple molecules and ions with up to six electron pairs (including lone pairs of electrons) surrounding the central atom.",
						},
					},
					1.36: {
						"sub-topic": "Bond polarity",
						spec: {
							1.361:
								"Electronegativity as the power of an atom to attract the pair of electrons in a covalent bond. ",
							1.362:
								"The electron distribution in a covalent bond between elements with different electronegativities will be unsymmetrical. This produces a polar covalent bond, and may cause a molecule to have a permanent dipole.",
							1.363:
								" Students should be able to:\n • use partial charges to show that a bond is polar\n • explain why some molecules with polar bonds do not have a permanent dipole.",
						},
					},
					1.37: {
						"sub-topic": "Forces between molecules",
						spec: {
							1.371:
								"Forces between molecules:\n • permanent dipole–dipole forces\n • induced dipole–dipole (van der Waals, dispersion, London) forces\n • hydrogen bonding.",
							1.372:
								" The melting and boiling points of molecular substances are influenced by the strength of these intermolecular forces. ",
							1.373:
								"The importance of hydrogen bonding in the low density of ice and the anomalous boiling points of compounds. ",
							1.374:
								"Students should be able to:\n • explain the existence of these forces between familiar and unfamiliar molecules\n • explain how melting and boiling points are influenced by these intermolecular forces.",
						},
					},
				},
			},
			1.4: {
				"sub-topic": "Energetics",
				spec: {
					1.41: {
						"sub-topic": "Enthalpy change",
						spec: {
							1.411: "Reactions can be endothermic or exothermic.",
							1.412:
								" Enthalpy change (∆H) is the heat energy change measured under conditions of constant pressure.",
							1.413:
								" Standard enthalpy changes refer to standard conditions, ie 100 kPa and a stated temperature (eg ∆H298Ɵ). ",
							1.414:
								"Students should be able to:\n • define standard enthalpy of combustion (∆cHƟ)\n • define standard enthalpy of formation (∆fHƟ).",
						},
					},
					1.42: {
						"sub-topic": "Calorimetry",
						spec: {
							1.421:
								"The heat change, q, in a reaction is given by the equation q = mc∆T\n where m is the mass of the substance that has a temperature change ∆T and a specific heat capacity c. ",
							1.422:
								"Students should be able to:\n • use this equation to calculate the molar enthalpy change for a reaction\n • use this equation in related calculations. ",
							1.423:
								"Students will not be expected to recall the value of the specific heat capacity, c, of a substance",
						},
					},
					1.43: {
						"sub-topic": "Applications of Hess’s law",
						spec: {
							1.431: "Hess’s law. ",
							1.432:
								"Students should be able to:\n • use Hess’s law to perform calculations, including calculation of enthalpy changes for reactions from enthalpies of combustion or from enthalpies of formation.",
						},
					},
					1.44: {
						"sub-topic": "Bond enthalpies",
						spec: {
							1.441: "Mean bond enthalpy. ",
							1.442:
								"Students should be able to:\n • define the term mean bond enthalpy\n • use mean bond enthalpies to calculate an approximate value of ∆H for reactions in the gaseous phase\n • explain why values from mean bond enthalpy calculations differ from those determined using Hess’s law.",
						},
					},
				},
			},
			1.5: {
				"sub-topic": "Kinetics",
				spec: {
					1.51: {
						"sub-topic": "Collision theory",
						spec: {
							1.511:
								"Reactions can only occur when collisions take place between particles having sufficient energy. ",
							1.512: "This energy is called the activation energy. ",
							1.513:
								"Students should be able to:\n • define the term activation energy\n • explain why most collisions do not lead to a reaction.",
						},
					},
					1.52: {
						"sub-topic": "Maxwell–Boltzmann distribution",
						spec: {
							1.521:
								"Maxwell–Boltzmann distribution of molecular energies in gases.",
							1.522:
								" Students should be able to:\n • draw and interpret distribution curves for different temperatures.",
						},
					},
					1.53: {
						"sub-topic": "Effect of temperature on reaction rate",
						spec: {
							1.531: "Meaning of the term rate of reaction. ",
							1.532:
								"The qualitative effect of temperature changes on the rate of reaction.",
							1.533:
								" Students should be able to:\n • use the Maxwell–Boltzmann distribution to explain why a small temperature increase can lead to a large increase in rate.",
						},
					},
					1.54: {
						"sub-topic": "Effect of concentration and pressure",
						spec: {
							1.541:
								"The qualitative effect of changes in concentration on collision frequency. ",
							1.542:
								"The qualitative effect of a change in the pressure of a gas on collision frequency.",
							1.543:
								" Students should be able to:\n • explain how a change in concentration or a change in pressure influences the rate of a reaction. ",
						},
					},
					1.55: {
						"sub-topic": "Catalysts",
						spec: {
							1.551:
								"A catalyst is a substance that increases the rate of a chemical reaction without being changed in chemical composition or amount. ",
							1.552:
								"Catalysts work by providing an alternative reaction route of lower activation energy. ",
							1.553:
								"Students should be able to:\n • use a Maxwell–Boltzmann distribution to help explain how a catalyst increases the rate of a reaction involving a gas.",
						},
					},
				},
			},
			1.6: {
				"sub-topic": "Chemical equilibria, Le Chatelier’s principle and Kc",
				spec: {
					1.61: {
						"sub-topic": "Chemical equilibria and Le Chatelier's principle",
						spec: {
							1.611: "Many chemical reactions are reversible.",
							1.612:
								" In a reversible reaction at equilibrium:\n • forward and reverse reactions proceed at equal rates\n • the concentrations of reactants and products remain constant",
							1.613: " Le Chatelier’s principle.",
							1.614:
								" Le Chatelier's principle can be used to predict the effects of changes in temperature, pressure and concentration on the position of equilibrium in homogeneous reactions.",
							1.615: "A catalyst does not affect the position of equilibrium. ",
							1.616:
								"Students should be able to:\n • use Le Chatelier’s principle to predict qualitatively the effect of changes in temperature, pressure and concentration on the position of equilibrium\n • explain why, for a reversible reaction used in an industrial process, a compromise temperature and pressure may be used.",
						},
					},
					1.62: {
						"sub-topic": "Equilibrium constant Kc for homogeneous systems",
						spec: {
							1.621:
								"The equilibrium constant Kc is deduced from the equation for a reversible reaction. ",
							1.622:
								"The concentration, in mol dm–3, of a species X involved in the expression for Kc is represented by [X] ",
							1.623:
								"The value of the equilibrium constant is not affected either by changes in concentration or addition of a catalyst.",
							1.624:
								" Students should be able to:\n • construct an expression for Kc for a homogeneous system in equilibrium\n • calculate a value for Kc from the equilibrium concentrations for a homogeneous system at constant temperature\n • perform calculations involving Kc\n • predict the qualitative effects of changes of temperature on the value of Kc",
						},
					},
				},
			},
			1.7: {
				"sub-topic": "Oxidation, reduction and redox equations",
				spec: {
					1.71: {
						"sub-topic": "Oxidation, reduction and redox equations",
						spec: {
							1.711:
								"Oxidation is the process of electron loss and oxidising agents are electron acceptors. ",
							1.712:
								"Reduction is the process of electron gain and reducing agents are electron donors. ",
							1.713: "The rules for assigning oxidation states. ",
							1.714:
								"Students should be able to:\n • work out the oxidation state of an element in a compound or ion from the formula\n • write half-equations identifying the oxidation and reduction processes in redox reactions\n • combine half-equations to give an overall redox equation.",
						},
					},
				},
			},
			1.8: {
				"sub-topic": "Thermodynamics (A-level only)",
				spec: {
					1.81: {
						"sub-topic": "Born–Haber cycles (A-level only)",
						spec: {
							1.811:
								"Lattice enthalpy can be defined as either enthalpy of lattice dissociation or enthalpy of lattice formation. ",
							1.812:
								"Born–Haber cycles are used to calculate lattice enthalpies using the following data:\n • enthalpy of formation\n • ionisation energy\n • enthalpy of atomisation\n • bond enthalpy\n • electron affinity. ",
							1.813:
								"Students should be able to:\n • define each of the above terms and lattice enthalpy\n • construct Born–Haber cycles to calculate lattice enthalpies using these enthalpy changes\n • construct Born–Haber cycles to calculate one of the other enthalpy changes\n • compare lattice enthalpies from Born–Haber cycles with those from calculations based on a perfect ionic model to provide evidence for covalent character in ionic compounds.",
							1.814:
								" Cycles are used to calculate enthalpies of solution for ionic compounds from lattice enthalpies and enthalpies of hydration.",
							1.815:
								" Students should be able to:\n • define the term enthalpy of hydration\n • perform calculations of an enthalpy change using these cycles.",
						},
					},
					1.82: {
						"sub-topic":
							"Gibbs free-energy change, ∆G, and entropy change, ∆S (A-level only)",
						spec: {
							1.821:
								"∆H, whilst important, is not sufficient to explain feasible change. ",
							1.822:
								"The concept of increasing disorder (entropy change, ∆S). ",
							1.823:
								"∆S accounts for the above deficiency, illustrated by physical changes and chemical changes. ",
							1.824:
								"The balance between entropy and enthalpy determines the feasibility of a reaction given by the relationship: \n ∆G = ∆H – T∆S (derivation not required). ",
							1.825:
								"For a reaction to be feasible, the value of ∆G must be zero or negative.",
							1.826:
								" Students should be able to:\n • calculate entropy changes from absolute entropy values\n • use the relationship ∆G = ∆H – T∆S to determine how ∆G varies with temperature\n • use the relationship ∆G = ∆H – T∆S to determine the temperature at which a reaction becomes feasible.",
						},
					},
				},
			},
			1.9: {
				"sub-topic": "Rate equations (A-level only)",
				spec: {
					1.91: {
						"sub-topic": "Rate equations (A-level only)",
						spec: {
							1.911:
								"The rate of a chemical reaction is related to the concentration of reactants by a rate equation of the form: Rate = k[A]m [B]n where m and n are the orders of reaction with respect to reactants A and B and k is the rate constant.",
							1.912:
								" The orders m and n are restricted to the values 0, 1, and 2. ",
							1.913:
								"The rate constant k varies with temperature as shown by the equation: k = Ae–Ea/RT where A is a constant, known as the Arrhenius constant, Ea is the activation energy and T is the temperature in K.",
							1.914:
								" Students should be able to:\n • define the terms order of reaction and rate constant\n • perform calculations using the rate equation\n • explain the qualitative effect of changes in temperature on the rate constant k\n • perform calculations using the equation k = Ae–Ea/RT\n • understand that the equation k = Ae–Ea/RT can be rearranged into the form ln k = –Ea /RT + ln A and know how to use this rearranged equation with experimental data to plot a straight line graph with slope –Ea/R These equations and the gas constant, R, will be given when required.",
						},
					},
					1.92: {
						"sub-topic": "Determination of rate equation (A-level only)",
						spec: {
							1.921:
								"The rate equation is an experimentally determined relationship.",
							1.922:
								" The orders with respect to reactants can provide information about the mechanism of a reaction. ",
							1.923:
								"Students should be able to:\n • use concentration–time graphs to deduce the rate of a reaction\n • use initial concentration–time data to deduce the initial rate of a reaction\n • use rate–concentration data or graphs to deduce the order (0, 1 or 2) with respect to a reactant\n • derive the rate equation for a reaction from the orders with respect to each of the reactants\n • use the orders with respect to reactants to provide information about the rate determining/limiting step of a reaction.",
						},
					},
				},
			},
			1.11: {
				"sub-topic":
					"Electrode potentials and electrochemical cells (A-level only)",
				spec: {
					1.111: {
						"sub-topic": "Electrode potentials and cells (A-level only)",
						spec: {
							1.1111:
								"IUPAC convention for writing half-equations for electrode reactions. ",
							1.1112: "The conventional representation of cells. ",
							1.1113:
								"Cells are used to measure electrode potentials by reference to the standard hydrogen electrode. ",
							1.1114:
								"The importance of the conditions when measuring the electrode potential, E (Nernst equation not required). ",
							1.1115:
								"Standard electrode potential, EƟ, refers to conditions of 298 K, 100 kPa and 1.00 mol dm−3 solution of ions. ",
							1.1116:
								"Standard electrode potentials can be listed as an electrochemical series.",
							1.1117:
								" Students should be able to:\n • use EƟ values to predict the direction of simple redox reactions\n • calculate the EMF of a cell\n • write and apply the conventional representation of a cell.",
						},
					},
					1.112: {
						"sub-topic":
							"Commercial applications of electrochemical cells (A-level only)",
						spec: {
							1.1121:
								"Electrochemical cells can be used as a commercial source of electrical energy. ",
							1.1122:
								"The  simplified electrode reactions in a lithium cell: Positive electrode: \n Li+ + CoO2 + e– → Li+ [CoO2] – \n Negative electrode: Li → Li+ + e– ",
							1.1123:
								"Cells can be non-rechargeable (irreversible), rechargeable or fuel cells. ",
							1.1124:
								"Fuel cells are used to generate an electric current and do not need to be electrically recharged.",
							1.1125:
								" The electrode reactions in an alkaline hydrogen–oxygen fuel cell.",
							1.1126:
								"The benefits and risks to society associated with using these cells. ",
							1.1127:
								"Students should be able to:\n • use given electrode data to deduce the reactions occurring in non-rechargeable and rechargeable cells\n • deduce the EMF of a cell\n • explain how the electrode reactions can be used to generate an electric current.",
						},
					},
				},
			},
			1.12: {
				"sub-topic": "Acids and bases (A-level only)",
				spec: {
					1.121: {
						"sub-topic":
							"Brønsted–Lowry acid–base equilibria in aqueous solution (A-level only)",
						spec: {
							1.1211: "An acid is a proton donor.",
							1.1211: " A base is a proton acceptor.",
							1.1211: " Acid–base equilibria involve the transfer of protons.",
						},
					},
					1.122: {
						"sub-topic": "Definition and determination of pH (A-level only)",
						spec: {
							1.1221:
								"The concentration of hydrogen ions in aqueous solution covers a very wide range. Therefore, a logarithmic scale, the pH scale, is used as a measure of hydrogen ion concentration.",
							1.1222: " pH = –log10[H+ ] ",
							1.1223:
								"Students should be able to:\n • convert concentration of hydrogen ions into pH and vice versa\n • calculate the pH of a solution of a strong acid from its concentration.",
						},
					},
					1.123: {
						"sub-topic": "The ionic product of water, Kw (A-level only)",
						spec: {
							1.1231: "Water is slightly dissociated. ",
							1.1232:
								"Kw is derived from the equilibrium constant for this dissociation.",
							1.1233: " Kw = [H+ ][OH– ] ",
							1.1234: "The value of Kw varies with temperature. ",
							1.1235:
								"Students should be able to:\n • use Kw to calculate the pH of a strong base from its concentration.",
						},
					},
					1.124: {
						"sub-topic":
							"Weak acids and bases Ka for weak acids (A-level only)",
						spec: {
							1.1241:
								"Weak acids and weak bases dissociate only slightly in aqueous solution.",
							1.1242: " Ka is the dissociation constant for a weak acid.",
							1.1243: " pKa = –log10 Ka ",
							1.1244:
								"Students should be able to:\n • construct an expression for Ka\n • perform calculations relating the pH of a weak acid to the concentration of the acid and the dissociation constant, Ka\n • convert Ka into pKa and vice versa.",
						},
					},
					1.125: {
						"sub-topic": "pH curves, titrations and indicators (A-level only)",
						spec: {
							1.1251: "Titrations of acids with bases. ",
							1.1252:
								"Students should be able to:\n • perform calculations for these titrations based on experimental results. ",
							1.1253:
								"Typical pH curves for acid–base titrations in all combinations of weak and strong monoprotic acids and bases. ",
							1.1254:
								"Students should be able to:\n • sketch and explain the shapes of typical pH curves\n • use pH curves to select an appropriate indicator.",
						},
					},
					1.126: {
						"sub-topic": "Buffer action (A-level only)",
						spec: {
							1.1261:
								"A buffer solution maintains an approximately constant pH, despite dilution or addition of small amounts of acid or base.",
							1.1262:
								" Acidic buffer solutions contain a weak acid and the salt of that weak acid. ",
							1.1263:
								"Basic buffer solutions contain a weak base and the salt of that weak base.",
							1.1264: "Applications of buffer solutions. ",
							1.1265:
								"Students should be able to:\n • explain qualitatively the action of acidic and basic buffers\n • calculate the pH of acidic buffer solutions.",
						},
					},
				},
			},
			1.13: {
				"sub-topic":
					"Equilibrium constant Kp for homogeneous systems (A-level only)",
				spec: {
					1.131: {
						"sub-topic":
							"Equilibrium constant Kp for homogeneous systems (A-level only)",
						spec: {
							1.1311:
								"The equilibrium constant Kp is deduced from the equation for a reversible reaction occurring in the gas phase. ",
							1.1312:
								"Kp is the equilibrium constant calculated from partial pressures for a system at constant temperature. ",
							1.1313:
								"Students should be able to:\n • derive partial pressure from mole fraction and total pressure\n • construct an expression for Kp for a homogeneous system in equilibrium\n • perform calculations involving Kp\n • predict the qualitative effects of changes in temperature and pressure on the position of equilibrium\n • predict the qualitative effects of changes in temperature on the value of Kp\n • understand that, whilst a catalyst can affect the rate of attainment of an equilibrium, it does not affect the value of the equilibrium constant.",
						},
					},
				},
			},
		},
	},
	2: {
		topic: "Inorganic chemistry",
		topicQuestions: "",
		subs: {
			2.1: {
				"sub-topic": "Periodicity",
				spec: {
					2.11: {
						"sub-topic": "Classification",
						spec: {
							2.111:
								"An element is classified as s, p, d or f block according to its position in the Periodic Table, which is determined by its proton number.",
						},
					},
					2.12: {
						"sub-topic": "Physical properties of Period 3 elements",
						spec: {
							2.121:
								"The trends in atomic radius, first ionisation energy and melting point of the elements Na–Ar",
							2.122:
								" The reasons for these trends in terms of the structure of and bonding in the elements.",
							2.123:
								" Students should be able to:\n • explain the trends in atomic radius and first ionisation energy\n • explain the melting point of the elements in terms of their structure and bonding",
						},
					},
				},
			},
			2.2: {
				"sub-topic": "Group 2, the alkaline earth metals",
				spec: {
					2.21: {
						"sub-topic": "Group 2, the alkaline earth metals",
						spec: {
							2.211:
								"The trends in atomic radius, first ionisation energy and melting point of the elements Mg–Ba ",
							2.212:
								"Students should be able to:\n • explain the trends in atomic radius and first ionisation energy\n • explain the melting point of the elements in terms of their structure and bonding.",
							2.213: "The reactions of the elements Mg–Ba with water. ",
							2.214:
								"The use of magnesium in the extraction of titanium from TiCl4 ",
							2.215:
								"The relative solubilities of the hydroxides of the elements Mg–Ba in water.",
							2.216: " Mg(OH)2 is sparingly soluble.  ",
							2.217:
								"The use of Mg(OH)2 in medicine and of Ca(OH)2 in agriculture.",
							2.218: "The use of CaO or CaCO3 to remove SO2 from flue gases. ",
							2.219:
								"The relative solubilities of the sulfates of the elements Mg–Ba in water.",
							2.211: " BaSO4 is insoluble. ",
							2.2111:
								"The use of acidified BaCl2 solution to test for sulfate ions.",
							2.2112: " The use of BaSO4 in medicine. ",
							2.2113:
								"Students should be able to:\n • explain why BaCl2 solution is used to test for sulfate ions and why it is acidified.",
						},
					},
				},
			},
			2.3: {
				"sub-topic": "Group 7(17), the halogens",
				spec: {
					2.31: {
						"sub-topic": "Trends in properties",
						spec: {
							2.311:
								"The trends in electronegativity and boiling point of the halogens.",
							2.312:
								"Students should be able to:\n • explain the trend in electronegativity\n • explain the trend in the boiling point of the elements in terms of their structure and bonding.",
							2.313:
								" The trend in oxidising ability of the halogens down the group, including displacement reactions of halide ions in aqueous solution. ",
							2.314:
								"The trend in reducing ability of the halide ions, including the reactions of solid sodium halides with concentrated sulfuric acid. ",
							2.315:
								"The use of acidified silver nitrate solution to identify and distinguish between halide ions.",
							2.316:
								" The trend in solubility of the silver halides in ammonia. ",
							2.317:
								"Students should be able to explain why:\n • silver nitrate solution is used to identify halide ions\n • the silver nitrate solution is acidified\n • ammonia solution is added.",
						},
					},
					2.32: {
						"sub-topic": "Uses of chlorine and chlorate(I)",
						spec: {
							2.321:
								"The reaction of chlorine with water to form chloride ions and chlorate(I) ions. ",
							2.322:
								"The reaction of chlorine with water to form chloride ions and oxygen. ",
							2.323:
								"Appreciate that society assesses the advantages and disadvantages when deciding if chemicals should be added to water supplies. ",
							2.324: "The use of chlorine in water treatment.",
							2.325:
								" Appreciate that the benefits to health of water treatment by chlorine outweigh its toxic effects. ",
							2.326:
								"The reaction of chlorine with cold, dilute, aqueous NaOH and uses of the solution formed.",
						},
					},
				},
			},
			2.4: {
				"sub-topic":
					"Properties of Period 3 elements and their oxides (A-level only)",
				spec: {
					2.41: {
						"sub-topic":
							"Properties of Period 3 elements and their oxides (A-level only)",
						spec: {
							2.411: "The reactions of Na and Mg with water. ",
							2.412:
								"The trends in the reactions of the elements Na, Mg, Al, Si, P and S with oxygen, limited to the formation of Na2O, MgO, Al2O3, SiO2, P4O10, SO2 and SO3",
							2.413:
								" The trend in the melting point of the highest oxides of the elements Na–S ",
							2.414:
								"The reactions of the oxides of the elements Na–S with water, limited to Na2O, MgO, Al2O3, SiO2, P4O10, SO2 and SO3, and the pH of the solutions formed",
							2.415:
								"The structures of the acids and the anions formed when P4O10, SO2 and SO3 react with water.",
							2.416:
								" Students should be able to:\n • explain the trend in the melting point of the oxides of the elements Na–S in terms of their structure and bonding\n • explain the trends in the reactions of the oxides with water in terms of the type of bonding present in each oxide\n • write equations for the reactions that occur between the oxides of the elements Na–S and given acids and bases.",
						},
					},
				},
			},
			2.5: {
				"sub-topic": "Transition metals (A-level only)",
				spec: {
					2.51: {
						"sub-topic":
							"General properties of transition metals (A-level only)",
						spec: {
							2.511:
								"Transition metal characteristics of elements Ti–Cu arise from an incomplete d sub-level in atoms or ions.",
							2.512:
								"The characteristic properties include:\n • complex formation\n • formation of coloured ions\n • variable oxidation state\n • catalytic activity. ",
							2.513:
								"A ligand is a molecule or ion that forms a co-ordinate bond with a transition metal by donating a pair of electrons.",
							2.514:
								"A complex is a central metal atom or ion surrounded by ligands. ",
							2.515:
								"Co-ordination number is number of co-ordinate bonds to the central metal atom or ion.",
						},
					},
					2.52: {
						"sub-topic": "Substitution reactions (A-level only)",
						spec: {
							2.521: "H2O, NH3 and Cl− can act as monodentate ligands. ",
							2.522:
								"The ligands NH3 and H2O are similar in size and are uncharged. ",
							2.523:
								"Exchange of the ligands NH3 and H2O occurs without change of co-ordination number (eg Co2+ and Cu2+).",
							2.524:
								"Substitution may be incomplete (eg the formation of [Cu(NH3)4(H2O)2] 2+). ",
							2.525:
								"The Cl− ligand is larger than the uncharged ligands NH3 and H2O",
							2.526:
								" Exchange of the ligand H2O by Cl– can involve a change of co-ordination number (eg Co2+, Cu2+ and Fe3+). ",
							2.527: "Ligands can be bidentate (eg H2NCH2CH2NH2 and C2O4 2–). ",
							2.528: "Ligands can be multidentate (eg EDTA4–). ",
							2.529: "Haem is an iron(II) complex with a multidentate ligand. ",
							2.521:
								"Oxygen forms a co-ordinate bond to Fe(II) in haemoglobin, enabling oxygen to be transported in the blood.",
							2.5211:
								" Carbon monoxide is toxic because it replaces oxygen coordinately bonded to Fe(II) in haemoglobin.",
							2.5212:
								" Bidentate and multidentate ligands replace monodentate ligands from complexes. This is called the chelate effect. ",
							2.5213:
								"Students should be able to:\n • explain the chelate effect, in terms of the balance between the entropy and enthalpy change in these reactions.",
						},
					},
					2.53: {
						"sub-topic": "Shapes of complex ions (A-level only)",
						spec: {
							2.531:
								"Transition metal ions commonly form octahedral complexes with small ligands (eg H2O and NH3). ",
							2.532:
								"Octahedral complexes can display cis–trans isomerism (a special case of E–Z isomerism) with monodentate ligands and optical isomerism with bidentate ligands.",
							2.533:
								" Transition metal ions commonly form tetrahedral complexes with larger ligands (eg Cl– ). ",
							2.534:
								"Square planar complexes are also formed and can display cis–trans isomerism. ",
							2.535: "Cisplatin is the cis isomer.",
							2.536:
								" Ag+ forms the linear complex [Ag(NH3)2] + as used in Tollens’ reagent.",
						},
					},
					2.54: {
						"sub-topic": "Formation of coloured ions (A-level only)",
						spec: {
							2.541:
								"Transition metal ions can be identified by their colour. ",
							2.542:
								"Colour arises when some of the wavelengths of visible light are absorbed and the remaining wavelengths of light are transmitted or reflected.",
							2.543:
								"d electrons move from the ground state to an excited state when light is absorbed.",
							2.544:
								"The energy difference between the ground state and the excited state of the d electrons is given by:\n  ∆E = hν = hc/λ ",
							2.545:
								"Changes in oxidation state, co-ordination number and ligand alter ∆E and this leads to a change in colour.",
							2.546:
								" The absorption of visible light is used in spectroscopy.",
							2.547:
								" A simple colorimeter can be used to determine the concentration of coloured ions in solution.",
						},
					},
					2.55: {
						"sub-topic": "Variable oxidation states (A-level only)",
						spec: {
							2.551: "Transition elements show variable oxidation states. ",
							2.552:
								"Vanadium species in oxidation states IV, III and II are formed by the reduction of vanadate(V) ions by zinc in acidic solution.",
							2.553:
								" The redox potential for a transition metal ion changing from a higher to a lower oxidation state is influenced by pH and by the ligand. ",
							2.554:
								"The reduction of [Ag(NH3)2] + (Tollens’ reagent) to metallic silver is used to distinguish between aldehydes and ketones. ",
							2.555:
								"Students should be able to:\n • perform calculations for these titrations and similar redox reactions.",
						},
					},
					2.56: {
						"sub-topic": "Catalysts (A-level only)",
						spec: {
							2.561:
								"Transition metals and their compounds can act as heterogeneous and homogeneous catalysts. ",
							2.562:
								"A heterogeneous catalyst is in a different phase from the reactants and the reaction occurs at active sites on the surface. ",
							2.563:
								"The use of a support medium to maximise the surface area of a heterogeneous catalyst and minimise the cost. ",
							2.565:
								"V2O5 acts as a heterogeneous catalyst in the Contact process.",
							2.566:
								" Fe is used as a heterogeneous catalyst in the Haber process.",
							2.567:
								" Heterogeneous catalysts can become poisoned by impurities that block the active sites and consequently have reduced efficiency; this has a cost implication.",
							2.568:
								"A homogeneous catalyst is in the same phase as the reactants. ",
							2.569:
								"When catalysts and reactants are in the same phase, the reaction proceeds through an intermediate species.",
							2.561:
								" Students should be able to:\n • explain the importance of variable oxidation states in catalysis\n • explain, with the aid of equations, how V2O5 acts as a catalyst in the Contact process\n • explain, with the aid of equations, how Fe2+ ions catalyse the reaction between I− and S2O8 2–\n • explain, with the aid of equations, how Mn2+ ions autocatalyse the reaction between C2O4 2– and MnO4 –",
						},
					},
				},
			},
			2.6: {
				"sub-topic": "Reactions of ions in aqueous solution (A-level only)",
				spec: {
					2.61: {
						"sub-topic": "Reactions of ions in aqueous solution (A-level only)",
						spec: {
							2.611:
								"In aqueous solution, the following metal-aqua ions are formed: \n [M(H2O)6] 2+, limited to M = Fe and Cu \n [M(H2O)6] 3+, limited to M = Al and Fe ",
							2.612:
								"The acidity of [M(H2O)6] 3+ is greater than that of [M(H2O)6] 2+ ",
							2.613:
								"Some metal hydroxides show amphoteric character by dissolving in both acids and bases (eg hydroxides of Al3+). ",
							2.614:
								"Students should be able to:\n • explain, in terms of the charge/size ratio of the metal ion, why the acidity of [M(H2O)6] 3+ is greater than that of [M(H2O)6] 2+\n • describe and explain the simple test-tube reactions of: M2+(aq) ions, limited to M = Fe and Cu, and of M3+(aq) ions, limited to M = Al and Fe, with the bases OH– , NH3 and CO3 2–",
						},
					},
				},
			},
		},
	},
	3: {
		topic: "Organic chemistry",
		topicQuestions: "",
		subs: {
			3.1: {
				"sub-topic": "Introduction to organic chemistry",
				spec: {
					3.11: {
						"sub-topic": "Nomenclature",
						spec: {
							3.111:
								"Organic compounds can be represented by:\n • empirical formula\n • molecular formula\n • general formula\n • structural formula\n • displayed formula\n • skeletal formula. ",
							3.112:
								"The characteristics of a homologous series, a series of compounds containing the same functional group. ",
							3.113: "IUPAC rules for nomenclature.",
							3.114:
								" Students should be able to:\n • draw structural, displayed and skeletal formulas for given organic compounds\n • apply IUPAC rules for nomenclature to name organic compounds limited to chains and rings with up to six carbon atoms each\n • apply IUPAC rules for nomenclature to draw the structure of an organic compound from the IUPAC name limited to chains and rings with up to six carbon atoms each.",
						},
					},
					3.12: {
						"sub-topic": "Reaction mechanisms",
						spec: {
							3.121:
								"Reactions of organic compounds can be explained using mechanisms.",
							3.122:
								"Free-radical mechanisms:\n • the unpaired electron in a radical is represented by a dot\n • the use of curly arrows is not required for radical mechanisms.",
							3.123:
								" Students should be able to:\n • write balanced equations for the steps in a free radical mechanism. ",
							3.124:
								"Other mechanisms:\n • the formation of a covalent bond is shown by a curly arrow that starts from a lone electron pair or from another covalent bond\n • the breaking of a covalent bond is shown by a curly arrow starting from the bond.",
							3.125:
								" Students should be able to:\n • outline mechanisms by drawing the structures of the species involved and curly arrows to represent the movement of electron pairs.",
						},
					},
					3.13: {
						"sub-topic": "Isomerism",
						spec: {
							3.131: "Structural isomerism. ",
							3.132: "Stereoisomerism.",
							3.133:
								"E–Z isomerism is a form of stereoisomerism and occurs as a result of restricted rotation about the planar carbon– carbon double bond. ",
							3.134: "Cahn–Ingold–Prelog (CIP) priority rules. ",
							3.135:
								"Students should be able to:\n • define the term structural isomer\n • draw the structures of chain, position and functional group isomers\n • define the term stereoisomer\n • draw the structural formulas of E and Z isomers\n • apply the CIP priority rules to E and Z isomers.",
						},
					},
				},
			},
			3.2: {
				"sub-topic": "Alkanes",
				spec: {
					3.21: {
						"sub-topic": "Fractional distillation of crude oil",
						spec: {
							3.211: "Alkanes are saturated hydrocarbons. ",
							3.212:
								"Petroleum is a mixture consisting mainly of alkane hydrocarbons that can be separated by fractional distillation.",
						},
					},
					3.22: {
						"sub-topic": "Modification of alkanes by cracking",
						spec: {
							3.221: "Cracking involves breaking C–C bonds in alkanes. ",
							3.222:
								"Thermal cracking takes place at high pressure and high temperature and produces a high percentage of alkenes (mechanism not required). ",
							3.223:
								"Catalytic cracking takes place at a slight pressure, high temperature and in the presence of a zeolite catalyst and is used mainly to produce motor fuels and aromatic hydrocarbons (mechanism not required).",
							3.224:
								"Students should be able to:\n • explain the economic reasons for cracking alkanes.",
						},
					},
					3.23: {
						"sub-topic": "Combustion of alkanes",
						spec: {
							3.231: "Alkanes are used as fuels. ",
							3.232:
								"Combustion of alkanes and other organic compounds can be complete or incomplete.",
							3.233:
								"The internal combustion engine produces a number of pollutants including NOx , CO, carbon and unburned hydrocarbons. ",
							3.234:
								"These gaseous pollutants from internal combustion engines can be removed using catalytic converters.",
							3.235:
								"Combustion of hydrocarbons containing sulfur leads to sulfur dioxide that causes air pollution.",
							3.236:
								"Students should be able to:\n • explain why sulfur dioxide can be removed from flue gases using calcium oxide or calcium carbonate.",
						},
					},
					3.24: {
						"sub-topic": "Chlorination of alkanes",
						spec: {
							3.241: "The reaction of methane with chlorine. ",
							3.242:
								"Students should be able to:\n • explain this reaction as a free-radical substitution mechanism involving initiation, propagation and termination steps.",
						},
					},
				},
			},
			3.3: {
				"sub-topic": "Halogenoalkanes",
				spec: {
					3.31: {
						"sub-topic": "Nucleophilic substitution",
						spec: {
							3.311: "Halogenoalkanes contain polar bonds. ",
							3.312:
								"Halogenoalkanes undergo substitution reactions with the nucleophiles OH– , CN– and NH3 ",
							3.313:
								"Students should be able to:\n • outline the nucleophilic substitution mechanisms of these reactions\n • explain why the carbon–halogen bond enthalpy influences the rate of reaction. ",
						},
					},
					3.32: {
						"sub-topic": "Elimination",
						spec: {
							3.321:
								"The concurrent substitution and elimination reactions of a halogenoalkane (eg 2-bromopropane with potassium hydroxide).",
							3.322:
								"Students should be able to:\n • explain the role of the reagent as both nucleophile and base\n • outline the mechanisms of these reactions.",
						},
					},
					3.33: {
						"sub-topic": "Ozone depletion",
						spec: {
							3.331:
								"Ozone, formed naturally in the upper atmosphere, is beneficial because it absorbs ultraviolet radiation.",
							3.332:
								"Chlorine atoms are formed in the upper atmosphere when ultraviolet radiation causes C–Cl bonds in chlorofluorocarbons (CFCs) to break.",
							3.333:
								"Chlorine atoms catalyse the decomposition of ozone and contribute to the hole in the ozone layer. ",
							3.334:
								"Appreciate that results of research by different groups in the scientific community provided evidence for legislation to ban the use of CFCs as solvents and refrigerants. Chemists have now developed alternative chlorine-free compounds.",
							3.335:
								"Students should be able to:\n • use equations, such as the following, to explain how chlorine atoms catalyse decomposition of ozone:\n 3.3.3.3 equation ",
						},
					},
				},
			},
			3.4: {
				"sub-topic": "Alkenes",
				spec: {
					3.41: {
						"sub-topic": "Structure, bonding and reactivity",
						spec: {
							3.411: "Alkenes are unsaturated hydrocarbons. ",
							3.412:
								"Bonding in alkenes involves a double covalent bond, a centre of high electron density. ",
						},
					},
					3.42: {
						"sub-topic": "Addition reactions of alkenes",
						spec: {
							3.421:
								"Electrophilic addition reactions of alkenes with HBr, H2SO4 and Br2 ",
							3.422: "The use of bromine to test for unsaturation.",
							3.423:
								"The formation of major and minor products in addition reactions of unsymmetrical alkenes.",
							3.424:
								"Students should be able to:\n • outline the mechanisms for these reactions\n • explain the formation of major and minor products by reference to the relative stabilities of primary, secondary and tertiary carbocation intermediates.",
						},
					},
					3.43: {
						"sub-topic": "Addition polymers",
						spec: {
							3.431:
								"Addition polymers are formed from alkenes and substituted alkenes. ",
							3.432: "The repeating unit of addition polymers.",
							3.433: "IUPAC rules for naming addition polymers.",
							3.434: "Addition polymers are unreactive.",
							3.435:
								"Appreciate that knowledge and understanding of the production and properties of polymers has developed over time. ",
							3.436:
								"Typical uses of poly(chloroethene), commonly known as PVC, and how its properties can be modified using a plasticiser. ",
							3.437:
								"Students should be able to:\n • draw the repeating unit from a monomer structure\n • draw the repeating unit from a section of the polymer chain\n • draw the structure of the monomer from a section of the polymer\n • explain why addition polymers are unreactive\n • explain the nature of intermolecular forces between molecules of polyalkenes.",
						},
					},
				},
			},
			3.5: {
				"sub-topic": "Alcohols",
				spec: {
					3.51: {
						"sub-topic": "Alcohol production",
						spec: {
							3.511:
								"Alcohols are produced industrially by hydration of alkenes in the presence of an acid catalyst. ",
							3.512:
								"Ethanol is produced industrially by fermentation of glucose. The conditions for this process. ",
							3.513:
								"Ethanol produced industrially by fermentation is separated by fractional distillation and can then be used as a biofuel. ",
							3.514:
								"Students should be able to:\n • explain the meaning of the term biofuel\n • justify the conditions used in the production of ethanol by fermentation of glucose\n • write equations to support the statement that ethanol produced by fermentation is a carbon neutral fuel and give reasons why this statement is not valid\n • outline the mechanism for the formation of an alcohol by the reaction of an alkene with steam in the presence of an acid catalyst\n • discuss the environmental (including ethical) issues linked to decision making about biofuel use.",
						},
					},
					3.52: {
						"sub-topic": "Oxidation of alcohols",
						spec: {
							3.521:
								"Alcohols are classified as primary, secondary and tertiary. ",
							3.521:
								"Primary alcohols can be oxidised to aldehydes which can be further oxidised to carboxylic acids. ",
							3.521: "Secondary alcohols can be oxidised to ketones. ",
							3.521: "Tertiary alcohols are not easily oxidised. ",
							3.521:
								"Acidified potassium dichromate(VI) is a suitable oxidising agent. ",
							3.521:
								"Students should be able to:\n • write equations for these oxidation reactions (equations showing [O] as oxidant are acceptable)\n • explain how the method used to oxidise a primary alcohol determines whether an aldehyde or carboxylic acid is obtained\n • use chemical tests to distinguish between aldehydes and ketones including Fehling’s solution and Tollens’ reagent.",
						},
					},
					3.53: {
						"sub-topic": "Elimination",
						spec: {
							3.531:
								"Alkenes can be formed from alcohols by acid-catalysed elimination reactions. ",
							3.532:
								"Alkenes produced by this method can be used to produce addition polymers without using monomers derived from crude oil. ",
							3.533:
								"Students should be able to:\n • outline the mechanism for the elimination of water from alcohols.",
						},
					},
				},
			},
			3.6: {
				"sub-topic": "Organic analysis",
				spec: {
					3.61: {
						"sub-topic":
							"Identification of functional groups by test-tube reactions",
						spec: {
							3.611:
								"The reactions of functional groups listed in the specification.",
							3.612:
								"Students should be able to:\n • identify the functional groups using reactions in the specification ",
						},
					},
					3.62: {
						"sub-topic": "Mass spectrometry",
						spec: {
							3.621:
								"Mass spectrometry can be used to determine the molecular formula of a compound.",
							3.622:
								"Students should be able to:\n • use precise atomic masses and the precise molecular mass to determine the molecular formula of a compound. ",
						},
					},
					3.63: {
						"sub-topic": "Infrared spectroscopy",
						spec: {
							3.631:
								"Bonds in a molecule absorb infrared radiation at characteristic wavenumbers.",
							3.632:
								"‘Fingerprinting’ allows identification of a molecule by comparison of spectra. ",
							3.633:
								"Students should be able to:\n • use infrared spectra and the Chemistry Data Sheet or Booklet to identify particular bonds, and therefore functional groups, and also to identify impurities. ",
							3.634:
								"The link between absorption of infrared radiation by bonds in CO2, methane and water vapour and global warming.",
						},
					},
				},
			},
			3.7: {
				"sub-topic": "Optical isomerism (A-level only)",
				spec: {
					3.71: {
						"sub-topic": "Optical isomerism (A-level only)",
						spec: {
							3.711:
								"Optical isomerism is a form of stereoisomerism and occurs as a result of chirality in molecules, limited to molecules with a single chiral centre. ",
							3.712:
								"An asymmetric carbon atom is chiral and gives rise to optical isomers (enantiomers), which exist as non super-imposable mirror images and differ in their effect on plane polarised light. ",
							3.713:
								"A mixture of equal amounts of enantiomers is called a racemic mixture (racemate). ",
							3.714:
								"Students should be able to:\n • draw the structural formulas and displayed formulas of enantiomers\n • understand how racemic mixtures (racemates) are formed and why they are optically inactive.",
						},
					},
				},
			},
			3.8: {
				"sub-topic": "Aldehydes and ketones (A-level only)",
				spec: {
					3.81: {
						"sub-topic": "Aldehydes and ketones (A-level only)",
						spec: {
							3.811: "Aldehydes are readily oxidised to carboxylic acids. ",
							3.812:
								"Chemical tests to distinguish between aldehydes and ketones including Fehling’s solution and Tollens’ reagent. ",
							3.813:
								"Aldehydes can be reduced to primary alcohols, and ketones to secondary alcohols, using NaBH4 in aqueous solution.These reduction reactions are examples of nucleophilic addition.  ",
							3.814:
								"The nucleophilic addition reactions of carbonyl compounds with KCN, followed by dilute acid, to produce hydroxynitriles.",
							3.815:
								"Aldehydes and unsymmetrical ketones form mixtures of enantiomers when they react with KCN followed by dilute acid.",
							3.816: "The hazards of using KCN. ",
							3.817:
								"Students should be able to:\n • write overall equations for reduction reactions using [H] as the reductant\n • outline the nucleophilic addition mechanism for reduction reactions with NaBH4 (the nucleophile should be shown as H– )\n • write overall equations for the formation of hydroxynitriles using HCN\n • outline the nucleophilic addition mechanism for the reaction with KCN followed by dilute acid\n • explain why nucleophilic addition reactions of KCN, followed by dilute acid, can produce a mixture of enantiomers",
						},
					},
				},
			},
			3.9: {
				"sub-topic": "Carboxylic acids and derivatives (A-level only)",
				spec: {
					3.91: {
						"sub-topic": "Carboxylic acids and esters (A-level only)",
						spec: {
							3.911: "The structures of:\n • carboxylic acids\n • esters. ",
							3.912:
								"Carboxylic acids are weak acids but will liberate CO2 from carbonates. ",
							3.913:
								"Carboxylic acids and alcohols react, in the presence of an acid catalyst, to give esters. ",
							3.914:
								"Common uses of esters (eg in solvents, plasticisers, perfumes and food flavourings). ",
							3.915:
								"Vegetable oils and animal fats are esters of propane-1,2,3-triol (glycerol). ",
							3.916:
								"Esters can be hydrolysed in acid or alkaline conditions to form alcohols and carboxylic acids or salts of carboxylic acids. ",
							3.917:
								"Vegetable oils and animal fats can be hydrolysed in alkaline conditions to give soap (salts of long-chain carboxylic acids) and glycerol. ",
							3.918:
								"Biodiesel is a mixture of methyl esters of long-chain carboxylic acids.",
							3.919:
								"Biodiesel is produced by reacting vegetable oils with methanol in the presence of a catalyst.",
						},
					},
					3.92: {
						"sub-topic": "Acylation (A-level only)",
						spec: {
							3.921:
								"The structures of:\n • acid anhydrides\n • acyl chlorides\n • amides. ",
							3.922:
								"The nucleophilic addition–elimination reactions of water, alcohols, ammonia and primary amines with acyl chlorides and acid anhydrides. ",
							3.923:
								"The industrial advantages of ethanoic anhydride over ethanoyl chloride in the manufacture of the drug aspirin. ",
							3.924:
								"Students should be able to outline the mechanism of nucleophilic addition–elimination reactions of acyl chlorides with water, alcohols, ammonia and primary amines.",
						},
					},
				},
			},
			3.1: {
				"sub-topic": "Aromatic chemistry (A-level only)",
				spec: {
					3.101: {
						"sub-topic": "Bonding (A-level only)",
						spec: {
							3.1011:
								"The nature of the bonding in a benzene ring, limited to planar structure and bond length intermediate between single and double. ",
							3.1012:
								"Delocalisation of p electrons makes benzene more stable than the theoretical molecule cyclohexa-1,3,5-triene. ",
							3.1013:
								"Students should be able to:\n • use thermochemical evidence from enthalpies of hydrogenation to account for this extra stability\n • explain why substitution reactions occur in preference to addition reactions.",
						},
					},
					3.102: {
						"sub-topic": "Electrophilic substitution (A-level only)",
						spec: {
							3.1021:
								"Electrophilic attack on benzene rings results in substitution, limited to monosubstitutions. ",
							3.1022:
								"Nitration is an important step in synthesis, including the manufacture of explosives and formation of amines. ",
							3.1023:
								"Friedel–Crafts acylation reactions are also important steps in synthesis.",
							3.1024:
								"Students should be able to outline the electrophilic substitution mechanisms of:\n • nitration, including the generation of the nitronium ion\n • acylation using AlCl3 as a catalyst.",
						},
					},
				},
			},
			3.11: {
				"sub-topic": "Amines (A-level only)",
				spec: {
					3.111: {
						"sub-topic": "Preparation (A-level only)",
						spec: {
							3.1111:
								"Primary aliphatic amines can be prepared by the reaction of ammonia with halogenoalkanes and by the reduction of nitriles. ",
							3.1112:
								"Aromatic amines, prepared by the reduction of nitro compounds, are used in the manufacture of dyes. ",
						},
					},
					3.112: {
						"sub-topic": "Base properties (A-level only)",
						spec: {
							3.1121: "Amines are weak bases.",
							3.1122:
								"The difference in base strength between ammonia, primary aliphatic and primary aromatic amines. ",
							3.1123:
								"Students should be able to:\n • explain the difference in base strength in terms of the availability of the lone pair of electrons on the N atom.",
						},
					},
					3.113: {
						"sub-topic": "Nucleophilic properties (A-level only)",
						spec: {
							3.1131: "Amines are nucleophiles. ",
							3.1132:
								"The nucleophilic substitution reactions of ammonia and amines with halogenoalkanes to form primary, secondary, tertiary amines and quaternary ammonium salts.",
							3.1133:
								"The use of quaternary ammonium salts as cationic surfactants. ",
							3.1134:
								"The nucleophilic addition–elimination reactions of ammonia and primary amines with acyl chlorides and acid anhydrides.",
							3.1135:
								"Students should be able to outline the mechanisms of:\n • these nucleophilic substitution reactions\n • the nucleophilic addition–elimination reactions of ammonia and primary amines with acyl chlorides.",
						},
					},
				},
			},
			3.12: {
				"sub-topic": "Polymers (A-level only)",
				spec: {
					3.121: {
						"sub-topic": "Condensation polymers (A-level only)",
						spec: {
							3.1211:
								"Condensation polymers are formed by reactions between:\n • dicarboxylic acids and diols\n • dicarboxylic acids and diamines\n • amino acids. ",
							3.1212:
								"The repeating units in polyesters (eg Terylene) and polyamides (eg nylon 6,6 and Kevlar) and the linkages between these repeating units. ",
							3.1213: "Typical uses of these polymers. ",
							3.1214:
								"Students should be able to:\n • draw the repeating unit from monomer structure(s)\n • draw the repeating unit from a section of the polymer chain\n • draw the structure(s) of the monomer(s) from a section of the polymer\n • explain the nature of the intermolecular forces between molecules of condensation polymers.",
						},
					},
					3.122: {
						"sub-topic":
							"Biodegradability and disposal of polymers (A-level only)",
						spec: {
							3.1221:
								"Polyalkenes are chemically inert and non-biodegradable. ",
							3.1222:
								"Polyesters and polyamides can be broken down by hydrolysis and are biodegradable. ",
							3.1223:
								"The advantages and disadvantages of different methods of disposal of polymers, including recycling. ",
							3.1224:
								"Students should be able to:\n • explain why polyesters and polyamides can be hydrolysed but polyalkenes cannot.",
						},
					},
				},
			},
			3.13: {
				"sub-topic": "Amino acids, proteins and DNA (A-level only)",
				spec: {
					3.131: {
						"sub-topic": "Amino acids (A-level only)",
						spec: {
							3.1311:
								"Amino acids have both acidic and basic properties, including the formation of zwitterions. ",
							3.1312:
								"Students should be able to draw the structures of amino acids as zwitterions and the ions formed from amino acids:\n • in acid solution\n • in alkaline solution.",
						},
					},
					3.132: {
						"sub-topic": "Proteins (A-level only)",
						spec: {
							3.1321:
								"Proteins are sequences of amino acids joined by peptide links. ",
							3.1322:
								"The importance of hydrogen bonding and sulfur–sulfur bonds in proteins. ",
							3.1323:
								"The primary, secondary (α-helix and β–pleated sheets) and tertiary structure of proteins. ",
							3.1324:
								"Hydrolysis of the peptide link produces the constituent amino acids. ",
							3.1325:
								"Amino acids can be separated and identified by thin-layer chromatography. ",
							3.1326:
								"Amino acids can be located on a chromatogram using developing agents such as ninhydrin or ultraviolet light and identified by their Rf values.",
							3.1327:
								"Students should be able to:\n • draw the structure of a peptide formed from up to three amino acids\n • draw the structure of the amino acids formed by hydrolysis of a peptide\n • identify primary, secondary and tertiary structures in diagrams\n • explain how these structures are maintained by hydrogen bonding and S–S bonds\n • calculate Rf values from a chromatogram",
						},
					},
					3.133: {
						"sub-topic": "Enzymes (A-level only)",
						spec: {
							3.1331: "Enzymes are proteins. ",
							3.1332:
								"The action of enzymes as catalysts, including the concept of a stereospecific active site that binds to a substrate molecule. ",
							3.1333:
								"The principle of a drug acting as an enzyme inhibitor by blocking the active site. ",
							3.1334: "Computers can be used to help design such drugs. ",
							3.1335:
								"Students should be able to:\n • explain why a stereospecific active site can only bond to one enantiomeric form of a substrate or drug.",
						},
					},
					3.134: {
						"sub-topic": "DNA (A-level only)",
						spec: {
							3.1341:
								"The structures of the phosphate ion, 2-deoxyribose (a pentose sugar) and the four bases adenine, cytosine, guanine and thymine are given in the Chemistry Data Booklet. ",
							3.1342:
								"A nucleotide is made up from a phosphate ion bonded to 2-deoxyribose which is in turn bonded to one of the four bases adenine, cytosine, guanine and thymine. ",
							3.1343:
								"A single strand of DNA (deoxyribonucleic acid) is a polymer of nucleotides linked by covalent bonds between the phosphate group of one nucleotide and the 2- deoxyribose of another nucleotide.This results in a sugar-phosphate-sugar-phosphate polymer chain with bases attached to the sugars in the chain.  ",
							3.1344:
								"DNA exists as two complementary strands arranged in the form of a double helix. ",
							3.1345:
								"Students should be able to:\n • explain how hydrogen bonding between base pairs leads to the two complementary strands of DNA.",
						},
					},
					3.135: {
						"sub-topic": "Action of anticancer drugs (A-level only)",
						spec: {
							3.1351:
								"The Pt(II) complex cisplatin is used as an anticancer drug. ",
							3.1352:
								"Cisplatin prevents DNA replication in cancer cells by a ligand replacement reaction with DNA in which a bond is formed between platinum and a nitrogen atom on guanine. ",
							3.1353:
								"Appreciate that society needs to assess the balance between the benefits and the adverse effects of drugs, such as the anticancer drug cisplatin. ",
							3.1354:
								"Students should be able to:\n • explain why cisplatin prevents DNA replication\n • explain why such drugs can have adverse effects.",
						},
					},
				},
			},
			3.14: {
				"sub-topic": "Organic synthesis (A-level only)",
				spec: {
					3.141: {
						"sub-topic": "Organic synthesis (A-level only)",
						spec: {
							3.1411:
								"The synthesis of an organic compound can involve several steps. ",
							3.1412:
								"Students should be able to:\n • explain why chemists aim to design processes that do not require a solvent and that use non-hazardous starting materials\n • explain why chemists aim to design production methods with fewer steps that have a high percentage atom economy\n • use reactions in this specification to devise a synthesis, with up to four steps, for an organic compound.",
						},
					},
				},
			},
			3.15: {
				"sub-topic": "Nuclear magnetic resonance spectroscopy (A-level only)",
				spec: {
					3.151: {
						"sub-topic":
							"Nuclear magnetic resonance spectroscopy (A-level only)",
						spec: {
							3.1511:
								"Appreciation that scientists have developed a range of analytical techniques which together enable the structures of new compounds to be confirmed. ",
							3.1512:
								"Nuclear magnetic resonance (NMR) gives information about the position of 13C or 1H atoms in a molecule. ",
							3.1513: "13C NMR gives simpler spectra than 1H NMR. ",
							3.1514: "The use of the δ scale for recording chemical shift. ",
							3.1515: "Chemical shift depends on the molecular environment. ",
							3.1516:
								"Integrated spectra indicate the relative numbers of 1H atoms in different environments. ",
							3.1517:
								"1H NMR spectra are obtained using samples dissolved in deuterated solvents or CCl4",
							3.1518: "The use of tetramethylsilane (TMS) as a standard. ",
							3.1519:
								"Students should be able to:\n • explain why TMS is a suitable substance to use as a standard\n • use 1H NMR and 13C NMR spectra and chemical shift data from the Chemistry Data Booklet to suggest possible structures or part structures for molecules\n • use integration data from 1H NMR spectra to determine the relative numbers of equivalent protons in the molecule\n • use the n+1 rule to deduce the spin–spin splitting patterns of adjacent, non-equivalent protons, limited to doublet, triplet and quartet formation in aliphatic compounds.",
						},
					},
				},
			},
			3.16: {
				"sub-topic": "Chromatography (A-level only)",
				spec: {
					3.161: {
						"sub-topic": "Chromatography (A-level only)",
						spec: {
							3.161:
								"Chromatography can be used to separate and identify the components in a mixture.",
							3.162:
								"Types of chromatography include:\n • thin-layer chromatography (TLC) – a plate is coated with a solid and a solvent moves up the plate\n • column chromatography (CC) – a column is packed with a solid and a solvent moves down the column\n • gas chromatography (GC) – a column is packed with a solid or with a solid coated by a liquid, and a gas is passed through the column under pressure at high temperature. ",
							3.163:
								" Separation depends on the balance between solubility in the moving phase and retention by the stationary phase. ",
							3.164:
								"Retention times and Rf values are used to identify different substances. ",
							3.165:
								"The use of mass spectrometry to analyse the components separated by GC. ",
							3.166:
								"Students should be able to:\n • calculate Rf values from a chromatogram\n • compare retention times and Rf values with standards to identify different substances.",
						},
					},
				},
			},
		},
	},
};
